import React from 'react';
import { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import { API } from 'aws-amplify';
import Pagination from '@material-ui/lab/Pagination';
import TextField from '@material-ui/core/TextField';
import moment from 'moment-timezone';
import Button from '@material-ui/core/Button';
import csvtojson from 'csvtojson';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import '../../../assets/css/main.css';
import iconUpload from '../../../assets/images/upload.svg'
import iconDownload from '../../../assets/images/download.svg'
import noMovements from '../../../assets/images/no_movements.svg'
import engine from '../../../assets/images/engine.svg'
import DeafultSearch from '../../common/deafultSearch'
import CheckValue from "../../../lib/formatValidations";
import Modal from '@material-ui/core/Modal';
import AyudaModal from './helpUpload';
import {FONT_DEFAULT} from '../../../lib/constants.js'
const axios = require('axios');

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  };
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flex: 1,
    backgroundColor: '#F6F6F6',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  container: {
    flex: 8,
    marginLeft: 30,
    marginRight: 40,
    marginBottom: 40,
  },
  titleConteiner: {
    padding: 10,
    marginTop: 20,
    marginBottom: 30,
    flexDirection: 'row'
  },
  transactionsConteiner: {
    backgroundColor: '#FFFFFF',
    padding: '40px 50px 50px 40px',
    borderRadius: 5,
    border: '1px solid #E0E0E0'
  },
  tableContainer: {
    display: 'flex',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: '#F9F9F9',
    borderRadius: 5,
    flexDirection: "row"
  },
  buttonContainer: {
    display: 'flex',
    marginBottom: 15,
  },
  tableGridContainer: {
    width: 'calc(100vw - 87px)',
    width: '100%',
    marginLeft: 0,
    marginRight: 0,
    marginTop: 30,
    marginBottom: 20,

  },
  actionButonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',

  },
  actionButon1: {
    cursor: 'pointer',
    marginRight: 10
  },
  actionButon2: {
    cursor: 'pointer',
    marginLeft: 10
  },
  tableHeaderCenter: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    color: '#434343',
    borderTop: '1px solid #BDBDBD',
    borderBottom: '1px solid #BDBDBD',
    height: 60,
    justifyContent: 'center',
    paddingLeft: 3,
    paddingRight: 3
  },
  tableHeaderText: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    color: '#434343',
    borderTop: '1px solid #BDBDBD',
    borderBottom: '1px solid #BDBDBD',
    height: 60,
    justifyContent: 'flex-start',
    paddingLeft: 3,
    paddingRight: 3
  },
  tableHeaderNumber: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid #BDBDBD',
    borderBottom: '1px solid #BDBDBD',
    height: 60,
    justifyContent: 'flex-end',
    paddingLeft: 3,
    paddingRight: 3
  },
  tableRowCenter: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 12,
    paddingTop: 15,
    paddingBottom: 15,
    display: 'flex',
    alignItems: 'center',
    height: 10,
    textAlign: 'center',
    justifyContent: 'center',
    paddingLeft: 3,
    paddingRight: 3
  },
  tableRowText: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 12,
    paddingTop: 15,
    paddingBottom: 15,
    display: 'flex',
    alignItems: 'center',
    height: 10,
    textAlign: 'center',
    justifyContent: 'flex-start',
    paddingLeft: 3,
    paddingRight: 3
  },
  tableRowNumber: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: '600l',
    fontSize: 12,
    paddingTop: 15,
    paddingBottom: 15,
    display: 'flex',
    alignItems: 'center',
    height: 10,
    textAlign: 'center',
    justifyContent: 'flex-end',
    paddingLeft: 3,
    paddingRight: 3
  },
  buttonStyle: {
    border: '1px solid #3B9A83',
    boxSizing: 'border-box',
    borderRadius: 5,
    width: 146,
    height: 38,
    margin: 2
  },
  buttonText: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 10,
    color: '#3B9A83'
  },
  plusIcon: {
    marginRight: 5
  },
  paper: {
    position: 'absolute',
    width: 497,
    height: 'auto',
    backgroundColor: '#F9F9F9',
    boxShadow: '0 4 14 rgba(0, 0, 0, 0.15)',
    borderRadius: 5,
    textAlign: 'center'
  },
  mainContainerRegister: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: 5
  },
  modalButtons: {
    marginBottom: 20,
    marginTop: 20
  },
  backgroundOddColumn: {
    backgroundColor: '#F9F9F9'
  },
  totalsLabel: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    textAlign: 'center',
    color: 'rgba(67, 67, 67, 0.6)',
    textAlign: 'left'
  },
  totalsValue: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center',
    textAlign: 'left'
  },
  totalsValueAlt: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 12,
    textAlign: 'center',
    marginLeft: 5
  },
  defaultButton: {
    border: '1px solid #3B9A83',
    boxSizing: 'border-box',
    borderRadius: 5,
    width: 204,
    height: 46,
    marginLeft: 6
  },
  settingsButtonStyle: {
    border: '1px solid #3B9A83',
    boxSizing: 'border-box',
    borderRadius: 5,
    width: 161,
    height: 46,
    marginLeft: 6
  },
  successButtonText: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 10,
    color: '#3B9A83'
  },
  backgrounOddColumn: {
    backgroundColor: '#F6F6F6',
    padding: 5
  },
  backgrounColumn: {
    backgroundColor: '#FFFF',
    padding: 5
  },
  formDate: {
    marginLeft: 5,
    marginRight: 5
  },
  depositButtonText: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 10,
    color: '#3B9A83',
    marginLeft: 20
  },
  margin20: {
    marginBottom: 20,
    border: '1px solid #BDBDBD',
    borderRadius: 5,
    padding: 20
  },
  title: {
    ...FONT_DEFAULT,
    marginTop: 10,
    marginBottom: 0,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 24,
    color: '#434343'
  },
  statusSending: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    color: '#6FCF97'
  },
  statusPending: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 12,
    color: '#EFAB23'
  },
  statusSending: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 12,
    color: '#77BEE0'
  },
  statusRefund: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 12,
    color: '#DA7B7B'
  },
  statusCompleted: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 12,
    color: '#6FCF97'
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 50,
    marginbottom: 80
  },
  errorText: {
    ...FONT_DEFAULT,
    fontSize: 16,
    fontWeight: 700,
    marginTop: 20
  },
  errorText2: {
    ...FONT_DEFAULT,
    fontSize: 14,
    fontWeight: 600,
    opacity: '0.6'
  },
  paginationTAB: {
    widht: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  titleFlex: {
    display: 'flex'
  },
  paper: {
    position: 'absolute',
    width: 440,
    height: 'auto',
    backgroundColor: '#FAFAFA',
    boxShadow: '0 4 14 rgba(0, 0, 0, 0.15)',
    borderRadius: 5,
    textAlign: 'center',
    paddingTop: 50
  },
  buttonWithCaption: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center'
  },
  captionForButton: {
    marginTop: '5px',
    paddingLeft: '10px',
    fontSize: 'xx-small',
    color: 'gray',
  }
});

const initialFromDate = `${moment().format('YYYY-MM-DD')}T00:00`
const initialToDate = `${moment().format('YYYY-MM-DD')}T23:59`
const perPage = 10

function Spei(props) {
  useEffect(() => {
    callApi(initialFromDate, initialToDate)
  }, [])

  const classes = useStyles();

  const [modalStyle] = React.useState(getModalStyle);
  const [isLoading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [transactions, setTransactions] = React.useState([]);
  const [searchedWord, setSearchedWord] = React.useState('');
  const [fromDate, setFromDate] = React.useState(initialFromDate);
  const [toDate, setToDate] = React.useState(initialToDate);
  const [selectedPage, setSelectedPage] = React.useState(1);
  const [pages, setPages] = React.useState(0);
  const [RFC, setRFC] = React.useState('');
  const [sourceAccount, setSourceAccount] = React.useState('');
  const [commission, setCommission] = React.useState(0);
  const [description, setDescription] = React.useState('');
  const [settings, setSettings] = React.useState(null);
  const [lastACProcessed, setLastACProcessed] = React.useState(0);
  const [lastSpeiProcessed, setLastSpeiProcessed] = React.useState(0);
  const [ayudaUpload, setAyudaUpload] = React.useState(false);
  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const getTransactions = async (from, to) => {
    try {
      const getTransactions = await API.get('speis-api', '/speis', {
        queryStringParameters: {
          since: from,
          to: to,
        },
      })
      return getTransactions
    } catch (err) {
      handleAlert('error', err.message)
      return null
    }
  }

  const getSettings = async () => {
    try {
      const settingsData = await API.get('setting-api', '/settings/default_spei_config')
      console.info(settingsData);
      if (settingsData.data && settingsData.data.length > 0 && settingsData.data[0].setup) {
        setRFC(settingsData.data[0].setup.RFC)
        setSourceAccount(settingsData.data[0].setup.sourceAccount)
        setCommission(settingsData.data[0].setup.commission)
        setDescription(settingsData.data[0].setup.description)
        setLastACProcessed(settingsData.data[0].setup.lastACProcessed || 0)
        setLastSpeiProcessed(settingsData.data[0].setup.lastSpeiProcessed || 0)
        setSettings(settingsData)
      }
    } catch (err) {
      handleAlert('error', err.message)
    }
  }
  const getSpeiFiles = () => {
    API.get('speis-api', '/speis/batch', {
      queryStringParameters: {
        AC: lastACProcessed,
        SPEI: lastSpeiProcessed,
      },
    }).then(result => {
      if (result) {
        console.info(result);
        const i = setInterval(downloadFiles, 1000, [result.ACAR, result.SPEI]);
        setLocalInterval(i);
        getSettings();
      }
    }).catch(error => {
      handleAlert('error', 'Error obteniendo archivos de transacciones SPEI');
      console.error(error);
    })

  }
  const CargarErrores = async event => {
    const [file] = event.target.files;
    const fileNameRX = /(ERRORES|EXITO)-(AC|SPEI)-\d+\.txt/ig;
    
    if (fileNameRX.test(file.name)) {
      const lote = file.name.replace(/\w+-(\w+-\d+\.txt)/ig, '$1');

      if (file.size === 0 && /EXITO/ig.test(file.name)) {
        handleAlert('info', `El 100% de las transacciones del ${lote} serán procesadas.`);
      } else {
        handleAlert('info', `Se procesarán las transacciones del ${lote} como errores y el resto como ENVIADAS.`);
      }
      const uploadUrl = await API.get('speis-api', '/speis/results', {
        queryStringParameters: {
          file: file.name,
        },
      });
      console.info(uploadUrl);
      if (uploadUrl.data.url) {
        await axios.put(uploadUrl.data.url, file, { headers: { 'Content-Type': 'text/plain' } });
      } else {
        handleAlert("error", "No se logró subir el documento");
      }

    } else {
      handleAlert('warning', 'El nombre del archivo de errores debe ser Errores-AC-#.txt o Errores-SPEI-#.txt')
    }
  }
  const [localInterval, setLocalInterval] = React.useState(null);
  const downloadFiles = (urls) => {
    try {
      if (urls.length === 0) {
        clearInterval(localInterval);
        setLocalInterval(null);
        return;
      }

      const url = urls.pop();
      console.log('downloading', url);
      let _a = document.createElement('a');
      document.body.appendChild(_a);
      _a.setAttribute('href', url);
      _a.setAttribute('download', url.split('?')[0].split('/').pop());
      _a.click();
      document.body.removeChild(_a);
    }
    catch (error) {
      clearInterval(localInterval);
      console.error(error);
    }
  }
  const getUsers = (userIds) => {
    return userIds.map(async userId => {
      const getUser = await API.get('profile-api', `/profile/${userId}`);
      return getUser.data[0]
    })
  }

  const callApi = async (from, to) => {
    try {
      const timeStampFrom = moment.utc(from).valueOf()
      const timeStampTo = moment.utc(to).valueOf()
      setLoading(true)
      if (settings === null) {
        getSettings()
      }
      if (timeStampFrom < timeStampTo) {
        const getTrans = getTransactions(timeStampFrom, timeStampTo)
        const trans = await Promise.resolve(getTrans)
        if (trans && trans.data && trans.data.length > 0) {
          const getUsersItems = getUsers(trans.data.map(item => item.userRecordKey))
          const users = await Promise.all(getUsersItems)
          const transactionItems = trans.data.map((value, index) => ({
            ...value,
            user: users[index].phoneNumber
          }))
          let pagenNumber = 1
          if (transactionItems.length > 10) {
            pagenNumber = Math.ceil(transactionItems.length / perPage)
          }
          setPages(pagenNumber)
          setTransactions(transactionItems)
          setLoading(false)
        } else {
          setLoading(false)
        }
      } else {
        handleAlert('error', 'La fecha inicio debe ser menor a la fecha de fin')
      }
    } catch (err) {
      handleAlert('error', err.message)
      setLoading(false)
    }
  };

  const handleChange = (event) => {
    const { value, name } = event.target
    switch (name) {
      case 'fromDate':
        setFromDate(value)
        callApi(value, toDate)
        break;
      case 'toDate':
        setToDate(value)
        callApi(fromDate, value)
        break;
      case 'searchedWord':
        setSearchedWord(value)
        break;
      default:
        handleAlert('error', `Input no válido ${name}`)
    }
  }

  const handlePagination = (event, value) => {
    setSelectedPage(value)
  }

  const setMoneyFormat = (number) => {
    if (number != 'N/A') {
      return '$ ' + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return number
    }
  }

  const AttachmentFile = () => {
    const inputRef = useRef(null);
    return (
      <div className={classes.buttonWithCaption}>
        <input
          type="file"
          ref={inputRef}
          hidden
          accept=".txt"
          onChange={CargarErrores}
        />
        <Button
          className={classes.defaultButton}
          onClick={event => {
            event.preventDefault();
            inputRef.current.click();
          }}
        >
          <img width="18" height="20.88" src={iconUpload} alt="" />
          <span className={classes.depositButtonText}>CARGAR COMPROBANTES</span>
        </Button>
        <a href="#" onClick={(event) => {event.preventDefault(); abrirAyuda();}} className={classes.captionForButton}>Ayuda</a>
      </div>
    );
  };
  const abrirAyuda = () => {
    setAyudaUpload(!ayudaUpload);
  }
  const buildstatus = (status) => {
    if (status) {
      switch (status) {
        case 'PENDIENTE':
          return <Typography className={classes.statusPending}><ul><li>PENDIENTE</li></ul></Typography>
        case 'ENVIADO':
          return <Typography className={classes.statusSending}><ul><li>ENVIADO</li></ul></Typography>
        case 'REEMBOLSADO':
          return <Typography className={classes.statusRefund}><ul><li>REEMBOLSADO</li></ul></Typography>
        case 'COMPLETADO':
          return <Typography className={classes.statusCompleted}><ul><li>COMPLETADO</li></ul></Typography>
        default:
          return <Typography><ul><li>{status}</li></ul></Typography>
      }
    } else {
      return null
    }
  }

  const handleOpen = async (merchant, isMerchant) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const saveSettings = async () => {
    let hasErros = false;
    if (!CheckValue("empty", RFC) || !CheckValue("rfc_moral", RFC)) {
      hasErros = true;
      handleAlert("error", 'Por favor ingrese un RFC válido.');
    }
    if (!CheckValue("empty", sourceAccount) || !CheckValue("integer", sourceAccount)) {
      hasErros = true;
      handleAlert("error", 'La cuenta solo debe contener valores numéricos.');
    }
    if (commission <= 0) {
      hasErros = true;
      handleAlert("error", 'La comisión debe ser mayor a 0.');
    }
    if (description.length > 30) {
      hasErros = true;
      handleAlert("error", 'La descripción debe ser menos de 30 caracteres');
    }
    if (!hasErros) {
      setLoading(true)
      try {
        let setup = {
          RFC: RFC,
          sourceAccount: sourceAccount,
          commission: commission,
          description: description
        }
        await API.put('setting-api', `/settings/default_spei_config`, {
          body: {
            setup
          },
        });
        handleClose()
        handleAlert('success', 'Actualizado con Éxito!')
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log('ERROR', error);
        handleAlert('error', error.message)
      }
    }
  }

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className={classes.mainContainerRegister}>
        <div className="SubContainer">
          <Typography className={classes.title}>
            Configuraciones
            </Typography>
          <FormControl className="inputForm col-12 col-sm-12 col-lg-12 col-xl-12">
            <InputLabel className="labelInputStyle">RFC</InputLabel>
            <Input
              id="RFC"
              type="text"
              name="RFC"
              className="defaultInputText"
              value={RFC}
              onChange={(event) => setRFC(event.target.value.toUpperCase())}
            />
          </FormControl>
          <FormControl className="inputForm col-12 col-sm-12 col-lg-12 col-xl-12">
            <InputLabel className="labelInputStyle">Cuenta Origen</InputLabel>
            <Input
              id="sourceAccount"
              type="text"
              name="sourceAccount"
              className="defaultInputText"
              value={sourceAccount}
              onChange={(event) => setSourceAccount(event.target.value)}
            />
          </FormControl>
          <FormControl className="inputForm col-12 col-sm-12 col-lg-12 col-xl-12">
            <InputLabel className="labelInputStyle">Comisión</InputLabel>
            <Input
              id="commission"
              type="number"
              name="commission"
              className="defaultInputText"
              value={commission}
              onChange={(event) => setCommission(event.target.value)}
            />
          </FormControl>
          <FormControl className="inputForm col-12 col-sm-12 col-lg-12 col-xl-12">
            <InputLabel className="labelInputStyle">Descripción</InputLabel>
            <Input
              id="description"
              type="text"
              name="description"
              className="defaultInputText"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
          </FormControl>
          <div className={classes.modalButtons}>
            {!isLoading ?
              <>
                <Button className={classes.buttonStyle} onClick={saveSettings}>
                  <Typography className={classes.buttonText} >GUARDAR</Typography>
                </Button>
              </>
              :
              <>
                <Button className={classes.buttonStyle} disabled={true}>
                  <Typography className={classes.buttonText}>GUARDANDO...</Typography>
                </Button>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      {isLoading ?
        <div className='gralinfo_form'>
          <CircularProgress color="secondary" />
        </div>
        :
        <div className={classes.container}>
          <div className={classes.titleConteiner}>
            <div className="row col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                <Typography className={classes.title}>Transacciones con SPEI</Typography>
              </div>
              <div className={`${classes.titleFlex} col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 justify-content-end`}>
                <div className={classes.buttonWithCaption}>
                  <Button className={classes.defaultButton}
                    onClick={event => { getSpeiFiles(); }}>
                    <img width="18" height="20.88" src={iconDownload} alt="" />
                    <span className={classes.depositButtonText}>Descargar Movimientos</span>
                  </Button>
                  <span className={classes.captionForButton}>Último SPEI procesado: {moment(lastSpeiProcessed).format('DD/MM/YYYY h:mm:ss a')}</span>
                  
                </div>
                <AttachmentFile />
                <Button
                  className={classes.settingsButtonStyle}
                  onClick={handleOpen}
                >
                  <img width="22" height="22" src={engine} alt="" />
                  <span className={classes.depositButtonText}>CONFIGURACIONES</span>
                </Button>

              </div>
            </div>
          </div>
          <div className={classes.transactionsConteiner}>
            <div className="row">
              <div className="col-lg-8 col-sm-12">
                <div className={`row ${classes.margin20}`}>
                  <form className={classes.formDate} noValidate>
                    <TextField
                      id="datetime-from"
                      name="fromDate"
                      value={fromDate}
                      onChange={handleChange}
                      label="Desde"
                      type="datetime-local"
                      defaultValue="2017-05-24T10:30"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </form>
                  <form className={classes.formDate} noValidate>
                    <TextField
                      id="datetime-to"
                      name="toDate"
                      value={toDate}
                      onChange={handleChange}
                      label="Hasta"
                      type="datetime-local"
                      defaultValue="2017-05-24T10:30"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </form>
                </div>
              </div>
              <div className={`${classes.titleFlex} col-lg-4 col-sm-12 justify-content-end`}>
                <DeafultSearch
                  className="outlinedSearchSmall"
                  label="Buscar por usuario, orden"
                  name="searchedWord"
                  value={searchedWord}
                  handleChange={handleChange}
                />
              </div>
            </div>
            {transactions.length > 0 ? <div className="mt-3 overflow-auto">
              <table className="table">
                <thead>
                  <tr>
                    <th>
                      <Typography className={classes.tableHeaderText}>Usuario</Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderNumber}>Monto</Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderNumber}>Comisión</Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderText}>CLABE</Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderText}>No. de Transacción</Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderCenter}>Fecha y Hora (Hora Centro)</Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderCenter}>Estado</Typography>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {transactions.slice(((selectedPage - 1) * perPage), (((selectedPage - 1) * perPage) + perPage)).map((transaction) => (
                    (searchedWord.length < 3 || JSON.stringify(transaction).toUpperCase().indexOf(searchedWord.toUpperCase()) !== -1) && <tr key={transaction.id}>
                      <td>
                        <Typography className={classes.tableRowText}>{transaction.user}</Typography>
                      </td>
                      <td className={classes.backgrounOddColumn}>
                        <Typography className={classes.tableRowNumber}>{setMoneyFormat(transaction.amount.toFixed(2))}</Typography>
                      </td>
                      <td>
                        <Typography className={classes.tableRowNumber}>{setMoneyFormat((transaction.subAmount - transaction.amount).toFixed(2))}</Typography>
                      </td>
                      <td className={classes.backgrounOddColumn}>
                        <Typography className={classes.tableRowText}>{transaction.clabe.includes('CLABE') ? transaction.clabe : 'DEBITO********'}</Typography>
                      </td>
                      <td>
                        <Typography className={classes.tableRowText}>{transaction.accountRecordKey}</Typography>
                      </td>
                      <td className={classes.backgrounOddColumn}>
                        <Typography className={classes.tableRowCenter}>{moment(transaction.createdAt).format('DD/MM/YYYY h:mm:ss a')}</Typography>
                      </td>
                      <td>
                        <Typography className={classes.tableRowCenter}>{buildstatus(transaction.status)}</Typography>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className={classes.paginationTAB}>
                <Pagination count={pages} shape="rounded" onChange={handlePagination} />
              </div>
            </div>
              :
              <div className={classes.errorContainer}>
                <img src={noMovements} alt=""></img>
                <Typography className={classes.errorText} >No se encontraron transacciones</Typography>
                <Typography className={classes.errorText2} >Por favor selecciona otro rango de fechas</Typography>
              </div>
            }
          </div>
        </div>
      }
      {ayudaUpload && <AyudaModal open={ayudaUpload} cancel={abrirAyuda} />}
    </div>
  );
}

export default withSnackbar(Spei)