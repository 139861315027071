function checkValue(validation, value) {
    switch (validation) {
        case "email":
            if (/^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
                return true
            } else {
                return false
            }
        case "phone":
            if (/^\+[0-9]{1,3}\d{10}$/.test(value.replace(/ /g, ""))) {
                return true
            } else {
                return false
            }
        case "zipcode":
            if (/^\d{5}$|^\d{5}-\d{4}$/.test(value)) {
                return true
            } else {
                return false
            }
        case "curp":
            if (/^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/.test(value)) {
                return true
            } else {
                return false
            }
        case "rfc":
            if (/^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/.test(value)) {
                return true
            } else {
                return false
            }
        case "rfc_moral":
            if (/^([A-Z,Ñ,&]{3}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/.test(value)) {
                return true
            } else {
                return false
            }
        case "website":
            if (/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/.test(value)) {
                return true
            } else {
                return false
            }
        case "hour":
            if (/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value)) {
                return true
            } else {
                return false
            }
        case "money":
            if (/^\d+(\.\d{2})?$/.test(value)) {
                return true
            } else {
                return false
            }
        case "empty":
            if (value !== undefined && value !== null && value.replace(/ /g, "") !== "") {
                return true
            } else {
                return false
            }
        case "integer":
            if (/^\d{1,18}$/.test(value)) {
                return true
            } else {
                return false
            }
        case "password":
            if (/^(?=.*[A-Za-z\d])(?=.*[!¡@#$%^&*(),.¿?":{}|<>_-])[A-Za-z\d!¡@#$%^&*(),.¿?":{}|<>_-]{6,}$/.test(value)) {
                return true
            } else {
                return false
            }
        case "verificationCode":
            if (/^\d{6}$/.test(value)) {
                return true
            } else {
                return false
            }
        case "hasConsecutiveRepeat":
            let lastChar = ''
            let countLastChar = 0
            let isConsecutiveRepeat = false
            for (var i = 0; i < value.length; i++) {
                if (lastChar === value.substring(i, i+1)) {
                    countLastChar++
                    if (countLastChar >= 2) {
                        isConsecutiveRepeat = true
                    }
                } else {
                    lastChar = value.substring(i, i+1)
                    countLastChar = 0
                }
            }
            return isConsecutiveRepeat
        case "hasConsecutive":
            let lastCode = 0
            let countLastCode = 0
            let lastDiference = 0
            let isConsecutive = false
            for (var i = 0; i < value.length; i++) {
                const diference = lastCode - value.charCodeAt(i)
                if ((diference === 1 || diference === -1) && (lastDiference === diference || lastDiference === 0)) {
                    lastDiference = diference
                    countLastCode++
                    if (countLastCode >= 2) {
                        isConsecutive = true
                    }
                } else {
                    lastDiference = 0
                    countLastCode = 0
                }
                lastCode = value.charCodeAt(i)
            }
            return isConsecutive
        default:
            return false;
    }
  }
  
  export default checkValue;
  