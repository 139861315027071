import React from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import '../../../assets/css/main.css';
import Tab1 from './tabs/tab1';
import Tab2 from './tabs/tab2';
import Tab3 from './tabs/tab3';
import Tab4 from './tabs/tab4';
import Tab5 from './tabs/tab5';
import { useSelector } from 'react-redux';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Typography>{children}</Typography>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    overflowX: 'hidden',
    padding: 10,
  },
  containerForm3: {
    backgroundColor: '#E0E0E0',
    position: 'relative',
    zIndex: 0,
    bottom: 22,
    height: 1,
    borderRadius: 5,
    flexDirection: 'row',
  },
  documentation: {
    padding: 0,
    borderRadius: 5,
    backgroundColor: 'white',
    border: '1px solid #E0E0E0',
  },
  infoTAB: {
    padding: 0,
    borderRadius: 5,
    backgroundColor: 'white',
    border: '1px solid #E0E0E0',
  },
});

const Fisica = (props) => {
  const settings = useSelector(state => state.settings.value);

  useEffect(() => {}, []);

  const classes = useStyles();
  const { service_contract, service_invoice_post_pay } = settings || {};

  const handleChange = (event, newValue) => {
    props.isCompleted && props.handleChangeTab(newValue);
  };

  return (
    <div className={classes.content}>
      <AppBar position='static' color='transparent' className={'profileTabs'}>
        <Tabs
          value={props.currentTab}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='primary'
          variant='scrollable'
          scrollButtons='auto'
          aria-label='scrollable auto tabs example'
        >
          <Tab label='Sobre ti' {...a11yProps(0)} />
          <Tab label='Sobre tu Negocio' {...a11yProps(1)} />
          <Tab label='Tus Direcciones' {...a11yProps(2)} />
          <Tab label='Tus Documentos' {...a11yProps(3)} />
          {service_contract && (
            <Tab label='Nuestro contrato' {...a11yProps(4)} />
          )}
        </Tabs>
        <div className={classes.containerForm3}></div>
      </AppBar>
      <div>
        <TabPanel
          className={classes.infoTAB}
          value={props.currentTab}
          index={0}
        >
          <Tab1
            merchantProfile={props.merchantProfile}
            ExecutingRequest={props.ExecutingRequest}
            handleCallRequest={props.handleCallRequest}
            setCompleted={props.setCompleted}
            isCompleted={props.isCompleted}
          />
        </TabPanel>
        <TabPanel
          className={classes.infoTAB}
          value={props.currentTab}
          index={1}
        >
          <Tab2
            merchantProfile={props.merchantProfile}
            ExecutingRequest={props.ExecutingRequest}
            handleCallRequest={props.handleCallRequest}
            setCompleted={props.setCompleted}
            isCompleted={props.isCompleted}
            serviceInvoicePostPay={service_invoice_post_pay}
          />
        </TabPanel>
        <TabPanel
          className={classes.infoTAB}
          value={props.currentTab}
          index={2}
        >
          <Tab3
            merchantProfile={props.merchantProfile}
            ExecutingRequest={props.ExecutingRequest}
            handleCallRequest={props.handleCallRequest}
            setCompleted={props.setCompleted}
            isCompleted={props.isCompleted}
          />
        </TabPanel>
        <TabPanel
          className={classes.infoTAB}
          value={props.currentTab}
          index={3}
        >
          <Tab4
            className={classes.infoTAB}
            merchantProfile={props.merchantProfile}
            ExecutingRequest={props.ExecutingRequest}
            handleCallRequest={props.handleCallRequest}
            isCompleted={props.isCompleted}
            setCompleted={props.setCompleted}
          />
        </TabPanel>
        {service_contract && (
          <TabPanel
            className={classes.infoTAB}
            value={props.currentTab}
            index={4}
          >
            <Tab5
              merchantProfile={props.merchantProfile}
              ExecutingRequest={props.ExecutingRequest}
              handleCallRequest={props.handleCallRequest}
              setCompleted={props.setCompleted}
              isCompleted={props.isCompleted}
            />
          </TabPanel>
        )}
      </div>
    </div>
  );
};

export default Fisica;
