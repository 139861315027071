import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';

const useStyles = makeStyles(theme =>({
    cardComponent:{
        transition: 'height 20s ease !important',
        cursor: 'pointer',
        width: '100%',
        backgroundColor: '#FFFFFF',
        border: '1px solid #E0E0E0',
        marginTop: 10,
        padding: '18px 24px',
        height: '120px',
        '&:hover': {
            height: "auto",
         },
    },
    userType: {
        ...theme.typography.smallText,
        color: 'rgba(67, 67, 67, 0.3)',
        width: '100%'
    },
    clientName: {
        ...theme.typography.mediumText,
        width: '100%',
        paddingBottom: 20,
        maxWidth: '11rem',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    orderNumber: {
        ...theme.typography.samllText,
        color: 'rgba(67, 67, 67, 0.6)',
        flex: 1
    },
    rowContainer: {    
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
    },
    invoice: {
        ...theme.typography.samllText,
        backgroundColor: '#FAFAFA'
    },
    amount: {
        width: '45px'
    },
    tableMargin: {
        marginTop: '20px'
    },
    tablePadding:{
        padding: '20px',
        backgroundColor: '#FAFAFA'
    }
}));

export default function CardOrder(props) {
    const classes = useStyles();

    const getProducts = (items) => {
        const orderItems = items.map(item => (
            <tr>
                <td className={`${classes.amount} ${classes.tablePadding}`}>
                    <Typography className={classes.invoice}>{item.amount}</Typography>
                </td>
                <td  className={classes.tablePadding}>
                    <Typography className={classes.invoice}>{item.product.productName}</Typography>
                </td>
            </tr>
            
        ))
        return orderItems
    }

    const getTime = (created) => {
        const createdDate = moment(created);
        const today = moment.utc(moment.now());
        let diff = today.diff(createdDate, 'days');
        if (diff !== 0) {
            if (diff > 1) {
                return 'Hace ' + diff + ' días'
            } else {
                return 'Hace ' + diff + ' día'
            }
        }
        diff = today.diff(createdDate, 'hours');
        if (diff !== 0) {
            if (diff > 1) {
                return 'Hace ' + diff + ' horas'
            } else {
                return 'Hace ' + diff + ' hora'
            }
        }
        diff = today.diff(createdDate, 'minutes');
        if (diff !== 0) {
            if (diff > 1) {
                return 'Hace ' + diff + ' minutos'
            } else {
                return 'Hace ' + diff + ' minuto'
            }
        }
        return 'Justo ahora'
    }
 
    return (
        <Card className={classes.cardComponent}>
            <CardContent className={classes.cardContent}>
                <div className="row m-0">
                    <Typography className={`${classes.userType}`} gutterBottom>
                        cliente
                    </Typography>
                    <Typography className={`${classes.clientName}`} gutterBottom>
                        {props.clientName}
                    </Typography>
                    <div className={classes.rowContainer}>
                        <Typography className={`${classes.orderNumber}`} gutterBottom>
                            No.{props.orderNo}
                        </Typography>
                        <Typography className={`${classes.orderNumber} text-right`} gutterBottom>
                            {getTime(props.createdAt)}
                        </Typography>
                    </div>
                    <table className={`col-12 p-0 ${classes.tableMargin}`}>
                        {getProducts(props.invoices)}
                    </table>
                </div>
            </CardContent>
        </Card>
    );
}