import React from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { API, Auth, Storage } from 'aws-amplify';
import { useHistory } from "react-router-dom";
import { withSnackbar } from "notistack";
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from 'react-google-autocomplete';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import { StoresMap, getLocData, onDragEnd, createMapSnapshot } from "../../common/storesMap";
import '../../../assets/css/main.css'
import DefaultInput from '../../common/defaultInput';
import DefaultButton from '../../common/defaultButton';
import CheckValue from '../../../lib/formatValidations'
import CheckSchedule from '../../../lib/storeIsOpen'
import DefaultSchedule from '../../common/storeSchedule'
import * as R from 'ramda'
import { API_STORE } from '../../../lib/constants'
import defaultStoreIcon from '../../../assets/images/default_store_icon.svg';
import Tab1 from './tab1'
import Tab2 from './tab2/tab2'
import mdiEdit2 from '../../../assets/images/mdi_edit2.svg'
import Tab3 from './tab3/index'
import Tab4 from './tab4'
import Button from '@material-ui/core/Button';
import PrimaryButton from '../../common/primaryButton';
import SecondaryButton from '../../common/secondaryButton';
import DefaultModal from '../../modal';

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
    marginTop: 0,
    backgroundColor: '#FAFAFA',
    flexDirection: 'column',
    width: '100%',
    height: 'calc(100vh)'
  },
  container: {
    flex: 8,
    marginLeft: 30,
    marginRight: 30,
    marginBottom: 40,
  },
  headerConteiner: {
    backgroundColor: '#FAFAFA',
    width: '70.5%',
    padding: '11px 11px 11px 11px',
    borderRadius: 5,
    marginLeft: 10
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    ...theme.typography.title,
    display: 'flex',
    // alignItems: 'center',
    maxWidth: '50%',
    marginTop: '0px',
    opacity: '.85',

  },
  titlesContainer: {
    width: '50%',
  },
  containerForm: {
    backgroundColor: '#FAFAFA',
    borderRadius: 0,
    marginTop: 30
  },
  statusStyle1: {
    width: 100,
    height: 20,
    fontSize: 12,
    background: '#6EB89D',
    borderRadius: 50,
    alignItems: 'center',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 0,
    padding: 15
  },
  statusText: {
    ...theme.typography.smallText,
    fontWeight: '800',
    color: 'white'
  },
  statusStyle2: {
    width: 100,
    height: 20,
    borderRadius: 50,
    backgroundColor: '#DA7B7B',
    alignItems: 'center',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center'
  },
  titleSublabelFirst: {
    ...theme.typography.paragraph,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
    letterSpacing: .8
  },
  titleSublabel: {
    justifyContent: 'right',
    ...theme.typography.smallText,
    fontWeight: 500,
    fontSize: 12,
    alignItems: 'center',
    marginLeft: 5,
    opacity: '.4',
    marginTop: '4px',
    letterSpacing: .8
  },
  actionButonsContainer: {
    display: 'flex',
    backgroundColor: '#F0F0F0',
    padding: '14px',
    marginLeft: 20,
    borderRadius: 5,

  },
  actionButons: {
    cursor: 'pointer',
    marginRight: 0
  },
  paper: {
    position: 'absolute',
    height: 'auto',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 4 14 rgba(0, 0, 0, 0.15)',
    borderRadius: 5,
    textAlign: 'center'
  },
  column60: {
    width: '60%',
    float: 'left',
    height: '100%',
    padding: 10,
    margin: 0
  },
  column40: {
    width: '40%',
    float: 'left',
    height: '100%',
    padding: 10,
    margin: 0
  },
  inputMargin: {
    marginTop: 15
  },
  centerComponent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageStyle: {
    width: '100%'
  },
  containerModal: {
    marginLeft: 30,
    marginTop: 30,
    marginBottom: 30,
    marginRight: 30
  },
  containerFormModal: {
    display: 'flex',
    flex: 1,
    padding: 40,
  },
  containerForm2: {
    backgroundColor: '#E0E0E0',
    position: 'relative',
    zIndex: 0,
    height: 1,
    flexDirection: "row",

  },
  editLable: {
    ...theme.typography.smallText,
    cursor: 'pointer'
  },
  paddingLeft20: {
    paddingLeft: 20
  },
  movimientosTAB: {
    border: '1px solid #E0E0E0',
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    padding: '0px 10px 0px 10px'
  },
  inventarioTAB: {
    padding: '24px',
    border: '1px solid #E0E0E0',
    backgroundColor: '#FFFFFF',
    borderRadius: 5
  },
  pedidosTAB: {
    padding: '0px',
    backgroundColor: '#FAFAFA'
  },
  empleadosTAB: {
    border: '1px solid #E0E0E0',
    borderRadius: 5,
    backgroundColor: '#FFFFFF',
  },
  previewImage: {
    width: '100%',
    height: 'auto'
  },
  storeprofilepic: {
    borderRadius: '100%',
    marginLeft: 0,
    marginRight: 20,
    height: 70,
    width: 70,
    borderStyle: 'solid',
    borderColor: '#eddd64',
  },
  schedulesTitle: {
    ...theme.typography.extraSmallText,
    color: 'rgba(67, 67, 67, 0.6)',
    marginTop: 15
  },
  plusScheduleButton: {
    ...theme.typography.extraSmallText,
    color: '#6EB89D',
    cursor: 'pointer'
  },
  scheduleList: {
    maxHeight: 220
  },
  redText: {
    color: 'red',
    textAlign: 'right',
    fontSize: 16,
  },
  buttonStyle: {
    border: 0,
    margin: 15,
    backgroundColor: 'white',
  },
  activate: {
    width: 125,
    height: 45,
    fontSize: 12,
    borderColor: '#6EB89D',
    borderRadius: 5,
    alignItems: 'center',
    color: '#6EB89D',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 0,
    padding: 15,
    border: '1px solid #3B9A83',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  activateText: {
    fontSize: 12,
    fontWeight: '800',
    fontFamily: 'Oxanium',
    color: '#6EB89D',
    marginInline: 10,
  },
  deactivate: {
    width: 125,
    height: 45,
    fontSize: 12,
    borderColor: '#DA7B7B',
    borderRadius: 5,
    alignItems: 'center',
    color: '#DA7B7B',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 0,
    padding: 15,
    border: '1px solid #DA7B7B',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  deactivateText: {
    fontSize: 12,
    fontWeight: '800',
    fontFamily: 'Oxanium',
    color: '#DA7B7B',
    marginInline: 10,
  },
  rowStyle: {
    display: 'flex',
    flexDirection: 'row',
  },
  rowEnd: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  }
}));

function Details(props) {
  useEffect(() => {
    getStore()
  }, [])
  const classes = useStyles();
  let history = useHistory();

  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [isLoading, setLoading] = React.useState(true);
  const [currentTab, setTab] = React.useState(props.location.to || 0);
  const [store, setStore] = React.useState(null);
  const [currentStatus, setCurrentStatus] = React.useState('CERRADO');
  const [currentStartTime, setCurrentStartTime] = React.useState('00:00');
  const [currentEndTime, setCurrentEndTime] = React.useState('00:00');

  const [storeName, setStoreName] = React.useState('');
  const [fiscalAddress, setFiscalAddress] = React.useState('');
  const [telephoneNumber, setTelephoneNumber] = React.useState('');
  const [schedules, setSchedules] = React.useState([{ days: [], start: '', end: '' }]);
  const [moreSchedules, setMoreSchedules] = React.useState(true);
  const [city, setCity] = React.useState('');
  const [stateLoc, setStateLoc] = React.useState('');
  const [country, setCountry] = React.useState('');
  const [hasAvatar, setHasAvatar] = React.useState(false);
  const [published, setPublished] = React.useState(false);

  const defaultLoc = { lat: 29.0892, lng: -110.96129 }; //hermosillo
  const [mapPosition, setMapPosition] = React.useState(defaultLoc);
  const [markerPosition, setMarkerPosition] = React.useState(defaultLoc);
  const [mapSnapshot, setSnapshot] = React.useState(null);
  const [file, setFile] = React.useState({ previewImage: '', data: '' });

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const updateStore = async (published) => {
      setLoading(true)
      const getUser = await Auth.currentAuthenticatedUser();
      try {
        await API.put(API_STORE, `/store/${props.location.state.storeId}`, {
          body: {
            merchant: store.merchant,
            createdat: store.createdat,
            published: published,
          },
        });
        await getStore()
        handleAlert('success', 'Se actualizó correctamente')
      } catch (err) {
        setLoading(false)
        handleAlert('error', err)
      }
  }

  const getStore = async () => {
    setLoading(true)
    try {
      const response = await API.get(API_STORE, `/store/${props.location.state.storeId}?merchant=${props.location.state.merchantId}`);
      const currentStore = R.pathOr({}, ['data', 'Items', 0,], response);
      const { latitude, longitude } = currentStore;
      const geolocation = latitude && longitude && {
        lat: latitude,
        lng: longitude,
      }
      setStore(currentStore)
      setStoreName(currentStore.storeName)
      setFiscalAddress(currentStore.address)
      setTelephoneNumber(currentStore.phone)
      setMapPosition(geolocation || defaultLoc);
      setMarkerPosition(geolocation || defaultLoc);
      setCity(currentStore.city);
      setStateLoc(currentStore.stateName);
      setCountry(currentStore.country);
      setSnapshot(currentStore.snapshotLocation);
      setHasAvatar(currentStore.hasAvatar);
      const image = currentStore.hasAvatar
      ? await Storage.get(`store/${props.location.state.storeId}/default.jpg`)
      : R.path(['location', 'state', 'merchantPicture',], props)
      ? props.location.state.merchantPicture
      : defaultStoreIcon;
      setFile({ previewImage: image, data: image });
      setPublished(currentStore.published);

      if (currentStore.schedules) {
        setSchedules(currentStore.schedules)
        const scheduleResult = CheckSchedule(currentStore.schedules)
        setCurrentStartTime(scheduleResult.start)
        setCurrentEndTime(scheduleResult.end)
        if (scheduleResult.isOpen) {
          setCurrentStatus('ABIERTO')
        }
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  const handleChangeTab = (event, newValue) => {
    setTab(newValue)
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    getStore();
  };

  const buildStatus = (status) => {
    switch (status) {
      case 'ABIERTO':
        return <div className={classes.statusStyle1}>
          <Typography className={classes.statusText}>{status}</Typography>
        </div>
      case 'CERRADO':
        return <div className={classes.statusStyle2}>
          <Typography className={classes.statusText}>{status}</Typography>
        </div>
      default:
        return <div className={`${classes.statusStyle4} col-12`}>
          <Typography className={classes.statusText}>{status}</Typography>
        </div>
    }
  }

  return (
    <div className={classes.root}>
      {isLoading ?
        <div className='gralinfo_form'>
          <CircularProgress color="secondary" />
        </div>
        :
        <>
          <div className={classes.container}>
            <div className={"storeHeader"}>
              <div className={classes.headerConteiner + ' col-9'}>
                <div className={classes.rowStyle}>
                    <img src={file.previewImage} className={classes.storeprofilepic} />
                  <div className={classes.titlesContainer}>
                    <div className={classes.rowStyle}>
                      <Typography className={!props.location.state.isManager ?
                        `${classes.title}`
                        : `${classes.title}`}>{store.storeName}</Typography>
                      <div className={`col-1 align-self-center justify-content-center ${classes.paddingLeft20}`}>
                        {buildStatus(currentStatus)}
                      </div>
                    </div>
                    <div>
                      {currentStatus === 'ABIERTO' ?
                        <div>
                          <Typography className={`${classes.titleSublabelFirst}`}>{store.phone}</Typography>
                          <Typography className={`${classes.titleSublabelFirst}`}>De {currentStartTime} a {currentEndTime}</Typography>
                        </div>
                        :
                        <Typography className={`${classes.titleSublabelFirst}`}>{store.phone} | Cerrado el día de hoy</Typography>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className={'col-4' + classes.rowEnd} >
                {!props.location.state.isManager &&
                  <div>
                    <SecondaryButton
                      onClick={() => updateStore(!published)}>
                      {published ? 'Desactivar' : 'Activar'}
                    </SecondaryButton>
                    <PrimaryButton
                      onClick={handleOpen}
                      children='Editar'
                    />
                  </div>
                }
              </div>
            </div>
            <div className={classes.containerForm}>
              <AppBar position="static" color="transparent" className={"storesTabs"}>
                <Tabs
                  value={currentTab}
                  onChange={handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab label="Movimientos" {...a11yProps(0)} />
                  <Tab label="Inventario" {...a11yProps(1)} />
                  <Tab label="Pedidos" {...a11yProps(2)} />
                  <Tab label="Empleados" {...a11yProps(3)} />
                </Tabs>
                <div className={classes.containerForm2}></div>
              </AppBar>
              <TabPanel className={classes.movimientosTAB} value={currentTab} index={0}>
                <Tab1 currentStore={store} />
              </TabPanel>
              <TabPanel className={classes.inventarioTAB} value={currentTab} index={1}>
                <Tab2 store={props.location.state.storeId} merchant={props.location.state.merchantId} />
              </TabPanel>
              <TabPanel className={classes.pedidosTAB} value={currentTab} index={2}>
                <Tab3 currentStore={store} from={props.location.from} email={props.location.email} />
              </TabPanel>
              <TabPanel className={classes.empleadosTAB} value={currentTab} index={3}>
                <Tab4 currentStore={store} from={props.location.from} email={props.location.email} />
              </TabPanel>
            </div>
          </div>
        </>
      }

      <DefaultModal
        open={open}
        handleClose={handleClose}
        edit={true}
        currentStore={store}
        modalType='newStore'
        merchantId={props.location.state.merchantId}
        storeId={props.location.state.storeId}
        file={file} />
    </div>
  );
}

export default withSnackbar(Details);