import React from 'react';
import { withSnackbar } from 'notistack';
import { Auth } from 'aws-amplify';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckValue from '../../lib/formatValidations';

import '../../assets/css/main.css';
import CognitoErrors from '../../lib/cognitoErrors';
import ThirdHeader from '../../components/headers/thirdHeader';
import countryCodes from '../../lib/countryCodes';
import PrimaryInput from '../../components/common/primaryInput';
import PrimaryButton from '../../components/common/primaryButton';
import PrimarySelect from '../../components/common/primarySelect';
import image from '../../assets/images/merchantBackground.png';
import { withStyles } from '@material-ui/core/styles';
import fetch from 'node-fetch';
import DefaultModal from '../../components/modal';

import UserPicture from '../../assets/images/user.png';
import MerchantPicture from '../../assets/images/merchant.png';

const styles = (theme) => ({
  background: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    minWidth: '100vw',
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    paddingLeft: '5%',
    paddingRight: '5%',
    paddingTop: '2%',
    paddingBottom: '2%',
    borderRadius: 20,
    minWidth: 320,
    maxWidth: 550,
  },
  spacer1: { width: '100%', height: 20 },
  spacer2: { width: '100%', height: 40 },
  cookiesWarning: {
    paddingTop: 10,
  },
  phoneContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
  },
  imagesContainer: {
    marginTop: 5,
    display: 'flex',
    flexDirection: 'row',
  },
  center: {
    textAlign: 'center',
  },
  image: {
    height: 100,
    marginLeft: 10,
    marginRight: 10,
    cursor: 'pointer',
    border: '5px solid #ededed',
    borderRadius: '50%',
  },
  selectedImage: {
    border: `5px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
  },

});

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      phone: '',
      password: '',
      confirmedpassword: '',
      showPassword1: false,
      showPassword2: false,
      isLoading: false,
      countryCode: '+52',
      privacyNotice: false,
      modalCookies: false,
      cookies: false,
      selected: 'user'
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
    this.handleAlert = this.handleAlert.bind(this);
  }

  handleAlert = (variant, message) => {
    this.props.enqueueSnackbar(message, { variant });
  };

  _attempMerchantSingUp = async (e) => {
    this.setState({ isLoading: true });
    const {
      email,
      password,
      confirmedpassword,
      phone,
      countryCode,
      cookies,
      selected
    } = this.state;
    if (email.length === 0) {
      this.setState({ isLoading: false });
      this.handleAlert('error', 'Ingresa tu correo electrónico');
      return;
    } else if (password.length === 0) {
      this.setState({ isLoading: false });
      this.handleAlert('error', 'Ingresa tu contraseña');
      return;
    } else if (confirmedpassword.length === 0) {
      this.setState({ isLoading: false });
      this.handleAlert('error', 'Confirma tu contraseña');
      return;
    } else if (phone.length === 0) {
      this.setState({ isLoading: false });
      this.handleAlert('error', 'Ingresa tu número de teléfono');
      return;
    } else if (phone.length !== 10) {
      this.setState({ isLoading: false });
      this.handleAlert(
        'error',
        'Tu número de télefono debe de ser de 10 dígitos'
      );
      return;
    } else if (password !== confirmedpassword) {
      this.setState({ isLoading: false });
      this.handleAlert('error', 'Contraseñas diferentes');
      return;
    } else if (password.includes(phone)) {
      this.setState({ isLoading: false });
      this.handleAlert(
        'error',
        'La contraseña no debe incluir el número de teléfono'
      );
      return;
    } else if (password.toLowerCase().includes('nerito')) {
      this.setState({ isLoading: false });
      this.handleAlert('error', 'La contraseña no debe incluir "Nerito"');
      return;
    } else if (!CheckValue('password', password)) {
      this.setState({ isLoading: false });
      this.handleAlert(
        'error',
        'La contraseña debe tener mínimo 6 caracteres, incluyendo por lo menos un número y un caracter especial (!¡@#$%^&*(),.¿?":{}|<>_-)'
      );
      return;
    } else if (CheckValue('hasConsecutiveRepeat', password)) {
      this.setState({ isLoading: false });
      this.handleAlert(
        'error',
        'La contraseña no debe incluir el mismo caracter 3 veces consecutivas.'
      );
      return;
    } else if (CheckValue('hasConsecutive', password)) {
      this.setState({ isLoading: false });
      this.handleAlert(
        'error',
        'La contraseña no debe incluir 3 caracteres consecutivos.'
      );
      return;
    } else if (cookies === false) {
      this.setState({ isLoading: false });
      this.handleAlert('error', 'Debes aceptar los Cookies');
      return;
    }
    try {
      const signup = await Auth.signUp({
        username: email,
        password,
        attributes: {
          'custom:usertype': selected,
          phone_number: countryCode + phone.trim(),
        },
        validationData: [],
      });

      if(selected === 'merchant'){
        fetch(process.env.REACT_APP_ZAPIER_REGISTER, {
          method: 'POST',
          body: JSON.stringify({
            pending: 'PENDIENTE',
            email: email,
            phoneNumber: countryCode + phone.trim(),
            leadSource: 'REGISTRO EN SISTEMA',
          }),
        })
        .then((response) => response.json())
        .then((data) => {})
        .catch((error) => {
        });
      }

      if (signup.user.username === email) {
        this.props.history.push({
          pathname: '/verify',
          state: { email: email, password },
        });
      }

    } catch (error) {
      this.setState({ isLoading: false });
      this.handleAlert('error', CognitoErrors(error.message));
    }
  };

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleClickShowPassword(number) {
    if (number === 1) {
      this.setState({ showPassword1: !this.state.showPassword1 });
    } else if (number === 2) {
      this.setState({ showPassword2: !this.state.showPassword2 });
    }
  }

  handleMouseDownPassword(event) {
    event.preventDefault();
  }

  handleToggleLogin(e) {
    e.preventDefault();
    this.props.history.push({
      pathname: '/login',
    });
  }

  handleGoTo(path) {
    this.props.history.push({
      pathname: path,
    });
  }

  render() {
    const { classes } = this.props;
    const {
      email,
      phone,
      password,
      confirmedpassword,
      showPassword1,
      showPassword2,
      isLoading,
      countryCode,
      cookies,
      selected,
    } = this.state;
    return (
      <div className={classes.background}>
        {isLoading ? (
          <div className='styleCircularProgress'>
            <CircularProgress color='secondary' />
          </div>
        ) : (
          <div className={classes.container}>
            <DefaultModal
              open={this.state.modalCookies}
              handleClose={(e) =>
                this.setState({ modalCookies: !this.state.modalCookies })
              }
              modalType='cookies'
            />
            <ThirdHeader />

            <Typography>
              Selecciona un tipo de usuario
            </Typography>

            <div className={classes.imagesContainer}>
              <div className={classes.center}>
                <img
                  className={selected === 'user' ? `${classes.image} ${classes.selectedImage}` : classes.image}
                  src={UserPicture}
                  onClick={ () => this.setState({ selected: 'user' }) }
                />
                <Typography>
                  Empleado
                  </Typography>
              </div>
              <div className={classes.center}>
                <img
                  className={selected === 'merchant' ? `${classes.image} ${classes.selectedImage}` : classes.image}
                  src={MerchantPicture}
                  onClick={ () => this.setState({ selected: 'merchant' }) }
                  
                />
                <Typography>
                  Negocio
                </Typography>
              </div>
            </div>

            <PrimaryInput
              id='email'
              type='text'
              name='email'
              className='defaultInputEmail'
              placeholder='Email'
              value={email}
              onChange={this.handleChange}
              fullWidth={true}
            />

            <PrimaryInput
              id='pwd'
              type={showPassword1 ? 'text' : 'password'}
              name='password'
              className='defaultInputPassword'
              placeholder='Contraseña'
              value={password}
              onChange={this.handleChange}
              fullWidth
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={(e) => this.handleClickShowPassword(1, e)}
                    onMouseDown={this.handleMouseDownPassword}
                    className='visibilityicon eyeIcon'
                  >
                    {showPassword1 ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />

            <PrimaryInput
              id='cpwd'
              type={showPassword2 ? 'text' : 'password'}
              name='confirmedpassword'
              className='defaultInputPassword'
              placeholder='Confirmar contraseña'
              value={confirmedpassword}
              onChange={this.handleChange}
              fullWidth
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={(e) => this.handleClickShowPassword(2, e)}
                    onMouseDown={this.handleMouseDownPassword}
                    className='visibilityicon eyeIcon'
                  >
                    {showPassword2 ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <div className={classes.phoneContainer}>
              <PrimarySelect
                name='countryCode'
                value={countryCode}
                onChange={this.handleChange}
                marginLeft='0'
                content={countryCodes().map((code) => {
                  return <option value={code.value}>{code.label}</option>;
                })}
              ></PrimarySelect>
              <PrimaryInput
                id='phone'
                name='phone'
                type='number'
                placeholder='(662)555-5555'
                value={phone}
                onChange={this.handleChange}
                marginRight='0'
                fullWidth
              />
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <Typography
                className={`noMargin subText1 ${classes.cookiesWarning}`}
              >
                Utilizamos cookies para mejorar tu experiencia en nuestra web.{' '}
              </Typography>
              <Typography className='noMargin subText1'>
                Infórmate más sobre el uso de cookies
                <text
                  className='subText1Pointer'
                  onClick={(e) =>
                    this.setState({ modalCookies: !this.state.modalCookies })
                  }
                >
                  aquí
                </text>
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    id='cookies'
                    name='cookies'
                    checked={cookies}
                    onChange={() => {
                      this.setState({
                        cookies: !this.state.cookies,
                      });
                    }}
                    color='#6EB89D'
                  />
                }
                label={
                  <Typography className='noMargin subText1'>
                    Aceptar Cookies
                  </Typography>
                }
              />

            </div>

            <PrimaryButton
              type='submit'
              className='default_button'
              onClick={this._attempMerchantSingUp}
              marginVertical={10}
              fullWidth
            >
              Registrarme
            </PrimaryButton>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <Typography className='subText1'>Ya tengo cuenta,</Typography>
              <Typography
                className='subText1Pointer'
                onClick={(e) => this.handleToggleLogin(e)}
              >
                Iniciar sesión
              </Typography>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withSnackbar(Register));
