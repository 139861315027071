import React from "react";
import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import { API } from "aws-amplify";
import CircularProgress from "@material-ui/core/CircularProgress";

import "../../../../assets/css/main.css";
import DefaultInput from "../../../common/defaultInput";
import DefaultSelect from "../../../common/defaultSelect";
import CheckValue from "../../../../lib/formatValidations";
import { Typography, Tooltip } from "@material-ui/core";
import SecondarySelect from '../../../common/secondarySelect';
import commercialBusinessTypeItems from '../../../../lib/commercialBusinessTypes';
import NumberOfEmployeesItems from '../../../../lib/numberOfEmployees';
import ClabeValidator from '../../../../lib/clabeValidator';
import InfoIcon from '../../../../assets/images/icons8-info.svg';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  firstRowStyle: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  rowStyle: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 25,
    flexWrap: "wrap",
  },
  F1: {
    flex: 1,
  },
  F2: {
    flex: 2,
  },
  title: {
    ...theme.typography.smallText,
    marginLeft: '20px',
    marginTop: '20px',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.gray.main,
    mixBlendMode: 'normal',
  },
  subtitle: {
    marginRight: '20px',
    ...theme.typography.smallText,
    color: '#B4B4B4',
  },
}));

function Tab2(props) {
  useEffect(() => {
    if (props.ExecutingRequest) {
      callApi();
    }
  }, [props.ExecutingRequest]);

  const classes = useStyles();

  const NoEmployeesItems = [
    "1 a 20",
    "21 a 40",
    "41 a 60",
    "61 a 80",
    "81 a 100",
    "Más de 100",
  ];

  const BusinessTypeItems = [
    'Comercio al por mayor',
    'Comercio al por menor',
    'Comercio al por menor de alimentos, bebidas y tabaco',
    'Comercio al por menor de vehículos de motor, refacciones, combustibles y lubricantes',
    'Comercio al por menor en tiendas de autoservicio y departamentales',
    '',
    'Agricultura, ganadería, aprovechamiento forestal, pesca y caza',
    'Comercio al por menor de artículos de ferretería, tlapalería y vidrios',
    'Comercio al por menor de artículos de papelería, para el esparcimiento y otros artículos de uso personal',
    'Comercio al por menor de artículos para el cuidado de la salud',
    'Comercio al por menor de enseres domésticos, computadoras y artículos para la decoración de interiores',
    'Comercio al por menor de productos textiles, accesorios de vestir y calzado',
    'Construcción',
    'Dirección de corporativos y empresas',
    'Electricidad, agua y suministro de gas por ductos al consumidor final',
    'Industrias manufactureras',
    'Intermediación y comercio al por menor por medios masivos de comunicación y otros medios',
    'Otros servicios excepto actividades del gobierno',
    'Servicios de alojamiento temporal y de preparación de alimentos y bebidas',
    'Servicios de apoyo a los negocios y manejo de desechos y servicios de remediación',
    'Servicios de esparcimiento culturales y deportivos, y otros servicios recreativos',
    'Servicios de salud y de asistencia social',
    'Servicios educativos',
    'Servicios financieros y de seguros',
    'Servicios inmobiliarios y de alquiler de bienes muebles e intangibles',
    'Servicios profesionales, científicos y técnicos',
  ];

  const [isLoading, setLoading] = React.useState(false);

  const [businessCommercialName, setBusinessCommercialName] = React.useState(
    props.merchantProfile.businessCommercialName
      ? props.merchantProfile.businessCommercialName
      : ""
  );
  const [RFC, setRFC] = React.useState(
    props.merchantProfile.RFC ? props.merchantProfile.RFC : ""
  );
  const [businessName, setBusinessName] = React.useState(
    props.merchantProfile.businessName ? props.merchantProfile.businessName : ""
  );
  const [numberOfEmployees, setNumberOfEmployees] = React.useState(
    props.merchantProfile.numberOfEmployees
      ? props.merchantProfile.numberOfEmployees
      : ""
  );
  const [businessType, setBusinessType] = React.useState(
    props.merchantProfile.businessType ? props.merchantProfile.businessType : ""
  );
  const [businessTypeCode, setBusinessTypeCode] = React.useState(
    props.merchantProfile.businessTypeCode ? props.merchantProfile.businessTypeCode : 0
  );
  const [website, setWebsite] = React.useState(
    props.merchantProfile.website ? props.merchantProfile.website : ""
  );
  const [phone, setPhone] = React.useState(
    props.merchantProfile.phone ? props.merchantProfile.phone : ""
  );
  const [email, setEmail] = React.useState(
    props.merchantProfile.email ? props.merchantProfile.email : ""
  );
  const [accountNumber, setAccountNumber] = React.useState(
    props.merchantProfile.accountNumber ? props.merchantProfile.accountNumber : ''
  );
  const [invoiceEmail, setInvoiceEmail] = React.useState(
    props.merchantProfile.invoiceEmail ? props.merchantProfile.invoiceEmail : ''
  );
  const [invoiceEmailError, setInvoiceEmailError] = React.useState('');
  const [accountNumberError, setAccountNumberError] = React.useState('');
  const [
    businessCommercialNameError,
    setBusinessCommercialNameError,
  ] = React.useState("");
  const [RFCError, setRFCError] = React.useState("");
  const [businessNameError, setBusinessNameError] = React.useState("");
  const [websiteError, setWebsiteError] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [businessTypeError, setBusinessTypeError] = React.useState('');
  const [numberOfEmployeesError, setNumberOfEmployeesError] = React.useState('');
  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case "businessCommercialName":
        setBusinessCommercialName(value);
        break;
      case "RFC":
        setRFC(value.toUpperCase());
        break;
      case "businessName":
        setBusinessName(value);
        break;
      case "numberOfEmployees":
        setNumberOfEmployees(value);
        break;
      case "businessType":
        const code = commercialBusinessTypeItems()[commercialBusinessTypeItems().findIndex(i => i.value === value)]['code'];
        setBusinessType(value);
        setBusinessTypeCode(code);
        break;
      case "website":
        setWebsite(value);
        break;
      case "phone":
        setPhone(value);
        break;
      case "email":
        setEmail(value);
        break;
      case 'accountNumber':
        setAccountNumber(value);
        break;
      case 'invoiceEmail':
        setInvoiceEmail(value);
        break;
      default:
        console.log("Input no reconocido", name);
    }
  };

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const cleanError = () => {
    setBusinessCommercialNameError("");
    setRFCError("");
    setBusinessNameError("");
    setWebsiteError("");
    setPhoneError("");
    setEmailError("");
    setBusinessTypeError("");
    setNumberOfEmployeesError("");
    setAccountNumberError('');
    setInvoiceEmailError('');
  };

  const isChanged = () => {
    if (
      props.merchantProfile.businessCommercialName !== businessCommercialName
    ) {
      return true;
    }
    if (props.merchantProfile.RFC !== RFC) {
      return true;
    }
    if (props.merchantProfile.businessName !== businessName) {
      return true;
    }
    if (props.merchantProfile.numberOfEmployees !== numberOfEmployees) {
      return true;
    }
    if (props.merchantProfile.businessType !== businessType) {
      return true;
    }
    if (props.merchantProfile.website !== website) {
      return true;
    }
    if (props.merchantProfile.phone !== phone) {
      return true;
    }
    if (props.merchantProfile.email !== email) {
      return true;
    }
    if (props.merchantProfile.accountNumber !== accountNumber && !props.serviceInvoicePostPay) {
      return true;
    }
    if (props.merchantProfile.invoiceEmail !== invoiceEmail && !props.serviceInvoicePostPay) {
      return true;
    }
    return false;
  };

  const callApi = async () => {
    cleanError();
    let hasErros = false;
    if (!CheckValue("empty", businessCommercialName)) {
      hasErros = true;
      setBusinessCommercialNameError(
        "Por favor ingrese el nombre de la empresa"
      );
    }
    if (!CheckValue("rfc", RFC)) {
      hasErros = true;
      setRFCError("Por favor ingrese un RFC válido");
    }
    if (!CheckValue("empty", businessName)) {
      hasErros = true;
      setBusinessNameError("Por favor ingrese la Denominación o Razón Social de la Empresa");
    }
    if (CheckValue("empty", website)) {
      if (!CheckValue("website", website)) {
        hasErros = true;
        setWebsiteError("Por favor ingrese un sitio web válido");
      }
    }
    if (!CheckValue("phone", "+52" + phone)) {
      hasErros = true;
      setPhoneError("Por favor ingrese un teléfono válido de 10 digitos");
    }
    if (!CheckValue("email", email)) {
      hasErros = true;
      setEmailError("Por favor ingrese un correo electrónico válido");
    }
    if (!CheckValue("empty", businessType)) {
      hasErros = true;
      setBusinessTypeError("Por favor seleccione una opción");
    }
    if (!CheckValue("empty", numberOfEmployees)) {
      hasErros = true;
      setNumberOfEmployeesError("Por favor seleccione una opción");
    }
    if (ClabeValidator(accountNumber) && !props.serviceInvoicePostPay) {
      setAccountNumberError(ClabeValidator(accountNumber));
      hasErros = true;
    }
    if (!CheckValue("email", invoiceEmail) && !props.serviceInvoicePostPay) {
      hasErros = true;
      setInvoiceEmailError("Por favor ingrese un correo electrónico válido");
    }

    if (hasErros) {
      setLoading(false);
      props.handleCallRequest(false, null);
    } else {
      if (isChanged()) {
        setLoading(true);
        try {
          const body = {
            merchantRecordKey: props.merchantProfile.merchantRecordKey,
            businessConstitution: "MORAL",
            businessCommercialName,
            RFC,
            businessName,
            numberOfEmployees,
            businessType,
            businessTypeCode,
            website,
            phone,
            email,
            merchantStatus: "pending",
            updateProfile: true,
          }
          if (!props.serviceInvoicePostPay) {
            body['accountNumber'] = accountNumber;
            body['invoiceEmail'] = invoiceEmail;
          }
          const response = await API.post("merchant-api", "/merchant", {
            body,
          });

          if (response && response.success) {
            handleAlert("success", "Guardado con éxito.");
            props.setCompleted(false);
            await props.handleCallRequest(true, 2);
            setLoading(false);
          } else {
            console.log(response);
            handleAlert("error", response.error);
            props.handleCallRequest(false, null);
            setLoading(false);
          }
        } catch (err) {
          console.log("Error: ", err);
          setLoading(false);
          handleAlert("error", err);
          props.handleCallRequest(false, null);
        }
      } else {
        setLoading(false);
        props.handleCallRequest(true, 2);
      }
    }
  };

  return (
    <div className={classes.root}>
      {isLoading ? (
        <div className="gralinfo_form">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <>
            <Typography className={classes.title}>DATOS DE TU NEGOCIO</Typography>
            <div className={classes.firstRowStyle}>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-3">
                <Typography className={classes.subtitle}>Denominación o Razón Social
                  <Tooltip title="Nombre único de la empresa que la distingue de otras">
                    <img width="13" height="13" fill='#B4B4B4' src={InfoIcon}></img>
                  </Tooltip>
                </Typography>
              <DefaultInput
                custom={{
                  handleChange,
                  value: businessName,
                  classForm: "inputForm col-12",
                  classLabel: "labelInputStyleForm",
                  id: "businessName",
                  type: "text",
                  name: "businessName",
                  className: "defaultInputTextForm col-12",
                  placeholder: "Denominación o Razón Social",
                  error: businessNameError,
                  }}
                />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-3">
              <DefaultInput
                custom={{
                  handleChange,
                  value: RFC,
                  classForm: "inputForm col-12",
                  classLabel: "labelInputStyleForm",
                  labelText: "RFC con Homoclave",
                  id: "RFC",
                  type: "text",
                  name: "RFC",
                  className: "defaultInputTextForm col-12",
                  placeholder: "AAA000000AA0",
                  error: RFCError,
                  maxLength: "12",
                }}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-3">
            <Typography className={classes.subtitle}>Nombre Comercial
                  <Tooltip title="Pseudónimo usado por empresas distinto al que fue registrado">
                    <img width="13" height="13" fill='#B4B4B4' src={InfoIcon}></img>
                  </Tooltip>
                </Typography>
              <DefaultInput
                custom={{
                  handleChange,
                  value: businessCommercialName,
                  classForm: "inputForm col-12",
                  classLabel: "labelInputStyleForm",
                  id: "businessCommercialName",
                  type: "text",
                  name: "businessCommercialName",
                  className: "defaultInputTextForm col-12",
                  placeholder: "Nombre Comercial",
                  error: businessCommercialNameError,
                }}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-3">
              <SecondarySelect
                value={businessType}
                onChange={handleChange}
                id='businessType'
                name='businessType'
                label='Giro Comercial'
                default={commercialBusinessTypeItems().filter(item => item.section === 'Default').map((item) => {
                  return (
                    <>
                      <option value={item.value}>{item.label}</option>
                    </>
                  );
                })}
                topOptions={commercialBusinessTypeItems().filter(item => item.section === 'Más frecuentes').map((item) => {
                  return (
                    <>
                      <option value={item.value}>{item.label}</option>
                    </>
                  );
                })}
                alphabeticalOrder={commercialBusinessTypeItems().filter(item => item.section === 'Orden Alfabético').map((item) => {

                  return (
                    <>
                      <option value={item.value}>{item.label}</option>
                    </>
                  );
                })}
                error={businessTypeError}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-3">
              <SecondarySelect
                value={numberOfEmployees}
                onChange={handleChange}
                id='numberOfEmployees'
                name='numberOfEmployees'
                label='Número de empleados'
                content={NumberOfEmployeesItems().map((item) => {

                  return (
                    <>
                      <option value={item.value}>{item.label}</option>
                    </>
                  );
                })}
                error={numberOfEmployeesError}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-3">
              <DefaultInput
                custom={{
                  handleChange,
                  value: website,
                  classForm: "inputForm col-12",
                  classLabel: "labelInputStyleForm",
                  labelText: "Página Web",
                  id: "website",
                  type: "text",
                  name: "website",
                  className: "defaultInputTextForm col-12",
                  placeholder: "https://www.nerito.com",
                  error: websiteError,
                }}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-3">
              <DefaultInput
                custom={{
                  handleChange,
                  value: phone,
                  classForm: "inputForm col-12",
                  classLabel: "labelInputStyleForm",
                  labelText: "Teléfono",
                  id: "phone",
                  type: "text",
                  name: "phone",
                  className: "defaultInputTextForm col-12",
                  placeholder: "(000) 000 00 00",
                  error: phoneError,
                }}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-3">
              <DefaultInput
                custom={{
                  handleChange,
                  value: email,
                  classForm: "inputForm col-12",
                  classLabel: "labelInputStyleForm",
                  labelText: "Correo de Contacto",
                  id: "email",
                  type: "text",
                  name: "email",
                  className: "defaultInputTextForm col-12",
                  placeholder: "nombre@email.com",
                  error: emailError,
                }}
              />
            </div>
            {!props.serviceInvoicePostPay && (
              <>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-3">
                  <DefaultInput
                    custom={{
                      handleChange,
                      value: accountNumber,
                      classLabel: 'labelInputStyleForm',
                      labelText: 'Cuenta Clabe',
                      id: 'accountNumber',
                      type: 'text',
                      name: 'accountNumber',
                      className: 'defaultInputTextForm col-12',
                      placeholder: '',
                      error: accountNumberError,
                    }}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-3">
                  <DefaultInput
                    custom={{
                      handleChange,
                      value: invoiceEmail,
                      classLabel: 'labelInputStyleForm',
                      labelText: 'Correo de Facturación',
                      id: 'invoiceEmail',
                      type: 'text',
                      name: 'invoiceEmail',
                      className: 'defaultInputTextForm col-12',
                      placeholder: '',
                      error: invoiceEmailError,
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default withSnackbar(Tab2);
