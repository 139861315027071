import React,{useEffect, useState} from 'react'
import { useLocation } from "react-router-dom";
import { useCookies } from 'react-cookie';
import GetAvailableQuestions from '../../lib/questionnaire/getAvailableQuestions';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveResponseQuestions from '../../lib/questionnaire/saveResponseQuestions';
import PrimaryInput from '../../components/common/primaryInput';
import { FormControlLabel, RadioGroup,Radio,Checkbox } from '@material-ui/core';
import PrimaryButton from '../../components/common/primaryButton';
import SecondaryButton from '../../components/common/secondaryButton';
import GetUserByEmailAndPhone from '../../lib/questionnaire/getUserByEmailAndPhone';

const Questionnaire = (props) => {
  const loc = useLocation();
  const classes = useStyle();

  const [questions, setQuestions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [answers, setAnswers] = useState({})
  const [user, setUser] = useState('')
  const [pages, setPages] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [cookies] = useCookies(['lastAction', 'user']);
  const perPage = 4
  useEffect(() => {
      if(loc.state){
        getAvailableQuestionnaire()
      }
  }, [loc])

  const getAvailableQuestionnaire = async () => {
    const lead = await GetUserByEmailAndPhone(loc.state.email,loc.state.phone,'accept')
    if(lead.success){
      setUser({leadRecordKey:lead.data[0].leadRecordKey, type: 'lead'})
      const questionnaire = await GetAvailableQuestions(cookies.user.key)
    
      setQuestions(questionnaire.data.length > 0 ? questionnaire.data : [])
      setPages(Math.ceil(questionnaire.data.length / perPage))
    }
    else {
      setUser({type: 'user'})
    }
    setIsLoading(false)
  }

  const handlePagination = (type) => {
      if(type === "add"){
        setSelectedPage(selectedPage + 1 <= questions.length ? selectedPage + 1 : questions.length)
      }
      else{
        setSelectedPage(selectedPage - 1 <= 0 ? 1 : selectedPage - 1 )
      }
  }

  const handleOnChange = props => (event) => {
      const { value, name,id } = event.target
      const { questionId,options } = props
  
      switch (name) {
        case 'type1':
          if(value === ''){
            delete answers[questionId]
            setAnswers({...answers})
          }
          else {
            setAnswers({...answers,[questionId]:{text: value}})
          }
        break;
  
        case 'type2':
          const filter = options.filter(item => item.optionId == value)
          const result = {...answers,[questionId]: {
            optionId: filter[0].optionId,
            description: filter[0].descripcion
          }}
          setAnswers(result)
        break;
  
        default:
          if(answers[questionId]){
              const question = answers[questionId]
              const questionOptions = question.filter(item => item.optionId == (id.slice(-1) || id.slice(-2)))
              
              if(questionOptions.length > 0){
                const questionOptions = question.filter(item => item.optionId != (id.slice(-1) || id.slice(-2)))
                if(questionOptions.length === 0 ){
                  delete answers[questionId]
                  setAnswers({...answers})
                }
                else{
                  const result = {...answers,[questionId]:questionOptions }
                  setAnswers(result)
                }
      
              }
  
              else {
                const filter = options.filter(item => item.optionId == (id.slice(-1) || id.slice(-2)))
                question.push(
                  {
                    optionId: filter[0].optionId,
                    value: true,
                    description: filter[0].descripcion
                  }
                )
                const result = {...answers,[questionId]:question }
  
              setAnswers(result)
            }
          }
          else {
            const filterOp = options.filter(item => item.optionId == (id.slice(-1) || id.slice(-2)))
            const result = {...answers,[questionId]:[{
              optionId: filterOp[0].optionId,
              value: true,
              description: filterOp[0].descripcion
            }]}
  
            setAnswers(result)
          }
        break;
      }
  }

  const saveQuestions = async() => {
      if(Object.keys(answers).length < questions.length){
        handleAlert('error','Favor de responder todas las preguntas')
      }
      else{
        const request = questions.map(async item =>{
          const body = {
            employeeId: user.leadRecordKey,
            neritoId: null,
            questionId: item.questionId,
            companyId: item.companyId,
            period: item.period,
            questionType: item.questionType,
            response: answers[item.questionId],
            userName: null,
          }
          const questionnaire = await SaveResponseQuestions(body)
    
          if(questionnaire.success){
            return true
          }
          else {
            return false
          }
        })
  
        const response = await Promise.all(request)
        const result = response.some(item => item === true)
  
        if(result){
          getAvailableQuestionnaire()
          setSelectedPage(0)
          setIsLoading(true)
  
          handleAlert("success","Cuestionario respondido con éxito")
        }
        else{
          handleAlert('error', 'Ha ocurrido un error, por favor inténtelo de nuevo más tarde')
        }
      }
  
  }

  const handleAlert = (variant, message) => { 
    props.enqueueSnackbar(message, { variant });
  };

  return (
    <main className={classes.containerBackground}>
        {
          (!isLoading && questions.length > 0) &&
          <h2 className={classes.pages}>{selectedPage} de {pages}</h2>
        }
        {
          user.type === 'lead' ?
            <>
              <h2 className={classes.title}>{user.type === 'lead' && 'Cuestionario'}</h2>

              <div className={questions.length > 0 ? classes.form : classes.formWithoutQuestions}>
                {
                  isLoading ?
                    <CircularProgress />
                  :
                  questions.length > 0 ?
                  questions.slice(((selectedPage - 1) * perPage), (((selectedPage - 1) * perPage) + perPage)).map(element => (
                    element.questionType === 1 ?
                      <div className={classes.type1}>
                        <h5>{element.questionText}</h5>
                        <PrimaryInput
                          type='text'
                          name='type1'
                          className='defaultInputName'
                          placeholder='Respuesta'
                          value={answers[element.questionId] ? answers[element.questionId].text :''}
                          onChange={handleOnChange(element)}
                          fullWidth={true}
                        />
                      </div>
                    :
                    element.questionType === 2 ?
                      <div>
                        <h5>{element.questionText}</h5>
                        <RadioGroup 
                          value = {answers[element.questionId] ? answers[element.questionId].optionId : ''} 
                          onChange={handleOnChange(element)}
                        >
                          {
                            element.options.map(options => (
                              <FormControlLabel name = 'type2' classes = {classes.labels} value = {options.optionId} control={<Radio color="primary" size="small"/>} label = {options.descripcion} />               
                            ))
                          }
                        </RadioGroup>
                      </div>
                    :
                      <div className = {classes.type3}>
                        <h5>{element.questionText}</h5>
                          {
                            element.options.map((options,index,
                                filterR) => (
                                  <>
                                    {
                                      (
                                        filterR = answers[element.questionId]? answers[element.questionId].filter(it => it.optionId === options.optionId): false,
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                                checked={filterR.length > 0 ? filterR[0].value : false}
                                                onChange={handleOnChange(element)}
                                                name={`check${options.descripcion}`}
                                                id = {`${element.questionId}-${options.optionId}`}
                                                size="small"
                                            />
                                          }
                                          label={options.descripcion}
                                          classes = {classes.labels}
                                        />
                                      )
                                    }
                                  </>                
                            ))
                          }
                      </div>
                  ))
                  :
                  <h3>No hay preguntas disponibles por el momento</h3>
              }
              </div>
              {
                (!isLoading && questions.length > 0) &&
                <div className={classes.buttonContainer}>
                  {
                    (selectedPage > 1 && selectedPage <= pages) &&
                    <SecondaryButton
                      onClick={() => (selectedPage <= pages && handlePagination('subtract'))}
                      fullWidth
                    >
                      Anterior
                    </SecondaryButton>
                  }
                  <PrimaryButton type='submit' onClick={() => (selectedPage < pages ? handlePagination('add') : saveQuestions())} fullWidth>
                    {selectedPage < pages ? "Siguiente" : "Guardar Respuestas"}
                  </PrimaryButton>
                </div>
              }
            </>
          :
          user.type === 'user' &&
          <div className={classes.messageContainer}>
            <h3 className={classes.message}>
              Has sido registrado correctamente en el programa de venta de datos. 
              Te contactaremos cuando encontremos una empresa interesada
            </h3>
          </div>
        }
          
    </main>
  )
}

export default withSnackbar(Questionnaire);

const useStyle = makeStyles(theme => ({
  containerBackground: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop:"2%",
    margin: 0,
  },
  title: {
    ...theme.typography.title,
    fontSize: 45,
    color: theme.palette.primary.main,
    textAlign: 'center',
    lineHeight: 1,
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  },
  form: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnGap: 20,
    margin: '0 5%',
    [theme.breakpoints.down('sm')]:{
      gridTemplateColumns: '1fr',
    }
  },
  buttonContainer: {
    width: '90%',
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto',
    gap: '5%'
  },
  pages: {
    textAlign: 'right',
    marginRight: '5%',
    [theme.breakpoints.down('md')]:{
      fontSize: 20,
      marginTop: '5%'
    }
  },
  formWithoutQuestions: {
    margin: '5%',
    textAlign: 'center'
  },
  type3: {
    display: "flex",
    flexDirection: 'column'
  },
  labels: {
    fontSize: 15
  },
  type1: {
    marginBottom: 10
  },
  messageContainer: {
    textAlign: 'center',
    margin: '0 40px'
  },
  message: {
    fontWeight: 'bold',
    fontSize: 35,
    [theme.breakpoints.down('md')]:{
      fontSize: 25,
    }
  }
}))
