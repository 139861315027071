import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import '../../assets/css/main.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import DefaultButton from '../common/defaultButton';
import DefaultInput from '../common/defaultInput';
import NewFantasyGS from './newFantasyGift';
import StoreIcon from '../../assets/images/store_icon.png';
import { Auth, API, Storage } from 'aws-amplify';
import { withSnackbar } from 'notistack';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  ListSubheader,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import {FONT_DEFAULT} from '../../lib/constants.js'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flex: 1,
    backgroundColor: '#faf9f9',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  container: {
    flex: 8,
    marginLeft: 25,
    marginRight: 50,
  },
  containerForm: {
    backgroundColor: '#FFFFFF',
    borderRadius: 19,
    flexDirection: 'row',
    display: 'flex',
    flex: 1,
    padding: 20,
    marginTop: 15,
  },
  containerForm2: {
    backgroundColor: '#BDBDBD',
    marginTop: 20,
    height: 2,
    borderRadius: 5,
    flexDirection: 'row',
  },
  F1: {
    display: 'flex',
    flex: 1,
    padding: 20,
  },
  F2: {
    display: 'flex',
    flex: 2,
    padding: 20,
  },
  openLabel: {
    color: '#3B9A83',
    display: 'flex',
    flex: 1,
    padding: 20,
  },
  closedLabel: {
    color: '#DA7B7B',
    display: 'flex',
    flex: 1,
    padding: 20,
  },
  title: {
    ...FONT_DEFAULT,
    fontWeight: 'bold',
    fontSize: 24,
    marginTop: 30,
  },
});

const FantasyGS = (props) => {
  const userKey = useSelector(state => state.user.value);

  useEffect(() => {
    searchFantasyGS(null);
  }, []);

  const [isLoading, setLoading] = useState(false);
  const [createFantasyGS, setCreateFantasyGS] = useState(false);
  const [fantasyGS, setFantasyGS] = useState([]);
  const [fantasyGE, setFantasyGE] = useState([]);
  const [fantasyGEI, setFantasyGEI] = useState([]);
  const [search, setSearch] = useState('');
  const [fgCategories, setFGCategories] = useState([]);
  const [open, setOpen] = useState(true);

  const handleChange = (event) => {
    setSearch(event.target.value);
    searchFantasyGS(event.target.value);
  };

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const searchFantasyGS = async (searchParam) => {
    setLoading(true);
    const getUser = await Auth.currentAuthenticatedUser();
    if (searchParam === null || searchParam === '') {
      try {
        const getGralProfile = await API.get(
          'profile-api',
          `/profile/${userKey}`
        );
        const merchant = getGralProfile.data[0].merchantRecordKey;
        const getProducts = await API.get('product-api', '/products', {
          queryStringParameters: {
            FilterExpression: 'merchantRecordKey=:merchantRecordKey',
            ExpressionAttributeValues: `:merchantRecordKey=FantasyShop`,
          },
        });
        let categories = [];
        getProducts.data.forEach((element) => {
          if (
            categories.indexOf(element.productType.toUpperCase().trim()) === -1
          ) {
            categories.push(element.productType.toUpperCase().trim());
          }
        });
        const pImages = getProducts.data.map((item) =>
          Storage.get(`products/${item.productRecordKey}/default.jpg`)
        );
        const p = await Promise.all(pImages);
        const upProductList = getProducts.data.map((value, index) => ({
          ...value,
          productImage: p[index],
        }));
        setFGCategories(categories);
        setFantasyGS(upProductList);
        setLoading(false);
      } catch (err) {
        handleAlert('Error', err);
        setLoading(false);
      }
    } else {
      try {
        const getGralProfile = await API.get(
          'profile-api',
          `/profile/${userKey}`
        );
        const merchant = getGralProfile.data[0].merchantRecordKey;
        const getProducts = await API.get('product-api', '/products', {
          queryStringParameters: {
            FilterExpression: `merchantRecordKey=:merchantRecordKey AND 
                            (contains(productName,:search) OR
                            contains(description,:search) OR
                            contains(productType,:search))`,
            ExpressionAttributeValues: `:merchantRecordKey=FantasyShop,:search=${searchParam}`,
          },
        });
        const pImages = getProducts.data.map((item) =>
          Storage.get(`products/${item.productRecordKey}/default.jpg`)
        );
        const p = await Promise.all(pImages);
        const upProductList = getProducts.data.map((value, index) => ({
          ...value,
          productImage: p[index],
        }));
        setFantasyGS(upProductList);
        setLoading(false);
      } catch (err) {
        handleAlert('Error', err);
        setLoading(false);
      }
    }
  };

  const handleNewFantasyGSBtn = () => {
    setCreateFantasyGS(!createFantasyGS);
  };
  const handleToggleEditClick = async (item, e) => {
    const pImage = await Storage.get(
      `products/${item.productRecordKey}/default.jpg`
    );
    setFantasyGE(item);
    setFantasyGEI(pImage);
    setCreateFantasyGS(!createFantasyGS);
  };

  const renderFantasyGS = (category) => {
    const filterFGS = fantasyGS.filter(
      (fantasyGF) => fantasyGF.productType.toUpperCase().trim() === category
    );
    const element = filterFGS.map(
      (fantasyGS) =>
        fantasyGS.isActive && (
          <div className={classes.containerForm}>
            <div className={classes.F1}>
              <img width='70' height='70' src={fantasyGS.productImage} />
            </div>
            <label className={classes.F1}>
              <strong>{fantasyGS.productName}</strong>
            </label>
            <label className={classes.F2}>{fantasyGS.description}</label>
            <label className={classes.F1}>{fantasyGS.productType}</label>
            <label className={classes.F1}>{fantasyGS.price}</label>
            <IconButton onClick={(e) => handleToggleEditClick(fantasyGS, e)}>
              <EditIcon />
            </IconButton>
          </div>
        )
    );
    return element;
  };

  const renderFantasyGSCategories = () => {
    const handleClick = () => {
      setOpen(!open);
    };
    const categories = fgCategories.map((category) => (
      <List>
        <ListItem button onClick={handleClick}>
          <ListItemText primary={category}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemText>
        </ListItem>
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <ListItem>
              <div>{renderFantasyGS(category)}</div>
            </ListItem>
          </List>
        </Collapse>
      </List>
    ));

    return categories;
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Typography className={classes.title}>Mis regalos</Typography>
        {!createFantasyGS ? (
          <div>
            <div className='rowStyle'>
              <DefaultInput
                custom={{
                  handleChange,
                  value: search,
                  classForm: 'inputForm',
                  classLabel: 'labelInputStyleForm',
                  labelText: '',
                  id: 'search',
                  type: 'text',
                  name: 'search',
                  className: 'defaultInputSearchTextFormLarge',
                  placeholder: 'Buscar Regalo',
                  error: '',
                }}
              />
              <DefaultButton
                buttonText='NUEVO REGALO'
                handleAction={handleNewFantasyGSBtn}
              />
            </div>
            <div className={classes.containerForm2}></div>
            {isLoading ? (
              <div className='styleCircularProgress'>
                <CircularProgress color='secondary' />
              </div>
            ) : (
              <div>{renderFantasyGSCategories()}</div>
            )}
          </div>
        ) : (
          <div className='rowStyle'>
            <NewFantasyGS
              createFantasyGS={handleNewFantasyGSBtn}
              EditFantasyG={fantasyGE}
              EditFantasyGI={fantasyGEI}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default withSnackbar(FantasyGS);
