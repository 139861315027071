import React from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { API } from 'aws-amplify';
import { CircularProgress, Grid } from '@material-ui/core';
import {phone} from '../../../../lib/constants';
import '../../../../assets/css/main.css';
import DefaultInput from '../../../common/defaultInput';
import DefaultSelect from '../../../common/defaultSelect';
import Countries from '../../../../lib/locations/countries';
import CheckValue from '../../../../lib/formatValidations';
import { Typography } from '@material-ui/core';
import SecondarySelect from '../../../common/secondarySelect';

const useStyles = makeStyles(theme => ({
  container: {
    padding: 24,
  },
  title: {
    marginLeft: '20px',
    marginTop: '20px',
    marginBottom: '10px',
    ...theme.typography.smallText,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.gray.main,
    mixBlendMode: 'normal',
  },
  subtitle: {
    ...theme.typography.smallText,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.gray.main,
    mixBlendMode: 'normal',
  },
  firstRowStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  rowStyle: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 25,
    flexWrap: 'wrap',
  },
  F1: {
    flex: 1,
  },
  F2: {
    flex: 2,
  },
  menu: {
    padding: 20,
  },
}));

function Tab1(props) {
  useEffect(() => {
    if (props.ExecutingRequest) {
      callApi();
    }
  }, [props.ExecutingRequest]);

  const classes = useStyles();
  const [isLoading, setLoading] = React.useState(false);
  const [legalRepresentativeName, setLegalRepresentativeName] = React.useState(
    props.merchantProfile.legalRepresentativeName
      ? props.merchantProfile.legalRepresentativeName
      : ''
  );
  const [
    legalRepresentativeLastName,
    setLegalRepresentativeLastName,
  ] = React.useState(
    props.merchantProfile.legalRepresentativeLastName
      ? props.merchantProfile.legalRepresentativeLastName
      : ''
  );
  const [
    legalRepresentativeSurName,
    setLegalRepresentativeSurName,
  ] = React.useState(
    props.merchantProfile.legalRepresentativeSurName
      ? props.merchantProfile.legalRepresentativeSurName
      : ''
  );
  const [legalRepresentativeCURP, setLegalRepresentativeCURP] = React.useState(
    props.merchantProfile.legalRepresentativeCURP
      ? props.merchantProfile.legalRepresentativeCURP
      : ''
  );
  const [
    legalRepresentativeBirthdate,
    setLegalRepresentativeBirthdate,
  ] = React.useState(
    props.merchantProfile.legalRepresentativeBirthdate
      ? props.merchantProfile.legalRepresentativeBirthdate
      : ''
  );
  const [
    legalRepresentativeNationality,
    setLegalRepresentativeNationality,
  ] = React.useState(
    props.merchantProfile.legalRepresentativeNationality
      ? props.merchantProfile.legalRepresentativeNationality
      : ''
  );
  const [legalRepresentativeRFC, setLegalRepresentativeRFC] = React.useState(
    props.merchantProfile.legalRepresentativeRFC
      ? props.merchantProfile.legalRepresentativeRFC
      : ''
  );
  const [
    legalRepresentativeCellPhone,
    setLegalRepresentativeCellPhone,
  ] = React.useState(
    props.merchantProfile.legalRepresentativeCellPhone
      ? props.merchantProfile.legalRepresentativeCellPhone
      : ''
  );
  const [
    legalRepresentativeEmail,
    setLegalRepresentativeEmail,
  ] = React.useState(
    props.merchantProfile.legalRepresentativeEmail
      ? props.merchantProfile.legalRepresentativeEmail
      : ''
  );

  const [
    legalRepresentativeNameError,
    setLegalRepresentativeNameError,
  ] = React.useState('');
  const [
    legalRepresentativeLastNameError,
    setLegalRepresentativeLastNameError,
  ] = React.useState('');
  const [
    legalRepresentativeCURPError,
    setLegalRepresentativeCURPError,
  ] = React.useState('');
  const [
    legalRepresentativeBirthdateError,
    setLegalRepresentativeBirthdateError,
  ] = React.useState('');
  const [
    legalRepresentativeNationalityError,
    setLegalRepresentativeNationalityError,
  ] = React.useState('');
  const [
    legalRepresentativeRFCError,
    setLegalRepresentativeRFCError,
  ] = React.useState('');
  const [
    legalRepresentativeCellPhoneError,
    setLegalRepresentativeCellPhoneError,
  ] = React.useState('');
  const [
    legalRepresentativeEmailError,
    setLegalRepresentativeEmailError,
  ] = React.useState('');

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'legalRepresentativeName':
        setLegalRepresentativeName(value);
        break;
      case 'legalRepresentativeLastName':
        setLegalRepresentativeLastName(value);
        break;
      case 'legalRepresentativeSurName':
        setLegalRepresentativeSurName(value);
        break;
      case 'legalRepresentativeCURP':
        setLegalRepresentativeCURP(value.toUpperCase());
        break;
      case 'legalRepresentativeBirthdate':
        setLegalRepresentativeBirthdate(value);
        break;
      case 'legalRepresentativeNationality':
        setLegalRepresentativeNationality(value);
        break;
      case 'legalRepresentativeRFC':
        setLegalRepresentativeRFC(value.toUpperCase());
        break;
      case 'legalRepresentativeCellPhone':
        setLegalRepresentativeCellPhone(value);
        break;
      case 'legalRepresentativeEmail':
        setLegalRepresentativeEmail(value);
        break;
      default:
        console.log('Input no reconocido', name);
    }
  };

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const cleanError = () => {
    setLegalRepresentativeNameError('');
    setLegalRepresentativeLastNameError('');
    setLegalRepresentativeCURPError('');
    setLegalRepresentativeBirthdateError('');
    setLegalRepresentativeNationalityError('');
    setLegalRepresentativeRFCError('');
    setLegalRepresentativeEmailError();
    setLegalRepresentativeCellPhoneError('');
  };

  const isChanged = () => {
    const merchantProfile = props.merchantProfile || {};
    if (merchantProfile.legalRepresentativeName !== legalRepresentativeName) {
      return true;
    }
    if (
      merchantProfile.legalRepresentativeLastName !==
      legalRepresentativeLastName
    ) {
      return true;
    }
    if (
      merchantProfile.legalRepresentativeSurName !== legalRepresentativeSurName
    ) {
      return true;
    }
    if (merchantProfile.legalRepresentativeCURP !== legalRepresentativeCURP) {
      return true;
    }
    if (
      merchantProfile.legalRepresentativeBirthdate !==
      legalRepresentativeBirthdate
    ) {
      return true;
    }
    if (
      merchantProfile.legalRepresentativeNationality !==
      legalRepresentativeNationality
    ) {
      return true;
    }
    if (merchantProfile.legalRepresentativeRFC !== legalRepresentativeRFC) {
      return true;
    }
    if (
      merchantProfile.legalRepresentativeCellPhone !==
      legalRepresentativeCellPhone
    ) {
      return true;
    }
    if (merchantProfile.legalRepresentativeEmail !== legalRepresentativeEmail) {
      return true;
    }
    return false;
  };
  const callApi = async () => {
    cleanError();
    let hasErros = false;
    if (!CheckValue('empty', legalRepresentativeName)) {
      hasErros = true;
      setLegalRepresentativeNameError(
        'Por favor ingrese el nombre de la persona física'
      );
    }
    if (!CheckValue('empty', legalRepresentativeLastName)) {
      hasErros = true;
      setLegalRepresentativeLastNameError(
        'Por favor ingrese el apellido paterno de la persona física'
      );
    }
    if (!CheckValue('curp', legalRepresentativeCURP)) {
      if (legalRepresentativeCURP.length === 0) {
        setLegalRepresentativeCURPError('Ingrese una CURP válida');
      } else {
        hasErros = true;
        setLegalRepresentativeCURPError('Ingrese una CURP válida');
      }
    }
    if (!CheckValue('empty', legalRepresentativeBirthdate)) {
      hasErros = true;
      setLegalRepresentativeBirthdateError('Por favor seleccione una fecha');
    }
    if (!CheckValue('empty', legalRepresentativeNationality)) {
      hasErros = true;
      setLegalRepresentativeNationalityError('Por favor seleccione un país');
    }
    if (!CheckValue('rfc', legalRepresentativeRFC)) {
      hasErros = true;
      setLegalRepresentativeRFCError('Por favor ingrese un RFC');
    }
    if (!CheckValue('email', legalRepresentativeEmail)) {
      hasErros = true;
      setLegalRepresentativeEmailError(
        'Por favor ingrese un correo electrónico válido'
      );
    }
    if (!CheckValue('phone', '+52' + legalRepresentativeCellPhone)) {
      hasErros = true;
      setLegalRepresentativeCellPhoneError(
        'Por favor ingrese un teléfono válido de 10 digitos'
      );
    }

    if (hasErros) {
      setLoading(false);
      props.handleCallRequest(false, null);
    } else {
      if (isChanged()) {
        setLoading(true);
        try {
          const body = {
            merchantRecordKey: props.merchantProfile.merchantRecordKey,
            businessConstitution: 'FISICA',
            legalRepresentativeName,
            legalRepresentativeLastName,
            legalRepresentativeSurName,
            ...(legalRepresentativeCURP && legalRepresentativeCURP.length !== 0
              ? { legalRepresentativeCURP }
              : {}),
            legalRepresentativeBirthdate,
            legalRepresentativeNationality,
            legalRepresentativeRFC,
            legalRepresentativeCellPhone,
            legalRepresentativeEmail,
            merchantStatus: 'pending',
            updateProfile: true,
          };
          const response = await API.post('merchant-api', '/merchant', {
            body,
          });
          if (response && response.success) {
            handleAlert('success', 'Guardado con éxito.');
            props.setCompleted(false);
            await props.handleCallRequest(true, 1);
            setLoading(false);
          } else {
            console.log(response);
            handleAlert('error', response.error);
            props.handleCallRequest(false, null);
            setLoading(false);
          }
        } catch (err) {
          console.log('Error: ', err);
          setLoading(false);
          handleAlert('error', err);
          props.handleCallRequest(false, null);
        }
      } else {
        setLoading(false);
        props.handleCallRequest(true, 1);
      }
    }
  };

  return (
    <div className={classes.container}>
      {isLoading ? (
        <div className='gralinfo_form'>
          <CircularProgress color='secondary' />
        </div>
      ) : (
        <>
          <div>
            <Typography className={classes.title}>
              REPRESENTANTE LEGAL
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <DefaultInput
                  custom={{
                    handleChange,
                    value: legalRepresentativeName,
                    classLabel: 'labelInputStyleForm',
                    labelText: 'Nombre(s)',
                    id: 'legalRepresentativeName',
                    type: 'text',
                    name: 'legalRepresentativeName',
                    className: 'defaultInputTextForm col-12',
                    placeholder: 'Nombre',
                    maxLength: 40,
                    error: legalRepresentativeNameError,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInput
                  custom={{
                    handleChange,
                    value: legalRepresentativeLastName,
                    classLabel: 'labelInputStyleForm',
                    labelText: 'Apellido Paterno',
                    id: 'legalRepresentativeLastName',
                    type: 'text',
                    name: 'legalRepresentativeLastName',
                    className: 'defaultInputTextForm col-12',
                    placeholder: 'Apellido Paterno',
                    maxLength: 40,
                    error: legalRepresentativeLastNameError,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInput
                  custom={{
                    handleChange,
                    value: legalRepresentativeSurName,
                    classForm: 'inputForm',
                    classLabel: 'labelInputStyleForm',
                    labelText: 'Apellido Materno',
                    id: 'legalRepresentativeSurName',
                    type: 'text',
                    maxLength: 40,
                    name: 'legalRepresentativeSurName',
                    className: 'defaultInputTextForm col-12',
                    placeholder: 'Apellido Materno',
                    error: '',
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInput
                  custom={{
                    handleChange,
                    value: legalRepresentativeBirthdate,
                    classLabel: 'labelInputStyleForm',
                    labelText: 'Fecha de Nacimiento',
                    id: 'legalRepresentativeBirthdate',
                    type: 'date',
                    name: 'legalRepresentativeBirthdate',
                    className: 'defaultInputTextForm col-12',
                    placeholder: 'dd/mm/yyyy',
                    error: legalRepresentativeBirthdateError,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInput
                  custom={{
                    handleChange,
                    value: legalRepresentativeRFC,
                    classForm: 'inputForm col-12',
                    classLabel: 'labelInputStyleForm',
                    labelText: 'RFC con Homoclave',
                    id: 'legalRepresentativeRFC',
                    type: 'text',
                    name: 'legalRepresentativeRFC',
                    className: 'defaultInputTextForm col-12',
                    placeholder: 'AAAA000000AA0',
                    error: legalRepresentativeRFCError,
                    maxLength: '13',
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SecondarySelect
                  value={legalRepresentativeNationality}
                  onChange={handleChange}
                  id='legalRepresentativeNationality'
                  name='legalRepresentativeNationality'
                  label='Nacionalidad'
                  content={Countries().map((item) => {
                    return (
                      <>
                        <option value={item.value}>{item.label}</option>
                      </>
                    );
                  })}
                  error={legalRepresentativeNationalityError}
                />
              </Grid>
        <Grid item xs={12} md={6}>
                <DefaultInput
                  custom={{
                    handleChange,
                    value: legalRepresentativeCURP,
                    classLabel: 'labelInputStyleForm',
                    labelText: 'CURP',
                    id: 'legalRepresentativeCURP',
                    type: 'text',
                    name: 'legalRepresentativeCURP',
                    className: 'defaultInputTextForm col-12',
                    placeholder: 'AAAA000000AAAAAA00',
                    error: legalRepresentativeCURPError,
                    maxLength: '18',
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInput
                  custom={{
                    handleChange,
                    value: legalRepresentativeEmail,
                    classLabel: 'labelInputStyleForm',
                    labelText: 'Correo Electrónico',
                    id: 'legalRepresentativeEmail',
                    type: 'text',
                    maxLength: 65,
                    name: 'legalRepresentativeEmail',
                    className: 'defaultInputTextForm col-12',
                    placeholder: 'nombre@email.com',
                    error: legalRepresentativeEmailError,
                  }}
                />
              </Grid>
                                <Grid item xs={12} md={6}>
                <DefaultInput
                  custom={{
                    handleChange,
                    value: legalRepresentativeCellPhone,
                    classLabel: 'labelInputStyleForm',
                    labelText: 'Celular',
                    id: 'legalRepresentativeCellPhone',
                    type: 'text',
                    maxLength: 10,
                    name: 'legalRepresentativeCellPhone',
                    className: 'defaultInputTextForm col-12',
                    placeholder: '(000) 000 00 00',
                    error: legalRepresentativeCellPhoneError,
                  }}
                   />
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </div>
  );
}

export default withSnackbar(Tab1);
