const mapDocumentTitles = {
  'officialIdentification': 'Identificación Oficial',
  'proofOfAddress': 'Comprobante de Domicilio',
  'constitutiveAct': 'Acta Constitutiva',
  'powerLetter': 'Poderes del Representate',
  'IdentificacionOficial': 'Identificación Oficial',
  'ComprobanteDomicilio': 'Comprobante de Domicilio',
  'ActaConstitutiva': 'Acta Constitutiva',
  'CartaPoder': 'Poderes del Representate'
}

const legalDocuments = [
  'IdentificacionOficial',
  'ComprobanteDomicilio',
  'ActaConstitutiva',
  'CartaPoder',
]

const checkIncompleteLegalDocs = (merchant, as) => {
  const legalDocs = merchant.legalDocuments || []
  const recentLegalDocs = [];
  legalDocs.forEach(doc => {
    recentLegalDocs[doc.documenttype] = doc;
  });
  const incomplete = legalDocuments.map(doc => {
    if (recentLegalDocs[doc])
      return {
        docType: mapDocumentTitles[doc],
        status: recentLegalDocs[doc].documentStatus
      }
    else
      return {
        docType: mapDocumentTitles[doc],
        status: 'NOTSENT'
      }
  })
    .filter(doc => {
      const docStatus = as === 'admin' 
        ? doc.status !== 'APPROVED' 
        : doc.status === 'REJECTED'
      return merchant.businessConstitution === 'MORAL' ?
        docStatus
        :
        docStatus &&
        doc.docType !== 'Poderes del Representate' &&
        doc.docType !== 'Acta Constitutiva'
      })

  return incomplete;
}

export default checkIncompleteLegalDocs;