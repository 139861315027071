import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme =>({
  verticalContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: (props) => props.marginLeft || 0,
    marginRight: (props) => props.marginRight || 0,
    marginTop: (props) => props.marginVertical || 0,
    marginBottom: (props) => props.marginVertical || 0,
    width: (props) => (props.fullWidth ? '100%' : props.width ? props.width : 'auto'),
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: 'white',
    color: theme.palette.gray.main,
    border: 'none',
    paddingLeft: 0,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 2,
    marginLeft: (props) => props.marginLeft || 0,
    marginRight: (props) => props.marginLeft || 0,
    marginTop: (props) => props.marginVertical || 0,
    marginBottom: (props) => props.marginVertical || 0,
    width: (props) => (props.fullWidth ? '100%' : 'auto'),
    borderBottom: '2px solid #00000038',
    ...theme.typography.input,
    fontSize: 14,
    fontWeight: 600,
  },
  error: {
    ...theme.typography.errorText,
    paddingLeft: 2,
    paddingRight: 2,
    paddingBottom: 5,
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },
  label: {
    ...theme.typography.inputLabel,
    textAlign: 'left',
  },
}));

const SecondarySelect = (props) => {
  const classes = useStyles(props);

  return (
    <>
      <div className={classes.verticalContainer}>
        <text className={classes.label}>{props.label}</text>
        <select
          value={props.value || undefined}
          onChange={props.onChange || null}
          name={props.name || undefined}
          className={classes.container}
          inputProps={props.inputProps || null}
          disabled = {props.disabled || false}
        >
          {props.default}
          {props.topOptions && <optgroup label='Más frecuentes'>{props.topOptions}</optgroup>}
          {props.alphabeticalOrder && <optgroup label='Orden Alfabético'>{props.alphabeticalOrder}</optgroup>}
          {props.content}
        </select>
        {props.error && <text className={classes.error}>{props.error}</text>}
      </div>
    </>
  );
};

export default SecondarySelect;
