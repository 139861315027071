import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import PrimaryButton from '../../components/common/primaryButton';
import SecondaryButton from '../../components/common/secondaryButton';
import defaultUser from '../../assets/images/default-user.png';
import DefaultButton from '../common/defaultButton';
import DefaultCancelButton from '../common/defultCancelButton';
import FileInput from '../common/fileInput';
import { Auth, API, Storage } from 'aws-amplify';
import { withSnackbar } from 'notistack';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const useStyles = makeStyles(theme => ({
  container: {
    width: 'auto',
    height: 'auto',
    maxHeight: '90vh',
    // overflow: 'scroll',
  },
  title: {
    textAlign: 'center',
    ...theme.typography.title,
  },
  imageAndFormContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  image: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: 200,
    height: 200,
    margin: 10,
    borderRadius: 10,
  },
  checkboxText: {
    ...theme.typography.checkbox
  },
  buttons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  loading: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  helpContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
  },
  helpText: {
    ...theme.typography.mediumText,
    color: 'red',
    cursor: 'pointer',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    ...theme.globals.center,
  },
  imageSize: {
    width: 180,
    height: 'auto',
  },
  spacer: { paddingTop: 20 },
  buttonGroupContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  buttonContainer: {
    width: '100%',
    ...theme.globals.center,
  },
  fileImputContainer: {
    width: '100%',
    ...theme.globals.center
  },
  hidden: {
    visibility: 'false',
  },
  cropContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      alignItems: 'center'
    },
  }
}));

const ModalLogotipo = (props) => {
  const userKey = useSelector(state => state.user.value);
  const classes = useStyles();

  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: '%',
    width: 50,
    height: 50,
    x: 25,
    y: 25,
    aspect: 1 / 1
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [showCrop, setShowCrop] = useState(false);
  const [file, setFile] = useState({ previewImage: '', data: '' });

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const cropImage = (e) => {
    setShowCrop(true)
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  function generateDownload(canvas, crop) {
    if (!crop || !canvas) {
      return;
    }
    canvas.toBlob(
      (blob) => {
        const previewUrl = window.URL.createObjectURL(blob);
        setFile({
          previewImage: URL.createObjectURL(blob),
          data: blob,
        });
        setShowCrop(false);
      },
      'image/png',
      1
    );
  }

  const updateProfileImage = async () => {
    if (typeof file.data == 'object') {
      try {
        const getUser = await Auth.currentAuthenticatedUser();
        const getGralProfile = await API.get(
          'profile-api',
          `/profile/${userKey}`
        );
        const config = {
          contentType: file.data.type,
          level: 'public',
        };
        const filePUT = await Storage.put(
          `profiles/${getGralProfile.data[0].merchantRecordKey}/default.jpg`,
          file.data,
          config
        );
        const editProfile = await API.put(
          'profile-api',
          `/profile/${userKey}`,
          {
            body: {
              hasAvatar: true,
            },
          }
        );
        props.handleCloseProfileModal();
        handleAlert('success', 'Foto de Perfil Guardada');
      } catch (err) {
        handleAlert('error', err);
      }
    } else handleAlert('error', 'No seleccionó una nueva foto');
  };

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  return (
    <div className={classes.container}>
      <div>
        <Typography className={classes.title}>Logotipo de empresa</Typography>
      </div>
      <div className={classes.spacer} />
      {showCrop ?
        <div className={classes.cropContainer}>
          <ReactCrop
            src={upImg}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
            style={{ maxWidth: 500, height: 'auto' }}
          />
          <div className={classes.hidden}>
            <canvas
              ref={previewCanvasRef}
              // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
              style={{
                width: 0,
                height: 0,
              }}
            />
          </div>
          <PrimaryButton
            type='button'
            paddingHorizontal={25}
            onClick={() => generateDownload(previewCanvasRef.current, completedCrop)}
            primaryButtonStyle={classes.buttonStyle}
          >
            Guardar Imagen
        </PrimaryButton>
        </div>
        :
        <div>
          <div className={`${classes.imageContainer}`}>
            {file.previewImage === '' ? (
              <img src={props.profilePicture} className={classes.imageSize} />
            ) : (
              <img
                src={file.previewImage}
                className={classes.image}
              />)}
          </div>
          <div>
            <div className={classes.fileImputContainer}>
              <FileInput
                onChange={cropImage}
                type='file'
                id='myFile'
                placeholder='Sube tu logotipo'
                fullWidth
                maxWidth={180}
                marginVertical={20}
                accept="image/*"
              />
            </div>
          </div>
          <div>
            <div className={classes.buttonGroupContainer}>
              <div className={classes.buttonContainer}>
                <SecondaryButton
                  onClick={props.handleClose}
                  fullWidth
                >
                  Cancelar
            </SecondaryButton>
              </div>
              <div className={classes.buttonContainer}>
                <PrimaryButton
                  onClick={() => updateProfileImage()}
                  fullWidth
                >
                  Guardar
            </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default withSnackbar(ModalLogotipo);
