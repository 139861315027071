import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import PrimaryInput from '../primaryInput';

const useStyles = makeStyles(theme =>({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.background.main,
    // backgroundColor: 'pink',
    color: theme.palette.gray.main,
    border: 'none',
    borderRadius: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 9,
    paddingBottom: 9,
    marginLeft: (props) => props.marginLeft || 5,
    marginRight: (props) => props.marginRight || 5,
    marginTop: (props) => props.marginVertical || 5,
    marginBottom: (props) => props.marginVertical || 5,
    // width: (props) => (props.fullWidth ? '100%' : 'auto'),
    width: (props) => (props.fullWidth ? '100%' : props.width ? props.width :'auto'),
    ...theme.typography.input,
  },
  startAndInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: (props) =>
      props.centerInputText ? 'center' : 'flex-start',
    width: (props) => (props.centerInputText ? '100%' : 'auto'),
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: (props) =>
      props.centerInputText ? 'center' : 'flex-start',
    width: (props) => (props.centerInputText ? '100%' : 'auto'),
  },
  input: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: (props) =>
      props.centerInputText ? 'center' : 'flex-start',
    textAlign: (props) => (props.centerInputText ? 'center' : 'auto'),
    alignContent: 'center',
    backgroundColor: theme.palette.background.main,
    color: theme.palette.gray.main,
    ...theme.typography.input,
    border: 'none',
    borderRadius: 10,
    width: (props) => props.inputWidth ? props.inputWidth : 'auto',
  },
  startAdornment: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: 5,
  },
  endAdornment: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

const PrimarySearchBar = (props) => {
  const classes = useStyles(props);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.startAndInputContainer}>
          <div className={classes.startAdornment}>
            <SearchIcon />
          </div>
          <div className={classes.inputContainer}>
            <input
              id={props.id || undefined}
              name={props.name || undefined}
              type={props.type || 'search'}
              placeholder={props.placeholder || null}
              value={props.value || undefined}
              onChange={props.onChange || null}
              className={classes.input}
              autoFocus={props.autoFocus || false}
              placeholder={props.placeholder || null}
              onKeyPress={props.keyPress || null}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PrimarySearchBar;
