import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { withSnackbar } from 'notistack';

import '../../assets/css/main.css';
import LegalHeader from '../../components/headers/legalHeader';
import TermsAndConditionsData from './termsAndConditions.json';
import { Grid } from '@material-ui/core';

const TermsAndConditions = (props) => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  return (
    <div className={!props.from && classes.background}>
      <LegalHeader greenLetters></LegalHeader>
      {isLoading ? (
        <div className='styleCircularProgress'>
          <CircularProgress color='secondary' />
        </div>
      ) : (
        <>
          <div className={classes.container}>
            {TermsAndConditionsData.content.map((element) => {
              switch (element.type) {
                case 'title':
                  return (
                    <>
                      <text
                        className={`${classes.title} ${classes.defaultText}`}
                      >
                        {element.value}
                      </text>
                      {element.space ? <br /> : null}
                      {element.doubleSpace ?
                        <>
                          <br />
                          <br />
                        </> :
                        null
                      }
                    </>
                  );
                case 'date':
                  return (
                    <>
                      <text
                        className={`${classes.subTitle} ${classes.defaultText}`}
                      >
                        {element.value}
                      </text>
                      {element.space ? <br /> : null}
                      {element.doubleSpace ? <><br /><br /></> : null}
                    </>
                  );
                case 'subtitle':
                  return (
                    <>
                      <br />
                      <text
                        className={`${classes.subTitle} ${classes.defaultText}`}
                      >
                        {element.value}
                      </text>
                      {element.space ? <br /> : null}
                      <br />
                    </>
                  );
                case 'paragraph':
                  return (
                    <>
                      <text className={`${classes.defaultText}`}>
                        {element.value}
                      </text>
                      {element.space ? <br /> : null}
                      {element.doubleSpace ? <><br /><br /></> : null}
                    </>
                  );
                case 'list':
                  return (
                    <>
                      <span className={`${classes.defaultText}`}>
                        {'• '}
                        {element.value}
                        {element.space ? <br /> : null}
                        {element.doubleSpace ? (
                          <>
                            <br />
                            <br />
                          </>
                        ) : null}
                      </span>
                    </>
                  );
                case 'link':
                  return (
                    <>
                      <a
                        href={element.value}
                        target='_blank'
                        className={`${classes.link} ${classes.defaultText}`}
                        rel='noopener noreferrer'
                      >
                        {element.value}
                      </a>
                      {element.space ? <br /> : null}
                      {element.doubleSpace ?
                        <>
                          <br />
                          <br />
                        </> :
                        null
                      }
                    </>
                  );
                case 'link-email':
                  return (
                    <>
                      <a
                        href={`mailto:${element.value}`}
                        target='_blank'
                        className={`${classes.link} ${classes.defaultText}`}
                        rel='noopener noreferrer'
                      >
                        {element.value}
                      </a>
                      {element.space ? <br /> : null}
                      {element.doubleSpace ? <><br /><br /></> : null}
                    </>
                  );
                case 'paragraph-link':
                  return (
                    <text className={`${classes.defaultText}`}>
                      {element.textBefore || null}
                      <a
                        href={element.link}
                        target='_blank'
                        className={`${classes.link} ${classes.defaultText}`}
                        rel='noopener noreferrer'
                      >
                        {element.link ? ` ${element.link}` : null}
                      </a>
                      {element.textMiddle ? ` ${element.textMiddle}` : null}
                      {element.link2 ? (
                        <a
                          href={element.link}
                          target='_blank'
                          className={`${classes.link} ${classes.defaultText}`}
                          rel='noopener noreferrer'
                        >
                          {' '}
                          {element.link2}
                        </a>
                      ) : null}
                      {element.textAfter ? ` ${element.textAfter}` : null}
                      <>
                        {element.endPeriod && '.'}
                        {element.space ? <br /> : null}
                        {element.doubleSpace ? (
                          <>
                            <br />
                            <br />
                          </>
                        ) : null}
                      </>
                    </text>
                  );
                case 'table-header':
                  return (
                    <Grid container className={classes.tableHeader}>
                      <Grid item>
                        <text className={classes.tableHeaderText}>
                          {element.value}
                          {element.space ? <br /> : null}
                          {element.doubleSpace ? (
                            <>
                              <br />
                              <br />
                            </>
                          ) : null}
                        </text>
                      </Grid>
                    </Grid>
                  );
                case 'table-row':
                  return (
                    <>
                      <div className={classes.tableRow}>
                        <div
                          className={`
                                ${classes.defaultColumn}
                                ${classes.leftColumn}
                                ${element.topRow ? classes.topRow : null}
                                ${element.bottomRow ? classes.bottomRow : null}
                                `}
                        >
                          <text className={classes.paragraph}>
                            {element.column1}
                          </text>
                        </div>
                        <div
                          className={`
                           ${classes.defaultColumn}
                           ${element.topRow ? classes.topRow : null}
                           ${element.bottomRow ? classes.bottomRow : null}
                           `}
                        >
                          <text className={classes.paragraph}>
                            {element.column2}
                          </text>
                        </div>
                        <div
                          className={`
                         ${classes.defaultColumn}
                         ${classes.rightColumn}
                         ${element.topRow ? classes.topRow : null}
                         ${element.bottomRow ? classes.bottomRow : null}
                         `}
                        >
                          <text className={classes.paragraph}>
                            {element.column3}
                          </text>
                        </div>
                      </div>
                      {element.space || element.doubleSpace ? (
                        <text className={classes.paragraph}>
                          {element.space ? <br /> : null}
                          {element.doubleSpace ? (
                            <>
                              <br />
                              <br />
                            </>
                          ) : null}
                        </text>
                      ) : null}
                    </>
                  );
                default:
                  return (
                    <text
                      className={`${classes.subTitle} ${classes.defaultText}`}
                    >
                      {element.value}
                    </text>
                  );
              }
            })}
          </div>

        </>
      )}
    </div>
  );
};

export default withSnackbar(TermsAndConditions);

const rem = 16;

const useStyles = makeStyles(theme => ({
  background: {
    backgroundColor: theme.palette.primary.main,
    width: '100vw',
    padding: '3%',
  },
  container: {
    width: '100%',
    height: 'auto',
    backgroundColor: 'white',
    borderRadius: '20px',
    overflow: 'hidden',
    padding: '3%',
  },
  defaultText: {
    textAlign: 'left',
    ...theme.typography.paragraph,
    fontWeight: 500,
  },
  title: {
    fontSize: 2.25 * rem,
    fontWeight: 700,
  },
  subTitle: {
    fontSize: 1.25 * rem,
    fontWeight: 700,
  },
  link: {
    color: theme.palette.primary.main,
    ':visited': {
      color: 'pink',
    },
  },
  tableHeader: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: 'center',
    border: `2px solid ${theme.palette.gray.main} `,
  },
  tableHeaderText: {
    ...theme.typography.paragraph,
    color: theme.palette.white.main,
  },
  tableRow: {
    ...theme.globals.center,
    display: 'inline-flex',
    alignItems: 'stretch',
    width: '100%',
    height: 'auto',
  },
  defaultColumn: {
    ...theme.globals.center,
    flex: 1,
    flexBasis: 0,
    flexShrink: 1,
    flexGrow: 1,
    flexWrap: 'wrap',
    whiteSpace: 'wrap',
    textOverflow: 'ellipsis',
    overflow: 'auto',
    width: '100%',
    paddingLeft: 5,
    paddingRight: 5,
    border: `1px solid ${theme.palette.gray.main} `,
  },
  topRow: {
    borderTop: 0,
  },
  bottomRow: {
    borderBottom: `2px solid ${theme.palette.gray.main} `,
  },
  rightColumn: {
    borderRight: `2px solid ${theme.palette.gray.main} `,
  },
  leftColumn: {
    borderLeft: `2px solid ${theme.palette.gray.main} `,
  },
}));
