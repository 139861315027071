import React from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar  } from 'notistack';
import { API } from 'aws-amplify';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux';
import { API_MERCHANT } from '../../../../lib/constants'

import '../../../../assets/css/main.css';
import Contract from '../../../common/contract';
import Terms from '../../../../pages/termsAndConditions';

const useStyles = makeStyles(theme =>({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    rowStyle: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 15,
        flexWrap: 'wrap'
    },
    F1: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center'
    },
    checkLabel: {
        ...theme.typography.mediumText,
        lineHeight: 17,
        color: theme.palette.gray.main,
    }
}));

/*
La version uno estara hardcode hasta 
implementar un sistema de control de 
versiones para el contrato
*/
const currentContractVersion = 'V1'

function Tab4(props) {
    const version = useSelector(state => state.contractVersion.value);

    useEffect(()=>{
        if (props.ExecutingRequest) {
            callApi()
        }
    },[props.ExecutingRequest])

  const classes = useStyles();

  const [isLoading, setLoading] = React.useState(false);
  const [agree, setAgree] = React.useState(false);

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar( message, { variant });
  };

  const handleChange = () => {
    setAgree(!agree)
  };

  const callApi = async () => {
    if (agree) {
        try {
            await API.post(API_MERCHANT, '/merchant', {
                body: {
                    merchantRecordKey: props.merchantProfile.merchantRecordKey,
                    merchantStatus: "progress",
                    contract_version: version,
                  },
            });
            handleAlert('success', 'Guardado con Éxito!')
            await props.handleCallRequest(true, 'END')
            setLoading(false)
        } catch (err) {
            console.log('Error: ', err);
            setLoading(false)
            handleAlert('error', err)
            props.handleCallRequest(false, null)
        }
    } else {
        handleAlert('error', 'Debes aceptar los términos y condiciones para continuar')
        props.handleCallRequest(false, null)
    }
  }

  return (
    <div className={classes.root}>
        {isLoading ?
            <div className='gralinfo_form'>
                <CircularProgress color="secondary" />
            </div>
        : 
        <>
            <Terms from={'profile'}/>
            {/* <Contract merchantProfile={props.merchantProfile} /> */}
            {!props.isCompleted && <div className={classes.rowStyle}>
                <div className={classes.F1}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={agree}
                                onChange={(handleChange)}
                                color="#6EB89D"
                            />
                        }
                        label="He leído y acepto los términos de este contrato."
                        className={classes.checkLabel}
                    />
                </div>
            </div>}
        </>}
    </div>
  );
}

export default withSnackbar(Tab4);