import React from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { API } from 'aws-amplify';
import CircularProgress from '@material-ui/core/CircularProgress';

import '../../../../assets/css/main.css';
import DefaultInput from '../../../common/defaultInput'
import DefaultSelect from '../../../common/defaultSelect'
import Countries from '../../../../lib/locations/countries'
import CheckValue from '../../../../lib/formatValidations'
import { Typography } from "@material-ui/core";
import SecondarySelect from '../../../common/secondarySelect';

const useStyles = makeStyles(theme =>({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  firstRowStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  rowStyle: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 25,
    flexWrap: 'wrap'
  },
  F1: {
    flex: 1
  },
  F2: {
    flex: 2
  },
  title: {
    ...theme.typography.smallText,
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.gray.main,
    mixBlendMode: 'normal',
  },
}));

function Tab1(props) {
  useEffect(() => {
    if (props.ExecutingRequest) {
      callApi()
    }
  }, [props.ExecutingRequest])

  const classes = useStyles();

  const genderList = [
    { label: 'Selecciona una categoría', section: 'Default' },
    { label: 'Masculino', value: 'Masculino', section: 'content' },
    { label: 'Femenino', value: 'Femenino', section: 'content' },
    { label: 'No binario', value: 'No binario', section: 'content' },
  ]

  const [isLoading, setLoading] = React.useState(false);

  const [legalRepresentativeName, setLegalRepresentativeName] = React.useState(
    props.merchantProfile.legalRepresentativeName ?
      props.merchantProfile.legalRepresentativeName :
      '');
  const [legalRepresentativeLastName, setLegalRepresentativeLastName] = React.useState(
    props.merchantProfile.legalRepresentativeLastName ?
      props.merchantProfile.legalRepresentativeLastName :
      '');
  const [legalRepresentativeSurName, setLegalRepresentativeSurName] = React.useState(
    props.merchantProfile.legalRepresentativeSurName ?
      props.merchantProfile.legalRepresentativeSurName :
      '');
  const [legalRepresentativeBirthdate, setLegalRepresentativeBirthdate] = React.useState(
    props.merchantProfile.legalRepresentativeBirthdate ?
      props.merchantProfile.legalRepresentativeBirthdate :
      '');
  const [legalRepresentativeGender, setLegalRepresentativeGender] = React.useState(
    props.merchantProfile.legalRepresentativeGender ?
      props.merchantProfile.legalRepresentativeGender :
      '');
  const [legalRepresentativeNationality, setLegalRepresentativeNationality] = React.useState(
    props.merchantProfile.legalRepresentativeNationality ?
      props.merchantProfile.legalRepresentativeNationality :
      '');
  const [legalRepresentativeCURP, setLegalRepresentativeCURP] = React.useState(
    props.merchantProfile.legalRepresentativeCURP ?
      props.merchantProfile.legalRepresentativeCURP :
      '');
  const [legalRepresentativeRFC, setLegalRepresentativeRFC] = React.useState(
    props.merchantProfile.legalRepresentativeRFC ?
      props.merchantProfile.legalRepresentativeRFC :
      '');
  const [legalRepresentativeRole, setLegalRepresentativeRole] = React.useState(
    props.merchantProfile.legalRepresentativeRole ?
      props.merchantProfile.legalRepresentativeRole :
      '');
  const [legalRepresentativeLandPhone, setLegalRepresentativeLandPhone] = React.useState(
    props.merchantProfile.legalRepresentativeLandPhone ?
      props.merchantProfile.legalRepresentativeLandPhone :
      '');
  const [legalRepresentativeCellPhone, setLegalRepresentativeCellPhone] = React.useState(
    props.merchantProfile.legalRepresentativeCellPhone ?
      props.merchantProfile.legalRepresentativeCellPhone :
      '');
  const [legalRepresentativeEmail, setLegalRepresentativeEmail] = React.useState(
    props.merchantProfile.legalRepresentativeEmail ?
      props.merchantProfile.legalRepresentativeEmail :
      '');

  const [legalRepresentativeNameError, setLegalRepresentativeNameError] = React.useState('');
  const [legalRepresentativeLastNameError, setLegalRepresentativeLastNameError] = React.useState('');
  const [legalRepresentativeBirthdateError, setLegalRepresentativeBirthdateError] = React.useState('');
  const [legalRepresentativeGenderError, setLegalRepresentativeGenderError] = React.useState('');
  const [legalRepresentativeNationalityError, setLegalRepresentativeNationalityError] = React.useState('');
  const [legalRepresentativeCURPError, setLegalRepresentativeCURPError] = React.useState('');
  const [legalRepresentativeRFCError, setLegalRepresentativeRFCError] = React.useState('');
  const [legalRepresentativeRoleError, setLegalRepresentativeRoleError] = React.useState('');
  const [legalRepresentativeLandPhoneError, setLegalRepresentativeLandPhoneError] = React.useState('');
  const [legalRepresentativeCellPhoneError, setLegalRepresentativeCellPhoneError] = React.useState('');
  const [legalRepresentativeEmailError, setLegalRepresentativeEmailError] = React.useState('');

  const handleChange = (event) => {
    const { name, value } = event.target
    switch (name) {
      case 'legalRepresentativeName':
        setLegalRepresentativeName(value)
        break;
      case 'legalRepresentativeLastName':
        setLegalRepresentativeLastName(value)
        break;
      case 'legalRepresentativeSurName':
        setLegalRepresentativeSurName(value)
        break;
      case 'legalRepresentativeBirthdate':
        setLegalRepresentativeBirthdate(value)
        break;
      case 'legalRepresentativeGender':
        setLegalRepresentativeGender(value)
        break;
      case 'legalRepresentativeNationality':
        setLegalRepresentativeNationality(value)
        break;
      case 'legalRepresentativeCURP':
        setLegalRepresentativeCURP(value)
        break;
      case 'legalRepresentativeRFC':
        setLegalRepresentativeRFC(value)
        break;
      case 'legalRepresentativeRole':
        setLegalRepresentativeRole(value)
        break;
      case 'legalRepresentativeLandPhone':
        setLegalRepresentativeLandPhone(value)
        break;
      case 'legalRepresentativeCellPhone':
        setLegalRepresentativeCellPhone(value)
        break;
      case 'legalRepresentativeEmail':
        setLegalRepresentativeEmail(value)
        break;

      default:
        console.log('Input no reconocido', name);
    }
  };

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const cleanError = () => {
    setLegalRepresentativeNameError('')
    setLegalRepresentativeLastNameError('')
    setLegalRepresentativeBirthdateError('')
    setLegalRepresentativeGenderError('')
    setLegalRepresentativeNationalityError('')
    setLegalRepresentativeCURPError('');
    setLegalRepresentativeRFCError('')
    setLegalRepresentativeLandPhoneError('')
    setLegalRepresentativeCellPhoneError('')
    setLegalRepresentativeEmailError('')
  }

  const isChanged = () => {
    const merchantProfile = props.merchantProfile || {};
    if (merchantProfile.legalRepresentativeName !== legalRepresentativeName) { return true }
    if (merchantProfile.legalRepresentativeLastName !== legalRepresentativeLastName) { return true }
    if (merchantProfile.legalRepresentativeSurName !== legalRepresentativeSurName) { return true }
    if (merchantProfile.legalRepresentativeBirthdate !== legalRepresentativeBirthdate) { return true }
    if (merchantProfile.legalRepresentativeGender !== legalRepresentativeGender) { return true }
    if (merchantProfile.legalRepresentativeNationality !== legalRepresentativeNationality) { return true }
    if (merchantProfile.legalRepresentativeCURP !== legalRepresentativeCURP) { return true }
    if (merchantProfile.legalRepresentativeRFC !== legalRepresentativeRFC) { return true }
    if (merchantProfile.legalRepresentativeRole !== legalRepresentativeRole) { return true }
    if (merchantProfile.legalRepresentativeLandPhone !== legalRepresentativeLandPhone) { return true }
    if (merchantProfile.legalRepresentativeCellPhone !== legalRepresentativeCellPhone) { return true }
    if (merchantProfile.legalRepresentativeEmail !== legalRepresentativeEmail) { return true }
    return false
  }

  const callApi = async () => {
    cleanError()
    let hasErros = false
    if (!CheckValue('empty', legalRepresentativeName)) {
      hasErros = true
      setLegalRepresentativeNameError('Escriba el nombre del Representante Legal de la empresa')
    }
    if (!CheckValue('empty', legalRepresentativeLastName)) {
      hasErros = true
      setLegalRepresentativeLastNameError('Escriba el apellido paterno del Representante Legal')
    }
    if (!CheckValue('empty', legalRepresentativeBirthdate)) {
      hasErros = true
      setLegalRepresentativeBirthdateError('Por favor seleccione una fecha de nacimiento')
    }
    if (!CheckValue('empty', legalRepresentativeGender)) {
      hasErros = true
      setLegalRepresentativeGenderError('Por favor seleccione un género')
    }
    if (!CheckValue('empty', legalRepresentativeNationality)) {
      hasErros = true
      setLegalRepresentativeNationalityError('Por favor seleccione una opción')
    }
    if (!CheckValue('curp', legalRepresentativeCURP)) {
      hasErros = true
      setLegalRepresentativeCURPError('Por favor ingrese un RFC válido de almenos 12 dígitos')
    }
    if (!CheckValue('rfc', legalRepresentativeRFC)) {
      hasErros = true
      setLegalRepresentativeRFCError('Por favor ingrese un RFC válido de almenos 12 dígitos')
    }
    if (!CheckValue('phone', '+52' + legalRepresentativeLandPhone)) {
      hasErros = true
      setLegalRepresentativeLandPhoneError('Por favor ingrese un teléfono válido de 10 digitos')
    }
    if (CheckValue('empty', legalRepresentativeCellPhone)) {
      if (!CheckValue('phone', '+52' + legalRepresentativeCellPhone)) {
        hasErros = true
        setLegalRepresentativeCellPhoneError('Por favor ingrese un teléfono válido de 10 digitos')
      }
    }
    if (CheckValue('empty', legalRepresentativeEmail)) {
      if (!CheckValue('email', legalRepresentativeEmail)) {
        hasErros = true
        setLegalRepresentativeEmailError('Por favor ingrese un correo electrónico válido')
      }
    }

    if (hasErros) {
      setLoading(false)
      props.handleCallRequest(false, null)
    } else {
      if (isChanged()) {
        setLoading(true)
        try {
          await API.post('merchant-api', '/merchant', {
            body: {
              merchantRecordKey: props.merchantProfile.merchantRecordKey,
              businessConstitution: "MORAL",
              legalRepresentativeName,
              legalRepresentativeLastName,
              legalRepresentativeSurName,
              legalRepresentativeBirthdate,
              legalRepresentativeGender,
              legalRepresentativeNationality,
              legalRepresentativeCURP,
              legalRepresentativeRFC,
              legalRepresentativeRole,
              legalRepresentativeLandPhone,
              legalRepresentativeCellPhone,
              legalRepresentativeEmail,
              merchantStatus: 'pending',
              updateProfile: true,
            },
          });
          props.setCompleted(false)
          handleAlert('success', 'Guardado con Éxito!')
          await props.handleCallRequest(true, 1);
          setLoading(false);
        } catch (err) {
          console.log('Error: ', err);
          setLoading(false)
          handleAlert('error', err.message)
          props.handleCallRequest(false, null)
        }
      } else {
        setLoading(false)
        props.handleCallRequest(true, 1)
      }
    }
  }

  return (
    <div className={classes.root}>
      {isLoading ?
        <div className='gralinfo_form'>
          <CircularProgress color="secondary" />
        </div>
        :
        <>
          <Typography className={classes.title}>REPRESENTANTE LEGAL</Typography>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
              <DefaultInput custom={{
                handleChange,
                value: legalRepresentativeName,
                classForm: "inputForm",
                classLabel: "labelInputStyleForm",
                labelText: "Nombre",
                id: "legalRepresentativeName",
                type: "text",
                name: "legalRepresentativeName",
                className: "defaultInputTextForm col-12",
                placeholder: "Nombre",
                error: legalRepresentativeNameError
              }} />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
              <DefaultInput custom={{
                handleChange,
                value: legalRepresentativeLastName,
                classForm: "inputForm",
                classLabel: "labelInputStyleForm",
                labelText: "Apellido Paterno",
                id: "legalRepresentativeLastName",
                type: "text",
                name: "legalRepresentativeLastName",
                className: "defaultInputTextForm col-12",
                placeholder: "Apellido Paterno",
                error: legalRepresentativeLastNameError
              }} />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
              <DefaultInput custom={{
                handleChange,
                value: legalRepresentativeSurName,
                classForm: "inputForm",
                classLabel: "labelInputStyleForm",
                labelText: "Apellido Materno",
                id: "legalRepresentativeSurName",
                type: "text",
                name: "legalRepresentativeSurName",
                className: "defaultInputTextForm col-12",
                placeholder: "Apellido Materno",
                error: ""
              }} />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
              <DefaultInput custom={{
                handleChange,
                value: legalRepresentativeBirthdate,
                classForm: "inputForm",
                classLabel: "labelInputStyleForm",
                labelText: "Fecha de Nacimiento",
                id: "legalRepresentativeBirthdate",
                type: "date",
                name: "legalRepresentativeBirthdate",
                className: "defaultInputTextForm col-12",
                placeholder: "dd/mm/yyyy",
                error: legalRepresentativeBirthdateError
              }} />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
              <SecondarySelect
                value={legalRepresentativeGender}
                onChange={handleChange}
                id='legalRepresentativeGender'
                name='legalRepresentativeGender'
                label='Género'
                content={genderList.map((item) => {
                  return (
                    <>
                      <option value={item.value}>{item.label}</option>
                    </>
                  );
                })}
                error={legalRepresentativeGenderError}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
              <SecondarySelect
                value={legalRepresentativeNationality}
                onChange={handleChange}
                id='legalRepresentativeNationality'
                name='legalRepresentativeNationality'
                label='Nacionalidad'
                content={Countries().map((item) => {
                  return (
                    <>
                      <option value={item.value}>{item.label}</option>
                    </>
                  );
                })}
                error={legalRepresentativeNationalityError}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
              <DefaultInput custom={{
                handleChange,
                value: legalRepresentativeCURP,
                classForm: "inputForm",
                classLabel: "labelInputStyleForm",
                labelText: "CURP",
                id: "legalRepresentativeCURP",
                type: "text",
                name: "legalRepresentativeCURP",
                className: "defaultInputTextForm col-12",
                placeholder: "AAAA0000AAA0000",
                error: legalRepresentativeCURPError
              }} />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
              <DefaultInput custom={{
                handleChange,
                value: legalRepresentativeRFC,
                classForm: "inputForm",
                classLabel: "labelInputStyleForm",
                labelText: "RFC",
                id: "legalRepresentativeRFC",
                type: "text",
                name: "legalRepresentativeRFC",
                className: "defaultInputTextForm col-12",
                placeholder: "AAAA000000AA0",
                error: legalRepresentativeRFCError
              }} />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
              <DefaultInput custom={{
                handleChange,
                value: legalRepresentativeRole,
                classForm: "inputForm",
                classLabel: "labelInputStyleForm",
                labelText: "Cargo en la Empresa",
                id: "legalRepresentativeRole",
                type: "text",
                name: "legalRepresentativeRole",
                className: "defaultInputTextForm col-12",
                placeholder: "Director Ejecutivo",
                error: legalRepresentativeRoleError
              }} />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
              <DefaultInput custom={{
                handleChange,
                value: legalRepresentativeLandPhone,
                classForm: "inputForm",
                classLabel: "labelInputStyleForm",
                labelText: "Teléfono",
                id: "legalRepresentativeLandPhone",
                type: "text",
                name: "legalRepresentativeLandPhone",
                className: "defaultInputTextForm col-12",
                placeholder: "(000) 000 00 00",
                error: legalRepresentativeLandPhoneError
              }} />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
              <DefaultInput custom={{
                handleChange,
                value: legalRepresentativeCellPhone,
                classForm: "inputForm",
                classLabel: "labelInputStyleForm",
                labelText: "Celular",
                id: "legalRepresentativeCellPhone",
                type: "text",
                name: "legalRepresentativeCellPhone",
                className: "defaultInputTextForm col-12",
                placeholder: "(000) 000 00 00",
                error: legalRepresentativeCellPhoneError
              }} />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
              <DefaultInput custom={{
                handleChange,
                value: legalRepresentativeEmail,
                classForm: "inputForm",
                classLabel: "labelInputStyleForm",
                labelText: "Correo Electrónico",
                id: "legalRepresentativeEmail",
                type: "text",
                name: "legalRepresentativeEmail",
                className: "defaultInputTextForm col-12",
                placeholder: "nombre@email.com",
                error: legalRepresentativeEmailError
              }} />
            </div>
          </div>
        </>}
    </div>
  );
}

export default withSnackbar(Tab1)