import React, { useState } from 'react';
import { API, Auth } from 'aws-amplify';
import { API_ANCHORS } from '../../../lib/constants';
import { useLocation, useHistory } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, CircularProgress } from '@material-ui/core';
import PrimaryInput from '../../common/primaryInput';
import PrimarySelect from '../../common/primarySelect';
import PrimaryButton from '../../common/primaryButton';
import STATES from '../../../lib/locations/states';
import CITIES from '../../../lib/locations/statesCities';
import CheckValue from '../../../lib/formatValidations';

const AUTH_DATA = {
  email: '',
  tempPassword: '',
};

const EMPTY_ANCHOR = {
  name: '',
  paternalLastName: '',
  maternalLastName: '',
  curp: '',
  role: '',
  state: '',
  city: '',
  address: '',
  baseSalary: '',
};

const ROLES = ['Jr', 'Sr'];

const Anchor = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const isEdit = location?.state?.anchor ? true : false;
  const title = isEdit ? 'Editar Ancla' : 'Registrar Ancla';

  const [loading, setLoading] = useState(false);
  const [authData, setAuthData] = useState({ ...AUTH_DATA });
  const [authErrors, setAuthErrors] = useState({ ...AUTH_DATA });
  const [anchor, setAnchor] = useState(isEdit ? location?.state?.anchor : { ...EMPTY_ANCHOR, role: 'Jr' });
  const [errors, setErrors] = useState({ ...EMPTY_ANCHOR });

  const handleChange = (event) => {
    const id = event.target.id;
    const value = event.target.value;
    if (id === 'state' && value === 'Selecciona un estado') {
      setAnchor({ ...anchor, [id]: '' });
    } else if (id === 'baseSalary' && isNaN(value)) {
      return;
    } else {
      const newErrors = { ...errors };
      newErrors[id] = '';
      setAnchor({ ...anchor, [id]: value });
      setErrors(newErrors);
    }
  }

  const handleAuthChange = (event) => {
    const id = event.target.id;
    const value = event.target.value;
    const newAuthErrors = { ...authErrors };
    newAuthErrors[id] = '';
    setAuthData({ ...authData, [id]: value });
    setAuthErrors(newAuthErrors);
  }

  const checkForErrors = () => {
    let error = false;
    const newErrors = { ...errors };

    //Anchor Data
    if (Number(anchor.baseSalary) < 1000) {
      error = true;
      newErrors['baseSalary'] = 'El sueldo base mínimo es $1000.00';
    }
    Object.keys(anchor).forEach(key => {
      if (anchor[key] === '') {
        error = true;
        newErrors[key] = 'Campo vacío';
      }
    });

    //Auth Data
    if (!isEdit) {
      const newAuthErrors = { ...authErrors };
      if (!CheckValue('email', authData.email)) {
        error = true;
        newAuthErrors['email'] = 'Correo electrónico no válido';
      }
      if (!CheckValue('password', authData.tempPassword)) {
        error = true;
        newAuthErrors['tempPassword'] = 'La contraseña debe tener mínimo 6 caracteres, un caracter alfanumérico (A-Z,a-z,0-9) y un caracter especial (@$!%*?&)';
      }
      Object.keys(authData).forEach(key => {
        if (authData[key] === '') {
          error = true;
          newAuthErrors[key] = 'Campo vacío';
        }
      });
      setAuthErrors(newAuthErrors);
    }

    setErrors(newErrors);
    return error;
  }

  const registerAnchor = async () => {
    try {
      setLoading(true);
      if (!checkForErrors()) {
        if (isEdit) {
          //Edit Anchor
          const key = anchor.anchorRecordKey;
          const updatedAnchor = { ...anchor };
          delete updatedAnchor.anchorRecordKey;
          await API.put(API_ANCHORS, `/anchors/${key}`, {
            body: {
              anchor: updatedAnchor,
            }
          });
          props.enqueueSnackbar('Ancla editado con éxito', { variant: 'success' });
          history.goBack();
        } else {
          //Add New Anchor
          const userType = 'anchor';
          const newUser = {
            username: authData.email,
            password: authData.tempPassword,
            attributes: {
              'custom:usertype': userType,
              'custom:firstName': anchor.name,
              'custom:lastName': `${anchor.paternalName} ${anchor.maternalName}`,
              'custom:temporaryPassword': authData.tempPassword,
            },
            validationData: [],
          };
          const { userSub } = await Auth.signUp(newUser);
          const newAnchor = { ...anchor, email: authData.email };
          await API.post(API_ANCHORS, '/anchors', {
            body: {
              anchorRecordKey: userSub,
              anchor: newAnchor,
            },
          });
          props.enqueueSnackbar('Ancla registrado con éxito', { variant: 'success' });
          history.goBack();
        }
      }
    } catch (err) {
      props.enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>
        {title}
      </Typography>
      <Grid className={classes.inputsContainer} spacing={5} container>
        <Grid xs={6} item>
          {!isEdit && (
            <>
              <div className={classes.inputContainer}>
                <Typography>Email</Typography>
                <PrimaryInput
                  id={'email'}
                  value={authData.email}
                  onChange={handleAuthChange}
                  error={authErrors.email}
                />
              </div>
              <div className={classes.inputContainer}>
                <Typography>Contraseña temporal</Typography>
                <PrimaryInput
                  id={'tempPassword'}
                  value={authData.tempPassword}
                  onChange={handleAuthChange}
                  error={authErrors.tempPassword}
                />
              </div>
            </>
          )}
          <div className={classes.inputContainer}>
            <Typography>Nombre(s)</Typography>
            <PrimaryInput
              id={'name'}
              value={anchor.name}
              onChange={handleChange}
              error={errors.name}
            />
          </div>
          <div className={classes.inputContainer}>
            <Typography>Apellido Paterno</Typography>
            <PrimaryInput
              id={'paternalLastName'}
              value={anchor.paternalLastName}
              onChange={handleChange}
              error={errors.paternalLastName}
            />
          </div>
          <div className={classes.inputContainer}>
            <Typography>Apellido Materno</Typography>
            <PrimaryInput
              id={'maternalLastName'}
              value={anchor.maternalLastName}
              onChange={handleChange}
              error={errors.maternalLastName}
            />
          </div>
          <div className={classes.inputContainer}>
            <Typography>CURP</Typography>
            <PrimaryInput
              id={'curp'}
              value={anchor.curp}
              onChange={handleChange}
              error={errors.curp}
            />
          </div>
        </Grid>
        <Grid xs={6} item>
          <div className={classes.inputContainer}>
            <Typography>Rango</Typography>
            <PrimarySelect
              id={'role'}
              value={anchor.role}
              content={ROLES.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
              onChange={handleChange}
              error={errors.role}
              paddingVertical={8}
            />
          </div>
          <div className={classes.inputContainer}>
            <Typography>Estado</Typography>
            <PrimarySelect
              id={'state'}
              value={anchor.state}
              content={STATES().map((item) => (
                <option key={item.label} value={item.label}>
                  {item.label}
                </option>
              ))}
              onChange={handleChange}
              error={errors.state}
              paddingVertical={8}
            />
          </div>
          <div className={classes.inputContainer}>
            <Typography>Ciudad</Typography>
            <PrimarySelect
              id={'city'}
              value={anchor.city}
              content={anchor.state !== '' ? CITIES(anchor.state).map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              )) : []}
              onChange={handleChange}
              disabled={anchor.state === ''}
              error={errors.state}
              paddingVertical={8}
            />
          </div>
          <div className={classes.inputContainer}>
            <Typography>Dirección</Typography>
            <PrimaryInput
              id={'address'}
              value={anchor.address}
              onChange={handleChange}
              error={errors.address}
            />
          </div>
          <div className={classes.inputContainer}>
            <Typography>Sueldo Base</Typography>
            <PrimaryInput
              id={'baseSalary'}
              placeholder='0.00'
              type='number'
              value={anchor.baseSalary}
              onChange={handleChange}
              startAdornment={<Typography>$</Typography>}
              error={errors.baseSalary}
            />
          </div>
          <div className={classes.buttonContainer}>
            <PrimaryButton fullWidth onClick={registerAnchor}>
              {title}
            </PrimaryButton>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default withSnackbar(Anchor);

const useStyles = makeStyles(theme => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '40vh',
  },
  container: {
    padding: '30px',
  },
  title: {
    ...theme.typography.title,
    marginBottom: 30,
  },
  inputContainer: {
    height: 90,
  },
  buttonContainer: {
    marginTop: 20,
  },
}));