import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextareaAutosize from 'react-textarea-autosize';

const useStyles = makeStyles(theme =>({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.main,
    color: theme.palette.gray.main,
    border: 'none',
    borderRadius: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    marginLeft: 5,
    marginRight: 5,
    marginTop: (props) => (props.marginVertical || 0),
    marginBottom: (props) => (props.marginVertical || 0),
    width: (props) => (props.fullWidth ? '100%' : 'auto'),
    ...theme.typography.input,
  },
  startAndInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: (props) => (props.fullInputWidth ? '100%' : null),
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: (props) => (props.fullInputWidth ? '100%' : null),
  },
  input: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',
    backgroundColor: theme.palette.background.main,
    color: theme.palette.gray.main,
    ...theme.typography.input,
    border: 'none',
    borderRadius: 10,
    width: (props) => (props.fullInputWidth ? '100%' : null),
    overflow: 'hidden'
  },
  startAdornment: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginRight: 5,
  },
  endAdornment: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  error: {
    ...theme.typography.errorText,
    paddingLeft: 15,
    paddingRight: 10,
    paddingBottom: 5,
    whiteSpace: 'nowrap',
  },
}));

const MultilineInput = (props) => {
  const classes = useStyles(props);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.startAndInputContainer}>
          {props.startAdornment && (
            <div className={classes.startAdornment}>{props.startAdornment}</div>
          )}
          <div className={classes.inputContainer}>
            <TextareaAutosize
              id={props.id || undefined}
              name={props.name || undefined}
              type={props.type || 'text'}
              placeholder={props.placeholder || null}
              value={props.value || undefined}
              onChange={props.onChange || null}
              className={classes.input}
              maxLength={props.maxLength || 65}
              minRows={props.minRows || 3}
            />
          </div>
        </div>
        {props.endAdornment && (
          <div className={classes.endAdornment}>{props.endAdornment}</div>
        )}
      </div>
      {props.error && <text className={classes.error}>{props.error}</text>}
    </>
  );
};

export default MultilineInput;
