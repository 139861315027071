import { createMuiTheme, createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  spacing: 8,
  palette: {
    primary: {
      main: '#3B9A83',
    },
    secondary: {
      main: '#EFAB23',
    },
    white: { main: '#FFFFFF' },
    gray: { main: '#434343'},
    background: { main: '#EDEDED'},
    red: { main: '#EE715B'},
    placeholder: { main: 'blue'},
    lightGrey: { main: '#9A9A9A'},
    blue: { main: '#039EDA' }
  },
  typography: {
    fontFamily: 'Montserrat Variable',
    fontSize: 16,
    fontWeightRegular: 500,
    fontWeightBold: 700,
    title: {
      fontFamily: 'Montserrat Variable',
      fontSize: 24,
      fontWeight: 700,
      textTransform: 'none',
      color: '#434343', // this can be overwritten wherever you use it, declaring color after the import of theme
    },
    subtitle: {
      fontFamily: 'Montserrat Variable',
      fontSize: 20,
      fontWeight: 700,
      textTransform: 'none',
      color: '#434343', // this can be overwritten wherever you use it, declaring color after the import of theme
    },
    cardTitle: {
      fontFamily: 'Montserrat Variable',
      fontSize: 18,
      fontWeight: 700,
      textTransform: 'none',
      color: '#434343', // this can be overwritten wherever you use it, declaring color after the import of theme
    },
    titleEmpty: {
      fontFamily: 'Montserrat Variable',
      fontSize: 18,
      fontWeight: 700,
      textTransform: 'none',
      color: '#434343', // this can be overwritten wherever you use it, declaring color after the import of theme
    },
    button: {
      fontFamily: 'Montserrat Variable',
      fontSize: 16,
      fontWeight: 700,
      textTransform: 'none',
    },
    input: {
      fontFamily: 'Montserrat Variable',
      fontSize: 16,
      fontWeight: 700,
    },
    checkbox: {
      fontFamily: 'Montserrat Variable',
      fontSize: 16,
      fontWeight: 700,
    },
    link: {
      fontFamily: 'Montserrat Variable',
      fontSize: 14,
      fontWeight: 700,
    },
    mediumText: {
      fontFamily: 'Montserrat Variable',
      fontSize: 14,
      fontWeight: 700,
      color: '#434343', // this can be overwritten wherever you use it, declaring color after the import of theme
    },
    advice: {
      fontFamily: 'Montserrat Variable',
      fontSize: 14,
      fontWeight: 600,
      color: '#434343', // this can be overwritten wherever you use it, declaring color after the import of theme
    },
    smallText: {
      fontFamily: 'Montserrat Variable',
      fontSize: 12,
      fontWeight: 700,
      color: '#434343', // this can be overwritten wherever you use it, declaring color after the import of theme
    },
    productName: {
      fontFamily: 'Montserrat Variable',
      fontSize: 12,
      fontWeight: 600,
      color: '#434343', // this can be overwritten wherever you use it, declaring color after the import of theme
    },
    extraSmallText: {
      fontFamily: 'Montserrat Variable',
      fontSize: 10,
      fontWeight: 600,
      color: '#434343', // this can be overwritten wherever you use it, declaring color after the import of theme
    },
    errorText: {
      fontFamily: 'Montserrat Variable',
      fontSize: 10,
      fontWeight: 500,
      color: '#EE715B', // red
    },
    successText: {
      fontFamily: 'Montserrat Variable',
      fontSize: 10,
      fontWeight: 500,
      color: '#3B9A83',
    },
    warningText: {
      fontFamily: 'Montserrat Variable',
      fontSize: 10,
      fontWeight: 500,
      color: '#EFAB23',
    },
    inputLabel: {
      fontFamily: 'Montserrat Variable',
      fontSize: 10,
      fontWeight: 700,
      color: '#00000061',
    },
    paragraph: {
      fontFamily: 'Montserrat Variable',
      fontSize: 16,
      fontWeight: 700,
      color: '#434343', // this can be overwritten wherever you use it, declaring color after the import of theme
    },
    popMenu: {
      fontFamily: 'Montserrat Variable',
      fontSize: 16,
      fontWeight: 700,
      color: '#3B9A83',
    },
  },
  globals: {
    cardWithNumber: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#fff',
      backgroundColor: '#ccc',
      width: '100%',
      height: 'auto',
      minWidth: 65,
      paddingTop: 5,
      paddingBottom: 5,
      borderRadius: 5,
      fontFamily: 'Montserrat Variable',
      fontWeight: 700,
      fontSize: 10,
      whiteSpace: 'nowrap',
    },
    center: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    },
    centerVertical: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
});

export default theme;
