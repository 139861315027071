import { API } from 'aws-amplify';
import { API_PROFILE } from '../../lib/constants';

const getProfileById = async(userId) => {
    try{
        const result = await API.get(API_PROFILE, `/profile/${userId}`)
        return {
            success: result.success,
            data: result.data[0]
        }
    }
    catch(error) {
        return { success: false, error: error}
    }
}

export default getProfileById