import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import '../../../assets/css/main.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import DefaultButton from '../../common/defaultButton';
import DefaultInput from '../../common/defaultInput';
import { Auth, API, Storage } from 'aws-amplify';
import CheckValue from '../../../lib/formatValidations';
import { withSnackbar } from "notistack";
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import moment from 'moment-timezone';

const useStyles = makeStyles({
    container: {
      flex: 8,
      marginLeft: 30,
      marginRight: 30,
    },
    containerForm: {
        backgroundColor: '#FFFFFF',
        borderRadius: 19,
        display: 'flex',
        flex: 1,
        padding: 40
    },
    column60: {
        width:'60%',
        float: 'left',
        height: '100%',
        padding: 10,
        margin: 0
    },
    column40: {
        width:'40%',
        float: 'left',
        height: '100%',
        padding: 10,
        margin: 0
    },
    inputMargin:{
        marginTop: 15
    },
    centerComponent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    imageStyle: {
        width: '100%'
    }
});

const NewFantasyGS= (props) =>{
    const edit=Object.keys(props.EditFantasyG).length;
    const [isLoading, setLoading] = React.useState(false);
    const [productName, setProductName] = React.useState( edit ? props.EditFantasyG.productName : '' );
    const [productType, setProductType] = React.useState( edit ? props.EditFantasyG.productType : '');
    const [description, setDescription] = React.useState( edit ? props.EditFantasyG.description : '');
    const [price, setPrice] = React.useState( edit ? props.EditFantasyG.price : '');
    const [file, setFile] = React.useState( edit ? 
        {
            previewImage: props.EditFantasyGI, 
            data: props.EditFantasyGI
        } : {
            previewImage: '',
            data: ''
    });
    const getImage = event => {
        setFile({
            previewImage: URL.createObjectURL(event.target.files[0]),
            data: event.target.files[0]
        })
    }
    /*Errors*/
    const [productNameError, setProductNameError] = React.useState('');
    const [productTypeError, setProductTypeError] = React.useState('');
    const [descriptionError, setDescriptionError] = React.useState('');
    const [priceError, setPriceError] = React.useState('');
    const [imageError, setImageError] = React.useState('');

    const handleChange = (event) =>{
        const {name,value} = event.target;
        switch(name){
            case 'productName':
                setProductName(value);
                break;
            case 'productType':
                setProductType(value);
                break;
            case 'description':
                setDescription(value);
                break;
            case 'price':
                setPrice(value);
                break;
            default:
                break;
        }
    }

    const cleanError = () => {
        setProductNameError('');
        setProductTypeError('');
        setDescriptionError('');
        setPriceError('');
        setImageError('');
    }

    const cleanForm = () => {
        setProductName('');
        setProductType('');
        setDescription('');
        setPrice('');
    }

    const handleAlert = (variant, message) => {
        props.enqueueSnackbar( message, { variant });
    };

    const getIventories = async () => {
        const getInvetories = await API.get('inventory-api', `/inventory`, {
            queryStringParameters: {
                entityId: 'FantasyShop',
                entityKey: 'store'
            }
        });
        return getInvetories.data
    };

    const createGift = async () => {
        cleanError()
        let hasErrors = false
        if(!CheckValue('empty',productName)){
            setProductNameError('Ingrese el nombre del regalo');
            hasErrors = true;
        }
        if(!CheckValue('empty',productType)){
            setProductTypeError('Ingrese el tipo de regalo');
            hasErrors = true;
        }
        if (!CheckValue('empty',description)){
            setDescriptionError('Ingrese la descripción del regalo');
            hasErrors = true;
        }
        if(!CheckValue('money',price)){
            setPriceError('Ingrese el precio (OPCIONAL: 2 decimales)');
            hasErrors = true;
        }
        if(!Object.keys(file).length){
            setImageError('Ingrese una imagen');
            hasErrors = true;
        }
        if(!hasErrors){
            if (edit) {
                const CREATE_PRODUCT = await API.put('product-api', `/products/${props.EditFantasyG.productRecordKey}/${props.EditFantasyG.merchantRecordKey}`, {
                    body: {
                        productType: productType,
                        price: price,
                        description: description,
                        productName: productName,
                    },
                }).then(result => {
                    console.log("Result Update", result)
                        if (typeof file.data == 'object') {
                            handleAlert('success','Registro Finalizado')
                            const config = {
                                contentType: file.data.type,
                                level: 'public',
                            };
                            const filePUT = Storage.put(`products/${result.data[0].productRecordKey}/default.jpg`, file.data, config);
                            cleanForm();
                            setLoading(false);
                            handleAlert('success','Imagen Guardada')
                            props.createFantasyGS();
                        } else if (typeof file.data == 'string'){
                            setLoading(false);
                            props.createFantasyGS();
                        }
                // eslint-disable-next-line no-unused-expressions
                }).catch(err => { 
                    setLoading(false)
                    handleAlert('error',err)
                });
            } else {
                setLoading(true)
                const getUser = await Auth.currentAuthenticatedUser();
                try {
                    const merchant = 'FantasyShop';
                    const CREATE_PRODUCT = await API.post('product-api', '/products',  {
                        body: {
                            merchantRecordKey: merchant,
                            productName: productName,
                            productType: productType,
                            description: description,
                            price: price,
                            inventoryable: false
                        },
                    });
                    const config = {
                        contentType: file.data.type,
                        level: 'public',
                    };
                    const filePUT = await Storage.put(`products/${CREATE_PRODUCT.data[0].productRecordKey}/default.jpg`, file.data, config);
                    setFile({
                        previewImage: '',
                        data: ''
                    })
                    console.log('CREATE_PRODUCT-->', CREATE_PRODUCT)
                    cleanForm();
                    setLoading(false);
                    handleAlert('success','Registro Finalizado');
                } catch (err) {
                    setLoading(false);
                    handleAlert('error',err);
                }  
            }
        }
    }

    const deleteGift = async () => {
        //e.preventDefault();
        setLoading(true);
        const getInventories = await getIventories()
        const inventories = await Promise.all(getInventories)
        const productRecordKey = props.EditFantasyG.productRecordKey
        const merchantRecordKey = 'FantasyShop';
        let isInInventory = false
        inventories.forEach(inventory => {
            if (inventory.productId === productRecordKey) {
                isInInventory = true
            }
        })
        if (isInInventory) {
            setLoading(false);
            handleAlert('error','El Producto que tratas de borrar, está actualmente en un inventario');
        } else {
            const today = moment.utc(moment.now());
            const CREATE_PRODUCT = await API.put('product-api', `/products/${productRecordKey}/${merchantRecordKey}`, {
                body: {
                    isActive: false,
                    obsoleteDate: today
                },
            }).then(result => {
                console.log("Result Update", result)
                setLoading(false);
                handleAlert('success','Producto eliminado');
                props.createFantasyGS();
            }).catch(err => { 
                console.log(err); 
                setLoading(false);
                handleAlert('error',err);
            });
        }
    }
   

    const classes = useStyles();

    return(
        <div className={classes.root}>
                {isLoading ?
                    <div className='styleCircularProgress'>
                        <CircularProgress color="secondary" />
                    </div>
                : 
                    <div className={classes.container}>
                        <div className={classes.containerForm}>
                            <div className={classes.column60}>
                                <DefaultInput custom={{
                                    handleChange,
                                    value: productName,
                                    labelText: "Nombre del Regalo",
                                    classLabel: "labelInputStyleForm",
                                    id: "productName",
                                    type: "text",
                                    name:"productName",
                                    className: `defaultInputTextFormLarge ${classes.inputMargin}`,
                                    placeholder:"Nombre del Regalo",
                                    error: productNameError
                                }}
                                />
                                <DefaultInput custom={{
                                    handleChange,
                                    value: productType,
                                    labelText: "Tipo de Regalo",
                                    classLabel: "labelInputStyleForm",
                                    id: "productType",
                                    type: "text",
                                    name: "productType",
                                    className: `defaultInputTextFormLarge ${classes.inputMargin}`,
                                    placeholder:"Tipo de Regalo",
                                    error: productTypeError
                                }}/>
                                <DefaultInput custom={{
                                    handleChange,
                                    value: description,
                                    labelText: "Descripción",
                                    classLabel: "labelInputStyleForm",
                                    id: "description",
                                    type: "text",
                                    name:"description",
                                    className: `defaultInputTextFormLarge ${classes.inputMargin}`,
                                    placeholder: "Descripción",
                                    error: descriptionError
                                }}/>
                                <DefaultInput custom={{
                                    handleChange,
                                    value: price,
                                    labelText: "Precio",
                                    classLabel: "labelInputStyleForm",
                                    id: "price",
                                    type: "text",
                                    name:"price",
                                    className: `defaultInputTextFormLarge ${classes.inputMargin}`,
                                    placeholder:"Precio",
                                    error: priceError
                                }}/>
                            </div>
                            <div className={classes.column40} custom={{error:imageError}}>
                                {file.previewImage ?
                                    <img src={file.previewImage} width="25%" height="40%"/>
                                : void 0}
                                    <label for="myFile">
                                        <AddAPhotoIcon color="secondary" style={{ fontSize: 40 }}/>
                                    </label>
                                    <input onChange={getImage} className={classes.textField} type="file" id="myFile"></input>
                                    {edit ?
                                        <DefaultButton handleAction={() => deleteGift()} buttonText={"Eliminar"} />
                                    : 
                                        void 0
                                    }
                            </div>    
                        </div>
                        <div className={classes.centerComponent}>
                            <DefaultButton handleAction={() => props.createFantasyGS()} buttonText={"Cancelar"} />
                            <DefaultButton handleAction={() => createGift()} buttonText={"Guardar"} />
                        </div>
                    </div>
                }
        </div>
    );
}

export default withSnackbar(NewFantasyGS);