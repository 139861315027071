import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, } from '@material-ui/core';
import PrimaryButton from '../../components/common/primaryButton';
import { withSnackbar } from 'notistack';
import SecondaryButton from '../../components/common/secondaryButton';

const ModalTransaction = ({ transaction }) => {

  const classes = useStyles();

  const formatMoney = (number) => {
    const decimalIsZero = number => number.toString().split('.')[2] === '0';
    const numberFixed = parseFloat(number).toFixed(2);
    return decimalIsZero(numberFixed)
      ? `${parseFloat(numberFixed)}`
      : `${numberFixed}`;
  }

  return (
    <div className={classes.mainContainer}>
      <div className={classes.mainInfoContainer}>
        <Typography className={classes.title}>
          Información de la transacción
        </Typography>
        <div className={classes.infoContainer}>
          <div className={classes.infoText}>
            <Typography className={classes.text}>
              Fecha de transaccion:
            </Typography>
          </div>
          <div className={classes.info}>
            <Typography className={classes.textInfo}>
              {transaction.transactionDateTime}
            </Typography>
          </div>
        </div>
        <div className={classes.infoContainer}>
          <div className={classes.infoText}>
            <Typography className={classes.text}>
              Id de transaccion:
            </Typography>
          </div>
          <div className={classes.info}>
            <Typography className={classes.textInfo}>
              {transaction.transactionId}
            </Typography>
          </div>
        </div>
        <div className={classes.infoContainer}>
          <div className={classes.infoText}>
            <Typography className={classes.text}>
              Id de usuario:
            </Typography>
          </div>
          <div className={classes.info}>
            <Typography className={classes.textInfo}>
              {transaction.userRecordKey}
            </Typography>
          </div>
        </div>
        <div className={classes.infoContainer}>
          <div className={classes.infoText}>
            <Typography className={classes.text}>
              Nombre del usuario:
            </Typography>
          </div>
          <div className={classes.info}>
            <Typography className={classes.textInfo}>
              {transaction.nameUser}
            </Typography>
          </div>
        </div>
        <div className={classes.infoContainer}>
          <div className={classes.infoText}>
            <Typography className={classes.text}>
              No. Referencia/Telefono:
            </Typography>
          </div>
          <div className={classes.info}>
            <Typography className={classes.textInfo}>
              {transaction.accountId}
            </Typography>
          </div>
        </div>
        <div className={classes.infoContainer}>
          <div className={classes.infoText}>
            <Typography className={classes.text}>
              Cantidad:
            </Typography>
          </div>
          <div className={classes.info}>
            <Typography className={classes.textInfo}>
              {formatMoney(transaction.amount)}
            </Typography>
          </div>
        </div>
        <div className={classes.infoContainer}>
          <div className={classes.infoText}>
            <Typography className={classes.text}>
              Mensaje de respuesta:
            </Typography>
          </div>
          <div className={classes.info}>
            <Typography className={classes.textInfoResopnse}>
              {transaction.responseMessage}
            </Typography>
          </div>
        </div>
        <div className={classes.infoContainer}>
          <div className={classes.infoText}>
            <Typography className={classes.text}>
              Tipo de transaccion:
            </Typography>
          </div>
          <div className={classes.info}>
            <Typography className={classes.textInfo}>
              {transaction.type === 'recharge' ? 'Recarga celular' : 'Pago de servicio'}
            </Typography>
          </div>
        </div>
        <div className={classes.infoContainer}>
          <div className={classes.infoText}>
            <Typography className={classes.text}>
              Comision:
            </Typography>
          </div>
          <div className={classes.info}>
            <Typography className={classes.textInfo}>
              {formatMoney(transaction.productFee)}
            </Typography>
          </div>
        </div>
        <div className={classes.infoContainer}>
          <div className={classes.infoText}>
            <Typography className={classes.text}>
              Total pagado:
            </Typography>
          </div>
          <div className={classes.info}>
            <Typography className={classes.textInfo}>
              {formatMoney(transaction.totalPay)}
            </Typography>
          </div>
        </div>
        <div className={classes.infoContainer}>
          <div className={classes.infoText}>
            <Typography className={classes.text}>
              Status:
            </Typography>
          </div>
          <div className={classes.info}>
            <Typography className={classes.textInfo}>
              {transaction.status}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )

}

const useStyles = makeStyles(theme => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'colum',
  },
  mainInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
  },
  title: {
    ...theme.typography.title,
    paddingBottom: '10px',
    textAlign: 'center'
  },
  text: {
    ...theme.typography,
    fontSize: 18,
  },
  textInfo: {
    ...theme.typography.subtitle,
    fontSize: 18,
  },
  textInfoResopnse: {
    ...theme.typography.subtitle,
    fontSize: 18,
    textAlign: 'end'
  },
  infoText: {
    width: '50%',
  },
  info: {
    width: '500px',
    padding: '10px',
    flexWrap: 'wrap',
    textAlign: 'right'
  }

}))

export default withSnackbar(ModalTransaction);