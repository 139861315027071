const BusinessTypesList = () => {
  return [
    { label: 'Selecciona una área', value: ''},
    { label: 'Ejecutivo', value: 'Ejecutivo' },
    { label: 'Comercial', value: 'Comercial' },
    { label: 'Operaciones', value: 'Operaciones' },
    { label: 'Finanzas', value: 'Finanzas' },
    { label: 'Tecnología', value: 'Tecnología' },
    { label: 'Compras', value: 'Compras' },
  ];
}

export default BusinessTypesList;
