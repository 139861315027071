import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import '../../assets/css/main.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import DefaultButton from '../common/defaultButton';
import DefaultInput from '../common/defaultInput';
import AddProduct from './editInventory'
import StoreIcon from '../../assets/images/store_icon.png'
import { Auth, API, Storage } from 'aws-amplify';
import { withSnackbar } from "notistack";
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { List, ListItem, ListItemText, Collapse, ListSubheader } from '@material-ui/core';
import { ExpandLess, ExpandMore, Label } from '@material-ui/icons';
import NewInventory from './editInventory';
import { FONT_DEFAULT} from '../../lib/constants.js';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flex: 1,
        backgroundColor: '#faf9f9',
        flexDirection: 'column',
        width: '100%',
        height: '100%'
    },
    container: {
        flex: 8,
        marginLeft: 30,
        marginBottom:30,
    },
    containerFormAvailable: {
        backgroundColor: '#daf1eb',
        borderRadius: 19,
        flexDirection: "row",
        display: 'flex',
        flex: 1,
        padding: 20,
        marginTop: 15
    },
    containerFormExhausted: {
      backgroundColor: '#ffcccc',
      borderRadius: 19,
      flexDirection: "row",
      display: 'flex',
      flex: 1,
      padding: 20,
      marginTop: 15
    },
    containerFormTitle: {
      backgroundColor: '#ffffff',
      borderRadius: 19,
      flexDirection: "row",
      display: 'flex',
      flex: 1,
      padding: 20,
      marginTop: 15
  },
    F1: {
        display: 'flex',
        flex: 1,
        padding: 20
    },
    F2: {
        display: 'flex',
        flex: 2,
        padding: 20,
        textJustify: 'left'
    },
    openLabel: {
        color: '#3B9A83',
        display: 'flex',
        flex: 1,
        padding: 20
    },
    closedLabel: {
        color: '#DA7B7B',
        display: 'flex',
        flex: 1,
        padding: 20
    },
  title: {
    ...FONT_DEFAULT,
    fontWeight: 'bold',
    fontSize: 24,
    marginTop: 25,
  },
});

const Inventory = (props) => {
    useEffect(() => {
      searchInventory(null);
    }, []);

    const [isLoading, setLoading] = useState(false);
    const [newProduct, setNewProduct] = useState(false);
    const [inventory, setInventory] = useState([]);
    const [createInventory, setCreateInventory] = useState(false);
    const [search, setSearch] = useState("");
    const [inventoryE, setInventoryE] = useState([]);
    const [inventoryEI, setInventoryEI] = useState([]);

    const handleChange = (event) => {
      setSearch(event.target.value);
      searchInventory(event.target.value);
    }

    const handleToggleEditClick = async (item, e) => {
      const pImage = await Storage.get(`products/${item.product.productRecordKey}/default.jpg`);
      setInventoryE(item);
      setInventoryEI(pImage);
      setCreateInventory(!createInventory);
    }

    const searchInventory = async (searchParam) => {
      setLoading(true)
      const getUser = await Auth.currentAuthenticatedUser();
      if (searchParam === null || searchParam === '') {
        try {
          const getInventory = await API.get('inventory-api', `/inventory`, {
              queryStringParameters: {
                  entityId: 'FantasyShop',
                  entityKey: 'store'
              }
          });
          const getProducts = await API.get('product-api', '/products', {
            queryStringParameters: {
                FilterExpression: 'merchantRecordKey=:merchantRecordKey',
                ExpressionAttributeValues: `:merchantRecordKey=FantasyShop`
            }
          });
          const pImages = getInventory.data.map(item => Storage.get(`products/${item.productId}/default.jpg`));
          const p = await Promise.all(pImages);
          const upInventoryList = getInventory.data.map((value, index) => ({
              ...value,
              productImage: p[index]
          }));
          setInventory(upInventoryList);
          setLoading(false);
          } catch (err) {
              console.error(err);
              setLoading(false);
          }
        } else {
          try {
            const getInventory = await API.get('inventory-api', `/inventory`, {
              queryStringParameters: {
                entityId: 'FantasyShop',
                entityKey: 'store',
              }
            });
            const getProducts = await API.get('product-api', '/products', {
              queryStringParameters: {
                FilterExpression:
                    `merchantRecordKey=:merchantRecordKey AND 
                    (contains(productName,:search) OR
                    contains(description,:search) OR
                    contains(productType,:search))`,
                ExpressionAttributeValues: `:merchantRecordKey=FantasyShop,:search=${searchParam}`
            }
            });
            const pImages = getInventory.data.map(item => Storage.get(`products/${item.productId}/default.jpg`));
            const p = await Promise.all(pImages);
            const upInventoryList = getInventory.data.map((value, index) => ({
                ...value,
                productImage: p[index]
            }));
            const filteredUpInventoryList = upInventoryList.filter(
              product => product.product.productName.includes(searchParam) || product.product.description.includes(searchParam) || product.product.productType.includes(searchParam)
            );
            setInventory(filteredUpInventoryList);
            setLoading(false);
          } catch (err) {
              console.error(err);
              setLoading(false);
          }
        }
      }

    const handleAddInventory = () => {
      setCreateInventory(!createInventory);
    }

    const handleAddNewInventory = () => {
      setCreateInventory(!createInventory);
      setNewProduct(!newProduct);
    }

    const renderInventory = () => {
      const element = inventory.map(item => (
        <div className={(item.quantity > 0 ? classes.containerFormAvailable : classes.containerFormExhausted)}>
          <div className={classes.F1} ><img width="70" height="70" src={item.productImage} /></div>
          <label className={classes.F1}>{item.quantity}</label>
          <label className={classes.F2}><strong>{item.product.productName}</strong></label>
          <label className={classes.F1}>{item.product.price}</label>
          <label className={classes.F1}>{item.product.productType}</label>
          {item.quantity > 0 ?
              <label className={classes.F1}>
                Disponible
              </label>
            :
              <label className={classes.F1}>
                Agotado
              </label>
            }
          <IconButton onClick={(e) => handleToggleEditClick(item, e)} >
              <EditIcon />
          </IconButton>
        </div>
      ))
      return element;
    }

    const classes = useStyles();
    return (
      <div className={classes.root}>
      <div className={classes.container}> 
      <Typography className={classes.title}>Inventario</Typography>
        {!createInventory ?
          <div>
            <div className={classes.buttonContainer}>
              <DefaultInput 
                custom={{
                    handleChange,
                    value: search,
                    classForm: "inputForm",
                    classLabel: "labelInputStyleForm",
                    labelText: "",
                    id: "search",
                    type: "text",
                    name:"search",
                    className:"defaultInputSearchTextFormLarge",
                    placeholder:"Buscar en inventario",
                    error: ""
                }} 
              />
              <DefaultButton
                  buttonText="AGREGAR A INVENTARIO"
                  handleAction={handleAddNewInventory}
              />
            </div>
            <div>
              {isLoading ?
                <div className='gralinfo_form'>
                    <CircularProgress color="secondary" />
                </div>
              : 
                <div className="mt-3 overflow-auto">
                    <div className={classes.containerFormTitle}>
                      <div className={classes.F1} ><label width="70" height="70" />Imagen</div>
                      <label className={classes.F1}>Cantidad</label>
                      <label className={classes.F2}>Descripción del producto</label>
                      <label className={classes.F1}>Precio</label>
                      <label className={classes.F1}>Categoría</label>
                      <label className={classes.F1}>Estatus</label>
                      <IconButton>
                        <EditIcon/>
                      </IconButton>                
                    </div>
                    <div>
                      {renderInventory()}
                    </div>
                </div>
              }
            </div>
          </div>
          :
          <div>
              <div className="rowStyle">
                <NewInventory createInventory={handleAddInventory} EditInventory={inventoryE} EditInventoryI={inventoryEI} />
              </div>
          </div>
        }
      </div>
    </div>
  );
}

export default withSnackbar(Inventory);
/*
          <Modal
            open={openModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
          >
            <div className={classes.quantityModal}>
                <h2>Cantidad para agregar</h2>
                <DefaultInput custom={{
                    handleChange,
                    value: newQuantity,
                    labelText: "",
                    classLabel: "labelInputStyleForm",
                    id: "newQuantity",
                    type: "text",
                    name:"newQuantity",
                    className: `defaultInputTextForm col-12`,
                    placeholder:"Nueva Cantidad",
                    error: newQuantityError
                  }}
                />
                <br/>
                <Button className={classes.cancelButtonStyle}>
                    <Typography className={classes.cancelButtonText} onClick={() => setOpenModal(!openModal)}>Cancelar</Typography>
                </Button>
                <Button className={classes.successButtonStyle}>
                    <Typography className={classes.successButtonText} onClick={() => updateInventory()}>Agregar</Typography>
                </Button>
            </div>
          </Modal>
*/