import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, } from '@material-ui/core';
import PrimaryButton from '../../components/common/primaryButton';
import { withSnackbar } from 'notistack';
import SecondaryButton from '../../components/common/secondaryButton';

const ModalInfoUser = (props) => {
  const user = props.userInfo;
  const classes = useStyles();

  const formatMoney = (number) => {
    const decimalIsZero = number => number.toString().split('.')[2] === '0';
    const numberFixed = parseFloat(number).toFixed(2);
    return decimalIsZero(numberFixed)
      ? `${parseFloat(numberFixed)}`
      : `${numberFixed}`;
  }

  return (
    <div className={classes.mainContainer}>
      <div className={classes.mainInfoContainer}>
        <Typography className={classes.title}>
          Información del usuario
        </Typography>
        <div className={classes.infoContainer}>
          <div className={classes.infoText}>
            <Typography className={classes.text}>
              Nombre
            </Typography>
          </div>
          <div className={classes.info}>
            <Typography className={classes.textInfo}>
              {`${user.firstName} ${user.lastName}`}
            </Typography>
          </div>
        </div>
        <div className={classes.infoContainer}>
          <div className={classes.infoText}>
            <Typography className={classes.text}>
              Correo
            </Typography>
          </div>
          <div className={classes.info}>
            <Typography className={classes.textInfo}>
              {user.email}
            </Typography>
          </div>
        </div>
        <div className={classes.infoContainer}>
          <div className={classes.infoText}>
            <Typography className={classes.text}>
              Estado
            </Typography>
          </div>
          <div className={classes.info}>
            <Typography className={classes.textInfo}>
              {user.state}
            </Typography>
          </div>
        </div>
        <div className={classes.infoContainer}>
          <div className={classes.infoText}>
            <Typography className={classes.text}>
              Numero de telefono
            </Typography>
          </div>
          <div className={classes.info}>
            <Typography className={classes.textInfo}>
              {user.phoneNumber}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'colum',
  },
  mainInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
  },
  title: {
    ...theme.typography.title,
    paddingBottom: '10px',
    textAlign: 'center'
  },
  text: {
    ...theme.typography,
    fontSize: 18,
  },
  textInfo: {
    ...theme.typography.subtitle,
    fontSize: 18,
  },
  textInfoResopnse: {
    ...theme.typography.subtitle,
    fontSize: 18,
    textAlign: 'end'
  },
  infoText: {
    width: '50%',
  },
  info: {
    width: '500px',
    padding: '10px',
    flexWrap: 'wrap',
    textAlign: 'right'
  }

}))

export default withSnackbar(ModalInfoUser);