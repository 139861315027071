import React from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import '../../../assets/css/main.css';

const useStyles = override => {
  const styles = makeStyles({
    buttonStyle: {
      backgroundColor: 'transparent',
      color: '#3B9A83',
      fontSize: 12,
      border: '1px solid #3B9A83',
      bordeColor:'#3B9A83',
      cursor: 'pointer',
      maxWidth: 180,
      minWidth: 120,
      height: 40,
      borderRadius: 5,
      ...override,
    },
    small: {
      backgroundColor: 'transparent',
      color: '#3B9A83',
      fontSize: 8,
      padding: '0px 40px',
      border: '1px solid #3B9A83',
      bordeColor:'#3B9A83',
      cursor: 'pointer',
      width: 85,
      height: 25,
      borderRadius: 5,
      ...override,
    }
});

  return styles();
}

export default function DefaultButton(props) {
  useEffect(()=>{
  },[])

  const classes = useStyles(props.override);
  const getStyle = (style = 'buttonStyle') => {
    switch(style){
      case'small': 
      return classes.small;
      default: 
      return classes.buttonStyle;
    }
  }
  return (
    <Button 
      className={getStyle(props.size)} 
      onClick={props.handleAction}
      disabled={props.disabled ? true : false}
    >
      {props.buttonText}
    </Button>
  );
}