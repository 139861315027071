import React from 'react';
import { useEffect, useState } from 'react';
import { withSnackbar } from 'notistack';
import { API } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import DefaultModal from '../../modal';
import { set } from 'ramda';
import {
  Typography,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import PrimaryButton from '../../common/primaryButton';
import PrimaryInput from '../../common/primaryInput';
import PrimarySelect from '../../common/primarySelect';
import PrimarySearchBar from '../../common/primarySearchBar';
import Checkbox from '@material-ui/core/Checkbox';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import removeAccents from '../../../lib/removeAccents';

const Operations = ({enqueueSnackbar}) => {

  const statusFilterSelect = [{ type: 'all', label: 'Todos' }, { type: 'Open', label: 'Open' }, { type: 'Closed', label: 'Closed' }]

  const [tickets, setTickets] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [userSelected, setUserSelected] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [checkBoxEnable, setCheckBoxEnable] = useState(false);
  const [statusFilter, setStatusFilter] = useState('all');
  const [showTickets, setShowTickets] = useState([]);
  const [searchText, setSearchText] = useState(null);
  const [assignee, setAssignee] = useState([]);
  const [assigneeFilter, setAssigneeFilter] = useState('Todos')


  const perPage = 11



  let accessToken = '';

  useEffect(() => {
    setInterval(getNewToken(), 2400000)
  }, [])

  const getTickets = async ({ access_token, token_type }) => {
    accessToken = access_token
    const responseTickets = await API.get(
      'zohoDesk-api',
      '/zohodesk/Tickets/getTickets', {
      queryStringParameters: {
        token_type,
        access_token
      }
    })
    setPages(Math.ceil(responseTickets.data.length / perPage))
    const assignee = ['Todos']
    responseTickets.data.map(ticket => {
      if (assignee.includes(`${ticket.assignee.firstName} ${ticket.assignee.lastName}`) === false) {
        assignee.push(`${ticket.assignee.firstName} ${ticket.assignee.lastName}`)
      }
    })
    setAssignee(assignee)
    setTickets(responseTickets.data);
  }

  const getNewToken = async () => {
    const responseRefreshToken = await API.get(
      'zohoDesk-api',
      '/zohodesk/Tickets/RefreshToken')
    accessToken = responseRefreshToken.access_token

    getTickets(responseRefreshToken)
    return responseRefreshToken
  }

  const getUserInTicket = async (phoneNumber) => {
    const responseRefreshToken = await API.get(
      'zohoDesk-api',
      '/zohodesk/Tickets/getUserInTicket', {
      queryStringParameters: {
        phoneNumber
      }
    })
    setUserSelected(responseRefreshToken)
    setOpenModal(true)
  }

  const filterRange = (from, dateTo, status, assignee) => {
    let ticketsFiltered = []
    if (dateTo === '') {
      ticketsFiltered = tickets.filter(ticket => ticket.createdTime >= from)
    } else if (from === '') {
      ticketsFiltered = tickets.filter(ticket => ticket.createdTime <= dateTo)
    } else if (from != '' && dateTo != '') {
      if (status != 'all') {
        ticketsFiltered = tickets.filter(ticket => (ticket.createdTime >= from && ticket.createdTime < dateTo) && (ticket.status === status))
      } else (
        ticketsFiltered = tickets.filter(ticket => ticket.createdTime >= from && ticket.createdTime < dateTo)
      )
    }
    if (status != 'all') {
      if (ticketsFiltered.length != 0) {
        ticketsFiltered = ticketsFiltered.filter(ticket => ticket.status === status)
      } else {
        ticketsFiltered = tickets.filter(ticket => ticket.status === status)
      }
    }
    if (assignee != 'Todos') {
      ticketsFiltered = ticketsFiltered.filter(ticket => ticket.status === status)
    }


    if (ticketsFiltered.length != 0) setPages(Math.ceil(ticketsFiltered.length / perPage))
    return ticketsFiltered
  }

  const filterText = (text) => {
    if (text === '') {
      setSearchText(null)
      setShowTickets(filterRange(dateFrom, dateTo, statusFilter, assigneeFilter))
    } else {
      const searchText = removeAccents(text).toLowerCase();
      if (showTickets.length != 0) {
        setShowTickets(showTickets.filter(ticket => {
          for (const property in ticket) {
            if (removeAccents(`${ticket[property]}`).toLowerCase().includes(searchText)) {
              return ticket
            }
          }
        }))
      } else {
        setShowTickets(tickets.filter(ticket => {
          for (const property in ticket) {
            if (removeAccents(`${ticket[property]}`).toLowerCase().includes(searchText)) {
              return ticket
            }
          }
        }))
      }
    }
  }

  const handleChange = (e) => {
    switch (e.target.name) {
      case 'depositDateTo':
        if (e.target.value > dateFrom) {
          setDateTo(e.target.value)
          setShowTickets(filterRange(dateFrom, e.target.value, statusFilter, assigneeFilter))
        } else {
          setDateTo('')
          handleAlert('error', 'Fecha menor a la de inicio');
        }
        break;
      case 'depositDateFrom':
        setDateFrom(e.target.value)
        setShowTickets(filterRange(e.target.value, dateTo, statusFilter, assigneeFilter))
        break;
      case 'statusFilter':
        setStatusFilter(e.target.value)
        setShowTickets(filterRange(dateFrom, dateTo, e.target.value, assigneeFilter))
        break;
      case 'assigneeFilter':
        setAssigneeFilter(e.target.value)
        setShowTickets(filterRange(dateFrom, dateTo, statusFilter, e.target.value))
        break;
      case 'searchBar':
        setSearchText(e.target.value)
        filterText(e.target.value)
        setPages(Math.ceil(showTickets.length / perPage))
        break
      default:
        break;
    }
  }

  const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };

  const handlePagination = (event, value) => {
    setSelectedPage(value)
  }

  const classes = useStyles();

  return (
    <>
      {openModal &&
        <DefaultModal
          open={openModal}
          modalType='userInformation'
          userInfo={userSelected}
          handleClose={(e) =>
            setOpenModal(!openModal)
          }
        />
      }
      <div className={classes.generalContainer}>
        <Typography className={classes.servicesHeader}> Operaciones </Typography>
        <div className={classes.filterTable}>
          <div className={classes.dateFilterContainer}>
            <div>
              <Typography className={classes.inputTitle}>Fecha de inicio</Typography>
              <PrimaryInput
                value={dateFrom}
                type='date'
                onChange={handleChange}
                id={'depositDateFrom'}
                name={'depositDateFrom'}>
              </PrimaryInput>
            </div>
            <div>
              <div className={classes.titleFilter}>
                <Checkbox
                  icon={<CircleUnchecked />}
                  checkedIcon={<CircleChecked />}
                  checked={checkBoxEnable}
                  onChange={() => setCheckBoxEnable(!checkBoxEnable)}
                  color="rgba(67, 67, 67, 0.6)"
                  style={{ padding: '0', paddingRight: '5px' }}
                />
                <Typography className={classes.inputTitle}>Fecha de fin</Typography>
              </div>
              <PrimaryInput
                value={dateTo}
                type='date'
                disabled={checkBoxEnable ? false : true}
                onChange={handleChange}
                id={'depositDateTo'}
                name={'depositDateTo'}>
              </PrimaryInput>
            </div>
            <div>
              <Typography className={classes.inputTitle}>Status</Typography>
              <PrimarySelect
                value={statusFilterSelect.label}
                content={statusFilterSelect.map((item, index) => {
                  return <option key={index} value={item.type}>{item.label}</option>;
                })}
                onChange={handleChange}
                id={'statusFilter'}
                name={'statusFilter'}
              >
              </PrimarySelect>
            </div>
            <div>
              <Typography className={classes.inputTitle}>Asignado</Typography>
              <PrimarySelect
                value={assigneeFilter}
                content={assignee.map((item, index) => {
                  return <option key={index} value={item}>{item}</option>;
                })}
                onChange={handleChange}
                id={'assigneeFilter'}
                name={'assigneeFilter'}
              >
              </PrimarySelect>
            </div>
          </div>
          <div className={classes.searchBarcontainer}>
            <Typography className={classes.inputTitle}>Buscar</Typography>
            <div className={classes.searchBar}>
              <PrimarySearchBar
                fullWidth={true}
                name='searchBar'
                onChange={e => handleChange(e)}
              />
            </div>
          </div>
        </div>
        {tickets && (
          <table className={classes.table}>
            <thead className={classes.tableHeader}>
              <tr>
                <th className={classes.tableHeader}>id</th>
                <th className={classes.tableHeader}>Nombre</th>
                <th className={classes.tableHeader}>Correo</th>
                <th className={classes.tableHeader}>Telefono</th>
                <th className={classes.tableHeader}>Asunto</th>
                <th className={classes.tableHeader}>Status</th>
                <th className={classes.tableHeader}>Asignado</th>
              </tr>
            </thead>
            <tbody>
              {showTickets.length === 0 ? tickets.slice(((selectedPage - 1) * perPage), (((selectedPage - 1) * perPage) + perPage)).map((ticket) => (
                <tr key={ticket.id} onClick={() => getUserInTicket(ticket.phone)}>
                  <td className={classes.tableRegister}>{ticket.id}</td>
                  <td className={classes.tableRegister}>{`${ticket.contact.lastName}`}</td>
                  <td className={classes.tableRegister}>{ticket.email}</td>
                  <td className={classes.tableRegister}>{ticket.phone}</td>
                  <td className={classes.tableRegister}>{ticket.subject}</td>
                  <td className={classes.tableRegister}>{ticket.status}</td>
                  <td className={classes.tableRegister}>{`${ticket.assignee.firstName} ${ticket.assignee.lastName}`}</td>
                </tr>
              )) : showTickets.slice(((selectedPage - 1) * perPage), (((selectedPage - 1) * perPage) + perPage)).map((ticket) => (
                <tr key={ticket.id} onClick={() => getUserInTicket(ticket.phone)}>
                  <td className={classes.tableRegister}>{ticket.id}</td>
                  <td className={classes.tableRegister}>{`${ticket.contact.lastName}`}</td>
                  <td className={classes.tableRegister}>{ticket.email}</td>
                  <td className={classes.tableRegister}>{ticket.phone}</td>
                  <td className={classes.tableRegister}>{ticket.subject}</td>
                  <td className={classes.tableRegister}>{ticket.status}</td>
                  <td className={classes.tableRegister}>{`${ticket.assignee.firstName} ${ticket.assignee.lastName}`}</td>
                </tr>
              ))
              }
            </tbody>
          </table>
        )}
        <div className={classes.pagination}>
          <Pagination count={pages} shape="rounded" onChange={handlePagination} />
        </div>
      </div>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  generalContainer: {
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: '30px',
    justifyContent: 'space-around',
    overflowX: 'auto',
  },
  table: {
    alignItems: 'flex-start',
    width: '100%',
  },
  tableHeader: {
    alignItems: 'flex-start',

  },
  tableRegister: {
    ...theme.typography.SUBtitle,
    textaAlign: 'left',
    padding: '10px',
    [theme.breakpoints.down('md')]: {
      paddingRight: '20px'
    },
  },
  servicesHeader: {
    ...theme.typography.title,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
    padding: 0,
    marginBottom: 20,
  },
  filterTable: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: '15px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  inputTitle: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'baseline',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  inputDate: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '50%',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  inputButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '50%',
    height: '100%',
    marginBottom: '10px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  titleFilter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  searchBar: {
    display: 'flex',
    alignItems: 'flex-end',
    width: '100%',
  },
  dateFilterContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '50%',
    alignItems: 'flex-end',
    justifyContent: 'space-around',
  },
  searchBarcontainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  pagination: {
    marginTop: '15px',
    widht: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '10px'
  },
}))
export default withSnackbar(Operations);
