import React,{ useState,useEffect} from 'react'
import { Auth } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { useCookies } from 'react-cookie';
import Logo from "../../assets/images/Logo.svg";
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { useLocation } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import EditUser from '../../lib/questionnaire/editUser';
import AcceptInterested from '../../lib/questionnaire/acceptInterested';
import PrimaryButton from '../../components/common/primaryButton';
import GetUser from '../../lib/questionnaire/getUserByEmailAndPhone';

const DataUsage = (props) => {
  const classes = useStyle();
  const loc = useLocation();

  const [cookies] = useCookies(['lastAction', 'user']);
  const [acceptManagesData, setAcceptManagesDataAccept] = useState(cookies.user.acceptManagesData)
  const [acceptInterested, setAcceptInterested] = useState(false)
  const [termConditions, setTermConditions] = useState(false)
  const [cookie, setCookie, removeCookie] = useCookies(['lastAction','user']);
  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState({
    userRecordKey: cookies.user.key,
    acceptManagesData: cookies.user.acceptManagesData
  })
  const [type, setType] = useState('user')
  useEffect(() => {
      if(loc.state){
        getUser()
        setUser({...user,phone:loc.state.phone, email: loc.state.email})
        setIsLoading(false)
      }
  }, [loc])


  const getUser = async() => {
    const user = await GetUser(loc.state.email,loc.state.phone)
    setType(user.success ? 'lead' : 'user')
  } 

  const handleAlert = (variant, message) => { 
    props.enqueueSnackbar(message, { variant });
  };
  
  const acceptManageData = async() => {
    const item = {
      acceptManagesData: true,
    }
    
    const response = await EditUser(user.userRecordKey,item)
    if(response.success){
      setAcceptManagesDataAccept(true)
      cookies.user.acceptManagesData = true
        setCookie('user', cookies.user, { path: '/' })
    }
    else{
      handleAlert("error",'Ha ocurrido un error, por favor inténtelo de nuevo más tarde')
    }
  }

  const handlerAcceptInterested = async() => {

    if(termConditions){
      const item = {
        acceptInterestedBuyer: true,
      }
      
      const response = await AcceptInterested(user.phone,user.email,user.userRecordKey)
      const responseEdit = await EditUser(user.userRecordKey,item)
      
      if(response.success && responseEdit.success){
        setAcceptInterested(true)
        cookies.user.acceptManagesData = true
        setCookie('user', cookies.user, { path: '/' })
        props.history.push('/',{sub: user.userRecordKey,phone: user.phone, email: user.email, acceptManagesData: true, acceptInterestedBuyer: true});
      }
      else{
        handleAlert("error",'Ha ocurrido un error, por favor inténtelo de nuevo más tarde')
      }
    }
    else{
      handleAlert("error",'Favor de aceptar los términos y condiciones')
    }
  }

  const logOut = () => {
    removeCookie('lastAction');
    Auth.signOut({ global: true })
    .then(() => {
      props.history.push('/login')
    })
    .catch((e) => {
      props.history.push('/login')
    });

  }

  return (
    <main className={classes.container}>
    {
      isLoading ?
        <CircularProgress />
      :
      !acceptManagesData ?
      <>
        <h2 className={classes.title}>Venta de Datos</h2>
        <h3 className={classes.subtitle}>¿Te gustaría ganar dinero por ventas de tus datos?</h3>
        <h3 className={classes.subtitle}>Al recolectar y poner en venta tus datos</h3>
        <h3 className={classes.subtitle}>
          <b>Sólo podrán ser usados en forma resumida y anónima para estadística y analítica.</b>
        </h3>
        <h3 className={classes.subtitle}>
          ¿Aceptas que tus datos sean manejados por FlexeAnalytics, según el artículo II. 
          Opción de Monetización de los 
          <a href={process.env.REACT_APP_TERMS_ANALYTICS} target="_blank" className={classes.link}> Términos y Condiciones</a>
          ?
        </h3>
      </>
      :
      acceptManagesData && type === 'user' ?
      <>
        <h3 className={classes.subtitle}>
          <b>Has sido registrado correctamente en el programa de venta de datos. 
          Te contactaremos cuando encontremos una empresa interesada</b>
        </h3>
      </>
      :
      (acceptManagesData && !acceptInterested && type === 'lead') &&
      <>
        <h3 className={classes.title2}>Hemos encontrado un comprador interesado en tus datos:</h3>
        <div className={classes.dataInterestedContainer}>
          <img src = {Logo} className = {classes.logo}/>
          <h2 className={classes.subtitle}><b>¿Quieres vender tus datos?</b></h2>
          <FormControlLabel
            control={
              <Checkbox
                  checked={termConditions}
                  onChange={() => setTermConditions(!termConditions)}
                  name="terms"
              />
            }
            label={
              <p className={classes.checkboxText}>Acepto los  
                <a href={process.env.REACT_APP_TERMS_ANALYTICS} target="_blank" className={classes.link}>
                    Términos y Condiciones
                </a> de FlexeAnalytics
              </p>
            }
          />

        </div>
      </>
    }
      <div className={classes.buttonContainer}>
        <PrimaryButton type='submit' 
          onClick={() => !acceptManagesData ? acceptManageData() : acceptManagesData && type === 'user' ? 
          logOut()
          : handlerAcceptInterested()} 
          fullWidth
        >
            Aceptar
        </PrimaryButton>
      </div>
    </main>
  )
}

export default withSnackbar(DataUsage);

const useStyle = makeStyles(theme => ({
    container: {
        display: 'flex',
        padding:"0% 10%",
        alignItems: 'center',
        textAlign: 'center',
        flexDirection: 'column'
    },
    title: {
        ...theme.typography.title,
        fontSize: 60,
        color: theme.palette.primary.main,
        lineHeight: 1,
        margin: '2% 0',
        [theme.breakpoints.down('sm')]: {
          fontSize: 25,
        },
    },
    subtitle:{
        fontWeight: 'normal',
        lineHeight: 2,
        fontSize: 25,
        [theme.breakpoints.down('sm')]: {
            fontSize: 15,
            lineHeight: 1.2
        },
    },
    buttonContainer: {
        width: '50%',
    },
    link:{
        textDecoration: 'none',
        fontWeight: 'bold',
        color: theme.palette.blue.main,
        "&:visited":{
          color: theme.palette.blue.main,
        }
    },
    title2:{
      ...theme.typography.title,
      fontSize: 45,
      color: theme.palette.primary.main,
      textAlign: 'center',
      lineHeight: 1,
      margin: '2% 0',
      [theme.breakpoints.down('sm')]: {
          fontSize: 25,
      },
    },
    dataInterestedContainer:{
      display: "flex",
      flexDirection: "column",
      alignItems: 'center'
    },
    logo:{
      width: "50%",
      margin: '5%',
      [theme.breakpoints.down('md')]:{
        width: "90%",
      }
    },
    checkboxText: {
      margin: 0,
      [theme.breakpoints.down('md')]:{
        fontSize: 16,
      }
    },

}))