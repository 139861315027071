import React from 'react';
import CardDocument from './cardDocument';
import CardWithImage from './cardWithImage';
import CardProduct from './cardProduct';
import CardOrder from './cardOrder';

export default function DefaultCard(props) {
    if(props.cardType === 'cardDocument')
        return (
            <CardDocument
                title={props.title}
                file={props.file}
                fileType={props.fileType}
                getFile={props.getFile}
                handleOpen={props.handleOpen}
                handleDelete={props.handleDelete}
            />
        );
    else if (props.cardType == 'cardWithImage'){
        return(
            <CardWithImage
                storeName={props.storeName}
                address={props.address}
                phone={props.phone}
                isOpen={props.isOpen}
                geoLocation={props.geoLocation}
                snapshot={props.snapshot}
                storeprofilepic={props.storeprofilepic}
            />
        );
    }
    else if (props.cardType == 'cardProduct')
        return(
            <CardProduct
                productImage={props.productImage}
                separador={props.separador}
                productName={props.productName}
                productPrice={props.price}
                productDescription={props.description}
            />
        );
    else if (props.cardType == 'cardOrder')
        return(
            <CardOrder
                clientName={props.order.lendFullName}
                orderNo={props.order.orderNo}
                createdAt={props.order.createdAt}
                invoices={props.order.invoiceItems}
            />
        );
}