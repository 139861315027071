import React from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import searchICon from './../../../assets/images/searchIcon.svg';

import '../../../assets/css/main.css';

const useStyles = makeStyles({});

export default function DefaultSearch(props) {
  useEffect(()=>{
  },[])

  const classes = useStyles();

  return (
    <div className={props.className || "outlinedSearch"}>
      <img src={searchICon}></img>
      <TextField 
          className={props.className || "outlinedSearchText"}
          placeholder={props.label}
          type="search"
          value={props.value}
          onChange={props.handleChange}
          name={props.name}
          onKeyPress={props.keyPress}
      />
    </div>
  );
}