import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

//Reducers
import contractVersionReducer from './slices/contractVersionSlice';
import ordersReducer from './slices/ordersSlice';
import settingsReducer from './slices/settingsSlice';
import socketReducer from './slices/socketSlice';
import storeReducer from './slices/storeSlice';
import userReducer from './slices/userSlice';

const persistConfig = { key: 'root', version: 1, storage };
const persistedUserReducer = persistReducer(persistConfig, userReducer);

export const store =  configureStore({
  reducer: {
    contractVersion: contractVersionReducer,
    orders: ordersReducer,
    settings: settingsReducer,
    socket: socketReducer,
    store: storeReducer,
    user: persistedUserReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);