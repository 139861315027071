import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar  } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";

import DeafultSearch from '../../common/deafultSearch';
import iconButtonPlus from '../../../assets/images/icon_button_plus.png'
import mdiKey from '../../../assets/images/mdi_key.png'
import mdiEdit from '../../../assets/images/mdi_edit2.png'
import {FONT_DEFAULT} from '../../../lib/constants.js'

import { API } from 'aws-amplify';
const useStyles = makeStyles({
    root: {
        display: 'flex',
        flex: 1,
        backgroundColor: '#F6F6F6',
        flexDirection: 'column',
        width: '100%',
        height: '100%'
    },
    container: {
      display: 'flex',
      marginLeft: 30, 
      marginRight: 30, 
      marginTop: 20,
      marginBottom: 20,
      backgroundColor: '#FFFFFF',
      borderRadius: 5,
      border: '1px solid #E0E0E0',
      flexDirection: "row",
      padding: "49px 37px",
    },
    title: {
        ...FONT_DEFAULT,
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 24,
      marginLeft: 30, 
      marginTop: 30,
    },
    buttonStyle: {
      border: '1px solid #3B9A83',
      boxSizing: 'border-box',
      borderRadius: 5,
      width: 146,
      height: 38,
      margin: 2
    },
    buttonText: {
        ...FONT_DEFAULT,
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 10,
      color: '#3B9A83'
    },
    plusIcon: {
      marginRight: 10
    },
    tableHeaderText: {
        ...FONT_DEFAULT,
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 12,
      display: 'flex',
      alignItems: 'center',
      color: '#434343',
      borderTop: '1px solid #BDBDBD',
      borderBottom: '1px solid #BDBDBD',
      height: 60,
      justifyContent: 'center'
    },
    tableRowText1: {
        ...FONT_DEFAULT,
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: 12,
      paddingTop: 30,
      paddingBottom: 30,
      display: 'flex',
      alignItems: 'center',
      color: '#434343',
      backgroundColor: '#FFFFFF',
      height: 40,
      textAlign: 'center',
      justifyContent: 'center'
    },
    tableRowText2: {
        ...FONT_DEFAULT,
      fontStyle: 'normal',
      fontWeight: '600l',
      fontSize: 12,
      paddingTop: 30,
      paddingBottom: 30,
      display: 'flex',
      alignItems: 'center',
      color: '#434343',
      height: 40,
      textAlign: 'center',
      justifyContent: 'center',
      backgroundColor: '#F9F9F9',
    },
    backgrounOddColumn:{
      backgroundColor: '#F9F9F9'
    },
    backgrounEvenColumn:{
      backgroundColor: 'white'
    },
    width100:{
        width: '100%'
    },
    tableTop:{
        marginTop: '30px'
    },
    actionButonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        height: 40,
        alignItems: 'center',
        marginTop: 9
        
    },
    actionButon1: {
        cursor: 'pointer',
        marginRight: 10,
        width: '18px',
        height: '18px',
    },
    actionButon2: {
      cursor: 'pointer',
      marginLeft: 0,
      width: '18px',
      height: '18px',
    },
});

function WalletUsers(props) {
    useEffect(()=>{
        getUsers();
    },[])

    let history = useHistory();

    const classes = useStyles();
    const [isLoading, setLoading] = useState(true);
    const [users, setUsers] = useState ([]);

    const handleAlert = (variant, message) => {
        props.enqueueSnackbar( message, { variant });
    }; 

    const goToDetails = (user) => {
        history.push({pathname: '/admin/walletUsersDetails', state: {user}})
    }

    const getUsers = async () => {
        try{
            const profiles = await API.get('profile-api', `/profile`);
            const completeProfiles = []
            for (let index = 0; index < profiles.data.length-1; index++) {
                const element = await API.get('profile-api', `/profile/${profiles.data[index].userRecordKey}`);
                completeProfiles.push(element.data[0])
            }
            console.log(completeProfiles)
            setUsers(completeProfiles);
            setLoading(false);
        }
        catch(err){
            handleAlert('error', err.message);
            setLoading(false);
        }
    }
    return(
        <div className={classes.root}>
            <Typography className={classes.title}>Usuarios Wallet</Typography>
            <div className={classes.container}> 
                <div className={classes.width100}>
                    <div>
                        <DeafultSearch 
                            className="outlinedSearch" 
                            label="Buscar por usuario, orden" 
                            name="searchedWord"

                        />
                        <Button className={classes.buttonStyle}>
                            <img width="8" height="8" src={iconButtonPlus} className={classes.plusIcon} />
                            <Typography className={classes.buttonText}>Nuevo Usuario</Typography>
                        </Button>
                    </div>
                    <div className={`${classes.width100} ${classes.tableTop} ${!isLoading && 'overflow-auto'}`}>
                        {isLoading ?
                            <div className={`gralinfo_form ${classes.width100}`}>
                                <CircularProgress color="secondary" />
                            </div>
                            :
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>
                                            <Typography className={classes.tableHeaderText}>No.ID</Typography>
                                        </th>
                                        <th>
                                            <Typography className={classes.tableHeaderText}>Nombre</Typography>
                                        </th>
                                        <th>
                                            <Typography className={classes.tableHeaderText}>Teléfono</Typography>
                                        </th>
                                        <th>
                                            <Typography className={classes.tableHeaderText}>Correo</Typography>
                                        </th>
                                        <th>
                                            <Typography className={classes.tableHeaderText}>Estatus</Typography>
                                        </th>
                                        <th>
                                            <Typography className={classes.tableHeaderText}>Acciones</Typography>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map(user =>
                                        <tr key={user.userRecordKey} item lg={12}>
                                            <td className={classes.backgrounOddColumn}>
                                                <Typography className={classes.tableRowText2} noWrap={true}>{user.userRecordKey}</Typography>
                                            </td>
                                            <td>
                                                <Typography className={classes.tableRowText1} noWrap={true}>{user.firstName} {user.lastName}</Typography>
                                            </td>
                                            <td className={classes.backgrounOddColumn}>
                                                <Typography className={classes.tableRowText2} noWrap={true}>{user.phoneNumber}</Typography>
                                            </td>
                                            <td>
                                                <Typography className={classes.tableRowText1} noWrap={true}>{user.email}</Typography>
                                            </td>
                                            <td className={classes.backgrounOddColumn}>
                                                <Typography className={classes.tableRowText2}>{user.isActive ? 'Activo' : 'Inactivo'}</Typography>
                                            </td>
                                            <td className={classes.backgrounEvenColumn}>
                                                <div className={classes.actionButonsContainer}>
                                                    <img width="23" height="23" src={mdiKey} 
                                                    className={classes.actionButon1} 
                                                    />
                                                    <img width="23" height="23" src={mdiEdit} 
                                                    className={classes.actionButon2} 
                                                    onClick={() => goToDetails(user)} 
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        }
                    </div>
                </div>
            </div>
        </div>
      );
}

export default withSnackbar(WalletUsers)