import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme =>({
  label: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
    borderRadius: 10,
    border: 'none',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    marginLeft: (props) => (props.marginHorizontal || 0),
    marginRight: (props) => (props.marginHorizontal || 0),
    marginTop: (props) => (props.marginVertical || 0),
    marginBottom: (props) => (props.marginVertical || 0),
    width: (props) => (props.fullWidth ? '100%' : props.width ? props.width : 'auto'),
    maxWidth: (props) => (props.maxWidth || '100'),
    ...theme.typography.input,
    '&:hover': {
      color: '#91D0B9',
   },
  },
  input: {
    position: 'absolute',
    width: 1,
    height: 1,
    padding: 0,
    margin: -1,
    overflow: 'hidden',
    border: 0,
  },
}));

const FileInput = (props) => {
  const classes = useStyles(props);

  return (
    <>
      <label for={props.id || undefined} className={classes.label}>
        <text>{props.placeholder || 'Subir Archivo'}</text>
        <input
          id={props.id || undefined}
          name={props.name || undefined}
          type={props.type || 'file'}
          placeholder={props.placeholder || null}
          value={props.value || undefined}
          onChange={props.onChange || null}
          className={classes.input}
          autoFocus={props.autoFocus || false}
          accept={props.accept || undefined}
        />
      </label>
    </>
  );
};

export default FileInput;
