import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import defaultProductImage from '../../../assets/images/noProductImage.png';

const useStyles = makeStyles( theme => ({
  cardComponent: {
    cursor: 'pointer',
    minHeight: '165px',
    backgroundColor: '#FFFFFF',
    marginTop: 20,
    marginRight: '-5px',
    height: 'auto',
  },
  productImageContainer: {
    borderRadius: 5,
    width: '100%',
    overflow: 'hidden',
    textAlign: 'center',
  },
  noProductImageContainer: {
    borderRadius: 5,
    // width: '180px',
    backgroundSize: '100% 100%',
    overflow: 'hidden',
    textAlign: 'center',
  },
  productImage: {
    height: '180px',
    width: '100%',
    objectFit: 'cover',
  },
  noProductImage: {
    height: '180px',
    width: '100%',
    objectFit: 'cover',
  },
  productName: {
    ...theme.typography.paragraph,
    paddingRight:50,
    marginRight:20,
    display:'flex',
    flexDirection:'row',
    flexWrap:'wrap',
    maxLength:20,
    
  },
  price: {
    ...theme.typography.paragraph,
    paddingRight: 5,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  description: {
    ...theme.typography.smallText,
    paddingRight: 10,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  statusStyle: {
    background: '#FD8197',
    width: '100%',
    borderRadius: 5,
    alignItems: 'center',
    color: '#FFFFFF',
    width: 70,
    justifyContent: 'center',
    height: 20,
  },
  statusOpen: {
    background: '#6EB89D',
    fontSize: 8,
  },
  statusClosed: {
    background: '#FD8197',
  },
  statusText: {
    fontSize: 10,
    fontWeight: 900,
    marginTop: 10,
    paddingTop: 2,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  separador: {
    height: 2,
    backgroundColor: '#F0F0F0',
  },
  textsContainer: {
    marginRight:20,
    padding: 10,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

export default function CardProduct(props) {
  const classes = useStyles();

  return (
    <Card className={classes.cardComponent}>
      <CardContent>
        <div
          className={`${
            props.productImage !== defaultProductImage
              ? classes.productImageContainer
              : classes.noProductImageContainer
          } justify-content-center`}
        >
          <img
            src={props.productImage}
            className={`${
              props.productImage !== defaultProductImage
                ? classes.productImage
                : classes.noProductImage
            }`}
          />
        </div>
        <div className={classes.separador}>{props.separador}</div>
        <div className={classes.textsContainer}>
          <Grid container xs={12}>
            <Grid item xs={8} sm={8}>
              <Typography className={`${classes.productName}`}>
                {props.productName.substring(0,15)}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={4}>
              <Typography className={`${classes.price}`}>
                {`$ ${Number.parseFloat(
                  props.productPrice.replace(/[$,]+/g, '')
                )
                  .toFixed(2)
                  .toLocaleString('es-MX')}`}
              </Typography>
            </Grid>
          </Grid>
          <Typography className={`${classes.description}`}>
            {props.productDescription}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
}
