import React from 'react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Auth, API } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import moment from 'moment-timezone';

import '../../assets/css/main.css';
import notasadmin from '../../assets/images/notas-administrador.svg';
import Footer from '../common/footer';
import DefaultButton from '../common/defaultButton';
import Fisica from './fisica';
import Moral from './moral';
import checkIncompleteLegalDocs from '../../lib/checkIncompleteLegalDocs';
import PrimaryButton from '../common/primaryButton';
import SecondaryButton from '../common/secondaryButton';

const useStyles = makeStyles(theme =>({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30,
    overflow: 'hidden',
    backgroundColor: '#FAFAFA',
    borderRadius: 20,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    overflowX: 'hidden',
    padding: 10,
  },
  containerProfile: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    backgroundColor: '#FAFAFA',
  },
  title: {
    marginTop: 40,
    ...theme.typography,
    lineHeight: 3,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.grey.main,
    mixBlendMode: 'normal',
  },
  conatinerTitleTabs: {
    display: 'flex',
    flexDirection: 'row',
    bakcgroundColor: '#FFFFFF',
  },
  conatinerTitleTabs1: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    marginBottom: 40,
    marginTop: 25,
  },
  conatinerTitleTabs2: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    overflow: 'hidden',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingBottom: 20,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      paddingBottom: 0,
    },
  },
  titleAndProfileContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '50%',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      textAlign: 'center',
      width: '100%',
    },
  },
  versionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      textAlign: 'center',
      width: '100%',
    },
  },
  version: {
    ...theme.typography.paragraph,
    color: theme.palette.gray.main,
    paddingTop: 20,
  },
  notesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      paddingTop: 20,
    },
  },
  notes: {
    ...theme.typography.paragraph,
    color: theme.palette.gray.main,
  },
  profile: {
    ...theme.typography.title,
    paddingTop: 20,
    color: theme.palette.gray.main,
    whiteSpace: 'wrap',
  },
  personType: {
    color: theme.palette.primary.main,
  },
  titleContract: {
    ...theme.typography.smallText,
    display: 'inline',
    alignItems: 'flex-end',
    lineHeight: 3,
    color: '#828282',
    marginLeft: 5,
  },
  titleNotes: {
    ...theme.typography.mediumText,
    display: 'inline',
    alignItems: 'center',
    lineHeight: 3,
    marginLeft: 5,
    cursor: 'pointer',
  },
  buttonNotes: {
    borderRadius: 10,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  containerForm: {
    backgroundColor: '#ffffff',
    borderRadius: 0,
    flexDirection: 'row',
    display: 'flex',
    flex: 1,
  },
  footContainer: {
    flexWrap: 'wrap',
    alignSelf: 'center',
    backgroundColor: '#faf9f9',
    paddingBottom: 100,
  },
  footText: {
    ...theme.typography.smallText,
    lineHeight: 1,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#919191',
  },
  containerLegalPerson: {
    flexDirection: 'row',
    paddingLeft: 30,
    paddingTop: 30,
    paddingBottom: 30,
    paddingRight: 30,
  },
  containerRadioLabel: {
    display: 'inline-table',
    marginRight: 20,
    marginTop: 10,
  },
  radioLabel: {
    lineHeight: 1,
    color: '#696969',
    paddingBottom: 20,
    paddingRight: 10,
    paddingLeft: 10,
    ...theme.typography.paragraph,
  },
  containerButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 50,
    marginRight: 50,
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  containerButtonNext: {
    display: 'inline',
    float: 'right',
  },
  containerButtonBack: {
    display: 'inline',
  },
  dropdown: {
    position: 'absolute',
    top: 28,
    right: 0,
    left: 0,
    zIndex: 1,
    padding: 24,
    border: 'solid 2px #A0A0A0',
    borderRadius: 5,
    backgroundColor: '#f9f9f9',
    maxWidth: 500,
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
  containerNote: {
    textAlign: 'left',
    marginBottom: 20,
  },
  noteTitle: {
    ...theme.typography.smallText,
    margin: 5,
    opacity: 0.6,
  },
  noteText: {
    ...theme.typography.smallText,
    margin: 5,
  },
  titleSubContainer1: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  primaryButtonStyle: {
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  secondaryButtonStyle: {
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  containerPersonType: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: 10,
    width: 'auto',

    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: 10,
      paddingRight: 10,
    },
  },
  containerPersonTypeItems: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20,
    paddingBottom: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  paddingButton: {
    paddingBottom: 20,
  },
  typePersonButton: {
    minWidth: 200,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minWidth: 0,
    },
  },
  titleRadioButton: {
    ...theme.typography.subtitle,
    fontWeight: 600,
    color: '#696969',
  },
}));

export default function Profile(props) {
  const userKey = useSelector(state => state.user.value);

  useEffect(() => {
    getuserprofile(true);
  }, []);

  const classes = useStyles();
  let history = useHistory();

  const [merchantProfile, setMerchantProfile] = React.useState(null);
  const [slp, setSelectedLegalPerson] = React.useState(null);
  const [value, setLegalPerson] = React.useState('FISICA');
  const [currentTab, setTab] = React.useState(0);
  const [ExecutingRequest, callRequest] = React.useState(false);
  const [isCompleted, setCompleted] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [openNotes, setOpenNotes] = React.useState(false);
  const [notes, setNotes] = React.useState(null);
  const [contract, setContract] = React.useState(null);
  const [signedDate, setSignedDate] = React.useState(null);

  const getuserprofile = async (settingLoading = false) => {
    if (settingLoading) {
      setLoading(true);
    }
    const getUser = await Auth.currentAuthenticatedUser();
    try {
      const getGralProfile = await API.get(
        'profile-api',
        `/profile/${userKey}`
      );
      const merchant = await API.get(
        'merchant-api',
        `/merchant?merchantRecordKey=${getGralProfile.data[0].merchantRecordKey}`
      );
      if (
        merchant.data.merchantStatus &&
        merchant.data.merchantStatus === 'approved'
      ) {
        setCompleted(true);
      }
      const incomplete = checkIncompleteLegalDocs(merchant.data, 'merchant');
      if (
        (merchant.data.merchantStatus &&
          merchant.data.merchantStatus === 'rejected') ||
        (merchant.data.merchantStatus === 'progress' && incomplete.length > 0)
      ) {
        getNotes(merchant.data);
      }
      merchant.data.identityValidationStep =
        getGralProfile.data[0].identityValidationStep;
      if (merchant.data.contract_version && merchant.data.signed_at) {
        setContract(merchant.data.contract_version);
        setSignedDate(moment(merchant.data.signed_at).format('DD/MM/YYYY'));
      }
      setMerchantProfile(merchant.data);
      if (
        merchant.data.businessConstitution !== undefined &&
        (merchant.data.businessConstitution === 'FISICA' ||
          merchant.data.businessConstitution === 'MORAL')
      ) {
        setSelectedLegalPerson(merchant.data.businessConstitution);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const getNotes = (merchantData) => {
    const notes = {
      global: '',
      IdentificacionOficial: '',
      ComprobanteDomicilio: '',
      ActaConstitutiva: '',
      CartaPoder: '',
    };
    if (merchantData.adminComment && merchantData.adminComment !== '') {
      notes['global'] = merchantData.adminComment;
    }
    if (merchantData.legalDocuments) {
      merchantData.legalDocuments.forEach((doc) => {
        if (doc.adminNotes !== '') {
          notes[doc.documenttype] = doc;
        }
      });
    }
    if (
      notes['global'] !== '' ||
      notes['IdentificacionOficial'] !== '' ||
      notes['ComprobanteDomicilio'] !== '' ||
      notes['ActaConstitutiva'] !== '' ||
      notes['CartaPoder'] !== ''
    ) {
      setNotes(notes);
    } else {
      setNotes(null);
    }
  };

  const handleCallRequest = async (isSuccess, newTab = null) => {
    callRequest(false);
    if (isSuccess) {
      await getuserprofile();
      if (newTab === 'END') {
        goToHome();
      } else {
        setTab(newTab);
      }
    }
  };

  const handleChange = (event) => {
    setLegalPerson(event.target.value);
  };

  const handleChangeTab = (value) => {
    if (!ExecutingRequest) {
      setTab(value);
    }
  };

  const handleNext = () => {
    if (!ExecutingRequest) {
      if (slp !== null) {
        if (slp === 'FISICA') {
          if (currentTab < 5) {
            callRequest(true);
          }
        } else if (slp === 'MORAL') {
          if (currentTab < 5) {
            callRequest(true);
          }
        } else {
          setTab(currentTab + 1);
        }
      } else {
        setSelectedLegalPerson(value);
      }
    }
  };

  const handleBack = () => {
    if (!ExecutingRequest) {
      if (currentTab > 0) {
        setTab(currentTab - 1);
      } else {
        setSelectedLegalPerson(null);
      }
    }
  };
  const goToHome = () => {
    history.push({
      pathname: '/dashboard',
    });
  };

  const handleOpenNotes = () => {
    setOpenNotes((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpenNotes(false);
  };

  const buildNotes = () => {
    const mapDocumentTitles = {
      IdentificacionOficial: 'Identificación Oficial',
      ComprobanteDomicilio: 'Comprobante de Domicilio',
      ...(merchantProfile.businessConstitution === 'MORAL' && {
        ActaConstitutiva: 'Acta Constitutiva',
        CartaPoder: 'Poderes del Representate',
      }),
    };
    return Object.keys(notes).map((doc) => {
      if (doc === 'global') {
        if (notes[doc] !== '')
          return (
            <div className={classes.containerNote}>
              <Typography className={classes.noteTitle}>
                Razón de rechazo
              </Typography>
              <Typography className={classes.noteText}>{notes[doc]}</Typography>
            </div>
          );
      } else {
        if (notes[doc].documentStatus === 'REJECTED')
          return (
            <div className={classes.containerNote}>
              <Typography className={classes.noteTitle}>
                {mapDocumentTitles[doc]}
              </Typography>
              <Typography className={classes.noteText}>
                {notes[doc].adminNotes}
              </Typography>
            </div>
          );
      }
    });
  };

  const labelNotes = () => {
    return (
      <div className={classes.conatinerTitleTabs2}>
        <div className={classes.buttonNotes} onClick={handleOpenNotes}>
          <img src={notasadmin} width={25} height={25} alt='clipboard' />
          <Typography className={classes.titleNotes}>
            Notas del Administrador
          </Typography>
          {openNotes ? (
            <div className={classes.dropdown} onClick={handleClickAway}>
              {buildNotes()}
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <div className={`${classes.content} scrollBarHidden`}>
        <ClickAwayListener onClickAway={handleClickAway}>
          {isLoading ? (
            <div className='gralinfo_form'>
              <CircularProgress color='secondary' />
            </div>
          ) : (
            <>
              <div>
                {slp === null ? (
                  <div className={classes.headerContainer}>
                    <div className={classes.titleAndProfileContainer}>
                      <Typography className={classes.profile}>
                        Mi Perfil:{' '}
                        <span className={classes.personType}>
                          Completar Perfil
                        </span>
                      </Typography>
                    </div>
                  </div>
                ) : (
                  <div className={classes.headerContainer}>
                    <div className={classes.titleAndProfileContainer}>
                      <Typography className={classes.profile}>
                        Mi Perfil:{' '}
                        <span className={classes.personType}>
                          {slp === 'FISICA'
                            ? ' Persona Física '
                            : ' Persona Moral '}
                        </span>
                      </Typography>
                    </div>

                    {contract && signedDate && (
                      <div className={classes.versionContainer}>
                        <Typography className={classes.version}>
                          Términos y condiciones aceptados en {signedDate}
                        </Typography>
                      </div>
                    )}
                  </div>
                )}
                <div style={{ paddingBottom: 20 }}>
                  {notes && (
                    <div className={classes.notesContainer}>
                      <Typography className={classes.notes}>
                        {labelNotes()}
                      </Typography>
                    </div>
                  )}
                </div>
                <div onClick={handleClickAway}>
                  {slp === null ? (
                    <>
                      <div className={classes.containerPersonType}>
                        <div className={classes.containerPersonTypeItems}>
                          <Typography className={classes.titleRadioButton}>
                            Este perfil pertenece a una:{' '}
                          </Typography>
                          <FormControl component='fieldset'>
                            <RadioGroup
                              aria-label='legalPerson'
                              name='legalPerson'
                              value={value}
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                className={classes.radioLabel}
                                value='FISICA'
                                control={<Radio color='#6EB89D' />}
                                label='Persona Física con Actividad Empresarial'
                              />
                              <FormControlLabel
                                className={classes.radioLabel}
                                value='MORAL'
                                control={<Radio color='#6EB89D' />}
                                label='Persona Moral'
                              />
                            </RadioGroup>
                          </FormControl>
                          <PrimaryButton
                            onClick={handleNext}
                            disabled={merchantProfile === null}
                            primaryButtonStyle={classes.typePersonButton}
                          >
                            Siguiente
                          </PrimaryButton>
                        </div>
                      </div>
                    </>
                  ) : slp === 'FISICA' ? (
                    <Fisica
                      merchantProfile={merchantProfile}
                      handleChangeTab={handleChangeTab}
                      currentTab={currentTab}
                      ExecutingRequest={ExecutingRequest}
                      handleCallRequest={handleCallRequest}
                      setCompleted={setCompleted}
                      isCompleted={isCompleted}
                    />
                  ) : slp === 'MORAL' ? (
                    <Moral
                      merchantProfile={merchantProfile}
                      handleChangeTab={handleChangeTab}
                      currentTab={currentTab}
                      ExecutingRequest={ExecutingRequest}
                      handleCallRequest={handleCallRequest}
                      setCompleted={setCompleted}
                      isCompleted={isCompleted}
                    />
                  ) : null}
                </div>
              </div>
              {slp !== null && (
                <div className={classes.containerButtons}>
                  {(currentTab > 0 ||
                    merchantProfile.businessConstitution === undefined ||
                    merchantProfile.businessConstitution === null ||
                    merchantProfile.businessConstitution === '') && (
                    <SecondaryButton
                      onClick={handleBack}
                      minWidth={100}
                      disabled={merchantProfile === null}
                      secondaryButtonStyle={classes.secondaryButtonStyle}
                    >
                      Atrás
                    </SecondaryButton>
                  )}
                  <div></div>
                  {((slp === 'FISICA' && !(isCompleted && currentTab === 4)) ||
                    (slp === 'MORAL' &&
                      !(isCompleted && currentTab === 4))) && (
                    <PrimaryButton
                      onClick={handleNext}
                      minWidth={100}
                      disabled={merchantProfile === null}
                      primaryButtonStyle={classes.primaryButtonStyle}
                    >
                      Siguiente
                    </PrimaryButton>
                  )}
                </div>
              )}
              {/* <div className={classes.footContainer}>
                <Footer />
              </div> */}
            </>
          )}
        </ClickAwayListener>
      </div>
    </div>
  );
}
