import React,{ useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import '../../../assets/css/main.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import DefaultButton from '../../common/defaultButton';
import DefaultInput from '../../common/defaultInput';
import { Auth, API, Storage } from 'aws-amplify';
import CheckValue from '../../../lib/formatValidations';
import { withSnackbar } from "notistack";
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import moment from 'moment-timezone';
import { Typography, Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const useStyles = makeStyles({
    container: {
      flex: 8,
      marginLeft: 30,
      marginRight: 30,
    },
    containerForm: {
        backgroundColor: '#f2f2f2',
        borderRadius: 19,
        display: 'flex',
        flex: 1,
        padding: 40
    },
    column60: {
        width:'60%',
        float: 'left',
        height: '100%',
        padding: 10,
        margin: 0
    },
    column40: {
        width:'40%',
        float: 'left',
        height: '100%',
        padding: 10,
        margin: 0
    },
    inputMargin:{
        marginTop: 15
    },
    centerComponent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    imageStyle: {
        width: '100%'
    },
    containerFormTitle: {
        backgroundColor: '#ffffff',
        borderRadius: 19,
        flexDirection: "row",
        display: 'flex',
        flex: 1,
        padding: 20,
        marginTop: 15
    },
    F1: {
        display: 'flex',
        flex: 1,
        padding: 20
    },
    F2: {
        display: 'flex',
        flex: 2,
        padding: 20
    },
});

const NewFantasyGS= (props) =>{
    useEffect(() => {
        loadInventory();
    }, []);

    const edit=Object.keys(props.EditInventory).length;
    const [isLoading, setLoading] = React.useState(false);
    const [newQuantity, setNewQuantity] = React.useState( edit ? props.EditInventory.quantity : '' );
    const [selectedProduct, setSelectedProduct] = useState(edit ? props.EditInventory : '' );
    const [inventory, setInventory] = useState([]);
    const [file, setFile] = React.useState( edit ? 
        {
            previewImage: props.EditInventoryI, 
            data: props.EditInventoryI
        } : {
            previewImage: '',
            data: ''
    });
    /*Errors*/
    const [newQuantityError, setNewQuantityError] = useState("")

    const handleChange = (event) => {
        setNewQuantity(event.target.value)
    }

    const cleanError = () => {
        setNewQuantityError('');
    }

    const cleanForm = () => {
        setNewQuantity('');
    }

    const handleAlert = (variant, message) => {
        props.enqueueSnackbar( message, { variant });
    };

    const updateInventory = async () => {
        setLoading(true)
        let hasErrors = false
        if(!CheckValue('integer',newQuantity)){
          setNewQuantityError('Ingrese números enteros');
          hasErrors = true;
        }
        if(!hasErrors){
          try {
            await API.post('inventory-api', '/inventory', {
                body: {
                    entityId: 'FantasyShop',
                    entityKey: 'store',
                    quantity: parseInt(newQuantity),
                    status: selectedProduct.status,
                    productId: selectedProduct.product.productRecordKey,
                }
            });
            props.createInventory();
            handleAlert('success', 'Actualizado con Éxito!');
          } catch (err) {
            handleAlert('warning', err);
            console.log(err)
            setLoading(false);
          }
        }
        else{
          setLoading(false);
        }
      }
   
    const handleToggleEditClick = async (item, e) => {
        setLoading(true)
        try {
            await API.post('inventory-api', '/inventory', {
                body: {
                    entityId: 'FantasyShop',
                    entityKey: "store",
                    quantity: 0,
                    status: "disponible",
                    productId: item.productRecordKey
                  },
            });
            handleAlert('success', 'Guardado con Éxito!')
            setLoading(false)
            props.createInventory()
        } catch (err) {
            handleAlert('warning', 'Error!')
            setLoading(false)
            props.createInventory()
        }
    }
    const loadInventory = async () => {
        try {
            const getInventory = await API.get('inventory-api', `/inventory`, {
                queryStringParameters: {
                    entityId: 'FantasyShop',
                    entityKey: 'store'
                }
            });
            const getProducts = await API.get('product-api', '/products', {
              queryStringParameters: {
                  FilterExpression: 'merchantRecordKey=:merchantRecordKey',
                  ExpressionAttributeValues: `:merchantRecordKey=FantasyShop`
              }
            });
            const inventoryAvailableList = getProducts.data.filter((elem) => !getInventory.data.find(({ productId }) => elem.productRecordKey === productId));
            const pImages = inventoryAvailableList.map(item => Storage.get(`products/${item.productRecordKey}/default.jpg`));
            const p = await Promise.all(pImages);
            const upInventoryAvailableList = inventoryAvailableList.map((value, index) => ({
                ...value,
                productImage: p[index]
            }));
            setInventory(upInventoryAvailableList);
            setLoading(false);
        } catch (err) {
            console.error(err);
            setLoading(false);
        }
      };

      const renderInventory = () => {
        const element = inventory.map(item => (
          <div className={classes.containerForm}>
            <div className={classes.F1} ><img width="70" height="70" src={item.productImage} /></div>
            <label className={classes.F2}><strong>{item.productName}</strong></label>
            <label className={classes.F1}>{item.price}</label>
            <label className={classes.F1}>{item.productType}</label>
            {item.quantity > 0 ?
                <label className={classes.F1}>
                  Disponible
                </label>
              :
                <label className={classes.F1}>
                  Agotado
                </label>
              }
            <IconButton onClick={(e) => handleToggleEditClick(item, e)} >
                <AddCircleIcon />
            </IconButton>
          </div>
        ))
        return element;
      }

    const classes = useStyles();

    return(
        <div className={classes.container}>
                {isLoading ?
                    <div className='styleCircularProgress'>
                        <CircularProgress color="secondary" />
                    </div>
                : 
                    <div>
                        {edit ?
                        <div className={classes.quantityModal}>
                            <h2>Cantidad para agregar a: {props.EditInventory.product.productName}</h2>
                            <img src={file.previewImage} width="25%" height="40%"/>
                            <DefaultInput custom={{
                                handleChange,
                                value: newQuantity,
                                labelText: "",
                                classLabel: "labelInputStyleForm",
                                id: "newQuantity",
                                type: "text",
                                name:"newQuantity",
                                className: `defaultInputTextForm col-12`,
                                placeholder:"Nueva Cantidad",
                                error: newQuantityError
                            }}
                            />
                            <br/>
                            <Button className={classes.cancelButtonStyle}>
                                <Typography className={classes.cancelButtonText} onClick={() => props.createInventory()}>Cancelar</Typography>
                            </Button>
                            <Button className={classes.successButtonStyle}>
                                <Typography className={classes.successButtonText} onClick={() => updateInventory()}>Agregar</Typography>
                            </Button>
                        </div>
                        :
                            <div>
                                <div className={classes.containerFormTitle}>
                                        <div className={classes.F1} ><label width="70" height="70" />Imagen</div>
                                        <label className={classes.F2}>Descripción del producto</label>
                                        <label className={classes.F1}>Precio</label>
                                        <label className={classes.F1}>Categoría</label>
                                        <label className={classes.F1}>Estatus</label>
                                        <IconButton>
                                            <AddCircleIcon/>
                                        </IconButton>
                                    </div>
                                <div>
                                    {renderInventory()}
                                </div>
                            </div>
                        }
                    </div>
                }
        </div>
    );
}

export default withSnackbar(NewFantasyGS);