import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { Auth, API, Storage } from 'aws-amplify';
import getMerchantData from '../../lib/getMerchant';
import PrimaryButton from '../common/primaryButton';
import createAccount from '../../lib/neritoPay/register';
import {
  CircularProgress,
} from '@material-ui/core';
import DefaultModal from '../modal';
import waitForIt from './registerNeritoPay/waitForIt';

const NeritoPay = (props) => {
  const userKey = useSelector(state => state.user.value);

  const [merchant, setMerchant] = useState()
  const [merchantPicture, setProfilePicture] = React.useState();
  const [isLoading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getUser();
  }, [])

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  let history = useHistory();
  const classes = useStyles();

  const getUser = async () => {
    setLoading(true);
    const userInfo = await Auth.currentAuthenticatedUser();
    const currentUser = userInfo.attributes;
    if (currentUser === undefined) {
      handleAlert('error', 'Ah ocurrido un error al cargar los datos del usuario');
      history.push('/login');
    } else {
      try {
        const getGralProfile = await API.get(
          'profile-api',
          `/profile/${userKey}`
        );
        if (getGralProfile.data[0].merchantRecordKey) {
          const merchant = await getMerchantData(
            'merchant',
            getGralProfile.data[0].merchantRecordKey
          );
          if (
            getGralProfile.data[0].hasAvatar ||
            getGralProfile.data[0].isManagerOfStore
          ) {
            const pImage = await Storage.get(
              `profiles/${merchant.merchantRecordKey}/default.jpg`
            );
            setProfilePicture(pImage);
          }
          setMerchant(merchant);
          setLoading(false);
        } else {
          setLoading(false);
          handleAlert('error', 'No es posible acceder a esta opción')
        }
      } catch (err) {
        handleAlert('error', 'Algo salió mal')
        setLoading(false);
      }
    }
  }

  const getMerchant = async () => {
    try {
      const getGralProfile = await API.get(
        'profile-api',
        `/profile/${userKey}`
      );
      if (getGralProfile.data[0].merchantRecordKey) {
        const merchant = await getMerchantData(
          'merchant',
          getGralProfile.data[0].merchantRecordKey
        );
        if (
          getGralProfile.data[0].hasAvatar ||
          getGralProfile.data[0].isManagerOfStore
        ) {
          const pImage = await Storage.get(
            `profiles/${merchant.merchantRecordKey}/default.jpg`
          );
          setProfilePicture(pImage);
        }
        setMerchant(merchant);
        return merchant;
      } else {
        handleAlert('error', 'No es posible acceder a esta opción')
      }
    } catch (err) {
      handleAlert('error', 'Algo salió mal')
      setLoading(false);
    }
  }

  const standBy = async () => {
    try {
      const merchant = await waitForIt(getMerchant)
      if (merchant.isCommissionAgent) {
        handleAlert('success', 'Se ha creado su usuario en Nerito Pay');
        history.push({ pathname: '/neritoPayStores', state: { merchantId: merchant.merchantRecordKey } });
      }
    } catch (err) {
      handleAlert('error', 'Algo salió mal')
      setLoading(false);
    }
  }

  const register = async () => {
    const registerResponse = await createAccount(merchant.merchantRecordKey);
    const { success, errorType, message, response } = registerResponse;
    if (success) {
      handleAlert('success', 'Se ha creado su usuario en Nerito Pay');
      history.push({ pathname: '/neritoPayStores', state: { merchantId: merchant.merchantRecordKey } });
    }
    else {
      handleAlert('error', message)
    }
  }

  return (
    <div>
      {isLoading ?
        <div className={classes.loadingContainer}>
          <CircularProgress color='secondary' />
        </div>
        :
        <div className={classes.container}>
          <div className={classes.titleContainer}>
            <text className={classes.title}>Registrate en
              <text className={classes.blueTitle}> NeritoPay</text>
            </text>
          </div>
          <div className={classes.contentContainer}>
            <div className={classes.halfContainer}>
              <text className={classes.subtitle}>¿Por qué crear tu negocio en <text className={classes.blueTitle}>NeritoPay</text>?</text>
              <text className={classes.info}> Asociar tu negocio a NeritoPay tiene muchas ventajas para el manejo óptimo de tu empresa, tales como:</text>
              <li className={classes.info}> Ahorrar comisiones</li>
              <li className={classes.info}> Sustituir o complementar el uso de terminal bancaria o cualquier punto de venta para tarjetas bancarias</li>
              <li className={classes.info}> Remunera al participar como punto de acceso de depósitos y retiros de efectivo para otros usuarios NeritoPay</li>
              <li className={classes.info}> Automáticamente, tu tienda en línea es un menú para tu establecimiento en físico</li>
              <li className={classes.info}> Puedes incluir promociones, ofertas o crear tus monederos de premios para tus clientes todo en uno</li>
              <text className={classes.info}>Para mas información visita <a className={classes.blueTitle} href="http://www.neritopay.com" target="_blank">neritopay.mx</a></text>

              <div className={classes.buttonContainer}>
                <PrimaryButton
                  color="#4B9BB8"
                  onClick={() => setShowModal(true)}>
                  Registrarme en Nerito Pay
                </PrimaryButton>
              </div>
            </div>
          </div>
          <DefaultModal
            open={showModal}
            handleClose={() => setShowModal(false)}
            modalType='NeritoPay'
            merchantId={merchant.merchantRecordKey}
            standBy={standBy}
          />
        </div>
      }
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 0,
    overflow: 'hidden',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.lightGrey.main}`,
    marginBottom: 15,
  },
  title: {
    ...theme.typography.title,
    paddingTop: 10,
    paddingBottom: 10,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  blueTitle: {
    color: "#4B9BB8",
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  subtitle: {
    ...theme.typography.subtitle,
    paddingTop: 10,
    paddingBottom: 10,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  halfContainer: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
  info: {
    ...theme.typography.paragraph,
    paddingRight: 10,
    fontWeight: 600,
  },
  loadingContainer: {
    margin: 'auto',
    paddingTop: '50px',
    ...theme.globals.center,
  },
  buttonContainer: {
    display: 'flex',
    marginTop: 20,
    marginBottom: 20,
    paddingBottom: 20,
    paddingTop: 20
  }
}));

export default withSnackbar(NeritoPay);