import React, { useEffect, useCallback } from "react";
import './style.css'

export default function CustomMatiButton (props) {
  const button = React.createRef(null);

  const handleLoaded = useCallback(() => {
    if(props.handleLoaded){
      props.handleLoaded();
    }else{
        console.warn("Mati Handle loaded not implemented" /* no payload */);
    }
  }, []);

  const handleFinished = useCallback(({ detail }) => {
    if(props.handleFinished){
      props.handleFinished(detail);
    }else{
        console.warn("Mati Handle finished not implemented" /* no payload */);
    }
  }, []);

  const handleExited = useCallback(() => {
    if(props.handleExited){
      props.handleExited();
    }else{
        console.warn("Mati Handle exited not implemented" /* no payload */);
    }
  }, []);

  useEffect(() => {
    const ref = button.current;
    if (ref) {
      // subscribe to callbacks
      ref.addEventListener("mati:loaded", handleLoaded);
      ref.addEventListener("mati:userFinishedSdk", handleFinished);
      ref.addEventListener("mati:exitedSdk", handleExited);
    }
    return () => {
      if (ref) {
        // unsubscribe from callbacks
        ref.removeEventListener("mati:loaded", handleLoaded);
        ref.removeEventListener("mati:userFinishedSdk", handleFinished);
        ref.removeEventListener("mati:exitedSdk", handleExited);
      }
    };
  }, [button, handleLoaded, handleFinished, handleExited]);
  console.log(props);
  return <mati-button color="#6EB89D" ref={button} clientid={process.env.REACT_APP_MATI_CLIENT_ID} flowId={process.env.REACT_APP_MATI_FLOW_ID}  metadata={JSON.stringify(props.metadata)} />;
}
