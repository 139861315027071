import React from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import { API } from 'aws-amplify';
import TextField from '@material-ui/core/TextField';
import moment from 'moment-timezone';
import Button from '@material-ui/core/Button';
import { CSVLink } from 'react-csv';

import '../../../assets/css/main.css';
import noMovements from '../../../assets/images/no_movements.svg';
import {FONT_DEFAULT} from '../../../lib/constants.js'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flex: 1,
    backgroundColor: '#F6F6F6',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    paddingTop: 10,
  },
  container: {
    backgroundColor: '#FFFFFF',
    padding: '40px 50px 50px 40px',
    borderRadius: 5,
    border: '1px solid #E0E0E0',
  },
  loadingContainer: {
    display: 'flex',
    flex: 1,
    backgroundColor: '#F6F6F6',
    justifyContent: 'center',
    alignItems: 'center',
  },
  csvLinkContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    marginLeft: 10,
  },
  tableHeaderText: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid #BDBDBD',
    borderBottom: '1px solid #BDBDBD',
    height: 60,
    justifyContent: 'center',
  },
  tableRowText: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 12,
    paddingTop: 15,
    paddingRight: 15,
    paddingLeft: 15,
    paddingBottom: 15,
    display: 'flex',
    alignItems: 'center',
    height: 10,
    textAlign: 'center',
    justifyContent: 'center',
  },
  tableRowNumber: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 12,
    paddingTop: 15,
    paddingRight: 15,
    paddingLeft: 15,
    paddingBottom: 15,
    display: 'flex',
    alignItems: 'center',
    height: 10,
    textAlign: 'center',
    justifyContent: 'flex-end',
  },
  buttonStyle: {
    border: '1px solid #3B9A83',
    boxSizing: 'border-box',
    borderRadius: 5,
    width: 146,
    height: 38,
    margin: 2,
  },
  buttonText: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 10,
    color: '#3B9A83',
    margin: 0,
  },
  downloadCsvButtonStyle: {
    border: '1px solid #3B9A83',
    borderRadius: 5,
    padding: 20,
    marginLeft: 10,
    width: 130,
  },
  backgrounOddColumn: {
    backgroundColor: '#F6F6F6',
    padding: 3,
  },
  formDate: {
    ...FONT_DEFAULT,
    fontWeight: 600,
    marginLeft: 5,
    marginRight: 5,
  },
  totalsSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: 10,
    marginBottom: 30,
  },
  filterConteiner: {
    padding: 20,
    border: '1px solid #BDBDBD',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'row',
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 50,
    marginbottom: 80,
  },
  errorText: {
    ...FONT_DEFAULT,
    fontSize: 16,
    fontWeight: 700,
    color: '#434343',
    marginTop: 20,
  },
  errorText2: {
    ...FONT_DEFAULT,
    fontSize: 14,
    fontWeight: 600,
    opacity: '0.6',
  },
});

const initialFromDate = `${moment().format('YYYY-MM-DD')}T00:00`;
const initialToDate = `${moment().format('YYYY-MM-DD')}T23:59`;

function InShop(props) {
  useEffect(() => {
    callApi(initialFromDate, initialToDate);
  }, []);

  const classes = useStyles();

  const [isLoading, setLoading] = React.useState(false);
  const [transactions, setTransactions] = React.useState([]);
  const [transactionsCSV, setTransactionsCSV] = React.useState([]);
  const [shouldShowDownloadCSV, setShouldShowDownloadCSV] = React.useState(false);

  const [fromDate, setFromDate] = React.useState(initialFromDate);
  const [toDate, setToDate] = React.useState(initialToDate);

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const getTransactions = async (from, to) => {
    try {
      const getTransactions = await API.get('transaction-api', '/transaction/allmoves', {
        queryStringParameters: {
          startDate: from,
          endDate: to,
        },
      });
      return getTransactions;
    } catch (err) {
      console.log(err, err);
      handleAlert('error', err.response.data.error);
      return null;
    }
  };

  const callApi = async (fromDate, toDate) => {
    const tz = moment.tz.guess();
    const timeStampFrom = moment(fromDate).tz(tz).utc().valueOf();
    const timeStampTo = moment(toDate).tz(tz).utc().valueOf();
    if (timeStampFrom < timeStampTo) {
      setLoading(true);
      try {
        const response = await getTransactions(timeStampFrom, timeStampTo);
        setTransactions(response.data);
        setShouldShowDownloadCSV(false);
      } catch (err) {
        handleAlert('error', err.message);
      } finally {
        setLoading(false);
      }
    } else {
      handleAlert('error', 'La fecha inicio debe ser menor a la fecha de fin');
    }
  };

  const handleChange = event => {
    const { value, name } = event.target;
    switch (name) {
      case 'fromDate':
        setFromDate(value);
        callApi(value, toDate);
        break;
      case 'toDate':
        setToDate(value);
        callApi(fromDate, value);
        break;
      default:
        handleAlert('error', `Input no válido ${name}`);
    }
  };

  const mapType = type => {
    switch (type) {
      case 'inshop_withdraw':
        return 'RETIRO';
      case 'inshop_pay':
        return 'PAGO';
      case 'inshop_deposit':
        return 'DEPÓSITO';
      default:
        return 'N/A';
    }
  };

  const downloadCsv = () => {
    const transactionsFixed = transactions.map(transaction => ({
      Fecha: moment(transaction.createdAt).format('DD/MM/YYYY'),
      Hora: moment(transaction.createdAt).format('h:mm:ss a'),
      Tipo_Movimiento: mapType(transaction.type),
      ID_Comercio: transaction.merchantRecordKey,
      Comercio: transaction.merchantName,
      ID_Sucursal: transaction.storeRecordKey,
      Sucursal: transaction.storeName,
      Empleado: transaction.employeeName,
      Monto: transaction.amount,
      Comision_Cobrada_Comercio: transaction.commissionChargedCommerce,
      Iva_Cobrado: transaction.ivaChargedCommerce,
      Comision_Pagada_Comercio: transaction.commissionPaidCommerce,
      Iva_Pagado: transaction.ivaPaidCommerce,
      Total: transaction.totalCommerce,
    }));
    setTransactionsCSV(transactionsFixed);
    setShouldShowDownloadCSV(true);
  };

  const setMoneyFormat = number => {
    if (number !== 'N/A') {
      return '$ ' + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return number;
    }
  };

  return (
    <div className={classes.root}>
      {isLoading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <div className={classes.container}>
          <div className={classes.totalsSection}>
            <div className={classes.filterConteiner}>
              <form className={classes.formDate} noValidate>
                <TextField
                  id="datetime-from"
                  name="fromDate"
                  value={fromDate}
                  onChange={handleChange}
                  label="Desde"
                  type="datetime-local"
                  defaultValue="2017-05-24T10:30"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </form>
              <form className={classes.formDate} noValidate>
                <TextField
                  id="datetime-to"
                  name="toDate"
                  value={toDate}
                  onChange={handleChange}
                  label="Hasta"
                  type="datetime-local"
                  defaultValue="2017-05-24T10:30"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </form>
            </div>
            <Button
              className={classes.downloadCsvButtonStyle}
              onClick={() => downloadCsv()}
              disabled={transactions.length <= 0}
            >
              <p className={classes.buttonText}>Reporte movimientos</p>
            </Button>
            {shouldShowDownloadCSV ? (
              <div className={classes.csvLinkContainer}>
                <CSVLink
                  data={transactionsCSV}
                  className={classes.green}
                  filename={`reporte_${moment(fromDate).format('DD/MM/YYYY')}_${moment(toDate).format(
                    'DD/MM/YYYY'
                  )}.csv`}
                >
                  Descargar CSV
                </CSVLink>
              </div>
            ) : isLoading ? (
              <div className="styleCircularProgress">
                <CircularProgress color="secondary" />
              </div>
            ) : null}
          </div>

          {transactions.length > 0 ? (
            <div className="mt-3 overflow-auto">
              <table className="table">
                <thead>
                  <tr>
                    <th>
                      <Typography className={classes.tableHeaderText}>Fecha</Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderText}>Hora</Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderText}>Tipo de Movimiento</Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderText}>Comercio</Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderText}>Sucursal</Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderText}>Empleado</Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderText}>Monto</Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderText}>Cobrado Comercio</Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderText}>IVA</Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderText}>Pagado Comercio</Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderText}>IVA</Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderText}>Total</Typography>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {transactions.map(transaction => (
                    <tr key={transaction.id}>
                      <td>
                        <Typography className={classes.tableRowText}>
                          {moment(transaction.createdAt).format('DD/MM/YYYY')}
                        </Typography>
                      </td>
                      <td className={classes.backgrounOddColumn}>
                        <Typography className={classes.tableRowText}>
                          {moment(transaction.createdAt).format('h:mm:ss a')}
                        </Typography>
                      </td>
                      <td>
                        <Typography className={classes.tableRowText}>{mapType(transaction.type)}</Typography>
                      </td>
                      <td className={classes.backgrounOddColumn}>
                        <Typography className={classes.tableRowText}>{transaction.merchantName}</Typography>
                      </td>
                      <td>
                        <Typography className={classes.tableRowText}>{transaction.storeName}</Typography>
                      </td>
                      <td className={classes.backgrounOddColumn}>
                        <Typography className={classes.tableRowText}>{transaction.employeeName}</Typography>
                      </td>
                      <td>
                        <Typography className={classes.tableRowNumber}>
                          {setMoneyFormat(transaction.amount.toFixed(2))}
                        </Typography>
                      </td>
                      <td className={classes.backgrounOddColumn}>
                        <Typography className={classes.tableRowNumber}>{transaction.commissionChargedCommerce.toFixed(2)}</Typography>
                      </td>
                      <td>
                        <Typography className={classes.tableRowNumber}>{transaction.ivaChargedCommerce.toFixed(2)}</Typography>
                      </td>
                      <td className={classes.backgrounOddColumn}>
                        <Typography className={classes.tableRowNumber}>{transaction.commissionPaidCommerce.toFixed(2)}</Typography>
                      </td>
                      <td>
                        <Typography className={classes.tableRowNumber}>{transaction.ivaPaidCommerce.toFixed(2)}</Typography>
                      </td>
                      <td className={classes.backgrounOddColumn}>
                        <Typography className={classes.tableRowNumber}>{transaction.totalCommerce.toFixed(2)}</Typography>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className={classes.errorContainer}>
              <img src={noMovements} alt="" />
              <Typography className={classes.errorText}>No se encontraron movimientos</Typography>
              <Typography className={classes.errorText2}>Por favor selecciona otro rango de fechas</Typography>
            </div>
          )}
        </div>
      )}
      <div id="csv" />
    </div>
  );
}

export default withSnackbar(InShop);
