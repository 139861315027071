import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, } from '@material-ui/core';
import PrimaryButton from '../../components/common/primaryButton';
import SecondaryButton from '../../components/common/secondaryButton';

const useStyles = makeStyles(() => ({
  container:{
    text: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 10,
  },
}));

const ModalTimeout = (props) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Typography>
        Su sesión se cerrará por inactividad
      </Typography>
      <Typography>
        en 15 segundos ¿Desea continuar?
      </Typography>
      <div className={classes.buttonContainer}>
        <PrimaryButton 
          type='submit' 
          onClick={props.handleKeepSession} 
          fullWidth>
          Continuar sesión
        </PrimaryButton>
        <SecondaryButton
          onClick={props.handleCloseSession}
          fullWidth
        >
          Cerrar Sesión
        </SecondaryButton>
      </div>
    </div>
  );
}

export default ModalTimeout;