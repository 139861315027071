function CognitoErrors(message) {
  switch (message) {
    case "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
      return 'La contraseña debe tener una longitud mayor o igual a 6';
    case 'Password did not conform with policy: Password must have symbol characters':
      return 'La contraseña debe tener mínimo un caracter especial: contraseña123!';
    case 'Password did not conform with policy: Password must have numeric characters':
      return 'La contraseña debe tener mínimo un caracter numérico: contraseña123!';
    case 'An account with the given phone_number already exists.':
      return 'Ya existe una cuenta con el número de teléfono proporcionado';
    case 'Invalid email address format':
      return 'El correo electrónico proporcionado tiene un formato inválido';
    case 'Attempt limit exceeded, please try after some time.':
      return 'Excediste el número de intentos, por favor intenta más tarde';
    case 'Username/client id combination not found.':
      return 'Correo electrónico no registrado';
    case 'User is already confirmed.':
      return 'Usuario ya confirmado, no es necesario volver a enviar el código';
    case 'Invalid verification code provided, please try again.':
      return 'Código de verificación inválido';
    case 'User cannot be confirmed. Current status is CONFIRMED':
      return 'Usuario ya confirmado';
    case 'Invalid code provided, please request a code again.':
      return 'Código inválido, intenta solicitando otro código de nuevo';
    case 'Incorrect username or password.':
      return 'Usuario o contraseña inválido';
    case 'User does not exist.':
      return 'El usuario no existe';
    case 'Username should be a phone number.':
      return 'El usuario debe ser un número telefónico';
    case 'An account with the given email already exists.':
      return 'Ya existe una cuenta con el correo electrónico proporcionado';
    default:
      return message;
  }
}

export default CognitoErrors;
