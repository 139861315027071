import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Storage } from 'aws-amplify';
import { useHistory } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import '../../../../assets/css/main.css'
import defaultUser from '../../../../assets/images/default-user.png';
import actionsIcon from '../../../../assets/images/mdi_edit2.png';
import Tab1 from './tab1';
import Tab2 from './tab2';
import {FONT_DEFAULT} from '../../../../lib/constants.js';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  
function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flex: 1,
    marginTop: 0,
    backgroundColor: '#F6F6F6',
  },
  container: {
    flex: 8,
    marginLeft: 30,
    marginRight: 30,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 25,
    marginBottom: 25,
    padding: 20,
  },
  title: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 24,
    display: 'flex',
    alignItems: 'center',
    color: '#434343'
  },
  titleTabs: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 12,
    display: 'inline-block',
    alignItems: 'left',
    lineHeight: 3,
    color: '#828282'
  },
  containerForm: {
    backgroundColor: '#FFFFFF',
    borderRadius: 0,
    height: '90%',
    backgroundColor: '#F6F6F6',
  },
  statusStyle1: {
    width: 80,
    height: 25,
    background: '#54D7A8',
    borderRadius: 5,
    alignItems: 'center',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 20
  },
  statusStyle2: {
    width: 80,
    height: 25,
    background: '#FD8197',
    borderRadius: 19,
    alignItems: 'center',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 20
  },
  statusText: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 12,
    color: '#FFFFFF',
  },
  profilePicture:{
    width: 36,
    height: 36,
    borderRadius: '100%', 
    marginRight: 21, 
  },
  actions:{
    marginRight: 0,
    marginLeft: 'auto',
  },
  actionsLabel:{
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 12,
    color: 'rgba(67, 67, 67, 0.6)',
  },
  actionsIcon:{
    widht: '20px',
    height: '20px',
    marginLeft: '16px',
  },
  userPanel: {
    marginTop: 20,
    borderRadius: 5,
    border: '1px solid #E0E0E0',
    backgroundColor: 'white',
    width: '95%',
    alignSelf: 'center'
  },
  containerForm2: {
    backgroundColor: '#BDBDBD',
    position: 'relative',
    zIndex: 0,
    height: 1,
    flexDirection: "row",
    opacity: '.6'
  },
});

export default function Details(props) {
  useEffect(()=>{
    getuserprofile()
  },[])

  const classes = useStyles();
  let history = useHistory();

  const [isLoading, setLoading] = useState(true);
  const [currentTab, setTab] = useState(0);
  const [user, setUser] = useState(props.location.state.user);
  const [profilePicture, setProfilePicture] = useState(defaultUser);

  const getuserprofile = async () => {
    setLoading(true)
    try {
        const pImage = await Storage.get(
          `profiles/${user.userRecordKey}/default.jpg`,
        );
        if(user.hasAvatar)
          setProfilePicture(pImage);
        setLoading(false)
    } catch (err) {
        console.error(err);
        setLoading(false)
    }
  }

  const buildStatus = (status) => {
    if(status)
        return (
            <div className={classes.statusStyle1}>
                <Typography className={classes.statusText}>Activo</Typography>
            </div>
        )
    else
        return (
            <div className={classes.statusStyle2}>
                <Typography className={classes.statusText}>Inactivo</Typography>
            </div>
        )
  }

  const handleChangeTab = (event, newValue) => {
    setTab(newValue)
  }

  return (
    <div className={classes.root}>
        {isLoading ?
            <div className='gralinfo_form'>
                <CircularProgress color="secondary" />
            </div>
        : 
            <>
                <div className={classes.container}>
                    <div className={classes.titleContainer}>
                      <Typography className={classes.title}>{user.firstName} {user.lastName}</Typography>
                      {buildStatus(user.isActive)}
                      <div className={classes.actions}>
                        <Typography className={classes.actionsLabel}>
                          ACCIONES
                          <img src={actionsIcon} className={classes.actionsIcon}/>
                        </Typography>
                        
                      </div>
                    </div>
                    <div className={classes.containerForm}>
                        <AppBar position="static"  color="transparent" className={"merchantTabs"}>
                            <Tabs
                                value={currentTab}
                                onChange={handleChangeTab}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                                >
                                <Tab label="Perfil" {...a11yProps(0)} />
                                <Tab label="Movimientos" {...a11yProps(1)} />
                                <Tab label="Pedidos" {...a11yProps(2)} />
                            </Tabs>
                            <div className={classes.containerForm2}></div>
                        </AppBar>
                        <TabPanel className={classes.userPanel} value={currentTab} index={0}>
                            <Tab1 user={user} />
                        </TabPanel>
                        <TabPanel className={classes.userPanel} value={currentTab} index={1}>
                            <Tab2 user={user} />
                        </TabPanel>
                        <TabPanel className={classes.userPanel} value={currentTab} index={2}>
                            Pedidos
                        </TabPanel>
                    </div>
                </div>
            </>
        }
    </div>
  );
}