import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import {withSnackbar} from "notistack";

import "../../assets/css/main.css";
import LegalHeader from "../../components/headers/legalHeader";
import PrivacyNoticeData from "./privacyNotice.json";

const PrivacyNotice = (props) => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  return (
    <div className={classes.background}>
      <LegalHeader></LegalHeader>
      {isLoading ? (
        <div className="styleCircularProgress">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <>
          <div className={classes.container}>
            {PrivacyNoticeData.content.map((element) => {
              switch (element.type) {
                case "title":
                  return (
                    <>
                      <text className={`${classes.title} ${classes.defaultText}`}>{element.value}</text>
                      {element.space? <br/> : null}
                    </>
                  );
                case "date":
                  return (
                    <>
                      <text className={`${classes.subTitle} ${classes.defaultText}`}>{element.value}</text>
                      {element.space? <br/> : null}
                    </>
                  );
                case "subTitle":
                  return (
                    <>
                      <br/>
                      <text className={`${classes.subTitle} ${classes.defaultText}`}>{element.value}</text>
                      {element.space? <br/> : null}
                      <br/>
                    </>
                  );
                case "paragraph":
                  return (
                    <>
                      <text className={`${classes.defaultText}`}>{element.value}</text>
                      {element.space? <br/> : null}
                    </>
                  );
                case "list":
                  return (
                    <>
                      <span className={`${classes.defaultText}`}>{"• "}{element.value}</span>
                      {element.space? <br/> : null}
                    </>
                  );
                case "link":
                  return (
                    <>
                    <a
                      href={element.value}
                      target="_blank"
                      className={`${classes.link} ${classes.defaultText}`}
                      rel="noopener noreferrer"
                      // onMouseEnter={()=> { setHover(true)}}
                      // onMouseLeave={()=> { setHover(false)}}
                    >
                      {element.value}
                     </a>
                     {element.space? <br/> : null}
                    </>
                  );
                case "link-email":
                  return (
                    <>
                    <a
                      href={`mailto:${element.value}`}
                      target="_blank"
                      className={`${classes.link} ${classes.defaultText}`}
                      rel="noopener noreferrer"
                      // onMouseEnter={()=> { setHover(true)}}
                      // onMouseLeave={()=> { setHover(false)}}
                    >
                      {element.value}
                     </a>
                     {element.space? <br/> : null}
                    </>
                  );
                default:
                  return <text className={`${classes.subTitle} ${classes.defaultText}`}>{element.value}</text>;
              }
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default withSnackbar(PrivacyNotice);

const rem = 16;

const useStyles = makeStyles(theme => ({
  background: {
    backgroundColor: theme.palette.primary.main,
    width: '100vw',
    padding: '3%',
  },
  container: {
    width: '100%',
    height: 'auto',
    backgroundColor: 'white',
    borderRadius: '20px',
    overflow: 'hidden',
    padding: '3%',
  },
  defaultText: {
    textAlign: 'left',
    ...theme.typography.paragraph,
    fontWeight: 500,
  },
  title: {
    fontSize: 2.25*rem,
    fontWeight: 700,
  },
  subTitle: {
    fontSize: 1.25*rem,
    fontWeight: 700,
  },
  link: {
    color: theme.palette.primary.main,
    ':visited': {
      color: 'pink'
    }
  }
}));
