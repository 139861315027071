const BusinessTypesList = () => {
  return [
    { label: 'Selecciona una posición', value: ''},
    { label: 'Directivo', value: 'Directivo' },
    { label: 'Gerente', value: 'Gerente' },
    { label: 'Agente', value: 'Agente' },
    { label: 'Otro', value: 'Otro' },
  ];
}

export default BusinessTypesList;
