import getUserByEmailAndPhone from "./getUserByEmailAndPhone";

const acceptInterested = async (phone,email,externalRecordKey) => {
    try {
        const data = await getUserByEmailAndPhone(email,phone,'accept')

        const response = await fetch(`${process.env.REACT_APP_USER_API}/analytics/linkUser`,{
            method: "PUT",
            headers:{
            'x-api-key': process.env.REACT_APP_USER_API_KEY,
            "Content-Type": "application/json"
            },
            body: JSON.stringify({
              externalRecordKey: externalRecordKey,
              leadRecordKey: data.data[0].leadRecordKey,
            })
        })
        const res = await response.json()
        if(res.success){
            return {
              success: true,
            }
          }
          else {
            return {
              success: false,
              msg: 'Algo salio mal'
            }
        }
    }
    catch(err){
        return {
        success: false,
        error: "Algo salio mal",
        };
    }
}

export default acceptInterested