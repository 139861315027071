import React from 'react';
import { withSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { Auth } from 'aws-amplify';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

import '../../assets/css/main.css';
import CognitoErrors from '../../lib/cognitoErrors';
import ThirdHeader from '../../components/headers/thirdHeader';
import CheckValue from '../../lib/formatValidations';

import PrimaryInput from '../../components/common/primaryInput';
import PrimaryButton from '../../components/common/primaryButton';
import SecondaryButton from '../../components/common/secondaryButton';
import image from '../../assets/images/merchantBackground.png';
import { withStyles } from '@material-ui/core/styles';
import theme from '../../styles/theme.js';

const styles = (theme) => ({
  background: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    minWidth: '100vw',
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    paddingLeft: '5%',
    paddingRight: '5%',
    paddingTop: '2%',
    paddingBottom: '2%',
    borderRadius: 20,
    minWidth: 320,
  },
  title: {
    ...theme.typography.title,
    textTransform: 'none',
  },
  paragraph: {
    ...theme.typography.paragraph,
    minWidth: 200,
    maxWidth: 400,
    textAlign: 'justify',
  },
  spacer1: { width: '100%', height: 20 },
  spacer2: { width: '100%', height: 40 },
});

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      confirmedpassword: '',
      showPassword1: false,
      showPassword2: false,
      isLoading: false,
      codeSended: false,
      code: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
    this.handleAlert = this.handleAlert.bind(this);
  }

  handleAlert = (variant, message) => {
    this.props.enqueueSnackbar(message, { variant });
  };

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleClickShowPassword(number) {
    if (number === 1) {
      this.setState({ showPassword1: !this.state.showPassword1 });
    } else if (number === 2) {
      this.setState({ showPassword2: !this.state.showPassword2 });
    }
  }

  handleMouseDownPassword(event) {
    event.preventDefault();
  }

  handleToggleRegister(e) {
    e.preventDefault();
    this.props.history.push({
      pathname: '/register',
    });
  }

  handleToggleLogin(e) {
    e.preventDefault();
    this.props.history.push({
      pathname: '/login',
    });
  }

  _validateUser = async () => {
    const { email } = this.state;
    try {
      this.setState({
        isLoading: true,
      });
      if (!email) {
        this.handleAlert('error', 'El correo electrónico no puede estar vacío');
        return;
      }
      const sendCode = await Auth.forgotPassword(email.trim());
      this.handleAlert('success', 'Código Enviado');
      this.setState({
        codeSended: true,
      });
    } catch (err) {
      this.handleAlert('error', CognitoErrors(err.message));
    } finally {
      this.setState({ isLoading: false });
    }
  };

  _resendCode = async () => {
    const { email } = this.state;
    this.setState({
      isLoading: true,
    });
    try {
      await Auth.forgotPassword(email.trim());
      this.handleAlert('success', 'Código Enviado');
    } catch (err) {
      this.handleAlert('error', CognitoErrors(err.message));
    } finally {
      this.setState({ isLoading: false });
    }
  };

  _newPassword = async (e) => {
    const { code, password, confirmedpassword, email } = this.state;
    this.setState({ isLoading: true });
    if (!code) {
      this.handleAlert(
        'error',
        'El código  de verificación no puede estar vació'
      );
      return;
    }
    if (!password || !confirmedpassword) {
      this.handleAlert('error', 'La contraseña no puede estar vacía');
      this.setState({ isLoading: false });
      return;
    } else if (password.includes(email)) {
      this.setState({ isLoading: false });
      this.handleAlert(
        'error',
        'La contraseña no debe ser igual o incluir el correo electrónico'
      );
      return;
    }
    if (password !== confirmedpassword) {
      this.setState({ isLoading: false });
      this.handleAlert('error', 'Contraseñas diferentes');
      return;
    } else if (!CheckValue('password', password)) {
      this.setState({ isLoading: false });
      this.handleAlert(
        'error',
        'La contraseña debe tener mínimo 6 caracteres, incluyendo por lo menos un número y un caracter especial (!¡@#$%^&*(),.¿?":{}|<>_-)'
      );
      return;
    } else if (password.toLowerCase().includes('nerito')) {
      this.setState({ isLoading: false });
      this.handleAlert('error', 'La contraseña no debe incluir "Nerito"');
      return;
    } else if (CheckValue('hasConsecutiveRepeat', password)) {
      this.setState({ isLoading: false });
      this.handleAlert(
        'error',
        'La contraseña no debe incluir el mismo caracter 3 veces consecutivas.'
      );
      return;
    } else if (CheckValue('hasConsecutive', password)) {
      this.setState({ isLoading: false });
      this.handleAlert(
        'error',
        'La contraseña no debe incluir 3 caracteres consecutivos.'
      );
      return;
    }

    try {
      await Auth.forgotPasswordSubmit(email.trim(), code, password);
      this.handleAlert('success', 'Contraseña restablecida exitosamente!');
      this.handleToggleLogin(e);
    } catch (err) {
      this.handleAlert('error', CognitoErrors(err.message));
    } finally {
      this.setState({ isLoading: false });
    }
  };

  _handleAlert = (variant, message) => {
    this.props.enqueueSnackbar(message, { variant });
  };

  _handleProblem = async () => {
    if (this.state.email === '') {
      this._handleAlert('error', 'Debe ingresar su correo electrónicos');
    } else {
      const description = 'No se puede iniciar sesión';
      const formData = new FormData();
      formData.append('name', 'n/a');
      formData.append('merchantID', 'n/a');
      formData.append('problemType', 'Activación');
      formData.append('problem', description);
      formData.append('email', this.state.email);
      fetch(process.env.REACT_APP_ZAPIER_ZOHO, {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('SUCCESS', data);
          this._handleAlert('success', 'Tu problema se envió correctamente');
        })
        .catch((error) => {
          console.log('ERR', error);
          this._handleAlert('error');
        });
    }
  };

  render() {
    const { classes } = this.props;

    const {
      email,
      password,
      confirmedpassword,
      showPassword1,
      showPassword2,
      isLoading,
      codeSended,
      code,
    } = this.state;
    return (
      <div className={classes.background}>
        {isLoading ? (
          <div className='styleCircularProgress'>
            <CircularProgress color='secondary' />
          </div>
        ) : (
          <div className={classes.container}>
            {codeSended ? (
              <>
                <ThirdHeader />
                <Typography className={classes.title}>
                  Recuperar Contraseña
                </Typography>
                <Typography className={classes.paragraph}>
                  Enviamos un código secreto a tu correo electrónico. Por favor
                  ingrésalo a continuación
                </Typography>

                <div className={classes.spacer1} />
                <PrimaryInput
                  id='code'
                  type='text'
                  name='code'
                  className='defaultInputPhone'
                  placeholder='Código'
                  value={code}
                  onChange={this.handleChange}
                  fullWidth
                />
                <div className={classes.spacer1} />

                <PrimaryInput
                  id='pwd'
                  type={showPassword1 ? 'text' : 'password'}
                  name='password'
                  className='defaultInputPassword'
                  placeholder='Nueva contraseña'
                  value={password}
                  onChange={this.handleChange}
                  fullWidth
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={(e) => this.handleClickShowPassword(1, e)}
                        onMouseDown={this.handleMouseDownPassword}
                        className='visibilityicon eyeIcon'
                      >
                        {showPassword1 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />

                <div className={classes.spacer1} />
                <PrimaryInput
                  id='cpwd'
                  type={showPassword2 ? 'text' : 'password'}
                  name='confirmedpassword'
                  className='defaultInputPassword'
                  placeholder='Confirmar contraseña'
                  value={confirmedpassword}
                  onChange={this.handleChange}
                  fullWidth
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={(e) => this.handleClickShowPassword(2, e)}
                        onMouseDown={this.handleMouseDownPassword}
                        className='visibilityicon eyeIcon'
                      >
                        {showPassword2 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <div className={classes.spacer1} />
                <PrimaryButton
                  type='submit'
                  className='default_button'
                  onClick={(e) => this._newPassword(e)}
                  fullWidth
                >
                  Enviar
                </PrimaryButton>
                <div className={classes.spacer1} />
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <Typography className='subText1'>
                    ¿No recibiste tu código?,{' '}
                  </Typography>
                  <Typography
                    className='subText1Pointer'
                    onClick={() => this._resendCode()}
                  >
                    Reenviar Mensaje
                  </Typography>
                </div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <Typography className='subText1'>
                    ¿Tienes problemas para iniciar sesión?
                  </Typography>
                  <Typography
                    className='subText1Pointer'
                    onClick={() => this._handleProblem()}
                  >
                    Contáctanos
                  </Typography>
                </div>
              </>
            ) : (
              <>
                <ThirdHeader></ThirdHeader>
                <Typography className='title'>Recuperar Contraseña</Typography>
                <Typography className={classes.paragraph}>
                  Enviaremos un código para restaurar contraseña a tu correo
                  electrónico asociado a la cuenta que deseas recuperar. Si aún
                  no tienes un correo asignado a tu cuenta, por favor contacta a
                  nuestro call center.
                </Typography>
                <div className={classes.spacer1} />
                <FormControl>
                  <PrimaryInput
                    id='email'
                    type='text'
                    name='email'
                    className='defaultInputEmail'
                    placeholder='Email'
                    value={email}
                    onChange={this.handleChange}
                    fullWidth
                  />
                  <div className={classes.spacer1} />
                  <PrimaryButton
                    type='submit'
                    onClick={this._validateUser}
                    fullWidth
                  >
                    Enviar
                  </PrimaryButton>
                  <div className={classes.spacer1} />
                  <div className='rowStyle centerItems padding_top_10'>
                    <Typography className='subText1'>
                      Aún no tengo cuenta,
                    </Typography>
                    <Typography
                      className='subText1Pointer'
                      onClick={(e) => this.handleToggleRegister(e)}
                    >
                      Registrarme
                    </Typography>
                  </div>
                  <div className='row centerItems padding_bottom_40'>
                    <Typography className='subText1'>
                      ¿Tienes problemas para iniciar sesión?
                    </Typography>
                    <Typography
                      className='subText1Pointer'
                      onClick={() => this._handleProblem()}
                    >
                      Contáctanos
                    </Typography>
                  </div>
                </FormControl>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(
  withSnackbar(ForgotPassword)
);
