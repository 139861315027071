import React, { useEffect, useState } from 'react';
import { withSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, CircularProgress } from '@material-ui/core';
import PrimaryInput from '../../common/primaryInput';
import PrimarySelect from '../../common/primarySelect';
import PrimaryButton from '../../common/primaryButton';

const RetentionsBilling = (props) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [baseAmount, setBaseAmount] = useState('');
  const [isr, setIsr] = useState(0);
  const [ivaTra, setIvaTra] = useState(0);
  const [ivaRet, setIvaRet] = useState(0);
  const [totalRet, setTotalRet] = useState(0);
  const [netAmount, setNetAmount] = useState(0);
  const [issuer, setIssuer] = useState({ name: '', rfc: '' });
  const [billingIssuers, setBillingIssuers] = useState([]);
  const [receiver, setReceiver] = useState({ name: '', rfc: '', zipCode: '' });
  const [serviceDate, setServiceDate] = useState(new Date());
  const [baseAmountError, setBaseAmountError] = useState('');
  const [receiverNameError, setReceiverNameError] = useState('');
  const [receiverRFCError, setReceiverRFCError] = useState('');
  const [receiverZipCodeError, setReceiverZipCodeError] = useState('');

  useEffect(() => {
    getBillingIssuers();
  }, []);

  const getBillingIssuers = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_BILLING_API}/accounts`, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          "x-api-key": process.env.REACT_APP_BILLING_API_KEY,
        }
      });
      const responseJSON = await response.json();
      const newIssuers = responseJSON.data.issuers;
      setBillingIssuers(newIssuers);
      setIssuer(newIssuers[0]);
    } catch (err) {
      props.enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }

  const handleAmountChange = (event) => {
    const value = event.target.value;
    if (!isNaN(value)) {
      setBaseAmount(value);
      calculateAmounts(value);
      setBaseAmountError('');
    }
  }

  const calculateAmounts = (base) => {
    const newIsr = Math.trunc((Number((base * 0.01))) * 100) / 100;
    const newIvaTra = Number((base * 0.16).toFixed(6));
    const newIvaRet = Math.trunc((Number((newIvaTra * 0.50))) * 100) / 100;
    const newTotalRet = Number((newIsr + newIvaRet).toFixed(6));
    const newNetAmount = Number((base - newIsr - newIvaRet).toFixed(6));
    setIsr(newIsr);
    setIvaTra(newIvaTra);
    setIvaRet(newIvaRet);
    setTotalRet(newTotalRet);
    setNetAmount(newNetAmount);
  }

  const handleIssuerChange = (event) => {
    const value = event.target.value;
    const newIssuer = billingIssuers.find(item => item.name === value);
    setIssuer(newIssuer);
  }

  const handleReceiverChange = (event) => {
    const input = event.target.name;
    const value = event.target.value;
    if (input === 'receiverName') {
      setReceiver({ ...receiver, name: value });
      setReceiverNameError('');
    }
    else if (input === 'receiverRFC') {
      setReceiver({ ...receiver, rfc: value });
      setReceiverRFCError('');
    } else {
      setReceiver({ ...receiver, zipCode: value });
      setReceiverZipCodeError('');
    }
  }

  const handleServiceDateChange = (event) => {
    const newDate = new Date(event.target.value);
    newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
    setServiceDate(newDate);
  }

  const checkForErrors = () => {
    let error = false;
    if (receiver.name.trim() === '') {
      setReceiverNameError('Campo vacío');
      error = true;
    }
    if (receiver.rfc.trim() === '') {
      setReceiverRFCError('Campo vacío');
      error = true;
    }
    if (receiver.zipCode.trim() === '') {
      setReceiverZipCodeError('Campo vacío');
      error = true;
    }
    if (baseAmount.trim() === '') {
      setBaseAmountError('Campo vacío');
      error = true;
    }
    if (baseAmount <= 1) {
      setBaseAmountError('El monto bruto no puede ser igual o menor a $0.00');
      error = true;
    }
    return error;
  }

  const generateBill = async () => {
    try {
      setLoading(true);
      if (!checkForErrors()) {
        const response = await fetch(`${process.env.REACT_APP_BILLING_API}/stampRetentions`, {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            "x-api-key": process.env.REACT_APP_BILLING_API_KEY,
          },
          body: JSON.stringify({
            amount: baseAmount,
            rfcReceiver: receiver.rfc,
            nameReceiver: receiver.name,
            zipCodeReceiver: receiver.zipCode,
            rfcIssuer: issuer.rfc,
            nameIssuer: issuer.name,
            serviceDate: serviceDate.toLocaleDateString('sv'),
          }),
        });
        const responseJSON = await response.json();
        if (!responseJSON.success) {
          props.enqueueSnackbar(responseJSON.error, { variant: 'error' });
        } else {
          setReceiver({ name: '', rfc: '', zipCode: '' });
          props.enqueueSnackbar('Factura timbrada exitosamente', { variant: 'success' });
        }
      }
    } catch (err) {
      props.enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>
        Factura de Retenciones
      </Typography>
      <Grid className={classes.inputsContainer} spacing={3} container>
        <Grid className={classes.columnContainer} xs={4} item>
          <div className={classes.inputContainer}>
            <Typography>Nombre Emisor</Typography>
            <PrimarySelect
              id={'issuerName'}
              name={'issuerName'}
              value={issuer.name}
              content={billingIssuers.map((item) => (
                <option key={item.rfc} value={item.name}>{item.name}</option>
              ))}
              onChange={handleIssuerChange}
            />
          </div>
          <div className={classes.rowContainer}>
            <Typography>RFC Emisor</Typography>
            <Typography>{issuer.rfc}</Typography>
          </div>
          <div className={classes.separator} />
          <div className={classes.inputContainer}>
            <Typography>Nombre Receptor</Typography>
            <PrimaryInput
              placeholder=''
              value={receiver.name}
              onChange={handleReceiverChange}
              id={'receiverName'}
              name={'receiverName'}
              error={receiverNameError}
            />
          </div>
          <div className={classes.inputContainer}>
            <Typography>RFC Receptor</Typography>
            <PrimaryInput
              placeholder=''
              value={receiver.rfc}
              onChange={handleReceiverChange}
              id={'receiverRFC'}
              name={'receiverRFC'}
              error={receiverRFCError}
            />
          </div>
          <div className={classes.inputContainer}>
            <Typography>Código Postal Receptor</Typography>
            <PrimaryInput
              placeholder=''
              value={receiver.zipCode}
              onChange={handleReceiverChange}
              id={'receiverZipCode'}
              name={'receiverZipCode'}
              error={receiverZipCodeError}
            />
          </div>
          <div className={classes.separator} />
          <div className={classes.inputContainer}>
            <Typography>
              Fecha de Servicio
            </Typography>
            <PrimaryInput
              value={serviceDate.toLocaleDateString('sv')}
              type='date'
              onChange={handleServiceDateChange}
              id={'serviceDate'}
              name={'serviceDate'}
            />
          </div>
        </Grid>
        <Grid className={classes.columnContainer} xs={4} item>
          <div className={classes.rowContainer} />
          <div className={classes.rowContainer}>
            <Typography>Monto Bruto</Typography>
            <PrimaryInput
              placeholder='0.00'
              value={baseAmount}
              type='number'
              onChange={handleAmountChange}
              id={'baseAmount'}
              name={'baseAmount'}
              startAdornment={<Typography>$</Typography>}
              error={baseAmountError}
            />
          </div>
          <div className={classes.rowContainer}>
            <Typography>IVA Trasladado:</Typography>
            <Typography>{`$${ivaTra.toFixed(2)}`}</Typography>
          </div>
          <div className={classes.rowContainer}>
            <Typography>IVA Retenido:</Typography>
            <Typography>{`$${ivaRet.toFixed(2)}`}</Typography>
          </div>
          <div className={classes.rowContainer}>
            <Typography>ISR Retenido:</Typography>
            <Typography>{`$${isr.toFixed(2)}`}</Typography>
          </div>
          <div className={classes.rowContainer}>
            <Typography>Total Retenido:</Typography>
            <Typography>{`$${totalRet.toFixed(2)}`}</Typography>
          </div>
          <div className={classes.rowContainer}>
            <Typography>Monto Neto:</Typography>
            <Typography>{`$${netAmount.toFixed(2)}`}</Typography>
          </div>
          <div className={classes.rowContainer} />
          <div className={classes.columnContainer}>
            <PrimaryButton fullWidth onClick={generateBill}>
              Generar Factura
            </PrimaryButton>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default withSnackbar(RetentionsBilling);

const useStyles = makeStyles(theme => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '40vh',
  },
  container: {
    padding: '30px',
  },
  title: {
    ...theme.typography.title
  },
  dataContainer: {
    marginTop: '20px',
  },
  columnContainer: {
    marginLeft: '20px',
    marginRight: '20px',
  },
  inputContainer: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '20px',
    marginLeft: '20px',
    marginRight: '20px',
  },
  separator: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));