import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";

//import { SearchBar } from 'react-native-elements';
import { useHistory } from "react-router-dom";
import DeafultSearch from "../../common/deafultSearch";
import iconButtonPlus from "../../../assets/images/icon_button_plus.png";
import mdiKey from "../../../assets/images/mdi_key.png";
import mdiEdit from "../../../assets/images/mdi_edit2.png";
import { FONT_DEFAULT } from "../../../lib/constants.js";
import PrimarySearchBar from '../../common/primarySearchBar';

import { API } from "aws-amplify";

function WalletUsers(props) {
  useEffect(() => {
    // getUsers();
  }, []);

  let history = useHistory();

  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [searchedWord, setSearchedWord] = useState("");
  const [users, setUsers] = useState([
    {
      userRecordKey: "1",
      firstName: "nancy",
      lastName: "colin",
      email: "nancy.smith@gmail.com",
      phoneNumber: "7226125489",
      isActive: true,
      alert: "Monto máximo mensual",
    },
    {
      userRecordKey: "2",
      firstName: "John",
      lastName: "Smith",
      email: "john.smith@gmail.com",
      phoneNumber: "6621345678",
      isActive: true,
      alert: "Monto máximo diario",
    },
    {
      userRecordKey: "3",
      firstName: "John",
      lastName: "Smith",
      email: "john.smith@gmail.com",
      phoneNumber: "6621345678",
      isActive: true,
      alert: "Monto máximo diario",
    },
    {
      userRecordKey: "4",
      firstName: "John",
      lastName: "Smith",
      email: "john.smith@gmail.com",
      phoneNumber: "6621345678",
      isActive: true,
      alert: "Monto máximo diario",
    },
    {
      userRecordKey: "6",
      firstName: "John",
      lastName: "Smith",
      email: "john.smith@gmail.com",
      phoneNumber: "6621345678",
      isActive: true,
      alert: "Monto máximo diario",
    },
    {
      userRecordKey: "7",
      firstName: "John",
      lastName: "Smith",
      email: "john.smith@gmail.com",
      phoneNumber: "6621345678",
      isActive: true,
      alert: "Monto máximo diario",
    },
    {
      userRecordKey: "8",
      firstName: "John",
      lastName: "Smith",
      email: "john.smith@gmail.com",
      phoneNumber: "6621345678",
      isActive: true,
      alert: "Monto máximo diario",
    },
    {
      userRecordKey: "9",
      firstName: "John",
      lastName: "Smith",
      email: "john.smith@gmail.com",
      phoneNumber: "6621345678",
      isActive: true,
      alert: "Monto máximo diario",
    },
  ]);


  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };
  const handleChange = (event) => {
    const { value, name } = event.target;
    switch (name) {
      case "searchedWord":
        setSearchedWord(value);
        break;
      default:
        break;
    }
  };

  const goToDetails = (user) => {
    history.push({ pathname: "/admin/usersDetails", state: { user } });
  };

  const getUsers = async () => {
    try {
      const profiles = await API.get("profile-api", `/profile`);
      const completeProfiles = [];
      for (let index = 0; index < profiles.data.length - 1; index++) {
        const element = await API.get(
          "profile-api",
          `/profile/${profiles.data[index].userRecordKey}`
        );
        completeProfiles.push(element.data[0]);
      }
      console.log(completeProfiles);
      setUsers(completeProfiles);
      setLoading(false);
    } catch (err) {
      handleAlert("error", err.message);
      setLoading(false);
    }
  };
  
  return (
    <div className={classes.root}>
      <Typography className={classes.title}>Usuarios Wallet PLD</Typography>
      <div className={classes.container}>
        <div className={classes.width100}>
          <div className={classes.width60}>
            <PrimarySearchBar
              label="Buscar por usuario, orden"
              name="searchedWord"
              value={searchedWord}
              onChange={handleChange}
              fullWidth
            />
          </div>
          <div className={`${classes.width100} ${classes.tableTop} ${!isLoading && "overflow-auto"}`}>
            {isLoading ? (
              <div className={`gralinfo_form ${classes.width100}`}>
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <table className="table">
                <thead>
                  <tr>
                    <th>
                      <Typography className={classes.tableHeaderText}>
                        ID
                      </Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderText}>
                        Nombre
                      </Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderText}>
                        Teléfono
                      </Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderText}>
                        Correo
                      </Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderText}>
                        Alertas
                      </Typography>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users.map(
                    (user) =>
                      (searchedWord.length < 3 ||
                        JSON.stringify(user)
                          .toUpperCase()
                          .indexOf(searchedWord.toUpperCase()) !== -1) && (
                        <tr key={user.userRecordKey} item lg={12} onClick={() => goToDetails(user)} className={classes.tableRow}>
                          <td className={classes.backgrounOddColumn}>
                            <Typography
                              className={classes.tableRowText2}
                              noWrap={true}
                            >
                              {user.userRecordKey}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              className={classes.tableRowText1}
                              noWrap={true}
                            >
                              {user.firstName} {user.lastName}
                            </Typography>
                          </td>
                          <td className={classes.backgrounOddColumn}>
                            <Typography
                              className={classes.tableRowText2}
                              noWrap={true}
                            >
                              {user.phoneNumber}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              className={classes.tableRowText1}
                              noWrap={true}
                            >
                              {user.email}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              className={classes.tableRowText3}
                              noWrap={true}
                            >
                              {user.alert}
                            </Typography>
                          </td>

                          {/* <td className={classes.backgrounEvenColumn}>
                            <div className={classes.actionButonsContainer}>
                              <img
                                width="23"
                                height="23"
                                src={mdiKey}
                                className={classes.actionButon1}
                              />
                              <img
                                width="23"
                                height="23"
                                src={mdiEdit}
                                className={classes.actionButon2}
                                onClick={() => goToDetails(user)}
                              />
                            </div>
                          </td> */}
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withSnackbar(WalletUsers);

const useStyles = makeStyles({
  root: {
    display: "flex",
    flex: 1,
    backgroundColor: "#FFFFFF",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  container: {
    display: "flex",
    marginLeft: 40,
    marginRight: 40,
    marginTop: 20,
    marginBottom: 20,
    flexDirection: "row",
  },
  title: {
    ...FONT_DEFAULT,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 24,
    marginLeft: 30,
    marginTop: 30,
  },
  buttonStyle: {
    border: "1px solid #3B9A83",
    boxSizing: "border-box",
    borderRadius: 5,
    width: 146,
    height: 38,
    margin: 2,
  },
  buttonText: {
    ...FONT_DEFAULT,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 10,
    color: "#3B9A83",
  },
  plusIcon: {
    marginRight: 10,
  },
  tableHeaderText: {
    ...FONT_DEFAULT,
    fontStyle: "bold",
    fontWeight: "bold",
    fontSize: 18,
    display: "flex",
    alignItems: "center",
    color: "black",
    borderTop: "1px solid #FFFFF",
    borderBottom: "1px solid #FFFFF",
    height: 60,
    justifyContent: "center",
  },
  tableRowText1: {
    ...FONT_DEFAULT,
    fontStyle: "bold",
    fontWeight: "bold",
    fontSize: 14,
    paddingTop: 10,
    paddingBottom: 10,
    // display: "flex",
    alignItems: "center",
    color: "black",
    backgroundColor: "white",
    height: 30,
    textAlign: "center",
    justifyContent: "center",
  },
  tableRowText2: {
    ...FONT_DEFAULT,
    fontStyle: "bold",
    fontWeight: "bold",
    fontSize: 14,
    paddingTop: 20,
    paddingBottom: 20,
    display: "flex",
    alignItems: "center",
    color: "black",
    height: 30,
    textAlign: "center",
    justifyContent: "center",
    backgroundColor: "white",
  },
  tableRowText3: {
    ...FONT_DEFAULT,
    fontStyle: "bold",
    fontWeight: "bold",
    fontSize: 14,
    paddingTop: 15,
    paddingBottom: 15,
    display: "flex",
    alignItems: "center",
    color: "white",
    height: 30,
    textAlign: "center",
    justifyContent: "center",
    backgroundColor: "#EE715B",
    borderRadius: 50,
    marginTop: 10,
    marginBottom: 10,
  },
  backgrounOddColumn: {
    backgroundColor: "white",
  },
  backgrounEvenColumn: {
    backgroundColor: "white",
  },
  width100: {
    width: "100%",
  },
  width60: {
    width: "100%",
  },
  tableTop: {
    marginTop: "30px",
  },
  actionButonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    height: 40,
    alignItems: "center",
    marginTop: 9,
  },
  actionButon1: {
    cursor: "pointer",
    marginRight: 10,
    width: "18px",
    height: "18px",
  },
  actionButon2: {
    cursor: "pointer",
    marginLeft: 0,
    width: "18px",
    height: "18px",
  },
  tableRow: {
    cursor: "pointer",
    // borderBottom: '1px solid gray',
    // alignItems: 'center',
    // display: 'flex',
    // justifyContent: "space-between",
  }
});