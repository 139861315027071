import { clabe } from 'clabe-validator'

function ClabeValidator(clabeString) {
  const clabeCheck = clabe.validate(clabeString);
  if (!clabeCheck.ok || !clabeCheck.formatOk || clabeCheck.error !== null) {
    const error = clabeCheck.error
    const message = clabeCheck.message
    switch (error) {
      case "invalid-length":
        return 'La clabe debe tener exactamente 18 dígitos.'
      case 'invalid-characters':
        return 'La clabe debe ser solo dígitos numéricos (sin letras).'
      case 'invalid-checksum':
        return `Suma de comprobación inválida el ultimo dígito debería ser:${message.split(':')[1]}`
      case 'invalid-bank':
        return `Código bancario no válido:${message.split(':')[1]}`
      case 'invalid-city':
        return `Código de ciudad no válido:${message.split(':')[1]}`
      default:
        return message
    }
  } else {
    return false
  }
}

export default ClabeValidator;