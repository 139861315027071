import React, { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { useHistory } from "react-router-dom";
import theme from '../../../styles/theme';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { withSnackbar } from 'notistack';
import PrimarySearchBar from '../../common/primarySearchBar';

const Merchants = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [merchants, setMerchants] = useState([]);
  const [shownMerchants, setShownMerchants] = useState([]);
  const [search, setSearch] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    getMerchants();
  }, []);

  const getMerchants = async () => {
    try {
      const response = await API.get('merchant-api', `/merchant/list`);
      setMerchants(response.data);
      setShownMerchants(response.data);
    } catch (err) {
      props.enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }

  const handleSearch = (event) => {
    const text = event.target.value.toLowerCase();
    setSearch(text);
    const merchantsFiltered = merchants.filter(item => {
      if (item.merchantRecordKey && item.merchantRecordKey.includes(text)) return true;
      if (item.businessCommercialName && item.businessCommercialName.toLowerCase().includes(text)) return true;
      const rfc = item.RFC ? item.RFC : item.legalRepresentativeRFC ? item.legalRepresentativeRFC : '';
      if (rfc && rfc.toLowerCase().includes(text)) return true;
    });
    setShownMerchants(merchantsFiltered);
    setPage(0);
  }

  const handleNavigation = (merchantRecordKey) => {
    history.push({
      pathname: '/admin/merchantDetails',
      state: { merchantRecordKey },
    });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const statusText = (status) => {
    switch (status) {
      case 'approved':
        return (
          <div className={classes.statusStyle1}>
            <Typography className={classes.statusText}>Activo</Typography>
          </div>
        );
      case 'rejected':
        return (
          <div className={classes.statusStyle2}>
            <Typography className={classes.statusText}>Rechazado</Typography>
          </div>
        );
      case 'progress':
        return (
          <div className={classes.statusStyle3}>
            <Typography className={classes.statusText}>En Proceso</Typography>
          </div>
        );
      default:
        return (
          <div className={classes.statusStyle4}>
            <Typography className={classes.statusText}>Pendiente</Typography>
          </div>
        );
    }
  }

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div>
        <Typography className={classes.title}>
          Comercios
        </Typography>
      </div>
      <div className={classes.searchContainer}>
        <PrimarySearchBar
          label='Buscar'
          placeholder='Buscar'
          value={search}
          onChange={handleSearch}
          fullWidth
        />
      </div>
      <TableContainer className={classes.tableContainer}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>RFC</TableCell>
              <TableCell align='center'>Sucursales</TableCell>
              <TableCell align='center'>Estatus</TableCell>
              <TableCell align='center'>Comisionista</TableCell>
              <TableCell align='center'>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? shownMerchants.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : shownMerchants
            ).map((merchant) => (
              <TableRow key={merchant.merchantRecordKey}>
                <TableCell>
                  {merchant.merchantRecordKey}
                </TableCell>
                <TableCell>
                  {merchant.businessCommercialName}
                </TableCell>
                <TableCell>
                  {merchant.RFC ? merchant.RFC : merchant.legalRepresentativeRFC ? merchant.legalRepresentativeRFC : ''}
                </TableCell>
                <TableCell align='center'>
                  {merchant.noOfStores || '0'}
                </TableCell>
                <TableCell align='center'>
                  {statusText(merchant.merchantStatus)}
                </TableCell>
                <TableCell align='center'>
                  {merchant.isCommissionAgent ? (
                    <CheckCircleIcon htmlColor={theme.palette.info.main} />
                  ) : (
                    <RemoveCircleIcon htmlColor={theme.palette.grey[500]} />
                  )}
                </TableCell>
                <TableCell align='center'>
                  <Tooltip title='Editar'>
                    <IconButton size='small' onClick={() => handleNavigation(merchant.merchantRecordKey)}>
                      <EditIcon fontSize='small' htmlColor={theme.palette.warning.main} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, { label: 'Todos', value: -1 }]}
                count={shownMerchants.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}

export default withSnackbar(Merchants);

const statusStyle = {
  minWidth: 80,
  height: 25,
  borderRadius: 5,
  alignItems: 'center',
  color: '#FFFFFF',
  display: 'flex',
  justifyContent: 'center',
};

const useStyles = makeStyles(theme => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '40vh',
  },
  container: {
    padding: '30px',
  },
  title: {
    ...theme.typography.title
  },
  searchContainer: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  dateInputContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '20px',
  },
  tableContainer: {
    marginTop: '20px',
  },
  statusStyle1: {
    ...statusStyle,
    background: '#115545',
  },
  statusStyle2: {
    ...statusStyle,
    background: '#DA7B7B',
  },
  statusStyle3: {
    ...statusStyle,
    background: '#6EB89D',
  },
  statusStyle4: {
    ...statusStyle,
    background: '#828282',
  },
  statusText: {
    fontSize: 12,
  },
}));