import { createSlice } from '@reduxjs/toolkit';

export const contractVersionSlice = createSlice({
  name: 'contractVersion',
  initialState: {
    value: '',
  },
  reducers: {
    setCurrentContract: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setCurrentContract } = contractVersionSlice.actions;

export default contractVersionSlice.reducer;