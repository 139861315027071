import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, IconButton } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import '../../../assets/css/main.css';
import '../../../assets/css/main.css';
import CloseButton from '../../common/closeButton';
import QRCode from 'react-qr-code';
import PrimaryButton from '../../common/primaryButton';
import SecondaryButton from '../../common/secondaryButton';
import { Shop, iOS } from '@material-ui/icons';
import theme from '../../../styles/theme';
import imageAndroid from '../../../assets/images/icon_google_play.png'
import imageIos from '../../../assets/images/icon_app_store.png'

const ModalWelcome = (props) => {
  const classes = useStyles();

  const [isLoading, setLoading] = useState(false);

  const data = { id: props.merchantId, type: 'commissionist' }

  useEffect(() => {
    props.standBy();
  }, [])

  return (
    <div className={classes.container}>
      <div className={classes.closeHeader}>
        <text className={classes.title}>¿Tienes cuenta en <text className={classes.blueTitle}>NeritoPay</text>?</text>
        <CloseButton type='button' onClick={props.handleClose} color="#4B9BB8" />
      </div>
      {isLoading ? (
        <div className='styleCircularProgress'>
          <CircularProgress color='secondary' />
        </div>
      ) : (
        <div className={classes.contentContainer}>
          <text>Si ya cuentas con una cuenta NeritoPay, escanea el siguiente QR con la app para recibir tus comisiones y pagos en tu cuenta.</text>
          <div className={classes.qrContainer}>
            <QRCode
              value={JSON.stringify(data)}
              size={200}
            />
          </div>
          <text>Si aún no cuentas con una cuenta NeritoPay, descarga la app y crea una nueva cuenta para aprovechar los beneficios.</text>
          <div className={classes.buttonsContainer}>
            <a
              href="https://play.google.com/store/apps/details?id=com.neritopay&pli=1"
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={imageAndroid} className={classes.buttonImage} />
            </a>
            <div className={classes.buttonContainer}>
              <a
                // href='https://play.google.com/apps/internaltest/4700453203728180392'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={imageIos} className={classes.buttonImage} />
              </a>
            </div>
          </div>

        </div>
      )}
    </div>
  );
};

export default withSnackbar(ModalWelcome);

const useStyles = makeStyles(theme => ({
  container: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '90vh',
    minWidth: 600,
    maxWidth: 600,
  },
  closeHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  defaultText: {
    textAlign: 'left',
    ...theme.typography.paragraph,
    fontWeight: 500,
  },
  link: {
    color: theme.palette.primary.main,
  },
  title: {
    ...theme.typography.title,
    paddingTop: 10,
    paddingBottom: 10,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    fontWeight: 600,
  },
  blueTitle: {
    color: "#4B9BB8",
  },
  contentContainer: {
    // backgroundColor: 'pink',
  },
  qrContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: 20,
  },
  buttonImage: {
    width: 'auto',
    height: '50px',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 20,
    gap: 50
  }
}));
