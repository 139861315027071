import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme =>({
  container: {
    backgroundColor: 'transparent',
    color: (props) => props.color || theme.palette.primary.main,
    border: 'none',
    paddingLeft: (props) => (props.paddingHorizontal || 10),
    paddingRight: (props) => (props.paddingHorizontal || 10),
    paddingTop: (props) => (props.paddingVertical || 5),
    paddingBottom: (props) => (props.paddingVertical|| 5),
    marginLeft: (props) => (props.marginHorizontal || 5),
    marginRight: (props) => (props.marginHorizontal || 5),
    marginTop: (props) => (props.marginVertical || 5),
    marginBottom: (props) => (props.marginVertical || 5),
    width: (props) => (props.fullWidth ? '100%' : 'auto'),
    alignItems: (props) => props.alignItems || 'center', // align vertically
    ...theme.typography.button,
  },
}));

const CloseButton = (props) => {
  const classes = useStyles(props);

  return (
    <>
      <button
        type={props.type || 'button'}
        onClick={props.onClick}
        className={classes.container}
      >
        <MenuIcon />
      </button>
    </>
  );
};

export default CloseButton;
