import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withSnackbar } from 'notistack';
import '../../assets/css/main.css';
import '../../assets/css/main.css';
import CloseButton from '../common/closeButton';

const rem = 16;

const useStyles = makeStyles(theme =>({
  container: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '90vh',
    maxWidth: 400,
  },
  closeHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  defaultText: {
    textAlign: 'left',
    ...theme.typography.paragraph,
    fontWeight: 500,
  },
  title: {
    fontSize: 2.25 * rem,
    fontWeight: 700,
  },
  subTitle: {
    fontSize: 1.25 * rem,
    fontWeight: 700,
  },
  link: {
    color: theme.palette.primary.main,
    ':visited': {
      color: 'pink',
    },
  },
}));

const ModalWelcome = (props) => {
  const classes = useStyles();

  const [isLoading, setLoading] = useState(false);

  return (
    <div className={classes.container}>
      <div className={classes.closeHeader}>
        <CloseButton type='button' onClick={props.handleClose} />
      </div>
      {isLoading ? (
        <div className='styleCircularProgress'>
          <CircularProgress color='secondary' />
        </div>
      ) : (
        <div style={{
          color: 'black',
          fontFamily: 'Montserrat Variable',

        }}>
          {props.content}
        </div>
      )}
    </div>
  );
};

export default withSnackbar(ModalWelcome);