const BusinessTypesList = () => {
  return [
    { label: 'Selecciona el tipo de negocio', value: ''},
    { label: 'Restaurante', value: 'Restaurante' },
    { label: 'Gasolinera', value: 'Gasolinera' },
    { label: 'Retail', value: 'Retail' },
    { label: 'Farmacia', value: 'Farmacia' },
    { label: 'Bancos', value: 'Bancos' },
    { label: 'Otro', value: 'Otro' },
  ];
}

export default BusinessTypesList;
