import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { Auth, API, Storage } from 'aws-amplify';
import {
  Typography,
  Grid,
  CircularProgress,
  Paper,
  Checkbox,
  Tooltip
} from '@material-ui/core';
import { API_STORE } from '../../lib/constants';
import defaultStoreIcon from './../../assets/images/default_store_icon.svg';
import '../../assets/css/main.css';
import PrimarySearchBar from '../common/primarySearchBar';
import removeAccents from '../../lib/removeAccents';
import noStoreImage from '../../assets/images/default_store_icon_1.svg';
import PrimaryButton from "../common/primaryButton";
import addStores from '../../lib/neritoPay/addStores';
import updateStores from '../../lib/neritoPay/updateStores'

const NeritoPayStores = (props) => {

  let history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    getStores();
  }, [])

  const merchant = props.location.state.merchantId;
  const merchantPicture = props.location.state.merchantPicture;
  const [isLoading, setLoading] = useState(true);
  const [stores, setStores] = useState([]);
  const [shownStores, setShownStores] = useState([]);
  const [storeprofilepics, setStoreProfilePics] = useState([]);
  const [loadingStores, setLoadingStores] = useState('');

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const getStores = async () => {
    setLoading(true)
    const getStores = await API.get(API_STORE, '/store', {
      queryStringParameters: {
        merchant: merchant,
        isFor: 'merchant',
      },
    });
    if (getStores.data && getStores.data.Items) {
      const imagesP = getStores.data.Items.map((store) =>
        Storage.get(`store/${store.id}/default.jpg`)
      );
      const pImages = await Promise.all(imagesP);
      const storesWithImages = getStores.data.Items.map(
        (store, index) => {
          return {
            ...store,
            storeImage: pImages[index],
          };
        }
      );
      const sortedStores = storesWithImages.sort((a, b) => b.published - a.published);
      setStores(sortedStores);
      setShownStores(sortedStores);
      setStoreProfilePics(pImages);
    }
    setLoading(false);
  }

  const activateStore = async (store) => {
    try {
      setLoading(true)
      const response = await addStores(store)
      if (response.success) {
        getStores()
        handleAlert('success', 'Se actualizaron las tiendas con éxito');
      } else {
        handleAlert('error', 'Algo salió mal')
      }
      setLoading(false)
    } catch (err) {
      handleAlert('error', 'Algo salió mal')
      setLoading(false)
    }
  }

  const deactivateStore = async (store) => {
    try {
      setLoading(true)
      const response = await updateStores(store)
      if (response.success) {
        getStores()
        handleAlert('success', 'Se actualizaron las tiendas con éxito');
      } else {
        handleAlert('error', 'Algo salió mal')
      }
      setLoading(false)
    } catch (err) {
      handleAlert('error', 'Algo salió mal')
      setLoading(false)
    }
  }

  const changeSelected = async (store, type, value, index) => {
    const storesTemp = [...stores];
    setLoadingStores(store.id)
    try {
      let item;
      if (type === 'withdraw') {
        item = { cashWithDrawal: value }
        storesTemp[index].cashWithDrawal = value;
      } else {
        item = { cashDeposit: value }
        storesTemp[index].cashDeposit = value;
      }
      const response = await API.put(
        API_STORE,
        `/store/${store.id}`,
        {
          body: {
            merchant: store.merchant,
            createdat: store.createdat,
            ...item
          },
        }
      );
      setLoadingStores('')
    } catch (err) {
      handleAlert('error', 'Algo salió mal')
      setLoading(false)
    }
  }

  const storeCard = (store, index) => {
    return (
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        lg={3}
        xl={2}
      >
        <Paper className={store.isNeritoPayStore ? classes.cardContainerActive : classes.cardContainerInactive}>
          <Grid container>
            <Grid item xs={12} sm={4} className={classes.cardImageContainer}>
              <img
                className={classes.cardImage}
                src={
                  store.hasAvatar
                    ? store.storeImage
                    : merchantPicture
                      ? merchantPicture
                      : defaultStoreIcon
                }
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <div className={classes.titleAndStatus}>
                <Typography className={classes.cardTitleText}>
                  {store.storeName}
                </Typography>
                <Tooltip title={store.published ? 'Tienda activa' : 'Tienda desactivada'}>
                  <div className={store.published ? classes.publishedStatusTextActive : classes.publishedStatusTextNoActive}></div>
                </Tooltip>
              </div>
              <Typography className={classes.cardSubtitle}>
                {`${store.schedules[0].start} - ${store.schedules[0].end}`}
              </Typography>
            </Grid>
            <Grid item className={classes.optionsContainer}>
              {loadingStores.includes(store.id) ?
                <Grid item className={classes.miniLoadingContainer}>
                  <CircularProgress color='secondary' />
                </Grid>
                :
                <>
                  <Grid item className={classes.option}>
                    <Typography className={classes.cardSubtitle}>
                      Retiros de efectivo
                    </Typography>
                    <div className={classes.checkboxContainer}>
                      <Checkbox
                        onChange={() => changeSelected(store, 'withdraw', !store.cashWithDrawal, index)}
                        color="rgba(67, 67, 67, 0.6)"
                        style={{ marginRight: '25px' }}
                        checked={store.cashWithDrawal}
                        disabled={loadingStores !== '' && store.id !== loadingStores}
                      />
                    </div>
                  </Grid>
                  <Grid item className={classes.option}>
                    <Typography className={classes.cardSubtitle}>
                      Dépositos de efectivo
                    </Typography>
                    <div className={classes.checkboxContainer}>
                      <Checkbox
                        onChange={() => changeSelected(store, 'deposit', !store.cashDeposit, index)}
                        color="rgba(67, 67, 67, 0.6)"
                        style={{ marginRight: '25px' }}
                        checked={store.cashDeposit}
                        disabled={loadingStores !== '' && store.id !== loadingStores}
                      />
                    </div>
                  </Grid>
                </>
              }
            </Grid>
            <Grid item className={classes.endContainer}>
              <button className={classes.deleteButton} onClick={() => store.isNeritoPayStore ? deactivateStore(store.id) : activateStore(store.id)}>
                <Typography className={store.isNeritoPayStore ? classes.deleteText : classes.addText}>{store.isNeritoPayStore ? 'Eliminar de NeritoPay' : 'Agregar a NeritoPay'}</Typography>
              </button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

    );
  };

  const handleSearchChange = (e) => {
    const search = removeAccents(e.target.value).toLowerCase();
    const filteredStores = stores.filter((store) =>
      removeAccents(store.storeName).toLowerCase().includes(search)
    );
    setShownStores(filteredStores);
  };

  return (
    <div className={classes.root}>
      {isLoading ?
        <div className={classes.loadingContainer}>
          <CircularProgress color='secondary' />
        </div>
        :
        <>
          <div className={classes.titleContainer}>
            <text className={classes.title}>Tiendas en
              <text className={classes.blueTitle}> NeritoPay</text>
            </text>
          </div>
          <div>
            <div className={classes.rowContainer}>
              <text className={classes.subtitle}>Selecciona las tiendas que podran realizar depositos, pagos y retiros de efectivo</text>
            </div>
            <Grid item xs={12}>
              <PrimarySearchBar
                type='search'
                placeholder='Buscar'
                autoFocus
                onChange={(e) => handleSearchChange(e)}
              />
            </Grid>
          </div>
          <div className={classes.storesContainer}>
            {shownStores.length > 0 ? (
              <div className={`${classes.contentContainer} scrollBarHidden`}>
                <Grid container direction="column">
                  <Typography className={classes.contentSubtitle}>
                    Tiendas en NeritoPay
                  </Typography>
                  <Grid container spacing={2} >
                    {shownStores.filter((store) => store.isNeritoPayStore).map((store, index) => storeCard(store, index))}
                  </Grid>
                  <Typography className={classes.contentSubtitle}>
                    Tiendas no registradas
                  </Typography>
                  <Grid container spacing={2} >
                    {shownStores.filter((store) => !store.isNeritoPayStore).map((store, index) => storeCard(store, index))}
                  </Grid>
                </Grid>
              </div>
            ) : (
              <div className={classes.empty}>
                <img src={noStoreImage} alt='noStoreImage' />
                <Typography>No hay sucursales.</Typography>
              </div>
            )}
          </div>
        </>
      }
    </div >
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 0,
    flex: '1 important'
  },
  loadingContainer: {
    margin: 'auto',
    paddingTop: '50px',
    ...theme.globals.center,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.lightGrey.main}`,
    marginBottom: 15,
    width: '100%',
  },
  title: {
    ...theme.typography.title,
    paddingTop: 10,
    paddingBottom: 10,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  blueTitle: {
    color: "#4B9BB8",
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    overflowX: 'hidden',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    flex: 1,
    height: '70vh'
  },
  subtitle: {
    ...theme.typography.subtitle,
    paddingTop: 10,
    paddingBottom: 10,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  cardContainerActive: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    cursor: 'pointer',
    transition: 'box-shadow 0.3s ease-in-out',
    height: '100px',
    position: 'relative',
    zIndex: 1,
    width: '290px',
    '&:hover': {
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.75)',
      height: '200px',
      zIndex: 10,
      position: 'absolute',
      width: '300px',
    },
    '&:hover $optionsContainer': {
      display: 'block',
    },
    '&:hover $endContainer': {
      display: 'flex',
    },
  },
  cardContainerInactive: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    cursor: 'pointer',
    transition: 'box-shadow 0.3s ease-in-out',
    height: '100px',
    position: 'relative',
    zIndex: 1,
    width: '290px',
    '&:hover': {
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.75)',
      height: '120px',
      zIndex: 10,
      position: 'absolute',
      width: '300px',
    },
    '&:hover $endContainer': {
      display: 'flex',
    },
  },
  cardImageContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  cardImage: {
    height: 60,
    width: 60,
    borderRadius: 50,
  },
  cardTitleText: {
    ...theme.typography.cardTitle,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  publishedStatus: {
    width: '100%',
    textAlign: 'end',
  },
  empty: {
    margin: 'auto',
    alignItems: 'center',
    paddingTop: '50px',
    textAlign: 'center',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      flexDirection: 'column',
    },
  },
  storesContainer: {
    display: 'flex',
    flex: 1
  },
  publishedStatusTextNoActive: {
    backgroundColor: theme.palette.red.main,
    width: '10px',
    height: '10px',
    borderRadius: '10px',
  },
  publishedStatusTextActive: {
    backgroundColor: theme.palette.primary.main,
    width: '10px',
    height: '10px',
    borderRadius: '10px',
  },
  titleAndStatus: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  contentSubtitle: {
    ...theme.typography.cardTitle,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginTop: '20px',
    marginBottom: '10px'
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  optionsContainer: {
    display: 'none',
    width: '100%',
  },
  option: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      flexDirection: 'column',
    },
  },
  deleteButton: {
    backgroundColor: 'transparent',
    border: 'none',
    display: 'flex',
    alignItems: 'flex-end',
  },
  deleteText: {
    color: theme.palette.error.main
  },
  addText: {
    color: "#4B9BB8"
  },
  endContainer: {
    display: 'none',
    width: '100%',
    justifyContent: 'flex-end',
  },
  miniLoadingContainer: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px',
    padding: '10px',
  }
}));

export default withSnackbar(NeritoPayStores);