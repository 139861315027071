import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import { API } from 'aws-amplify';
import { withSnackbar } from 'notistack';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import moment from 'moment-timezone';
import getInitials from '../../../../lib/getInitials';
import fixNumber from '../../../../lib/FixNumber'
import { API_PROFILE } from '../../../../lib/constants';
import { Avatar, Select, MenuItem } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import DefaultButton from '../../../common/defaultButton';
import DefaultCancelButton from '../../../common/defultCancelButton';
import theme from '../../../../styles/theme';

const useStyles = makeStyles(theme => ({
  drawer: {
    flexShrink: 0,
  },
  details: {
    minWidth: 430,
    padding: '0px 30px 0px 30px',
  },
  row: {
    flexDirection: 'row'
  },
  title: {
    ...theme.typography.cardTitle,
    marginTop: '40px',
    textAlign: 'center'
  },
  subtitleContainer: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  subtitle: {
    ...theme.typography.smallText,
    fontWeight: '600',
    marginTop: '10px',
    marginBottom: '10px',
    color: 'rgba(67, 67, 67, 0.6)',
  },
  statusCanceled: {
    ...theme.typography.smallText,
    fontWeight: '900',
    backgroundColor: '#EE715B',
    textAlign: 'center',
    padding: '5px 0px',
    marginLeft: '30%',
    marginRight: '30%',
    borderRadius: 3,
    color: 'white',
  },
  statusReady: {
    ...theme.typography.smallText,
    color: 'white',
    fontWeight: '900',
    backgroundColor: '#6EB89D',
    textAlign: 'center',
    padding: '5px 0px',
    marginLeft: '30%',
    marginRight: '30%',
    borderRadius: 3,
  },
  statusReceived: {
    color: 'white',
    ...theme.typography.smallText,
    fontWeight: '900',
    backgroundColor: '#EDDD64',
    textAlign: 'center',
    padding: '5px 0px',
    marginLeft: '30%',
    marginRight: '30%',
    borderRadius: 3,
  },
  statusProcessing: {
    ...theme.typography.smallText,
    color: 'white',
    fontWeight: '900',
    backgroundColor: '#F2C94C',
    textAlign: 'center',
    padding: '3px 0px',
    marginLeft: '30%',
    marginRight: '30%',
    borderRadius: 3,
  },
  clientInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 60,
    marginBottom: 60,
  },
  clientInfoText: {
    marginLeft: 20,
  },
  avatarLarge: {
    width: 50,
    height: 50,
  },
  productInfo: {
    justifyContent: 'space-between'
  },
  total: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: '0%',
    flexDirection: 'row',
    marginTop: '10px'
  },
  generalNote: {
    display: 'flex',
    marginRight: '0%',
    flexDirection: 'row',
    marginTop: '10px',
    maxWidth: 328,
  },
  status: {
    marginTop: 50,
  },
  clientName: {
    ...theme.typography.mediumText,
    fontWeight: '600',
    textAlign: 'center',
  },
  clientPhone: {
    ...theme.typography.mediumText,
    color: 'rgba(67, 67, 67, 0.6)',
    textAlign: 'center',
    maxWidth: 200,
  },
  productText: {
    width: '100%',
    ...theme.typography.productName,
    textAlign: 'right',
  },
  boldLabel: {
    ...theme.typography.smallText,
    marginBottom: '10px',
  },
  label: {
    ...theme.typography.productName,
    marginBottom: '10px',
    marginLeft: '10px',
    textAlign: 'justify',
  },
  divider: {
    marginTop: 20,
    marginBottom: 20,
  },
  modalStyle: {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
  productListContainer: {
    padding: '20px 20px 10px 20px',
    backgroundColor: '#F9F9F9',
    borderRadius: 5,
    border: '1px solid #E0E0E0'
  },
  redText: {
    color: 'red',
    textAlign: 'right',
    fontSize: 16,
  },
  buttonStyle: {
    border: 0,
    margin: 15,
    backgroundColor: 'white',
  },
  noteContainer: {
    maxWidth: 328,
  },
  paymentTypeText: {
    ...theme.typography.mediumText,
    fontWeight: '600',
    textAlign: 'center',
    marginTop: '10px',
  },
}));

function TemporaryDrawer(props) {
  useEffect(() => {
    getLendUser();
  }, [])

  const classes = useStyles(theme);
  const [loading, setLoading] = useState(true);
  const clientName = props.order.lendFullName;
  const productList = props.order.invoiceItems;
  const date = moment(props.order.createdAt).format('DD/MM/YYYY hh:mm a');
  const noOrder = props.order.orderNo;
  const total = props.order.amount;
  const note = props.order.note;
  const [status, setStatus] = useState(props.order.orderStatus);
  const [lend, setLend] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const order = props.order;
  const email = props.merchantEmail;

  const statuses = [
    { value: 'received', label: 'PENDIENTE' },
    { value: 'processing', label: 'EN PREPARACIÓN' },
    { value: 'ready', label: 'LISTO' },
    { value: 'canceled', label: 'CANCELADO' },
    { value: 'delivered', label: 'ENTREGADO' }
  ];

  const getStatus = status => {
    if (status === 'received') {
      return 'PENDIENTE'
    } else if (status === 'processing') {
      return 'EN PREPARACIÓN'
    } else if (status === 'ready') {
      return 'LISTO'
    } else if (status === 'delivered') {
      return 'ENTREGADO'
    } else {
      return 'CANCELADO'
    }
  };

  const renderProductList = () => {
    if (productList !== 0) {
      return productList.map(item => (<>
        <div className={classes.productList}>
          <Grid container
            direction="row"
            justify="space-between"
            alignItems="center">
            <Grid item lg={2} >
              <b className={classes.productText}>{item.product.amount}</b>
            </Grid>
            <Grid item lg={2}>
              <b className={classes.productText}>{item.product.productName}</b>
            </Grid>
            <Grid>
              <b className={classes.productText}>{`$${fixNumber(item.product.price)}`}</b>
            </Grid>
          </Grid>
        </div>
        {item.note && (
          <div className={classes.noteContainer}>
            <Typography className={classes.boldLabel}>{`Nota:`}</Typography>
            <Typography className={classes.label}>{item.product.note}</Typography>
          </div>
        )}
      </>))
    }
  }

  const getLendUser = async () => {
    const lendId = props.order.lend;
    try {
      const result = await API.get(API_PROFILE, `/profile/${lendId}`, {
        queryStringParameters: {
          project: 'userRecordKey,avatarUrl,hasAvatar,providerType,firstName,lastName,phoneNumber',
        },
      });
      const { avatarUrl, hasAvatar, firstName, lastName, phoneNumber } = result.data[0];
      const lend = {
        avatarUrl: hasAvatar ? avatarUrl : null,
        firstName: firstName,
        lastName: lastName,
        initials: hasAvatar ? null : getInitials(firstName, lastName),
        phoneNumber: phoneNumber,
      }
      setLend(lend)
    } catch (err) {
      handleAlert('error', 'Algo salió mal');
      return null;
    } finally {
      setLoading(false);
    }
  }

  const handleChange = (event) => {
    const newStatus = event.target.value;
    if (newStatus !== status) {
      if (newStatus === 'canceled') {
        setOpenModal(true);
      }
      else {
        changeInvoiceStatus(newStatus);
      }
    } else
      handleAlert('error', 'Seleccione un estado diferente');
  }

  const changeInvoiceStatus = async (newStatus) => {
    setLoading(true);
    try {
      const changeInvoice = await API.put('invoice-api', `/invoices/${props.order.invoiceRecordKey}`, {
        body: {
          status: newStatus
        }
      });
      setStatus(newStatus)
      newStatus === 'canceled' && closeModal();
      handleAlert('success', 'Estado del pedido cambió exitosamente')
    } catch (err) {
      handleAlert('error', err.response.data.error);
    } finally {
      setLoading(false);
    }
  }

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const closeModal = () => {
    setOpenModal(false);
  }

  const handleProblem = async () => {
    const description = 'Problema con pedido ' + order.orderNo + ' del cliente ' +
      order.lendFullName + ' con fecha ' + date + ' con status ' + order.orderStatus +
      ' transaccion ' + order.transaction;
    const formData = new FormData();
    formData.append('name', order.oweFullName);
    formData.append('merchantID', order.invoiceItems[0].merchantRecordKey);
    formData.append('problemType', 'Pedidos');
    formData.append('problem', description);
    formData.append('email', email);
    fetch(process.env.REACT_APP_ZAPIER_ZOHO, {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        handleAlert('success', 'Tu problema se envió correctamente')
      })
      .catch((error) => {
        handleAlert('error', error.response.data.error);
      });
  }

  const orderDetail = side => (
    <div>
      {loading ?
        <div className='circulaLoading'>
          <CircularProgress color="secondary" />
        </div>
        :
        <div className={classes.details}>
          <Typography className={classes.title}>
            Detalle de Orden
          </Typography>
          <div className={classes.subtitleContainer}>
            <Typography className={classes.subtitle}>
              {`${date}`}
            </Typography>
            <Typography className={classes.subtitle}>
              {`Order #${noOrder}`}
            </Typography>
          </div>
          <div>
            <Typography
              className={status === 'ready' || status === 'delivered' ? classes.statusReady
                : status === 'canceled' ? classes.statusCanceled
                  : status === 'received' ? classes.statusReceived
                    : classes.statusProcessing}>
              {getStatus(status)}
            </Typography>
          </div>
          <div className={classes.clientInfo}>
            <Avatar
              src={
                lend.avatarUrl
                  ? lend.avatarUrl
                  : null
              }
              className={classes.avatarLarge}
              rounded
            >{lend.avatarUrl ? null : lend.initials} </Avatar>
            <div className={classes.clientInfoText}>
              <Typography className={classes.clientName}>{clientName}</Typography>
              <Typography className={classes.clientPhone}>{`Tel. ${lend.phoneNumber.slice(lend.phoneNumber.length - 10)}`}</Typography>
              <Typography className={classes.clientPhone}>{order.invoiceType === 'pickup'
                ? `Hora de Recolección: ${order.time || 'N/A'}`
                : `Dirección de entrega: ${order.address || 'N/A'}`}
              </Typography>
            </div>
          </div>
          <Typography className={classes.boldLabel}>Orden:</Typography>
          <div className={classes.productListContainer}>
            <div>
              {renderProductList()}
            </div>
            <Divider className={classes.divider} />
            <div className={classes.total}>
              <Typography className={classes.boldLabel}> {`Total: $ ${fixNumber(total)}`}</Typography>
            </div>
            {note &&
              <div className={classes.generalNote}>
                <Typography className={classes.boldLabel}>{`Nota General:`}</Typography>
                <Typography className={classes.label}>{note}</Typography>
              </div>
            }
          </div>
          <div className={classes.status}>
            <Typography className={classes.boldLabel}>Estatus:</Typography>
            <Select
              value={status}
              onChange={handleChange}
              disabled={status === 'canceled' || status === 'delivered'}
              className="form-control">
              {statuses.map(item =>
              (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              )
              )}
            </Select>
          </div>
          {props.from ?
            <div>
              <button className={classes.buttonStyle} onClick={() => handleProblem()}>
                <Typography className={classes.redText}>
                  Tengo un problema con este pedido
                </Typography>
              </button>
            </div>
            : null
          }
          {order.paymentType === 'card' ?
            <div>
              <Typography className={classes.paymentTypeText}>
                Esta Orden se pagó con Tarjeta
              </Typography>
            </div>
            : null
          }
          <Modal
            open={openModal}
            onClose={closeModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className={`${classes.modalStyle} col-11 col-sm-9 col-md-7 col-lg-5 col-xl-4 bg-white rounded row p-3`}>
              <Typography className="col-12 text-center mt-5">¿Está seguro de cancelar ésta orden?</Typography>
              <div className="col-6 text-center mt-5 mb-4">
                <DefaultCancelButton
                  handleAction={closeModal}
                  override={{
                    width: 120,
                  }}
                  buttonText={"Cancelar"}
                />
              </div>
              <div className="col-6 text-center mt-5 mb-4">
                <DefaultButton
                  handleAction={() => changeInvoiceStatus('canceled')}
                  buttonText={"Aceptar"}
                />
              </div>
            </div>
          </Modal>
        </div>
      }
    </div>
  )

  return (
    <div>
      <Drawer anchor="right" open={props.open} onClose={props.cancel}>
        {orderDetail('right')}
      </Drawer>
    </div>
  );
}

export default withSnackbar(TemporaryDrawer);

