import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import PrimaryButton from '../common/primaryButton';
import {
  CircularProgress,
} from '@material-ui/core';
import {
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core';
import PrimaryInput from '../common/primaryInput';
import PrimarySelect from '../common/primarySelect';
import {
  API_MERCHANT,
  API_PROFILE,
} from '../../lib/constants';
import { Auth, API, } from 'aws-amplify';
import * as R from 'ramda';
import States from "../../lib/locations/states";

const Analitics = (props) => {
  const userKey = useSelector(state => state.user.value);

  const [isLoading, setLoading] = useState(true);
  const [merchant, setMerchant] = useState({});
  const [economicSector, setEconomicSector] = useState([
    { type: 'agricultural', label: 'Agropecuario', value: false },
    { type: 'industry', label: 'Industrial', value: false },
    { type: 'services', label: 'Servicios', value: false },
  ]);
  const [dataUsage, setDataUsage] = useState([
    { type: 'accept', label: 'Aceptar', value: false },
  ]);
  const [appsUsage, setAppsUsage] = useState([
    { type: 'financial', label: 'Financieras', value: false },
    { type: 'entertainment', label: 'Entretenimiento', value: false },
    { type: 'news', label: 'Noticias', value: false },
    { type: 'shopping', label: 'Compras', value: false },
    { type: 'education', label: 'Educación', value: false },
    { type: 'games', label: 'Juegos', value: false },
  ]);


  const [startAge, setStartAge] = useState({ type: 'startAge', label: 'Desde', value: 16 });
  const [endAge, setEndAge] = useState({ type: 'endAge', label: 'Hasta', value: 130 });

  const [gender, setGender] = useState({
    type: 'gender', label: 'Género', value: "", dataType: "selection", options: [
      "Masculino", "Femenino", "Otro"
    ]
  })

  const [state, setState] = useState({
    type: 'state', label: 'Estado', value: "", dataType: "selection"
  })

  useEffect(() => {
    getSettings();
  }, [])

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const classes = useStyles();

  const handleChangeFlagsArray = (index, value, array, set) => {
    const temp = [...array];
    temp[index].value = value;
    set(temp);
  }

  const getSettings = async () => {
    setLoading(true);
    try {
      const getUser = await Auth.currentAuthenticatedUser();
      const getGralProfile = await API.get(
        API_PROFILE,
        `/profile/${userKey}`
      );
      const profile = R.pathOr({}, ['data', 0], getGralProfile);
      const getMerchant = await API.get(API_MERCHANT, `/merchant?merchantRecordKey=${profile.merchantRecordKey}`);
      const merchantProfile = R.pathOr({}, ['data'], getMerchant);
      setMerchant(merchantProfile);
      if (merchantProfile.isAnaliticsUser) {
        const response = await API.get(API_MERCHANT, '/analitics', {
          queryStringParameters: {
            merchantRecordKey: profile.merchantRecordKey,
          }
        })

        const { data } = response;
        setAppsUsage(data.appsUsage)
        setEconomicSector(data.economicSector)
        setDataUsage(data.dataUsage)
        setGender(data.gender)
        setEndAge(data.endAge)
        setStartAge(data.startAge)
        setState(data.state)
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      handleAlert('error', 'Algo salió mal')
    }
  }

  const register = async () => {
    setLoading(true);
    try {
      const body = {
        merchantRecordKey: merchant.merchantRecordKey,
        settings: {
          economicSector,
          dataUsage,
          appsUsage,
          startAge,
          endAge,
          gender,
          state,
        }
      }
      if (merchant.isAnaliticsUser) {
        await API.put(API_MERCHANT, '/analitics', {
          body,
        });
      } else {
        await API.post(API_MERCHANT, '/analitics', {
          body,
        });
        setMerchant({ ...merchant, isAnaliticsUser: true })

      }
      setLoading(false);
      handleAlert('success', 'Se registraron los parámetros de analíticas')
    } catch (err) {
      setLoading(false);
      handleAlert('error', 'Algo salió mal')
    }
  }

  const handleOnChange = (e) => {
    const value = e.target.value;
    switch (e.target.name) {
      case 'startAge':
        setStartAge({ ...startAge, value: value >= 0 ? parseInt(value) : 0 })
        break;
      case 'endAge':
        setEndAge({ ...endAge, value: value >= 0 ? parseInt(value) : 0 })
        break;
      case 'genderType':
        setGender({ ...gender, value: value })
        break;
      case 'state':
        setState({ ...state, value: value })
        break;
      default:
        break;
    }
  }

  return (
    <div className={classes.overflowHidden}>
      {isLoading ?
        <div className={classes.loadingContainer}>
          <CircularProgress color='secondary' />
        </div>
        :
        <div className={classes.container}>
          <div className={classes.titleContainer}>
            <text className={classes.title}>{merchant.isAnaliticsUser ? '' : 'Registrate en'}
              <text className={classes.greenTitle}> Analíticas</text>
            </text>
          </div>
          <text className={classes.subtitle}>{merchant.isAnaliticsUser
            ? 'Ya estas registrado en '
            : '¿Quieres comprar'}
            Analíticas de <text className={classes.greenTitle}>Nerito</text>
            {merchant.isAnaliticsUser
              ? ''
              : '?'}
          </text>
          <text className={classes.info}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Sed vitae vehicula ipsum, vitae dapibus diam. Sed vulputate euismod pellentesque.
            Nullam ultrices pulvinar posuere. Nulla eget dui molestie, lacinia est id, sagittis odio.
            Sed eget rhoncus diam, sed volutpat quam. Aenean sit amet lacus ac est euismod ultrices.
            Sed vel nulla ut ligula sollicitudin gravida in in purus. Donec rutrum neque mauris.
            Quisque ut purus et felis efficitur vehicula nec ut purus.
          </text>
          <text className={classes.subtitle}>¿Qué información te interesa?</text>
          <div className={`${classes.contentContainer} `}>
            <div className={classes.halfContainer}>
              <text className={classes.dataType}>Sectores Económicos</text>
              {
                economicSector.map((item, index) =>
                  <FormControlLabel
                    control={<Checkbox
                      checked={item.value}
                      onChange={() => handleChangeFlagsArray(index, !item.value, economicSector, setEconomicSector)}
                      name={item.type} />}
                    label={item.label}
                  />)
              }
              <text className={classes.dataType}>Consumo de Datos</text>
              {
                dataUsage.map((item, index) =>
                  <FormControlLabel
                    control={<Checkbox
                      checked={item.value}
                      onChange={() => handleChangeFlagsArray(index, !item.value, dataUsage, setDataUsage)}
                      name={item.type} />}
                    label={item.label}
                  />)
              }
              <text className={classes.dataType}>Uso de Aplicaciones</text>
              <div className={classes.contentContainer}>
                <div className={classes.halfContainer}>
                  {
                    appsUsage.slice(0, Math.ceil(appsUsage.length / 2)).map((item, index) =>
                      <FormControlLabel
                        control={<Checkbox
                          checked={item.value}
                          onChange={() => handleChangeFlagsArray(index, !item.value, appsUsage, setAppsUsage)}
                          name={item.type} />}
                        label={item.label}
                      />)
                  }
                </div>
                <div className={classes.halfContainer}>
                  {
                    appsUsage.slice(Math.ceil(appsUsage.length / 2), appsUsage.length).map((item, index) =>
                      <FormControlLabel
                        control={<Checkbox
                          checked={item.value}
                          onChange={() => handleChangeFlagsArray((index + Math.ceil(appsUsage.length / 2)), !item.value, appsUsage, setAppsUsage)}
                          name={item.type} />}
                        label={item.label}
                      />)
                  }
                </div>
              </div>
            </div>
            <div className={classes.halfContainer}>
              <text className={classes.dataType}>Datos Demográficos</text>
              <Typography className={classes.inputTitle}>Rango de Edad</Typography>
              <div className={`${classes.contentContainer} `}>
                <PrimaryInput
                  type='number'
                  id={'startAge'}
                  name={'startAge'}
                  placeholder={startAge.label}
                  value={startAge.value}
                  onChange={handleOnChange}
                  fullWidth
                />
                <PrimaryInput
                  type='number'
                  id={'endAge'}
                  name={'endAge'}
                  placeholder={endAge.label}
                  value={endAge.value}
                  onChange={handleOnChange}
                  fullWidth
                />
              </div>
              <Typography className={classes.inputTitle}>{gender.label}</Typography>
              <PrimarySelect
                value={gender.value}
                content={gender.options.map((item, index) => <option key={index} value={item}>{item}</option>)}
                onChange={handleOnChange}
                id={'genderType'}
                name={'genderType'}
              >
              </PrimarySelect>
              <Typography className={classes.inputTitle}>{state.label}</Typography>
              <PrimarySelect
                value={state.value}
                content={States().map((item, index) => <option key={index} value={item.lael}>{item.label}</option>)}
                onChange={handleOnChange}
                id={'state'}
                name={'state'}
              >
              </PrimarySelect>
              <div className={classes.buttonContainer}>
                <PrimaryButton
                  onClick={register}
                >
                  {merchant.isAnaliticsUser ? 'Editar Parámetros' : 'Registrarme en Analíticas'}
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.lightGrey.main}`,
    marginBottom: 15,
  },
  title: {
    ...theme.typography.title,
    paddingTop: 10,
    paddingBottom: 10,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  greenTitle: {
    color: theme.palette.primary.main,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  subtitle: {
    ...theme.typography.subtitle,
    paddingTop: 10,
    paddingBottom: 10,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  dataType: {
    ...theme.typography.subtitle,
    paddingTop: 10,
    paddingBottom: 10,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    fontSize: 16,
  },
  halfContainer: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  info: {
    ...theme.typography.paragraph,
    paddingRight: 10,
    fontWeight: 600,
  },
  loadingContainer: {
    margin: 'auto',
    paddingTop: '50px',
    ...theme.globals.center,
  },
  overflowHidden: {
    overflowY: 'scroll',
    overflow: 'hidden'
  },
  buttonContainer: {
    marginTop: '25px',
    width: '100%',
    display: 'flex',
    justifyContent: 'right',
  }
}));

export default withSnackbar(Analitics);