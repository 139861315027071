import React from 'react';
import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar  } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import { API, Auth } from 'aws-amplify';
import Pagination from '@material-ui/lab/Pagination';
import TextField from '@material-ui/core/TextField';
import moment from 'moment-timezone';
import Button from '@material-ui/core/Button';
import { CSVLink, CSVDownload } from "react-csv";


import { API_PROFILE, API_TRANSACTION } from '../../../../lib/constants';
import '../../../../assets/css/main.css';
import noMovements from '../../../../assets/images/no_movements.svg';
import DefaultInput from "../../../common/defaultInput";
import DefaultSelect from "../../../common/defaultSelect";


const useStyles = makeStyles(theme =>({
  root: {
      flexGrow: 1,
      width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 0px 10px 0px',
  },
  topContainer: {
    marginLeft: '15px',
    marginRight: '15px',
  },
  tableContainer: {
    display: 'flex',
    marginLeft: 0, 
    marginRight: 0, 
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: '#F9F9F9',
    borderRadius: 5,
    flexDirection: "row"
  },
  buttonContainer: {
    display: 'flex',
    marginBottom: 15,
  },
  tableGridContainer: {
    width: 'calc(100vw - 87px)',
    width: '100%',
    marginLeft: 0, 
    marginRight: 0, 
    marginTop: 20,
    marginBottom: 20,
    
  },
  actionButonsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      height: '100%',
      alignItems: 'center',
      
  },
  actionButon1: {
      cursor: 'pointer',
      marginRight: 10
  },
  actionButon2: {
    cursor: 'pointer',
    marginLeft: 10
  },
  tableHeaderText: {
    ...theme.typography.smallText,
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid #BDBDBD',
    borderBottom: '1px solid #BDBDBD',
    height: 60,
    justifyContent: 'center'
  },
  tableFilterInput: {
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    borderRadius: '10px',
  },
  tableRowText: {
    ...theme.typography.smallText,
    paddingTop: 15,
    paddingRight: 15,
    paddingLeft: 15,
    paddingBottom: 15,
    display: 'flex',
    alignItems: 'center',
    height: 10,
    textAlign: 'center',
    justifyContent: 'center'
  },
  tableRowNumber: {
    ...theme.typography.smallText,
    paddingTop: 15,
    paddingRight: 15,
    paddingLeft: 15,
    paddingBottom: 15,
    display: 'flex',
    alignItems: 'center',
    height: 10,
    textAlign: 'center',
    justifyContent: 'flex-end',
  },
  buttonStyle: {
    border: '1px solid #3B9A83',
    boxSizing: 'border-box',
    borderRadius: 5,
    width: 146,
    height: 38,
    margin: 2
  },
  buttonText: {
    ...theme.typography.extraSmallText,
    color: theme.palette.primary.main
  },
  plusIcon: {
    marginRight: 5
  },
  paper: {
    position: 'absolute',
    width: 497,
    height: 'auto',
    backgroundColor: '#F9F9F9',
    boxShadow: '0 4 14 rgba(0, 0, 0, 0.15)',
    borderRadius: 5,
    textAlign: 'center'
  },
  mainContainerRegister: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: 5
  },
  modalButtons: {
    marginBottom: 20,
    marginTop: 20
  },
  title: {
    ...theme.typography.title,
    marginTop: 20
  },
  backgroundOddColumn:{
    backgroundColor: '#F9F9F9'
  },
  totalsConteiner:{
    border: '1px solid #BDBDBD',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '130px',
    width: '100%',
    height: 90,
    justifyContent: 'center',
    margin: '0px auto 5px'
  },
  totalsLabel: {
    ...theme.typography.inputLabel,
    width: '100%',
    textAlign: 'center',
    color: 'rgba(67, 67, 67, 0.6)',

  },
  totalsValue: {
    fontFamily: 'Oxanium',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 18,
    marginTop: 5,
    textAlign: 'center',
    color: '#434343',
    textAlign: 'left'
  },
  totalsValueAlt: {
    fontFamily: 'Oxanium',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 18,
    textAlign: 'center',
    color: '#434343',
    marginTop: 5,
  },
  downloadCsvButtonStyle: {
    border: '1px solid #3B9A83',
    borderRadius: 5,
    height: '100%',
    textAlign: 'center',
    justifyContent: 'center',
  },
  successButtonText: {
    ...theme.typography.extraSmallText,
    color: theme.palette.primary.main
  },
  backgrounOddColumn:{
    backgroundColor: '#F6F6F6',
    padding: 3
  },
  backgrounColumn:{
    backgroundColor: '#FFFF',
    padding: 3
  },
  formDate: {
    fontSize: 14,
    fontFamily: 'Oxanium',
    fontWeight: 600,
    marginLeft: 20,
    marginRight: 20,
    display: 'flex',

  },
  dateInput: {
    fontFamily: 'Nerito',
    color: '#434343',
    width: '100%',
    padding: '5px 10px'
  },
  totalsSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: 10,
    marginBottom: 30,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  filterConteiner: {
    border: '1px solid #BDBDBD',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '0px 3px 5px'
  },
  errorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 50,
    marginbottom: 80
  },
  noResultText: {
    ...theme.typography.paragraph,
    marginTop: 20
  },
  noResultText2: {
    ...theme.typography.mediumText,
    opacity: '0.6'
  },
  paginationTAB: {
    widht: '100%',
    display: 'flex',
    justifyContent: 'center'
  }
}));

const initialFromDate = `${moment().format('YYYY-MM-DD')}T00:00`;
const initialToDate = `${moment().format('YYYY-MM-DD')}T23:59`;
const perPage = 10;
const transTypes = [
  'TODOS',
  'ORDEN',
  'FONDOS',
  'DEPOSITO EN TIENDA',
  'ENVIO DE DINERO',
  'PAGO DE COPERACHA',
  'PAGO DE PRESTAMO',
  'PAGO EN TIENDA',
  'PRESTAMO ACEPTADO',
  'REEMBOLSO',
  'REGALO',
  'RETIRO EN TIENDA',
];

function Tab2(props) {
  useEffect(()=>{
    callApi(initialFromDate, initialToDate);
  },[])

  const classes = useStyles();

  const [isLoading, setLoading] = React.useState(true);
  const [transactions, setTransactions] = React.useState([]);
  const [shownTransactions, setShownTransactions] = React.useState([]);

  const [totalBalance, setTotalBalance] = React.useState(0);
  const [totalDeposit, setDeposit] = React.useState(0);
  const [totalWithdraw, setWithdraw] = React.useState(0);

  const [fromDate, setFromDate] = React.useState(initialFromDate);
  const [toDate, setToDate] = React.useState(initialToDate);
  const [selectedPage, setSelectedPage] = React.useState(1);
  const [pages, setPages] = React.useState(0);

  const [filters, setFilters] = React.useState({
    id: {active: false, value: ''},
    type: {active: false, value: 'all'},
    namePay: {active: false, value: ''},
    nameReceives: {active: false, value: ''},
    amountFormatted: {active: false, value: ''},
    date: {active: false, value: ''},
    hour: {active: false, value: ''},
    comment: {active: false, value: ''},
  });

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar( message, { variant });
  };

  const getTransactions = async (from, to) => {
    try {
      const getTransactions = await API.get(API_TRANSACTION, '/transaction/breakdown', {
        queryStringParameters: {
          startDate: from,
          endDate: to,
          userid: props.user.userRecordKey,
        },
      })
      return getTransactions;
    } catch (err) {
      handleAlert('error', err.message);
      return null;
    }
  }

  const calcTotals = (data) => {
    let balance = 0;
    let deposit = 0;
    let withdraw = 0;

    data.forEach(mov => {
      switch (mov.type) {
        case 'inshop_withdraw':
          withdraw += mov.total || mov.amount
          break;
        case 'inshop_deposit':
          deposit += mov.total || mov.amount
          break;
        default:
          break;
      }
    })
    setTotalBalance(balance.toFixed(2));
    setDeposit(deposit.toFixed(2));
    setWithdraw(withdraw.toFixed(2));
  }

  const callApi = async (from, to, data=[]) => {
    const timeStampFrom = moment.utc(from).valueOf();
    const timeStampTo = moment.utc(to).valueOf();
    if (timeStampFrom < timeStampTo) {
      setLoading(true)
      try {
        const getTrans = getTransactions(timeStampFrom, timeStampTo);
        const trans = await Promise.resolve(getTrans);
        if (trans.length > 0) {
          const transactionsData = trans.map(transaction => {
            return {
              ...transaction,
              amountFormatted: setMoneyFormat(transaction.amount.toFixed(2)),
              date: moment(transaction.createdAt).format('DD/MM/YYYY'),
              hour: moment(transaction.createdAt).format('h:mm:ss a')
            }
          });
          setPages(Math.ceil(transactionsData.length / perPage));
          setTransactions(transactionsData);
          setShownTransactions(transactionsData);
          calcTotals(transactionsData);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        handleAlert('error', err.message)
        setLoading(false)
      }
    } else {
      handleAlert('error', 'La fecha inicio debe ser menor a la fecha de fin')
    }
  };

  const handleChange = (event) => {
    const {value, name} = event.target
    switch (name) {
      case 'fromDate':
        setFromDate(value)
        callApi(value, toDate)
        break;
      case 'toDate':
        setToDate(value)
        callApi(fromDate, value)
        break;
      default:
        handleAlert('error', `Input no válido ${name}`)
    }
  }

  const handlePagination = (event, value) => {
    setSelectedPage(value)
  }

  const mapType = (type) => {
    switch (type) {
      case 'all':
        return 'TODOS' 
      case 'inshop_withdraw':
        return 'RETIRO EN TIENDA'
      case 'inshop_pay':
        return 'PAGO EN TIENDA'
      case 'inshop_deposit':
        return 'DEPOSITO EN TIENDA'
      case 'invoice':
        return 'ORDEN'
      case 'refund':
        return 'REEMBOLSO'
      case 'payment_paid':
        return 'PAGO DE PRESTAMO'
      case 'funds':
        return 'FONDOS'
      case 'invoice_gift':
        return 'REGALO'
      case 'transaction_money_sended':
        return 'ENVIO DE DINERO'
      case 'credit_accepted':
        return 'PRESTAMO ACEPTADO'
      case 'split_paid':
        return 'PAGO DE COPERACHA'
      default:
        return 'N/A'
    }
  }

  const mapTypeBackwards = (type) => {
    switch(type) {
      case 'TODOS':
        return 'all'
      case 'RETIRO EN TIENDA':
        return 'inshop_withdraw'
      case 'PAGO EN TIENDA':
        return 'inshop_pay'
      case 'DEPOSITO EN TIENDA':
        return 'inshop_deposit'
      case 'ORDEN':
        return 'invoice'
      case 'REEMBOLSO':
        return 'refund'
      case 'PAGO DE PRESTAMO':
        return 'payment_paid'
      case 'FONDOS':
        return 'funds'
      case 'REGALO':
        return 'invoice_gift'
      case 'ENVIO DE DINERO':
        return 'transaction_money_sended'
      case 'PRESTAMO ACEPTADO':
        return 'credit_accepted'
      case 'PAGO DE COPERACHA':
        return 'split_paid'
      default:
        return 'N/A'
    }
  }

  const downloadCsv = () => {
    // const csvData = [["Tipo","Fecha","Hora","Cliente","Empleado","Monto",
    // "Comisión Comercio","IVA","Comisión Nerito","IVA","Total"]]
    // transactions.forEach((transaction) => (
    //   csvData.push([
    //     mapType(transaction.type),
    //     moment(transaction.createdAt).format('DD/MM/YYYY'),
    //     moment(transaction.createdAt).format('h:mm:ss a'),
    //     '*' + transaction.client.substr(transaction.client.length - 4, transaction.client.length - 1),
    //     transaction.employee,
    //     transaction.amount.toFixed(2),
    //     getCommission(false, transaction),
    //     getIva(false, transaction),
    //     getCommission(true, transaction),
    //     getIva(true, transaction),
    //     ((transaction.type === 'inshop_deposit' || transaction.type === 'refund') ? '-' : '') + (transaction.total ? transaction.total.toFixed(2) : transaction.amount.toFixed(2)),
    //   ])
    // ))
    // ReactDOM.unmountComponentAtNode(document.querySelector('#csv'));
    // const element= (<CSVDownload data={csvData} target="_blank"/>);
    // ReactDOM.render(element, document.querySelector('#csv'));
  }

  const setMoneyFormat = (number) => {
    if (number != 'N/A') {
      return '$ ' + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return number
    }
  }

  function filterTransactions(transaction, filtersCopy) {
    for (const [key, filter] of Object.entries(filtersCopy)) {
      if (filter.active && transaction[key]) {
        if (!transaction[key].includes(filter.value)) {
          return false;
        }
      }
      if (filter.active && !transaction[key]) {
        return false;
      }
    }
    return true;
  }

  const handleFilterChange = (event) => {
    const {name, value} = event.target;
    const filtersCopy = {...filters};

    if (name === 'type') {
      filtersCopy[name]['value'] = mapTypeBackwards(value);
      if (value === 'TODOS') {
        filtersCopy[name]['active'] = false;
      } else if (value !== 'TODOS' && filtersCopy[name]['active'] === false) {
        filtersCopy[name]['active'] = true;
      }
    } else {
      filtersCopy[name]['value'] = value;
      if (filtersCopy[name]['active'] === true && value === '') {
        filtersCopy[name]['active'] = false;
      } else if ( filtersCopy[name]['active'] === false && value !== '') {
        filtersCopy[name]['active'] = true;
      }
    }

    const transactionsFiltered = transactions.filter(transaction => filterTransactions(transaction, filtersCopy));
    setFilters(filtersCopy);
    setShownTransactions(transactionsFiltered);
    setPages(Math.ceil(transactionsFiltered.length / perPage));
  }
  
  return (
    <div className={classes.root}>
        {isLoading ?
            <div className='gralinfoform align-center justify-center text-center'>
                <CircularProgress color="secondary" />
            </div>
        :
        <div className={classes.container}> 
                <div className={`row ${classes.topContainer}`}>
                  <div className={classes.filterConteiner + ' col-sm-12 col-lg-6'}>
                    <form className="col-12 filterDate" noValidate>
                        <TextField className={classes.dateInput + ' col-sm-12 col-lg-6'}
                            id="datetime-from"
                            name="fromDate"
                            value={fromDate}
                            onChange={handleChange}
                            label="Desde"
                            type="datetime-local"
                            defaultValue="2017-05-24T10:30"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                          <TextField className={classes.dateInput + ' col-sm-12 col-lg-6'}
                              id="datetime-to"
                              name="toDate"
                              value={toDate}
                              onChange={handleChange}
                              label="Hasta"
                              type="datetime-local"
                              defaultValue="2017-05-24T10:30"
                              InputLabelProps={{
                                  shrink: true,
                              }}
                          />
                    </form>
                  </div>
                  <div className={ 'col-auto lbl-total ' + classes.totalsConteiner}>
                      <Typography className={classes.totalsLabel}>DEPÓSITOS:</Typography>
                      <Typography className={classes.totalsValueAlt}>{setMoneyFormat(totalDeposit)}</Typography>
                  </div>
                  <div className={ 'col-auto lbl-total ' + classes.totalsConteiner}>
                      <Typography className={classes.totalsLabel}>RETIROS:</Typography>
                      <Typography className={classes.totalsValueAlt}>{setMoneyFormat(totalWithdraw)}</Typography>
                  </div>
                  <div className={ 'col-auto lbl-total ' + classes.totalsConteiner}>
                      <Typography className={`${classes.totalsLabel}`}>BALANCE TOTAL:</Typography>
                      <Typography className={`${classes.totalsValue}`}>{setMoneyFormat(totalBalance)}</Typography>
                  </div>
                  <div className='col-auto btn-download lbl-total '>
                    <Button className={`${classes.downloadCsvButtonStyle} tooltip`} onClick={() => downloadCsv()} disabled={transactions.length <= 0}>
                      <p className={classes.buttonText}>Descargar movimientos</p>
                    </Button>
                  </div>
                </div>
    
          <div className="mt-3  mt-5">
            <div className=""> 
            <table className="table mt-4">
              <thead>
                <tr>
                    <th>
                      <Typography className={classes.tableHeaderText}>ID</Typography>
                    </th>
                    <th>
                        <Typography className={classes.tableHeaderText}>Tipo</Typography>
                    </th>
                    <th>
                        <Typography className={classes.tableHeaderText}>Emisor</Typography>
                    </th>
                    <th>
                        <Typography className={classes.tableHeaderText}>Receptor</Typography>
                    </th>
                    <th>
                        <Typography className={classes.tableHeaderText}>Cantidad</Typography>
                    </th>
                    <th>
                        <Typography className={classes.tableHeaderText}>Fecha</Typography>
                    </th>
                    <th>
                        <Typography className={classes.tableHeaderText}>Hora</Typography>
                    </th>
                    <th>
                        <Typography className={classes.tableHeaderText}>Asunto</Typography>
                    </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={classes.tableFilterRow}>
                    <DefaultInput
                      custom={{
                        handleChange: handleFilterChange,
                        value: filters.id.value,
                        classLabel: "labelInputStyleForm",
                        // labelText: "Nombre(s)",
                        id: "id",
                        type: "text",
                        name: "id",
                        className: `${classes.tableFilterInput} col-12`,
                        placeholder: "",
                        // error: legalRepresentativeNameError,
                      }}
                    />
                  </td>
                  <td className={classes.tableFilterRow}>
                    <DefaultSelect
                      custom={{
                        handleChange: handleFilterChange,
                        value: mapType(filters.type.value),
                        classForm: "inputForm col-12",
                        classLabel: "labelInputStyleForm",
                        // labelText: "Nacionalidad",
                        id: "type",
                        name: "type",
                        className: `${classes.tableFilterInput} col-12`,
                        // placeholder: "TODOS",
                        items: transTypes,
                        // error: legalRepresentativeNationalityError,
                      }}
                    />
                  </td>
                  <td className={classes.tableFilterRow}>
                    <DefaultInput
                      custom={{
                        handleChange: handleFilterChange,
                        value: filters.namePay.value,
                        classLabel: "labelInputStyleForm",
                        // labelText: "Nombre(s)",
                        id: "namePay",
                        type: "text",
                        name: "namePay",
                        className: `${classes.tableFilterInput} col-12`,
                        placeholder: "",
                        // error: legalRepresentativeNameError,
                      }}
                    />
                  </td>
                  <td className={classes.tableFilterRow}>
                    <DefaultInput
                      custom={{
                        handleChange: handleFilterChange,
                        value: filters.nameReceives.value,
                        classLabel: "labelInputStyleForm",
                        // labelText: "Nombre(s)",
                        id: "nameReceives",
                        type: "text",
                        name: "nameReceives",
                        className: `${classes.tableFilterInput} col-12`,
                        placeholder: "",
                        // error: legalRepresentativeNameError,
                      }}
                    />
                  </td>
                  <td className={classes.tableFilterRow}>
                    <DefaultInput
                      custom={{
                        handleChange: handleFilterChange,
                        value: filters.amountFormatted.value,
                        classLabel: "labelInputStyleForm",
                        // labelText: "Nombre(s)",
                        id: "amountFormatted",
                        type: "text",
                        name: "amountFormatted",
                        className: `${classes.tableFilterInput} col-12`,
                        placeholder: "",
                        // error: legalRepresentativeNameError,
                      }}
                    />
                  </td>
                  <td className={classes.tableFilterRow}>
                    <DefaultInput
                      custom={{
                        handleChange: handleFilterChange,
                        value: filters.date.value,
                        classLabel: "labelInputStyleForm",
                        // labelText: "Nombre(s)",
                        id: "date",
                        type: "text",
                        name: "date",
                        className: `${classes.tableFilterInput} col-12`,
                        placeholder: "",
                        // error: legalRepresentativeNameError,
                      }}
                    />
                  </td>
                  <td className={classes.tableFilterRow}>
                    <DefaultInput
                      custom={{
                        handleChange: handleFilterChange,
                        value: filters.hour.value,
                        classLabel: "labelInputStyleForm",
                        // labelText: "Nombre(s)",
                        id: "hour",
                        type: "text",
                        name: "hour",
                        className: `${classes.tableFilterInput} col-12`,
                        placeholder: "",
                        // error: legalRepresentativeNameError,
                      }}
                    />
                  </td>
                  <td className={classes.tableFilterRow}>
                    <DefaultInput
                      custom={{
                        handleChange: handleFilterChange,
                        value: filters.comment.value,
                        classLabel: "labelInputStyleForm",
                        // labelText: "Nombre(s)",
                        id: "comment",
                        type: "text",
                        name: "comment",
                        className: `${classes.tableFilterInput} col-12`,
                        placeholder: "",
                        // error: legalRepresentativeNameError,
                      }}
                    />
                  </td>
                </tr>
                {shownTransactions.slice(((selectedPage - 1) * perPage), (((selectedPage - 1) * perPage) + perPage)).map((transaction) => (
                  <tr key={transaction.id}>
                    <td className={classes.backgrounOddColumn}>
                      <Typography className={classes.tableRowText}>{transaction.id}</Typography>
                    </td>
                    <td>
                      <Typography className={classes.tableRowText}>{mapType(transaction.type)}</Typography>
                    </td>
                    <td className={classes.backgrounOddColumn}>
                      <Typography className={classes.tableRowText}>{transaction.namePay}</Typography>
                    </td>
                    <td>
                      <Typography className={classes.tableRowText}>{transaction.nameReceives}</Typography>
                    </td>
                    <td className={classes.backgrounOddColumn}>
                      <Typography className={classes.tableRowNumber}>{transaction.amountFormatted}</Typography>
                    </td>
                    <td>
                      <Typography className={classes.tableRowText}>{transaction.date}</Typography>
                    </td>
                    <td className={classes.backgrounOddColumn}>
                      <Typography className={classes.tableRowText}>{transaction.hour}</Typography>
                    </td>
                    <td>
                      <Typography className={classes.tableRowText}>{transaction.comment}</Typography>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {shownTransactions.length > 0 && (
            <div className={classes.paginationTAB}>
              <Pagination  count={pages} shape="rounded" onChange={handlePagination}/>
            </div>
          )}
        </div>
        {shownTransactions.length === 0 &&
            <div className={classes.errorContainer}>
              <img src={noMovements}></img>
              <Typography className={classes.noResultText} >No se encontraron movimientos</Typography>
              <Typography className={classes.noResultText2} >Por favor selecciona otro rango fechas</Typography>
            </div>
          }
        </div>
        }
        {<div id="csv" />}
    </div>
  );
}

export default withSnackbar(Tab2)