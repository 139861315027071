import React, { useEffect, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import { API } from 'aws-amplify';
import { withSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { setCurrentContract } from '../../../redux/slices/contractVersionSlice';
import { API_SETTING, MONTHS } from '../../../lib/constants'
import NunitoBold from '../../../assets/fonts/Nunito-ExtraBold.ttf'
import HtmlContract from './htmlContract'

import '../../../assets/css/main.css';

const useStyles = makeStyles(theme => ({
    firstRowStyle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    rowStyle: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 15,
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    F1: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        maxWidth: 717,
    },
    R1End: {
        flex: 1,
        textAlign: 'end'
    },
    titleContainer: {
        width: 717,
        display: 'flex'
    },
    printText: {
      ...theme.typography.mediumText,
        color: '#828282',
        cursor: 'pointer',
        textAlign: 'right'
    }
}));



const pdfStyles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#ffffff'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    title: {
        fontFamily: 'Nunito-bold',
        fontStyle: 'normal',
        fontSize: 14,
        color: '#000000',
        textAlign: 'center',
        paddingBottom: 10,
        display: 'block',
        justifyContent: 'center',
        fontWeight: 'bold'
    }
});

function Contract(props) {
  const dispatch = useDispatch();

  useEffect(()=>{
      getContract();
  },[])

  const classes = useStyles();
  const [isLoading, setLoading] = React.useState(true);
  const [contractData, setContractData] = React.useState({});

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const ContractDocument = () => (
    <Document>
        <Page size="A4" style={pdfStyles.page}>
            <View style={pdfStyles.section}>
                <Text style={pdfStyles.title}>
                    CONTRATO COMERCIAL
                </Text>
                <HtmlContract contractData={contractData}/>
            </View>
        </Page>
    </Document>
  );

  const getContract = async () => {
    try {
      const settingsData = await API.get(API_SETTING, '/settings/default_contracts_config')
      if (settingsData.data && settingsData.data.length > 0 && settingsData.data[0].setup) {
        const contractVersion = settingsData.data[0].setup.default || 'N/A'
        dispatch(setCurrentContract(contractVersion));
        const defaultContract = settingsData.data[0].setup[contractVersion] || null

        if (defaultContract) {
          buildContract(defaultContract)
        } else {
          handleAlert('error', 'Error al cargar contrato.')
          setLoading(false)
        }
      }
    } catch (err) {
      handleAlert('error', err.message)
      setLoading(false)
    }
  }

  const buildContract = (jsonTemplate) => {
    const today = new Date();
    let commissions = props.merchantProfile.commissions || null
    if (commissions === null) {
        commissions = {
            "chargedClient": {
              "deposit": {
                "amount": 0,
                "type": "fixed"
              },
              "pay": {
                "amount": 0,
                "type": "fixed"
              },
              "withdraw": {
                "amount": 0,
                "type": "fixed"
              }
            },
            "chargedCommerce": {
              "deposit": {
                "amount": 0,
                "type": "fixed"
              },
              "pay": {
                "amount": 0,
                "type": "fixed"
              },
              "withdraw": {
                "amount": 0,
                "type": "fixed"
              }
            },
            "paidCommerce": {
              "deposit": {
                "amount": 0,
                "type": "fixed"
              },
              "pay": {
                "amount": 0,
                "type": "fixed"
              },
              "withdraw": {
                "amount": 0,
                "type": "fixed"
              }
            }
          }
    }
    const cd = {
        day: today.getDate(),
        month: MONTHS[today.getMonth()],
        year: today.getFullYear(),
        city: props.merchantProfile.city || '',
        businessCommercialName: props.merchantProfile.businessCommercialName || '',
        legalAddress: props.merchantProfile.legalAddress || '',
        commissions,
        jsonTemplate
    }
    try {
      setContractData(cd)
    } catch (err) {
      handleAlert('error', err.message)
    } finally {
      setLoading(false);
    }
  }

  const DownloadPdf = () => {
    return useMemo(
      () => (
        <PDFDownloadLink className={classes.printText} document={<ContractDocument />} fileName="contract.pdf">
          {({ blob, url, loading, error }) => (loading ? 'Cargando...' : 'Imprimir')}
        </PDFDownloadLink>
      ),
      [],
    )
  }

  return (
    <>
        <div className={classes.firstRowStyle}>
                <div className={classes.titleContainer}>
                    <div className={classes.R1End}>
                        {!isLoading && 
                            <DownloadPdf />
                        }
                    </div>
                </div>
        </div>
        {!isLoading && 
            <div className={classes.rowStyle}>
                <div className={classes.F1}>
                    {ContractDocument()}
                </div>
            </div>
        }
    </>
  );
}

export default withSnackbar(Contract);