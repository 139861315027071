import React from 'react';
import { Text, StyleSheet, Font } from '@react-pdf/renderer';
import * as R from 'ramda'
import setMoneyFormat from '../../../lib/moneyFormat'
import NunitoRegular from '../../../assets/fonts/Nunito-Regular.ttf'
import NunitoBold from '../../../assets/fonts/Nunito-ExtraBold.ttf'
import '../../../assets/css/main.css';

import theme from '../../../styles/theme.js';


const pdfStyles = StyleSheet.create({
    title: {
        ...theme.typography.mediumText,
        color: '#000000',
        textAlign: 'center',
        paddingBottom: 10,
        display: 'block',
        justifyContent: 'center',
        fontWeight: 'bold'
    },
    paragraphStyle: {
        width: '100%',
        textAlign: 'justify',
        paddingBottom: 20,
        display: 'block',
    },
    paragraphStyleNumeric: {
        width: '100%',
        textAlign: 'justify',
        paddingBottom: 5,
        display: 'block',
    },
    paragraphStyleNumericLast: {
        width: '100%',
        textAlign: 'justify',
        paddingBottom: 20,
        display: 'block',
    },
    paragraphStylePoint: {
        width: '100%',
        textAlign: 'justify',
        paddingBottom: 5,
        paddingLeft: 10,
        display: 'block',
    },
    paragraphStylePointLast: {
        width: '100%',
        textAlign: 'justify',
        paddingBottom: 20,
        paddingLeft: 10,
        display: 'block',
    },
    commonText: {
        ...theme.typography.extraSmallText,
        color: '#333333',
    },
    commonTextCenter: {
        ...theme.typography.extraSmallText,
        color: '#333333',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center'
    },
    boldText: {
        ...theme.typography.extraSmallText,
        color: '#333333',
        fontWeight: 'bold'
    }
  });

const availableTypes = {
    NORMAL: 'normal',
    NORMAL_CENTER: 'normalCenter',
    TITLE: 'title',
    SUB_TITLE: 'subTitle',
    NUMERIC_LIST: 'numericList',
    NUMERIC_LIST_LAST: 'numericListLast',
    POINT_LIST_COMMISSIONS: 'pointListCommissions',
    POINT_LIST_COMMISSIONS_LAST: 'pointListCommissionsLast'
}

export default function HtmlContract(props) {

  const paragraphs = R.path(['contractData', 'jsonTemplate', 'parrafos'], props) || [];

  const getValueFromData = (attribute, parent) => {
      const data = props.contractData
      if (parent) {
        const pAttributes = parent.split('.')
        const value = R.path(pAttributes, data.commissions) || {};
        if (attribute === '[simbol]') {
            if (value.type === 'fixed') {
                return '$'
            } else {
                return '%'
            }
        } else if (attribute === '[amount]') {
            if (value.type === 'fixed') {
                return setMoneyFormat(value.amount)
            } else {
                return value.amount
            }
        } else if (attribute === '[type]') {
            if (value.type === 'fixed') {
                return 'Monto Fijo'
            } else {
                return 'Porcentaje'
            }
        } else {
            return value[attribute.replace('[', '').replace(']', '')] || attribute
        }
      } else {
        return data[attribute.replace('[', '').replace(']', '')] || attribute
      }
  }

  const buildNormal = (value, type, parent) => {
    const wordsList = value.split('^')
    const element = wordsList.map((word, index) => {
        return (
            (word.indexOf('[') !== -1 && word.indexOf(']') !== -1)  ? 
                <Text style={pdfStyles.boldText}>
                    {getValueFromData(word, parent)}
                </Text>
            :
                <Text style={type === availableTypes.NORMAL ? pdfStyles.commonText : pdfStyles.commonTextCenter}>
                    {word}
                </Text>
        );
        });
        return element;
  }

  const buildTitle = (value) => {
    return (
        <Text style={pdfStyles.title}>
            {value}
        </Text>
    )
  }

  const buildParagraphsByType = (paragraph) => {
    const pType = paragraph.type
    if (pType === availableTypes.NORMAL || pType === availableTypes.NORMAL_CENTER) {
        return (
            <Text style={pdfStyles.paragraphStyle}>
                {buildNormal(paragraph.value, paragraph.type)}
            </Text>
        ) 
    } else if (pType === availableTypes.TITLE || pType === availableTypes.SUB_TITLE) {
        return (
            <Text style={pdfStyles.paragraphStyle}>
                {buildTitle(paragraph.value)}
            </Text>
        ) 
    } else if (pType === availableTypes.NUMERIC_LIST) {
        return (
            <Text style={pdfStyles.paragraphStyleNumeric}>
                {buildNormal(paragraph.value, availableTypes.NORMAL)}
            </Text>
        )
    } else if (pType === availableTypes.NUMERIC_LIST_LAST) {
        return (
            <Text style={pdfStyles.paragraphStyleNumericLast}>
                {buildNormal(paragraph.value, availableTypes.NORMAL)}
            </Text>
        )
    } else if (pType === availableTypes.POINT_LIST_COMMISSIONS) {
        return (
            <Text style={pdfStyles.paragraphStylePoint}>
                {buildNormal(paragraph.value, availableTypes.NORMAL, paragraph.parent)}
            </Text>
        )
    } else if (pType === availableTypes.POINT_LIST_COMMISSIONS_LAST) {
        return (
            <Text style={pdfStyles.paragraphStylePointLast}>
                {buildNormal(paragraph.value, availableTypes.NORMAL, paragraph.parent)}
            </Text>
        )
    } else {
        return null
    }
  }


return (
    <> 
        {paragraphs.map((paragraph) => (
                buildParagraphsByType(paragraph)
            ))
        }
    </>
)
}