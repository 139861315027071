import React from 'react';
import { useEffect, useState } from 'react';
import { withSnackbar } from 'notistack';
import { API } from 'aws-amplify';
import { API_EMIDAMEX } from '../../../lib/constants';
import {
  Input,
  Typography,
  AppBar,
  MenuItem,
  Menu,
  Modal,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MovementsStats from '../../common/movementsStats';
import PrimaryButton from '../../common/primaryButton';
import PrimarySearchBar from '../../common/primarySearchBar';
import PrimaryInput from '../../common/primaryInput';
import PrimarySelect from '../../common/primarySelect';
import DefaultModal from '../../modal';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment-timezone';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import removeAccents from '../../../lib/removeAccents';

const TransactionsView = ({ onViewChange, enqueueSnackbar }) => {

  moment.locale('es')

  const types = [{ label: 'Seleccione una opción' }, { type: 'recharge', label: 'Recarga celular' }, { type: 'service', label: 'Pago de servicios' }]
  const typesFilter = [{ type: 'all', label: 'Todos' }, { type: 'recharge', label: 'Recarga celular' }, { type: 'service', label: 'Pago de servicios' }]

  const classes = useStyles();

  const [selectedPage, setSelectedPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [transactions, setTransactions] = useState([])
  const [filterValues, setFilterValues] = useState([])
  const [showTransactions, setShowTransactions] = useState([]);
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [typeFilter, setTypeFilter] = useState('all');
  const [searchText, setSearchText] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [transactionSelected, setTransactionSelected] = useState([])
  const [loading, setLoading] = useState(true);
  const [checkBoxEnable, setCheckBoxEnable] = useState(false);

  const perPage = 10

  useEffect(() => {
    getTransactions();

  }, [])

  const handlePagination = (event, value) => {
    setSelectedPage(value)
  }

  const formatMoney = (number) => {
    const decimalIsZero = number => number.toString().split('.')[2] === '0';
    const numberFixed = parseFloat(number).toFixed(2);
    return decimalIsZero(numberFixed)
      ? `${parseFloat(numberFixed)}`
      : `${numberFixed}`;
  }

  const formatDate = (date, index) => {
    const cuteDate = date.substring(0, 10);
    const formatNewDate = moment(cuteDate).format('YYYY-MM-DD');
    return formatNewDate
  }

  const getTransactions = async () => {
    const responseRecharge = await API.get(
      API_EMIDAMEX,
      '/emidamex/globalActions/getAllTransactions', {
      queryStringParameters: {
        type: 'recharge'
      }
    })

    const responseService = await API.get(
      API_EMIDAMEX,
      '/emidamex/globalActions/getAllTransactions', {
      queryStringParameters: {
        type: 'service'
      }
    })

    let aux = [...responseRecharge.Items, ...responseService.Items]
    setTransactions(aux.sort((firstItem, secondItem) =>
      Date.parse(formatDate(secondItem.transactionDateTime)) - Date.parse(formatDate(firstItem.transactionDateTime))
    ))
    setPages(Math.ceil(aux.length / perPage))
    setLoading(false)
  }

  const filterRange = (from, dateTo, type) => {
    let transactionsFiltered = []
    if (dateTo === '') {
      transactionsFiltered = transactions.filter(transaction => formatDate(transaction.transactionDateTime) >= from)
    } else if (from === '') {
      transactionsFiltered = transactions.filter(transaction => formatDate(transaction.transactionDateTime) <= dateTo)
    } else if (from != '' && dateTo != '') {
      if (type != 'all') {
        transactionsFiltered = transactions.filter(transaction => (formatDate(transaction.transactionDateTime) >= from && formatDate(transaction.transactionDateTime) < dateTo) && (transaction.type === type))
      } else (
        transactionsFiltered = transactions.filter(transaction => formatDate(transaction.transactionDateTime) >= from && formatDate(transaction.transactionDateTime) < dateTo)
      )
    }
    if (type != 'all') {
      if (transactionsFiltered.length != 0) {
        transactionsFiltered = transactionsFiltered.filter(transaction => transaction.type === type)
      } else {
        transactionsFiltered = transactions.filter(transaction => transaction.type === type)
      }
    }
    setPages(Math.ceil(transactionsFiltered.length / perPage))
    return transactionsFiltered
  }

  const handleChange = (e) => {
    if (e.target.name === 'depositDateFrom') {
      setDateFrom(e.target.value)
      setShowTransactions(filterRange(e.target.value, dateTo, typeFilter))
    } if (e.target.name === 'depositDateTo') {
      if (e.target.value > dateFrom) {
        setDateTo(e.target.value)
        setShowTransactions(filterRange(dateFrom, e.target.value, typeFilter))
      } else {
        handleAlert('error', 'Fecha menor a la de inicio');
      }
    } else if (e.target.name === 'typeFilter') {
      setTypeFilter(e.target.value)
      setShowTransactions(filterRange(dateFrom, dateTo, e.target.value))

    } else if (e.target.name === 'searchBar') {
      setSearchText(e.target.value)
      filterText(e.target.value)
      setPages(Math.ceil(showTransactions.length / perPage))
    }
  }

  const filterText = (text) => {
    if (text === '') {
      setSearchText(null)
      setShowTransactions(filterRange(dateFrom, dateTo, typeFilter))
    } else {
      const searchText = removeAccents(text).toLowerCase();
      if (showTransactions.length != 0) {
        setShowTransactions(showTransactions.filter(transaction => {
          for (const property in transaction) {
            if (removeAccents(`${transaction[property]}`).toLowerCase().includes(searchText)) {
              return transaction
            }
          }
        }))
      } else {
        setShowTransactions(transactions.filter(transaction => {
          for (const property in transaction) {
            if (removeAccents(`${transaction[property]}`).toLowerCase().includes(searchText)) {
              return transaction
            }
          }
        }))
      }
    }
  }

  const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };

  const handleOpen = (transaction) => {
    setOpenModal(true)
    setTransactionSelected(transaction)
  }


  return (
    <>
      {openModal &&
        <DefaultModal
          open={openModal}
          modalType='transaction'
          transaction={transactionSelected}
          handleClose={(e) =>
            setOpenModal(!openModal)
          }
        />
      }
      <div className={classes.tableContainer}>
        <div className={classes.titelButtonContainer}>
          <Typography className={classes.title}>
            Transacciones
          </Typography>
          <PrimaryButton
            onClick={() => onViewChange(0)}>
            Regresar
          </PrimaryButton>
        </div>
        <div className={classes.filterOptions}>
          <div className={classes.filter}>
            <Typography className={classes.otherLabel}>Fecha de inicio</Typography>
            <PrimaryInput
              value={dateFrom}
              type='date'
              onChange={handleChange}
              id={'depositDateFrom'}
              name={'depositDateFrom'}>
            </PrimaryInput>
          </div>
          <div className={classes.filter}>
          <div className={classes.titleFilter}>
            <Checkbox
              icon={<CircleUnchecked />}
              checkedIcon={<CircleChecked />}
              checked={checkBoxEnable}
              onChange={() => setCheckBoxEnable(!checkBoxEnable)}
              color="rgba(67, 67, 67, 0.6)"
              style={{padding:'0', paddingRight:'5px'}}
            />
            <Typography className={classes.inputTitle}>Fecha de fin</Typography>
          </div>
            <PrimaryInput
              value={dateTo}
              type='date'
              disabled={checkBoxEnable ? false : true}
              onChange={handleChange}
              id={'depositDateTo'}
              name={'depositDateTo'}>
            </PrimaryInput>
          </div>
          <div className={classes.filter}>
            <Typography className={classes.otherLabel}>Tipo de transacción</Typography>
            <PrimarySelect
              value={typeFilter}
              content={typesFilter.map((item, index) => {
                return <option key={index} value={item.type}>{item.label}</option>;
              })}
              onChange={handleChange}
              id={'typeFilter'}
              name={'typeFilter'}
            >
            </PrimarySelect>
          </div>
          <div className={classes.searchBar}>
            <PrimarySearchBar
              name='searchBar'
              onChange={e => handleChange(e)}
            />
          </div>
        </div>
        <div className={classes.tableContainer}>
          {loading ?
            <div className={classes.loading}>
              <CircularProgress />
            </div>
            :
            <>
              <table className={classes.table}>
                <thead>
                  <tr className={classes.tableHeader}>
                    <th className={classes.tableHeader}>Fecha de transacción</th>
                    <th className={classes.tableHeader}>ID</th>
                    <th className={classes.tableHeader}>Nombre del usuario</th>
                    <th className={classes.tableHeader}>No. referencia/Teléfono</th>
                    <th className={classes.tableHeader}>Monto</th>
                    <th className={classes.tableHeader}>Comisión</th>
                    <th className={classes.tableHeader}>Tipo de transacción</th>
                    <th className={classes.tableHeader}>Total</th>
                    <th className={classes.tableHeader}>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {showTransactions.length === 0 ? transactions.slice(((selectedPage - 1) * perPage), (((selectedPage - 1) * perPage) + perPage)).map((transaction) => (
                    <tr onClick={() => handleOpen(transaction)}>
                      <td className={classes.tableRegister}>{transaction.transactionDateTime}</td>
                      <td className={classes.tableRegister}>{transaction.userRecordKey}</td>
                      <td className={classes.tableRegister}>{transaction.nameUser}</td>
                      <td className={classes.tableRegister}>{transaction.accountId}</td>
                      <td className={classes.tableRegister}>${transaction.totalPay ? formatMoney(transaction.amount) : 0}</td>
                      <td className={classes.tableRegister}>${transaction.totalPay ? formatMoney(transaction.productFee) : 0}</td>
                      <td className={classes.tableRegister}>{transaction.type === 'recharge' ? 'Recarga celular' : 'Pago de servico'}</td>
                      <td className={classes.tableRegister}>${transaction.totalPay ? formatMoney(transaction.totalPay) : 0}</td>
                      <td className={classes.tableRegister}>{transaction.status}</td>
                    </tr>
                  )) : showTransactions.slice(((selectedPage - 1) * perPage), (((selectedPage - 1) * perPage) + perPage)).map((transaction) => (
                    <tr onClick={() => handleOpen(transaction)}>
                      <td className={classes.tableRegister}>{transaction.transactionDateTime}</td>
                      <td className={classes.tableRegister}>{transaction.userRecordKey}</td>
                      <td className={classes.tableRegister}>{transaction.nameUser}</td>
                      <td className={classes.tableRegister}>{transaction.accountId}</td>
                      <td className={classes.tableRegister}>${transaction.totalPay ? formatMoney(transaction.amount) : 0}</td>
                      <td className={classes.tableRegister}>${transaction.totalPay ? formatMoney(transaction.productFee) : 0}</td>
                      <td className={classes.tableRegister}>{transaction.type === 'recharge' ? 'Recarga celular' : 'Pago de servico'}</td>
                      <td className={classes.tableRegister}>${transaction.totalPay ? formatMoney(transaction.totalPay) : 0}</td>
                      <td className={classes.tableRegister}>{transaction.status}</td>
                    </tr>
                  ))
                  }
                </tbody>
              </table>
            </>
          }
          <div className={classes.pagination}>
            <Pagination count={pages} shape="rounded" onChange={handlePagination} />
          </div>
        </div>
      </div>
    </>
  )

}

const useStyles = makeStyles(theme => ({

  balanceContainer: {
    justifyContent: 'space-between',
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '40%'
  },
  inputsButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '30%'
  },
  depositButton: {
    paddingTop: '50px',
    width: '100%',

  },
  transactionsButton: {
    paddingTop: '50px',
    width: '100%',
  },
  movementsStatsContainer: {
    height: '30%',
    marginBottom: '10px'
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '50%',
      minWidth: '100%',
      minHeight: '50%'
    },
  },
  tableContainer: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    alignItems: 'flex-start',
    width: '100%',
  },
  tableHeader: {
    alignItems: 'flex-start',
  },
  tableHeader: {
    padding: '10px'
  },
  tableRegister: {
    ...theme.typography.SUBtitle,
    textaAlign: 'left',
    paddingBottom: '10px',
    paddingTop: '10px',
    [theme.breakpoints.down('md')]: {
      paddingRight: '20px'
    },
  },
  pagination: {
    marginTop: '15px',
    widht: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '10px'
  },
  titelButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    ...theme.typography.title
  },
  filterOptions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-arround',
    width: '100%',
    alignItems: 'flex-end',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  filter: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  searchBar: {
    width: '100%',
  },
  loading: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  titleFilter:{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },

}));

export default withSnackbar(TransactionsView);
