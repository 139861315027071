const getUser = async (userRecordKey) => {
    try {
  
      const result = await fetch(`${process.env.REACT_APP_USER_API}/analytics/getUser?userRecordKey=${userRecordKey}`, {
          method: "GET",
          headers:{
              'x-api-key': process.env.REACT_APP_USER_API_KEY,
              "Content-Type": "application/json"
          }
      })
      const res = await result.json()
  
      if(res.success){
          return {
            success: true,
            data: res.data
          }
        }
        else {
          return {
            success: false,
            msg: 'Algo salio mal'
          }
      }
    } catch (err) {
      return {
        success: false,
        error: 'Algo salio mal',
      }
    }
  }
  
  export default getUser;