import React from 'react';
import { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import DefaultInput from '../../common/defaultInput';
import DefaultButton from '../../common/defaultButton'
import { API } from 'aws-amplify';
import { API_SETTING } from '../../../lib/constants';
import { withSnackbar } from 'notistack';
import CheckValue from '../../../lib/formatValidations';
import {FONT_DEFAULT} from '../../../lib/constants.js'
import sortBy from '../../../lib/sortBy';

import '../../../assets/css/main.css';
import solicitudes from '../../../assets/images/solicitudes.svg'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flex: 1,
    backgroundColor: '#F6F6F6',
    padding: '0px 30px 0px 30px',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  tableContainer: {
    border: '1px solid #E0E0E0',
    borderRadius: 5,
    marginTop: 0,
    marginBottom: 20,
    backgroundColor: '#FFFFFF',
    padding: 20,
  },
  title: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 24,
    marginLeft: 13
  },
  tableHeaderText: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid #BDBDBD',
    padding: 20,
    borderBottom: '1px solid #BDBDBD',
  },
  tableRowText: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  servicesHeader: {
      width: '100%',
      display:'flex',
      flexDirection: 'row',
      alignItems: 'center',
      textAlign: 'center',
      padding: 0,
      marginTop: 30,
      marginBottom: 30,
  },
  tableSeparator: {
    borderBottom: '1px solid #E0E0E0',
  },
});

const Services = (props) => {
  useEffect(() => {
    callApi();
  }, [])

  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [conektaIndex, setConektaIndex] = useState(-1);

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const callApi = async () => {
    try {
      setLoading(true)
      const response = (await API.get(API_SETTING, `/settings/getall/services`,{
        queryStringParameters: {
          all: true
        },
      })).Items;
      const sortByName = sortBy(response, 'ASC', 'name')
      const filterBoolean = sortByName.filter(item => (typeof item.setup) === 'boolean')
      const filterOtherTypes = sortByName.filter(item => (typeof item.setup) !== 'boolean')
      const servicesTemp = [...filterBoolean, ...filterOtherTypes];
      const conektaIndexTemp = servicesTemp.findIndex(item => item.settingRecordKey === 'service_conekta_commissions');
      setServices(servicesTemp);
      setConektaIndex(conektaIndexTemp);
      setLoading(false)
    } catch (err) {
      setLoading(false)
      handleAlert('error', 'Error al cargar los servicios')
    }
  }

  const changeServiceVaule = index => {
    const servicesTemp = [...services];
    const value = servicesTemp[index].setup;
    servicesTemp[index].setup = !value;
    servicesTemp[index].updated = true;
    setServices(servicesTemp)
  }

  const changeTextServiceSetup = (value, index) => {
    const servicesTemp = [...services]
    if (CheckValue('money', value)) {
      servicesTemp[index].setup = value;
      servicesTemp[index].updated = true;
      servicesTemp[index].fixable = true;
    }
    else if (CheckValue('money', `${value}0`)) {
      servicesTemp[index].setup = value;
      servicesTemp[index].updated = true;
      servicesTemp[index].fixable = true;

    }
    else if (
      value.slice(0, -1).indexOf('.') === -1 &&
      value.slice(-1) === '.' &&
      CheckValue('money', value.slice(0, -1))) {
      servicesTemp[index].setup = value;
      servicesTemp[index].updated = true;
      servicesTemp[index].fixable = false;
    }
    else if (value === '') {
      servicesTemp[index].setup = value;
      servicesTemp[index].updated = true;
      servicesTemp[index].fixable = true;
    }
    setServices(servicesTemp)
  }

  const changeConektaSetting = (value, service, type) => {
    const conektaTemp = {...services[conektaIndex]};
    if (!isNaN(value)) {
      const newValue = value.replace(/(\.\d{2})\d+/g, '$1');
      conektaTemp['setup'][service][type] = newValue;
      conektaTemp['updated'] = true;
      const servicesTemp = [...services];
      servicesTemp[conektaIndex] = conektaTemp;
      setServices(servicesTemp);
    }
  };

  const buildServices = () => {
    const servicesTemp = [...services];
    const conektaObj = servicesTemp.splice(conektaIndex, 1)[0];

    const elements = servicesTemp.map((service, index) => (
      <tr key={index}>
        <td>
          <Typography className={classes.tableRowText}>{`${service.name}`}</Typography>
        </td>
        <td>
          {typeof service.setup !== 'boolean'?
            <DefaultInput custom={{
              handleChange: (e) => changeTextServiceSetup(e.target.value, index),
              value: service.setup,
              error: service.error,
            }} />
            :
            <Checkbox
              onChange={() => changeServiceVaule(index)}
              icon={<CircleUnchecked />}
              checkedIcon={<CircleChecked />}
              checked={service.setup}
              color="rgba(67, 67, 67, 0.6)"
            />
          }
        </td>
      </tr>
    ));

    return (
      <>
        {elements}
        {conektaObj && (
          <>
            <tr>
              <td><div className={classes.tableSeparator} /></td>
              <td><div className={classes.tableSeparator} /></td>
            </tr>
            <tr>
              <td><Typography className={classes.title} gutterBottom>{conektaObj.name}</Typography></td>
            </tr>
            <tr>
              <td>
                <Typography className={classes.tableRowText}>Comisión Fija (Conekta)</Typography>
              </td>
              <DefaultInput custom={{
                handleChange: (e) => changeConektaSetting(e.target.value, 'conekta', 'fixed'),
                value: conektaObj.setup.conekta.fixed,
              }} />
            </tr>
            <tr>
              <td>
                <Typography className={classes.tableRowText}>Porcentaje Comisión (Conekta)</Typography>
              </td>
              <DefaultInput custom={{
                handleChange: (e) => changeConektaSetting(e.target.value, 'conekta', 'percent'),
                value: conektaObj.setup.conekta.percent,
              }} />
            </tr>
            <tr>
              <td>
                <Typography className={classes.tableRowText}>Porcentaje IVA (Conekta)</Typography>
              </td>
              <DefaultInput custom={{
                handleChange: (e) => changeConektaSetting(changeConektaSetting(e.target.value, 'conekta', 'ivaPercent')),
                value: conektaObj.setup.conekta.ivaPercent,
              }} />
            </tr>
            <tr>
              <td>
                <Typography className={classes.tableRowText}>Comisión Fija (Nerito)</Typography>
              </td>
              <DefaultInput custom={{
                handleChange: (e) => changeConektaSetting(e.target.value, 'nerito', 'fixed'),
                value: conektaObj.setup.nerito.fixed,
              }} />
            </tr>
            <tr>
              <td>
                <Typography className={classes.tableRowText}>Porcentaje Comisión (Nerito)</Typography>
              </td>
              <DefaultInput custom={{
                handleChange: (e) => changeConektaSetting(e.target.value, 'nerito', 'percent'),
                value: conektaObj.setup.nerito.percent,
              }} />
            </tr>
            <tr>
              <td>
                <Typography className={classes.tableRowText}>Porcentaje IVA (Nerito)</Typography>
              </td>
              <DefaultInput custom={{
                handleChange: (e) => changeConektaSetting(e.target.value, 'nerito', 'ivaPercent'),
                value: conektaObj.setup.nerito.ivaPercent,
              }} />
            </tr>
          </>
        )}
      </>
    );
  }

  const saveSettings = async () => {
    try {
      setLoading(true);
      const updatedValues = services.filter(service => service.updated && !service.error)
      if (updatedValues.length > 0) {
        const saveAll = updatedValues.map(service => {
          const {
            name,
            setup,
            settingRecordKey,
            fixable,
          } = service;

          if (service.settingRecordKey === 'service_conekta_commissions') {
            const newSetup = {
              conekta: {
                fixed: Number(service.setup.conekta.fixed),
                percent: Number(service.setup.conekta.percent),
                ivaPercent: Number(service.setup.conekta.ivaPercent),
              },
              nerito: {
                fixed: Number(service.setup.nerito.fixed),
                percent: Number(service.setup.nerito.percent),
                ivaPercent: Number(service.setup.nerito.ivaPercent),
              },
            };
            return API.put(API_SETTING, `/settings/${settingRecordKey}`,
              {
                body: {
                  name,
                  setup: newSetup,
                  type: 'service'
                },
              }
            );
          } else {
            const newSetup = typeof setup === 'string' && (fixable ?
              parseInt(setup).toFixed(2) :
              parseInt(setup.slice(0, -1)).toFixed(2))

            return API.put(API_SETTING, `/settings/${settingRecordKey}`,
              {
                body: {
                  name,
                  setup: newSetup || setup,
                  type: 'service'
                },
              });
          };
        });
        await Promise.all(saveAll);
        setLoading(false)
        handleAlert('success', 'Servicios guardados correctamente')
      }
      else {
        setLoading(false)
        handleAlert('error', 'Favor de cambiar al menos uno de los servicios para guardar')
      }
    } catch (err) {
      setLoading(false)
      handleAlert('error', 'Favor de guardar los servicios')
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.servicesHeader}>
        <Typography className={classes.title}>Servicios</Typography>
      </div>
      {isLoading ?
        <div className='gralinfo_form'>
          <CircularProgress color="secondary" />
        </div>
        :
        <>
          <div className={classes.tableContainer}>
            <table className="table mt-4">
              <thead>
                <tr>
                  <th>
                    <Typography className={classes.tableHeaderText}>Servicio</Typography>
                  </th>
                  <th >
                    <Typography className={classes.tableHeaderText}>Valor</Typography>
                  </th>
                </tr>
              </thead>
              <tbody>
                {buildServices()}
              </tbody>
            </table>
            <div className="d-flex justify-content-end">
              <DefaultButton
                handleAction={() => saveSettings()}
                buttonText={"Guardar"}
              />
            </div>
          </div>
        </>
      }
    </div>
  );
}

export default withSnackbar(Services);
