import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import DefaultInput from '../../common/defaultInput';
import { ABBREVIATED_DAYS, SCHEDULE_CARD_COLOR } from '../../../lib/constants'
import garbage from '../../../assets/images/garbage.svg'
import PrimaryInput from '../primaryInput';
import Grid from '@material-ui/core/Grid';


import '../../../assets/css/main.css';

const useStyles = makeStyles(theme => ({
  schedulesTitle: {
    ...theme.typography.mediumText,
    textAlign: 'center'
  },
  schedulesSubtitle: {
    ...theme.typography.smallText,
    paddingLeft: 10,
  },
  scheduleContainer: {
    backgroundColor: '#EDEDED',
    borderRadius: 10,
    paddingLeft: 10,
  },
  removeIcon: {
    marginLeft: 10,
    cursor: 'pointer'
  },
  rowStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rowStyleDays: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: -10,
  },
  rowStyleHours: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
    paddingRight: 10,
  },
  iconContainer: {
    backgroundColor: '#C4C4C4',
    padding: '5px',
    borderRadius: 100,
    width:30,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: '#C4C4C4',
    borderWidth: 2,
    border: 'solid',
  },
  checkedIconContainer:{ 
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
    border: 'solid',
    padding: '5px',
    borderRadius: 100,
    width: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    ...theme.typography.smallText,
    color: theme.palette.gray.main,
    textAlign: 'center'
  },
  checkedIcon: {
    ...theme.typography.smallText,
    color: theme.palette.white.main,
    textAlign: 'center'
  }
}));

export default function DefaultSchedule(props) {
  const classes = useStyles();
  const { allDays, data, position, remove, updateSchedule, scheduleFromError, scheduleToError, inModal } = props

  const isDisabled = (day, position) => {
    let disabled = false
    allDays.forEach((element, index) => {
      if (element.days && element.days.indexOf(day) !== -1 && index !== position) {
        disabled = true
      }
    });
    return disabled
  }

  const renderIcons = (day) => {
    return (
      <div className={classes.iconContainer}>
        <Typography className={classes.icon}>{day}</Typography>
      </div>
    )
  }

  const renderCheckedIcons = (day) => {
    return (
      <div className={classes.checkedIconContainer}>
        <Typography className={classes.checkedIcon}>{day}</Typography>
      </div>
    )
  }

  return (
    <div className={`mt-2 ${classes.scheduleContainer}`}>
      <div className='mt-1 align-self-center'>
        <Typography className={classes.schedulesTitle}>
          Horario
        </Typography>
        <Typography className={classes.schedulesSubtitle}>
          Días de Servicio:
        </Typography>
        <div className={classes.rowStyleDays}>
          {ABBREVIATED_DAYS.map(day =>
            <FormControlLabel
              control={
                <Checkbox
                  icon={renderIcons(day)}
                  checkedIcon={renderCheckedIcons(day)}
                  checked={data.days && data.days.indexOf(day) !== -1}
                  onClick={() => updateSchedule('days', day, position)}
                  color="#434343"
                  disabled={isDisabled(day, position)}
                />
              }
              className="weekCheckbox"
            />
          )}
          {position > 0 &&
            <img className={classes.removeIcon} src={garbage} alt="Eliminar" onClick={() => remove(false, position)} />
          }
        </div>
      </div>
      <div className={classes.rowStyleHours}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <div className={classes.rowStyle}>
              <Typography className={classes.schedulesSubtitle}>
                Abre
              </Typography>
              <PrimaryInput
                onChange={(e) => updateSchedule('start', e.target.value, position)}
                value={data.start}
                id={"scheduleFrom_" + position}
                type="time"
                name={"scheduleFrom_" + position}
                error={scheduleFromError[position]}
                paddingTop={'none'}
                paddingBottom={'none'}
                paddingLeft={10}
                paddingRight={10}
                color={'#C4C4C4'}
                marginTop={0}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.rowStyle}>
              <Typography className={classes.schedulesSubtitle}>
                Cierra
              </Typography>
              <PrimaryInput
                onChange={(e) => updateSchedule('end', e.target.value, position)}
                value={data.end}
                type="time"
                name={"scheduleFrom_" + position}
                error={scheduleToError[position]}
                paddingTop={'none'}
                paddingBottom={'none'}
                paddingLeft={10}
                paddingRight={10}
                color={'#C4C4C4'}
                marginTop={0}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </ div>
  );
}