import React from 'react';
import { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';

import '../../../assets/css/main.css';

export default function DefaultInput(props) {
  useEffect(()=>{
  },[])

  return (
    <TextField
      id={props.custom.id}
      className={props.custom.className}
      label={props.custom.labelText}
      type={props.custom.type}
      name={props.custom.name}
      value={props.custom.value}
      onChange={props.custom.handleChange}
      placeholder={props.custom.placeholder}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: props.custom.classLabel
        }
      }}
      helperText={props.custom.error}
      inputProps={props.custom.maxLength ? { maxLength: props.custom.maxLength } : (props.custom.min && props.custom.max ) && { min: props.custom.min, max:props.custom.max }}
    />
  );
}