import React from "react";
import { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Typography } from "@material-ui/core";
import { API } from "aws-amplify";
import Pagination from "@material-ui/lab/Pagination";
import TextField from "@material-ui/core/TextField";
import moment from "moment-timezone";
import Button from "@material-ui/core/Button";
import csvtojson from "csvtojson";

import "../../../assets/css/main.css";
import iconUpload from "../../../assets/images/upload.svg";
import noMovements from "../../../assets/images/no_movements.svg";
import DeafultSearch from "../../common/deafultSearch";
import { FONT_DEFAULT } from "../../../lib/constants.js";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flex: 1,
    marginTop: 0,
    backgroundColor: "#F6F6F6",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  container: {
    flex: 8,
    marginLeft: 30,
    marginRight: 40,
    marginBottom: 40,
  },
  titleConteiner: {
    padding: 10,
    marginTop: 20,
    marginBottom: 30,
  },
  transactionsConteiner: {
    backgroundColor: "#FFFFFF",
    padding: "40px 50px 50px 40px",
    borderRadius: 5,
    border: "1px solid #E0E0E0",
  },
  tableContainer: {
    display: "flex",
    marginLeft: 0,
    marginRight: 0,
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: "#F9F9F9",
    borderRadius: 5,
    flexDirection: "row",
  },
  buttonContainer: {
    display: "flex",
    marginBottom: 15,
  },
  tableGridContainer: {
    width: "calc(100vw - 87px)",
    width: "100%",
    marginLeft: 0,
    marginRight: 0,
    marginTop: 30,
    marginBottom: 20,
  },
  actionButonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    height: "100%",
    alignItems: "center",
  },
  actionButon1: {
    cursor: "pointer",
    marginRight: 10,
  },
  actionButon2: {
    cursor: "pointer",
    marginLeft: 10,
  },
  tableHeaderText: {
    ...FONT_DEFAULT,
    fontStyle: "Bold",
    fontWeight: "Bold",
    fontSize: 20,
    display: "flex",
    alignItems: "center",

    height: 60,
    justifyContent: "justify",
  },
  tableRowText: {
    ...FONT_DEFAULT,
    fontStyle: "Bold",
    fontWeight: "Bold",
    fontSize: 14,
    paddingTop: 15,
    paddingBottom: 15,
    display: "flex",
    alignItems: "center",
    height: 10,
    textAlign: "right",
    justifyContent: "right",
  },
  tableRowText1: {
    ...FONT_DEFAULT,
    fontStyle: "Bold",
    fontWeight: "Bold",
    fontSize: 14,
    paddingTop: 15,
    paddingBottom: 15,
    display: "flex",
    alignItems: "center",
    height: 3,
    textAlign: "right",
    justifyContent: "center",
    border: "1px solid #EDEDED",
    boxSizing: "border-box",
    borderRadius: 50,
  },
  tableRowNumber: {
    ...FONT_DEFAULT,
    fontStyle: "Bold",
    fontWeight: "Bold",
    fontSize: 14,
    paddingTop: 15,
    paddingBottom: 15,
    display: "flex",
    alignItems: "center",
    height: 10,
    textAlign: "right",
    justifyContent: "right",
  },
  buttonStyle: {
    border: "1px solid #3B9A83",
    boxSizing: "border-box",
    borderRadius: 5,
    width: 146,
    height: 38,
    margin: 2,
  },
  buttonText: {
    ...FONT_DEFAULT,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 10,
    color: "#3B9A83",
  },
  plusIcon: {
    marginRight: 5,
  },
  paper: {
    position: "absolute",
    width: 497,
    height: "auto",
    backgroundColor: "#F9F9F9",
    boxShadow: "0 4 14 rgba(0, 0, 0, 0.15)",
    borderRadius: 5,
    textAlign: "center",
  },
  mainContainerRegister: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "white",
    borderRadius: 5,
  },
  modalButtons: {
    marginBottom: 20,
    marginTop: 20,
  },
  backgroundOddColumn: {
    backgroundColor: "#FFFFF",
  },
  totalsLabel: {
    ...FONT_DEFAULT,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 12,
    textAlign: "center",
    color: "rgba(67, 67, 67, 0.6)",
    textAlign: "left",
  },
  totalsValue: {
    ...FONT_DEFAULT,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 18,
    textAlign: "center",
    color: "#434343",
    textAlign: "left",
  },
  totalsValueAlt: {
    ...FONT_DEFAULT,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    textAlign: "center",
    color: "#434343",
    marginLeft: 5,
  },
  newDepositsButtonStyle: {
    border: "1px solid #3B9A83",
    boxSizing: "border-box",
    borderRadius: 5,
    width: 204,
    height: 40,
    marginLeft: 6,
  },
  successButtonText: {
    ...FONT_DEFAULT,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 10,
    color: "#3B9A83",
  },
  backgrounOddColumn: {
    backgroundColor: "#ffff",
    padding: 5,
  },
  backgrounColumn: {
    backgroundColor: "#FFFF",
    padding: 5,
  },
  formDate: {
    marginLeft: 15,
    marginRight: 10,
    width: "45%",
  },
  depositButtonText: {
    ...FONT_DEFAULT,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 10,
    color: "#3B9A83",
    marginLeft: 20,
  },
  margin20: {
    marginBottom: 20,
    border: "1px solid #BDBDBD",
    borderRadius: 5,
    padding: "20px 0px",
    backgroundColor: "#44A38B24",
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
    shadowColor: 'rgba(0,0,0,15)',
  
  },
  title: {
    ...FONT_DEFAULT,
    marginTop: 0,
    marginBottom: 0,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 24,
    color: "#434343",
  },
  statusDone: {
    ...FONT_DEFAULT,
    fontStyle: "bold",
    fontWeight: "bold",
    fontSize: 14,
    display: "flex",
    alignItems: "center",
    color: "#44A38B",
  },
  statusPending: {
    ...FONT_DEFAULT,
    fontStyle: "bold",
    fontWeight: "bold",
    fontSize: 14,
    color: "#FFD400",
  },
  errorContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 50,
    marginbottom: 80,
  },
  errorText: {
    ...FONT_DEFAULT,
    fontSize: 16,
    fontWeight: 700,
    color: "#434343",
    marginTop: 20,
  },
  errorText2: {
    ...FONT_DEFAULT,
    fontSize: 14,
    fontWeight: 600,
    color: "#434343",
    opacity: "0.6",
  },
  paginationTAB: {
    widht: "100%",
    display: "flex",
    justifyContent: "center",
  },
});

const initialFromDate = `${moment().format("YYYY-MM-DD")}T00:00`;
const initialToDate = `${moment().format("YYYY-MM-DD")}T23:59`;
const perPage = 10;

function Transactions(props) {
  useEffect(() => {
    callApi(initialFromDate, initialToDate);
  }, []);

  const classes = useStyles();

  const [isLoading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [searchedWord, setSearchedWord] = useState("");
  const [fromDate, setFromDate] = useState(initialFromDate);
  const [toDate, setToDate] = useState(initialToDate);
  const [selectedPage, setSelectedPage] = useState(1);
  const [pages, setPages] = useState(0);

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const getTransactions = async (from, to, type) => {
    try {
      const getTransactions = await API.get("transaction-api", "/transaction", {
        queryStringParameters: {
          type,
          startDate: from,
          endDate: to,
        },
      });
      return getTransactions;
    } catch (err) {
      handleAlert("error", err.response.data.error);
      return null;
    }
  };

  const callApi = async (from, to) => {
    const timeStampFrom = moment.utc(from).valueOf();
    const timeStampTo = moment.utc(to).valueOf();
    const type = "funds";
    if (timeStampFrom < timeStampTo) {
      setLoading(true);
      try {
        const getTrans = getTransactions(timeStampFrom, timeStampTo, type);
        const trans = await Promise.resolve(getTrans);
        if (trans.length > 0) {
          setPages(Math.ceil(trans.length / perPage));
          setTransactions(trans);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        handleAlert("error", err);
        setLoading(false);
      }
    } else {
      handleAlert('error', 'La fecha inicio debe ser menor a la fecha de fin')
    }
  };

  const handleChange = (event) => {
    console.log("event->", event);
    const { value, name } = event.target;
    switch (name) {
      case "fromDate":
        setFromDate(value);
        callApi(value, toDate);
        break;
      case "toDate":
        setToDate(value);
        callApi(fromDate, value);
        break;
      case "searchedWord":
        setSearchedWord(value);
        break;
      default:
        handleAlert("error", `Input no válido ${name}`);
    }
  };

  const handlePagination = (event, value) => {
    setSelectedPage(value);
  };

  const setMoneyFormat = (number) => {
    if (number != "N/A") {
      return "$ " + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return number;
    }
  };

  const AttachmentFile = () => {
    const inputRef = useRef(null);
    return (
      <>
        <input
          type="file"
          ref={inputRef}
          hidden
          accept=".csv"
          onChange={async (event) => {
            try {
              setLoading(true);
              const [file] = event.target.files;

              const fileText = await file.text();

              const json = await csvtojson().fromString(fileText);

              const orders = json.map(
                ({ id_orden, estatus, mensaje_error }) => ({
                  conektaOrderRecordKey: id_orden,
                  conektaStatus: estatus,
                  conektaErrorMessage: mensaje_error,
                })
              );

              const response = await API.put(
                "funds-api",
                "/funds/update/orders",
                {
                  body: {
                    orders: orders,
                  },
                }
              );
            } catch (error) {
              console.error('error:', error);
              handleAlert('error', 'Ocurrió un error al cargar el documento');
            } finally {
              setLoading(false);
            }
          }}
        />
        <Button
          className={classes.newDepositsButtonStyle}
          onClick={event => {
            event.preventDefault();
            inputRef.current.click();
          }}
        >
          <img width="18" height="20.88" src={iconUpload} alt=""/>
          <span className={classes.depositButtonText}>ACTUALIZAR DEPÓSITOS</span>
        </Button>
      </>
    );
  };

  const buildstatus = (status) => {
    if (status === "funds_deposit_done") {
      return <Typography className={classes.statusDone}>Acreditado</Typography>;
    } else {
      return (
        <Typography className={classes.statusPending}>Por Acreditar</Typography>
      );
    }
  };

  return (
    <div className={classes.root}>
      {isLoading ? (
        <div className="gralinfo_form">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <div className={classes.container}>
          <div className={classes.titleConteiner}>
            <Typography className={classes.title}>
              Transacciones con Conekta
            </Typography>
          </div>
          <div className={classes.transactionsConteiner}>
            <div className="row">
              <div className="col-5 col-sm-5 col-md-5 col-lg-5 col-xl-5">
                <div className={`row ${classes.margin20}`}>
                  <form className={`${classes.formDate} dateInput`} noValidate>
                    <TextField
                      id="datetime-from"
                      name="fromDate" 
                      
                      value={fromDate}
                      onChange={handleChange}
                      label="Desde"
                      type="datetime-local"
                      defaultValue="2017-05-24T10:30"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </form>
                  <form className={`${classes.formDate} dateInput`} noValidate>
                    <TextField
                      id="datetime-to"
                      name="toDate"
                      value={toDate}
                      onChange={handleChange}
                      label="Hasta"
                      type="datetime-local"
                      defaultValue="2017-05-24T10:30"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </form>
                </div>
              </div>
              <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 justify-content-end">
                <AttachmentFile />
              </div>
              <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 justify-content-start">
            
                <DeafultSearch
                  className="outlinedSearchSmall"
                  label="Buscar por usuario, estado"
                  name="searchedWord"
                  value={searchedWord}
                  handleChange={handleChange}
                />
              </div>
            </div>
            {transactions.length > 0 ? (
              <div className="mt-3 overflow-auto">
                <table className="table">
                  <thead>
                    <tr>
                      <th>
                        <Typography className={classes.tableHeaderText}>
                          Usuario
                        </Typography>
                      </th>
                      <th>
                        <Typography className={classes.tableHeaderText}>
                          Monto
                        </Typography>
                      </th>
                      <th>
                        <Typography className={classes.tableHeaderText}>
                          Comisión
                        </Typography>
                      </th>
                      <th>
                        <Typography className={classes.tableHeaderText}>
                          Saldo
                        </Typography>
                      </th>

                      <th>
                        <Typography className={classes.tableHeaderText}>
                          Fecha y Hora (Hora Centro)
                        </Typography>
                      </th>
                      <th>
                        <Typography className={classes.tableHeaderText}>
                          Estado
                        </Typography>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactions
                      .slice(
                        (selectedPage - 1) * perPage,
                        (selectedPage - 1) * perPage + perPage
                      )
                      .map(
                        (user) =>
                          (searchedWord.length < 1 ||
                            JSON.stringify(user.namePay)
                              .toUpperCase()
                              .indexOf(searchedWord.toUpperCase()) !== -1) && (
                            <tr key={user.userRecordKey} item lg={12}>
                              <td>
                                <Typography className={classes.tableRowText}>
                                  {user.namePay}
                                </Typography>
                              </td>
                              <td className={classes.backgrounOddColumn}>
                                <Typography className={classes.tableRowNumber}>
                                  {setMoneyFormat(user.amount.toFixed(2))}
                                </Typography>
                              </td>
                              <td>
                                <Typography className={classes.tableRowNumber}>
                                  {setMoneyFormat(user.commission.toFixed(2))}
                                </Typography>
                              </td>
                              <td className={classes.backgrounOddColumn}>
                                <Typography className={classes.tableRowNumber}>
                                  {setMoneyFormat(user.newBalance.toFixed(2))}
                                </Typography>
                              </td>

                              <td className={classes.backgrounOddColumn}>
                                <Typography className={classes.tableRowText}>
                                  {moment(user.createdAt).format(
                                    "DD/MM/YYYY h:mm:ss a"
                                  )}
                                </Typography>
                              </td>
                              <td>
                                <Typography className={classes.tableRowText1}>
                                  {buildstatus(user.status)}
                                </Typography>
                              </td>
                            </tr>
                          )
                      )}
                  </tbody>
                </table>
                <div className={classes.paginationTAB}>
                  <Pagination
                    count={pages}
                    shape="rounded"
                    onChange={handlePagination}
                  />
                </div>
              </div>
            ) : (
              <div className={classes.errorContainer}>
                <img src={noMovements} alt=""></img>
                <Typography className={classes.errorText} >No se encontraron transacciones</Typography>
                <Typography className={classes.errorText2}>Por favor selecciona otro rango de fechas</Typography>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default withSnackbar(Transactions);
