
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import { withSnackbar  } from 'notistack';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';
import iconInfo from '../../../assets/images/modal_message_picture.svg'

const useStyles = makeStyles(theme => ({
    drawer: {
        flexShrink: 0,
        
    },
    addInventory:{
        minWidth: 500,
        padding: '0px 30px 0px 30px'
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        ...theme.mixins.toolbar,
        color: '#434343' 
    },
    drawerBody: {
        padding: 5,
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    drawerBodyNoPointer: {
        padding: 5,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign:'center'
    },
    cancelButtonStyle: {
        border: '1px solid #DA7B7B',
        boxSizing: 'border-box',
        borderRadius: 5,
        width: 146,
        height: 38,
        margin: 2
    },
    cancelButtonText: {
        ...theme.typography.extraSmallText,
        fontSize: 10,
        color: '#DA7B7B'
    },
    productImage: {
        width: 60,
        height: 50,
        objectFit: 'contain'
    },
    productInfo: {
        display: 'flex',
        flexDirection: 'column'
    },
    textCenter: {
        ...theme.typography.smallText,
        marginBottom: 5,
        opacity: '.6'
    },
    price: {
        fontSize: 14,
        fontFamily: 'Oxanium',
        fontWeight: '500'
    },
    successButtonStyle: {
        border: '1px solid #3B9A83',
        boxSizing: 'border-box',
        borderRadius: 5,
        height: 38,
        margin: 2,
        marginRight: '10px',
        minWidth: '40%'
    },
    successButtonText: {
        ...theme.typography.extraSmallText,
        color: theme.palette.primary.main
    },
    warningButtonStyle: {
        border: '1px solid #EFAB23',
        boxSizing: 'border-box',
        borderRadius: 5,
        height: 38,
        margin: 2,
        minWidth: '40%'
    },
    warningButtonText: {
        ...theme.typography.extraSmallText,
        color: theme.palette.secondary.main
    },
    title:{
        ...theme.typography.paragraph,
        marginTop: '40px',
    },
    subtitle:{
        ...theme.typography.mediumText,
        marginTop: '10px',
        marginBottom: '25px',
        color: 'rgba(67, 67, 67, 0.6)'
    },
    checkboxColor: {
        color: '#3B9A83',
        backgroudn: '#3B9A83'
    },
    imgHolder: {
        display: 'flex',
        flexDirection: 'column',
        width: '300px',
        textAlign: 'center',
        margin: '60px 0px 60px 60px'
    },
    noProductText: {
        ...theme.typography.subtitle,
        marginTop: 30
    },
    noProductTextSub: {
        ...theme.typography.mediumText,
        opacity: '.6',
        fontWeight: '400'
    },
    noProductConteiner: {
        textAlign: 'center',
        justifyContent: 'center'
    }
}));

function TemporaryDrawer(props) {
    useEffect(()=>{
    },[])

    const classes = useStyles();
    let history = useHistory();

    const handleAlert = (variant, message) => {
        // variant could be success, error, warning, info, or default
        props.enqueueSnackbar( message, { variant });
    }

    const handleChangeCheckbox = (productRecordKey) => {

    }

    const [loading, setLoading] = useState(false);
    
    const sideList = side => (
        <div className="col-12">
        {loading ?
            <div className='circulaLoading'>
                <CircularProgress color="secondary" />
            </div>
        :
            <div className={classes.addInventory}>
                
                <Typography className={`${classes.title}`}><img src={iconInfo} height="32" /> &nbsp;Procedimiento para transacciones Banorte</Typography>
                <Typography className={`${classes.subtitle}`}></Typography>
                <Divider />
                <ol>
                    <li><b>Descargar Movimientos</b></li>
                    Al presionar DESCARGAR MOVIMIENTOS arrojará 2 archivos <br />
                    con el siguiente nombre: <br/>
                     - <b>AC-###.txt</b> (Alta de cuentas de proveedores) <br />
                     - <b>SPEI-###.txt</b> (Alta de transacciones)<br />
                    Es muy importante mantener los mismos nombres para referencia. <br />
                    Estos archivos están listos para ser cargados en el portal de Banorte. <br/>
                    <br/>
                    <li><b>Procesar en Portal Banorte</b></li>
                    Cargar el Alta de Cuentas (AC-#.txt) Banorte, en caso de algún error, <br />
                    seleccionar "Imprimir" el resultado de errores y generar un <br />
                    archivo separado por tabuladores con el resultado. <br />
                    Dicho archivo deberá llamarse: <b>ERRORES-AC-#.txt </b> <br />
                    (donde # es el mismo número que AC-#.txt).
                    <br/>
                    <br />
                    Cargar las Transacciones SPEI (SPEI-#.txt) y seleccionar <br />
                    "Imprimir" resultados y generar un archivo separado por tabs llamado <br />
                    EXITO-SPEI-#.txt (donde # es el mismo que SPEI-#.txt). <br /> <br/>
                    <li><b>Cargar comprobantes</b></li>
                    Una vez se tengan los archivos de ERRORES y EXITO correspondientes, <br />
                    cargarlos uno por uno en el boton CARGAR COMPROBANTES.
                </ol>
                    
                <Divider />
                <div className="text-center pt-3">
                    <Button className={classes.cancelButtonStyle} onClick={props.cancel}>
                        <Typography className={classes.cancelButtonText}>Cerrar</Typography>
                    </Button>
                </div>
            </div>
        }   
    </div>
    );

    return (
        <div>
        <Drawer anchor="right" open={props.open} onClose={props.cancel}>
            {sideList('right')}
        </Drawer>
        </div>
    );
}
export default withSnackbar(TemporaryDrawer);

