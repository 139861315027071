import React, { useState } from 'react';
import { useEffect, useRef, useLayoutEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Auth, API, Storage } from 'aws-amplify';
import {
  AppBar,
  MenuItem,
  Menu,
  Modal,
} from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import { withSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import { addSettings } from '../../../redux/slices/settingsSlice';
import { addOrders } from '../../../redux/slices/ordersSlice';
import Notification from 'react-web-notification';
import useSound from 'use-sound';
import VerifyEmail from './verifyEmail';
import getMerchantData from '../../../lib/getMerchant';

import '../../../assets/css/main.css';

import neritoLogo from '../../../assets/images/neritoLogoGreen.svg';
import newNeritoLogo from '../../../assets/images/new-nerito-logo.svg';
import defaultUser from '../../../assets/images/default-user.png';
import notificationsIcon from '../../../assets/images/bell.png';
import soundMp3 from '../../../assets/sounds/sound.mp3';
import * as R from 'ramda';
import sortBy from '../../../lib/sortBy';
import WebsocketHeartbeatJs from 'websocket-heartbeat-js';
import help from '../../../assets/images/help.svg';
import DefaultModal from '../../modal';
import {
  API_SETTING,
  API_MERCHANT
} from '../../../lib/constants';

const awsmobile = JSON.parse(process.env.REACT_APP_AWS_MOBILE);

const useStyles = makeStyles(theme => ({
  root: {
    border: 'unset',
    position: 'fixed',
    whiteSpace: 'nowrap',
    width: 65,
    backgroundColor: '#3B9A83',
    height: '100%',
    zIndex: 2,
    //transition: "width 200ms ease",
  },
  modalStyle: {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
  imageContainer: {
    overflow: 'hidden',
    width: 180,
    height: 180,
  },
  imageSize: {
    width: 180,
    height: 'auto',
  },
  profileOption: {
    display: 'flex',
    flexDirection: 'row',
  },
  profileMenu: {
    padding: 30,
  },
  adminIcons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    marginLeft: 0,
  },
  appBar: {
    backgroundColor: '#115545',
    // marginBottom: 50,
    // borderTopLeftRadius: '20px',
    // borderTopRightRadius: '20px',
  },
  menuOpened: {
    width: 65,
    border: 'unset',
    position: 'fixed',
    backgroundColor: '#3B9A83',
    height: '100%',
    zIndex: 2,
    transition: 'width 200ms ease',
  },
  helpOption: {
    display: 'flex',
    flexDirection: 'row',
  },
  popMenu: {
    ...theme.typography.popMenu
  },
  navUser: {
    justifyContent: 'space-between'
  }
}));

const NavMenu = (props) => {
  const ordersRedux = useSelector(state => state.orders.value);
  const storeRedux = useSelector(state => state.store.value);
  const settingsRedux = useSelector(state => state.settings.value);
  const dispatch = useDispatch();
  const classes = useStyles();
  let history = useHistory();

  const [profilePicture, setProfilePicture] = useState(defaultUser);
  const [isAdmin, setAdmin] = useState(false);
  const [isManager, setManager] = useState(false);
  const [isUser, setUser] = useState(false);
  const [isMerchant, setMerchant] = useState(false);
  const [merchantRecordKey, setMerchantRecordKey] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedModal, setSelectedModal] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [file, setFile] = useState({ previewImage: '', data: '' });
  const isMenuOpen = Boolean(anchorEl);
  const refProps = useRef(props);
  const [open, setOpen] = useState(false);

  const [notifyIgnore, setIgnore] = useState(true);
  const [notifyTitle, setTitle] = useState('');
  const [notifyOptions, setOptions] = useState({});
  const refIgnore = useRef(notifyIgnore);
  const [reconnect, setReconnect] = useState(true);
  const [socket, setSocket] = useState({});
  const [hamburguerVisible, setHamburguer] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const [
    openModalTermsAndConditions,
    setOpenModalTermsAndConditions,
  ] = useState(false);

  const [openModalPrivacy, setOpenModalPrivacy,] = useState(false);
  const [openModalLogotipo, setOpenModalLogotipo,] = useState(false);
  const [disconnectSocket, setDisconnectSocket,] = useState(false);

  useEffect(() => {
    getSettings();
    callApi();
  }, []);

  useEffect(() => {
    if (disconnectSocket) {
      setReconnect(false);
      socket.close && socket.close()
    }
  }, [disconnectSocket]);

  useLayoutEffect(() => {
    window.addEventListener('resize', getScreenSize);
    getScreenSize();
    return () => window.removeEventListener('resize', getScreenSize);
  }, []);

  useEffect(() => {
    refProps.current = props;
  }, [props]);

  React.useEffect(() => {
    refIgnore.current = notifyIgnore;
  }, [notifyIgnore]);

  const [playActive] = useSound(soundMp3, { volume: 0.25 });

  const getSettings = async () => {
    try {
      const response = (await API.get(API_SETTING, `/settings/getall/services`, {
        queryStringParameters: {
          app: 'merchant web'
        },
      })).Items;
      const invoicePostPay = (await API.get(API_SETTING, `/settings/service_invoice_post_pay`)).data;
      const neritoPay = (await API.get(API_SETTING, `/settings/service_nerito_pay`)).data;
      const sharingIsCaring = (await API.get(API_SETTING, `/settings/service_sharing_is_caring`)).data;
      const analitics = (await API.get(API_SETTING, `/settings/service_buy_analitics`)).data;
      const paymentMethods = (await API.get(API_SETTING, `/settings/service_payment_methods_store`)).data;
      const inshopActions = (await API.get(API_SETTING, `/settings/service_edit_inshop_actions`)).data;
      const settings = response.concat(invoicePostPay, neritoPay, sharingIsCaring, analitics, paymentMethods, inshopActions);
      dispatch(addSettings(settings));
    }
    catch (err) {
      if (err.toString() === 'Error: Request failed with status code 403') {
        setTimeout(() => {
          handleLogOut();
        }, 1000);
      }
    }
  };

  const callApi = async () => {
    try {
      const getUser = await Auth.currentAuthenticatedUser();
      const userKey = getUser.attributes['sub'];
      if (getUser.attributes !== undefined) {
        if (
          getUser.signInUserSession.idToken.payload['cognito:groups'][0] ==
          'admin'
        ) {
          setAdmin(true);
        } else {
          setAdmin(false);
          if (
            getUser.signInUserSession.idToken.payload[
              'cognito:groups'
            ][0].indexOf('manager') !== -1
          ) {
            setManager(true);
          } 
          else if(getUser.signInUserSession.idToken.payload['cognito:groups'][0].indexOf('user') !== -1){
            setUser(true);
            setManager(false);
          }
          else {
            setUser(false);
            setMerchant(true);
          }
          const getGralProfile = await API.get(
            'profile-api',
            `/profile/${userKey}`
          );
          let logedMerchant = null;
          if (getGralProfile.data[0].merchantRecordKey) {
            logedMerchant = getGralProfile.data[0].merchantRecordKey;
          } else if (
            getGralProfile.data[0].isManagerOfStore &&
            getGralProfile.data[0].isEmployeeActive
          ) {
            const merchant = await getMerchantData(
              'store',
              getGralProfile.data[0].isManagerOfStore
            );
            logedMerchant = merchant.merchantRecordKey;
          }
          const isManagerOfStore =
            getGralProfile.data[0].isManagerOfStore || null;
          if (getGralProfile.data[0].hasAvatar || isManagerOfStore) {
            const pImage = await Storage.get(
              `profiles/${logedMerchant}/default.jpg`
            );
            setProfilePicture(pImage);
          }
          setMerchantRecordKey(logedMerchant);
          connectSocket(logedMerchant, isManagerOfStore);
        }
      } else {
        history.push('/login');
      }
    } catch (e) {
      history.push('/login');
    }
  };

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const handleProfileMenuOpen = (event) => {
    setSelectedModal('profile');
    setAnchorEl(event.currentTarget);
  };

  const handleFinanceMenuOpen = (event) => {
    setSelectedModal('finance');
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedModal(null);
  };
  const handleLogOut = () => {
    setDisconnectSocket(true);
    props.removeCookie();
    Auth.signOut({ global: true })
      .then(() => {
        history.push('/login');
      })
      .catch((e) => {
        history.push('/login');
      });
  };

  const handleCloseProfileModal = async () => {
    setOpenModal(false);
    setOpenModalLogotipo(false)
    setFile({
      previewImage: '',
      data: '',
    });
    const pImage = await Storage.get(
      `profiles/${merchantRecordKey}/default.jpg`
    );
    setProfilePicture(pImage);
  };

  const handleCloseEmailModal = () => {
    setOpenEmailModal(false);
  };

  const openProfileModal = () => {
    handleMenuClose();
    setOpenModal(true);
  };

  const openTermsAndConditions = () => {
    handleMenuClose();
    setOpenModalTermsAndConditions(true);
  };

  // const openPrivacy = () => {
  //   handleMenuClose();
  //   setOpenModalPrivacy(true);
  // };

  const openLogotipo = () => {
    handleMenuClose();
    setOpenModalLogotipo(true);
  };

  const handleOpenEmailModal = () => {
    handleMenuClose();
    setOpenEmailModal(true);
  };

  const goToProfile = () => {
    setAnchorEl(null);
    history.push('/profile');
  };
  const goToSettings = () => {
    setAnchorEl(null);
    history.push('/settingsMenu');
  };
  const goToAdminFinances = (target) => {
    setAnchorEl(null);
    switch (target) {
      case 'conekta':
        history.push('/admin/transactions');
        break;
      case 'spei':
        history.push('/admin/spei');
        break;
      case 'inShop':
        history.push('/admin/inShop');
        break;
      default:
        history.push('/admin/dashboard');
    }
  };

  /** notify functions **/
  const handlePermissionGranted = () => {
    setIgnore(false);
  };
  const handlePermissionDenied = () => {
    setIgnore(true);
  };
  const handleNotSupported = () => {
    setIgnore(true);
  };

  const handleNotificationOnClick = (e, tag) => {
  };

  const handleNotificationOnError = (e, tag) => {
  };

  const handleNotificationOnClose = (e, tag) => {
  };

  const handleNotificationOnShow = (e, tag) => {
    playSound();
  };

  const playSound = () => {
    playActive();
  };

  const handleDisplayNotify = (notifyData) => {
    if (refIgnore.current) {
      return;
    }

    const title = notifyData.title;
    const body = notifyData.subTitle;
    const tag = notifyData.order.invoiceRecordKey;
    const icon = notificationsIcon;

    // Available options
    // See https://developer.mozilla.org/en-US/docs/Web/API/Notification/Notification
    const options = {
      tag: tag,
      body: body,
      icon: icon,
      lang: 'en',
      dir: 'ltr',
    };
    setOptions(options);
    setTitle(title);
  };

  const getEmployeeInfo = (user) => {
    const userSplit = R.pathOr('', ['attributes', 'custom:usertype'], user).split('@')
    const userInfo = JSON.parse(userSplit[1]);
    return userInfo;
  }

  const connectSocket = async (merchant, store) => {
    if (merchant !== '') {
      try {
        const getUser = await Auth.currentAuthenticatedUser();
        const userInfo = store ? getEmployeeInfo(getUser) : null;
        const userRecordKey = R.path(['attributes', 'sub'], getUser)
        const queryString = `${awsmobile.aws_webSocket_Endpoint}?Authorization=${Auth.user.signInUserSession.accessToken.jwtToken}&userRecordKey=${userInfo ? `${userInfo.store}@${userInfo.merchant}` : merchant
          }${store
            ? `&isManager=${userRecordKey}`
            : `&isMerchant=${userRecordKey}`
          }`;

        const currentSocket = new WebsocketHeartbeatJs({
          url: queryString,
          reconnectTimeout: 2000,
          pingTimeout: 300000,
        });

        const desconnectionParams = {
          action: 'message',
          messageType: 'logoutSession',
          userRecordKey: userInfo ? `${userInfo.store}@${userInfo.merchant}` : merchant,
          ...store
            ? { isManager: userRecordKey }
            : { isMerchant: userRecordKey }
        };

        currentSocket.onopen = () => {
          currentSocket.send(JSON.stringify(desconnectionParams));
        };
        currentSocket.onmessage = (e) => {
          if (typeof e.data === 'string') {
            const jsonData = JSON.parse(e.data);
            const notificationKind =
              R.path(['notification', 'kind'], jsonData) || '';
            const newOrder = R.path(['notification', 'order'], jsonData) || {};
            const currentStore = storeRedux || '';
            const orders = ordersRedux || [];
            const orderList = [...orders];
            if (jsonData.type === 'logOutSession') {
              currentSocket.close();
              handleAlert('error', jsonData.notification);
              handleLogOut();
            }
            if (newOrder.owe === currentStore)
              if (notificationKind === 'order_created') {
                orderList.push(newOrder);
                const sortedOrders = sortBy(orderList, 'ASC', 'createdAt');
                dispatch(addOrders(sortedOrders));
              } else if (notificationKind === 'order_updated') {
                const invoiceRecordKey =
                  R.path(
                    ['notification', 'order', 'invoiceRecordKey'],
                    jsonData
                  ) || '';
                const newOrderIndex = orderList.findIndex(
                  (item) => item.invoiceRecordKey === invoiceRecordKey
                );
                orderList[newOrderIndex] = newOrder;
                const sortedOrders = sortBy(orderList, 'ASC', 'createdAt');
                dispatch(addOrders(sortedOrders));
              }
            if (store === null || newOrder.owe === store) {
              handleDisplayNotify(jsonData.notification);
            }
          }
        };
        if (reconnect) {
          currentSocket.onreconnect = () => {
          };
        }
        currentSocket.onerror = () => {
          currentSocket.close();
          handleAlert('error', 'La sesión ya no está disponible');
          handleLogOut();
        };
        currentSocket.onclose = () => {
        };
        setSocket(currentSocket);
      } catch (err) {
        handleAlert('error', err.message);
      }
    }
  };

  const menuId = 'primary-search-account-menu';

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      className='menuProfile'
    >
      {selectedModal === 'profile' ? (
        <>
          {isMerchant && <MenuItem className={classes.popMenu} onClick={goToSettings}>Ajustes de cuenta</MenuItem>}
          {isMerchant && (
            <MenuItem className={classes.popMenu} onClick={openLogotipo}>Logotipo de empresa</MenuItem>
          )}
          <MenuItem className={classes.popMenu} onClick={openTermsAndConditions}>
            Términos y Condiciones
          </MenuItem>

          <MenuItem className={classes.popMenu} onClick={handleLogOut}>Cerrar Sesión</MenuItem>
        </>
      ) : (
        selectedModal === 'finance' && (
          <>
            <MenuItem onClick={() => goToAdminFinances('conekta')}>
              Depósitos / Conekta
            </MenuItem>
            <MenuItem onClick={() => goToAdminFinances('spei')}>
              Retiros SPEI
            </MenuItem>
            <MenuItem onClick={() => goToAdminFinances('inShop')}>
              Movimientos en Comercios
            </MenuItem>
          </>
        )
      )}
    </Menu>
  );

  const handleMenuOpen = () => {
    setOpen(true);
  };

  const goToOption = (option) => {
    setHamburguer(false);
    if (history.location.pathname === option) {
      history.go(0);
    } else {
      history.push(option);
    }
  };

  const getScreenSize = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 960) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  const neritoPay = async () => {
    try {
      const getMerchant = await API.get(API_MERCHANT, `/merchant?merchantRecordKey=${merchantRecordKey}`);
      const isCommissionist = R.pathOr(false,['data', 'isCommissionAgent'], getMerchant)
      if (!isCommissionist) {
        goToOption('/neritoPay')
      }
      else {
        history.push({ pathname: '/neritoPayStores', state: { merchantId: merchantRecordKey } });
      }
    } catch (err) {
      handleAlert('error', err.message);
    }
  }

  const renderMenuList = (size) => {
    const classIconName = size === 'small' ? 'iconNameSmall' : 'iconName';
    return (
      <div style={{ width: '100%' }}>
        <div className={hamburguerVisible && (isMerchant || isAdmin) ? 'navList active' : 'navList'}>
          <div onClick={() => goToOption('/dashboard')}>
            <div className='navListItemIconheader'>
              <img className='neritoLogo' src={newNeritoLogo} alt='home' />
            </div>
          </div>

          { isMerchant === true ? (
            <div
              className={
                hamburguerVisible ? 'ActionIcons active' : 'ActionIcons'
              }
            >
              <div
                button
                className='navListItem'
                onClick={() => goToOption('/dashboard')}
              >
                <div className='navListItemIcon'>
                  <div className={classIconName}>Inicio</div>
                </div>
              </div>
              <div
                button
                className='navListItem'
                onClick={() => goToOption('/products')}
              >
                <div className='navListItemIcon'>
                  <div className={classIconName}>Productos</div>
                </div>
              </div>
              <div
                button
                className='navListItem'
                onClick={() => goToOption('/stores')}
              >
                <div className='navListItemIcon'>
                  <div className={classIconName} text='Mis sucursales'>
                    Sucursales
                  </div>
                </div>
              </div>
              <div
                button
                className='navListItem'
                onClick={() => goToOption('/merchantLeads')}
              >
                <div className='navListItemIcon'>
                  <div className={classIconName} text='MerchantLeads'>
                    Recomienda Nerito
                  </div>
                </div>
              </div>
              {settingsRedux?.service_nerito_pay &&
                <div
                  button
                  className='navListItem'
                  onClick={() => neritoPay()}
                >
                  <div className='navListItemIcon'>
                    <div className={classIconName} text='NeritoPay'>
                      Nerito Pay
                    </div>
                  </div>
                </div>
              }
              {settingsRedux?.service_sharing_is_caring &&
                <div
                  button
                  className='navListItem'
                  onClick={() => goToOption('/sharingIsCaring')}
                >
                  <div className='navListItemIcon'>
                    <div className={classIconName} text='NeritoPay'>
                      Sharing is Caring
                    </div>
                  </div>
                </div>
              }
              {settingsRedux?.service_buy_analitics &&
                <div
                  button
                  className='navListItem'
                  onClick={() => goToOption('/analitics')}
                >
                  <div className='navListItemIcon'>
                    <div className={classIconName} text='Analíticas'>
                      Analíticas
                    </div>
                  </div>
                </div>
              }
            </div>
          ) : isAdmin === true && (
            <div
              className={
                hamburguerVisible ? 'ActionIcons active' : 'ActionIcons'
              }
            >
              <div
                button
                className='navListItem'
                onClick={() => goToOption('/admin/dashboard')}
              >
                <div className='navListItemIcon'>
                  <div className={classIconName}>Dashboard</div>
                </div>
              </div>
              <div
                button
                className='navListItem'
                onClick={() => goToOption('/admin/merchants')}
              >
                <div className='navListItemIcon'>
                  <div className={classIconName}>Comercios</div>
                </div>
              </div>
              <div
                button
                className='navListItem'
                onClick={() => goToOption('/admin/walletUsers')}
              >
                <div className='navListItemIcon'>
                  <div className={classIconName}>Usuarios Wallet</div>
                </div>
              </div>
              <div
                button
                className='navListItem'
                onClick={() => goToOption('/admin/anchors')}
              >
                <div className='navListItemIcon'>
                  <div className={classIconName}>Anclas</div>
                </div>
              </div>
              {/* <div
                button
                className='navListItem'
                onClick={() => goToOption('/admin/reportes')}
              >
                <div className='navListItemIcon'>
                  <div className={classIconName}>Reportes SITI</div>
                </div>
              </div> */}
              {/* <div
                button
                className='navListItem'
                onClick={() => goToOption('/admin/fantasyGS')}
              >
                <div className='navListItemIcon'>
                  <div className={classIconName}>Regalos</div>
                </div>
              </div> */}
              <div
                button
                className='navListItem'
                onClick={() => goToOption('/admin/billing')}
              >
                <div className='navListItemIcon'>
                  <div className={classIconName}>Facturación</div>
                </div>
              </div>
              {/* <div
                button
                className='navListItem'
                onClick={() => goToOption('/admin/Inventory')}
              >
                <div className='navListItemIcon'>
                  <div className={classIconName}>Inventario</div>
                </div>
              </div> */}
              <div
                button
                className='navListItem'
                onClick={handleFinanceMenuOpen}
              >
                <div className='navListItemIcon'>
                  <div className={classIconName}>Finanzas</div>
                </div>
              </div>
              <div
                button
                className='navListItem'
                onClick={() => goToOption('/admin/services')}
              >
                <div className='navListItemIcon'>
                  <div className={classIconName}>Servicios</div>
                </div>
              </div>
              {/* <div
                button
                className='navListItem'
                onClick={() => goToOption('/admin/emidamex')}
              >
                <div className='navListItemIcon'>
                  <div className={classIconName}>Emidamex</div>
                </div>
              </div> */}
              {/* <div
                button
                className='navListItem'
                onClick={() =>
                  goToOption('/admin/operations')
                }
              >
                <div className='navListItemIcon'>
                  <div className={classIconName}>Operaciones</div>
                </div>
              </div> */}
              {/* <div
                button
                className='navListItem'
                onClick={() => goToOption('/admin/pldConsole')}
              >
                <div className='navListItemIcon'>
                  <div className={classIconName}>Consola PLD</div>
                </div>
              </div> */}
            </div>
          )}

          {
            isUser === true ?
            <div
              button
              className={`navListItem ${classes.navUser}`}
              onClick={handleProfileMenuOpen}
            >
              <div className={`navListItemfooter ${classIconName}`}>
                  {isMobile ? (
                    null
                  ) : (
                    <img
                      src={profilePicture}
                      width={30}
                      height={30}
                      style={{ borderRadius: '100%', marginLeft: 0 }}
                      alt='profile'
                    />
                  )}
              </div>
            </div>

            :

            <div
              className={
                hamburguerVisible
                  ? 'globalOptionsContainer active'
                  : 'globalOptionsContainer'
              }
            >
            <div className='globalOptions'>
              <div
                button
                className='navListItem'
                onClick={() => goToOption('/help')}
              >
                <div className='navListItemfooter'>
                  <div className={classIconName}>
                    {isMobile ? (
                      <div className={classIconName}>Ayuda</div>
                    ) : (
                      <img
                        src={help}
                        width={30}
                        height={30}
                        style={{ borderRadius: '100%', marginLeft: 0 }}
                        alt='help'
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='globalOptions'>
              <div
                button
                className='navListItem'
                onClick={handleProfileMenuOpen}
              >
                <div className='navListItemfooter'>
                  <div className={classIconName}>
                    {isMobile ? (
                      null
                    ) : (
                      <img
                        src={profilePicture}
                        width={30}
                        height={30}
                        style={{ borderRadius: '100%', marginLeft: 0 }}
                        alt='profile'
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            </div>
          }

          {
            isAdmin === true && isMerchant === true &&
            <>
              <Modal
                open={openEmailModal}
                onClose={handleCloseEmailModal}
                className=''
              >
              <VerifyEmail handleCloseEmailModal={handleCloseEmailModal} />
              </Modal>
              <DefaultModal open={openModalTermsAndConditions}
                handleClose={() => setOpenModalTermsAndConditions(false)}
                modalType='terminos'
              />
              <DefaultModal open={openModalPrivacy}
                handleClose={() => setOpenModalPrivacy(false)}
                modalType='privacidad'
              />
              <DefaultModal open={openModalLogotipo}
                handleClose={() => setOpenModalLogotipo(false)}
                modalType='logotipo'
                handleCloseProfileModal={handleCloseProfileModal}
                profilePicture={profilePicture}
              />
            </>
          }

        </div>
        {renderMenu}
        <Notification
          ignore={notifyIgnore && notifyTitle !== ''}
          askAgain={true}
          notSupported={handleNotSupported}
          onPermissionGranted={handlePermissionGranted}
          onPermissionDenied={handlePermissionDenied}
          onShow={handleNotificationOnShow}
          onClick={handleNotificationOnClick}
          onClose={handleNotificationOnClose}
          onError={handleNotificationOnError}
          timeout={10000}
          title={notifyTitle}
          options={notifyOptions}
        />
      </div>
    );
  };

  const handleClick = () => setHamburguer(!hamburguerVisible);

  return (
    <div>
      <AppBar
        position='relative'
        color='transparent'
        className={classes.appBar}
      >
        <Toolbar>
          {
            !isAdmin || !isMerchant &&

            <div className='menu-icon' onClick={() => handleClick()}>
              <MenuIcon />
            </div>
          }
          {renderMenuList('small')}
          {isMobile &&
            <div button
              className='profileOption'
              onClick={handleProfileMenuOpen}>
              <img
                src={profilePicture}
                width={30}
                height={30}
                style={{ borderRadius: '100%', marginLeft: 0 }}
                alt='help'
              />
            </div>}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default withSnackbar(NavMenu);