import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import '../../assets/css/main.css';
import {
  Grid,
  Checkbox,
  IconButton,
} from '@material-ui/core';
import PrimaryInput from '../common/primaryInput';
import MultilineInput from '../common/multilineInput';
import PrimaryButton from '../common/primaryButton/index.js';
import SecondaryButton from '../common/secondaryButton/index.js';
import { useHistory } from 'react-router-dom';
import CheckValue from '../../lib/formatValidations';
import { Auth, API, Storage } from 'aws-amplify';
import getMerchantData from '../../lib/getMerchant';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import FileInput from '../common/fileInput';
import noProduct from '../../assets/images/noProduct.svg';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import theme from '../../styles/theme.js';
import DeleteIcon from '@material-ui/icons/Delete.js';

const ModalNewProduct = (props) => {
  const userKey = useSelector(state => state.user.value);

  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: '%',
    width: 100,
    height: 100,
    aspect: 1/1,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [showCrop, setShowCrop] = useState(false);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const getImage = () => {
    setFile({
      previewImage: URL.revokeObjectURL(completedCrop),
      data: completedCrop,
    });
  };

  const cropImage = (e) => {
    setShowCrop(true)
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  function generateDownload(canvas, crop) {
    if (!crop || !canvas) {
      return;
    }
    canvas.toBlob(
      (blob) => {
        const previewUrl = window.URL.createObjectURL(blob);
        setFile({
          previewImage: URL.createObjectURL(blob),
          data: blob,
        });
        setShowCrop(false);
      },
      'image/png',
      1
    );
  }

  let history = useHistory();

  const [isLoading, setLoading] = useState(false);
  const from = props.from || null;
  const [productName, setProductName] = useState(
    props.edit ? props.editProduct.productName : ''
  );
  const [productType, setProductType] = useState(
    props.edit ? props.editProduct.productType : ''
  );
  const [description, setDescription] = useState(
    props.edit ? props.editProduct.description : ''
  );
  const [price, setPrice] = useState(
    props.edit ? props.editProduct.price.replace(/[$,]+/g, '') : ''
  );
  const [inventoriable, setInventoriable] = useState(
    props.edit ? props.editProduct.inventoryable : false
  );
  const [file, setFile] = useState(
    props.edit
      ? {
        previewImage: props.editProduct.productImage,
        data: props.editProduct.productImage,
      }
      : { previewImage: '', data: '' }
  );
  const hasImage = props.edit ? props.editProduct.hasImage : null;
  const [deactivated, setDeactivated] = useState(
    props.edit ? props.editProduct.deactivated : false
  );
  const [extraFields, setExtraFields] = useState(
    (props.edit && props.editProduct?.extraFields) ? props.editProduct.extraFields : []
  );

  /*Errors*/
  const [productNameError, setProductNameError] = useState('');
  const [productTypeError, setProductTypeError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [priceError, setPriceError] = useState('');

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'productName':
        setProductName(value);
        break;
      case 'productType':
        setProductType(value);
        break;
      case 'description':
        setDescription(value);
        break;
      case 'price':
        if (CheckValue('money', value)) setPrice(value);
        else if (CheckValue('money', `${value}0`)) setPrice(value);
        else if (
          value.slice(0, -1).indexOf('.') === -1 &&
          value.slice(-1) === '.' &&
          CheckValue('money', value.slice(0, -1))
        )
          setPrice(value);
        else if (value === '') setPrice(value);
        break;
      default:
        break;
    }
  };

  const cleanError = () => {
    setProductNameError('');
    setProductTypeError('');
    setDescriptionError('');
    setPriceError('');
  };

  const cleanForm = () => {
    setProductName('');
    setProductType('');
    setDescription('');
    setPrice('');
    setInventoriable(false);
    setFile({
      previewImage: '',
      data: '',
    });
  };

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const handleDeactivate = async () => {
    setLoading(true);
    try {
      const EDIT_PRODUCT = await API.put(
        'product-api',
        `/products/${props.editProduct.productRecordKey}/${props.editProduct.merchantRecordKey}`,
        {
          body: {
            productName: productName,
            productType: productType,
            description: description,
            price: price[price.length - 3] !== '.' ? `${price}.00` : price,
            inventoryable: inventoriable,
            ...(typeof file.data == 'object' && {
              hasImage: true,
            }),
            deactivated: !deactivated,
          },
        }
      );
      const config = {
        contentType: file.data.type,
        level: 'public',
      };
      if (typeof file.data == 'object') {
        const filePUT = await Storage.put(
          `products/${EDIT_PRODUCT.data[0].productRecordKey}/default.jpg`,
          file.data,
          config
        );
      }
      setLoading(false);
      // props.toggleSidePanel();
      props.reloadProducts('');
      props.handleClose();
      handleAlert('success', 'Registro Finalizado');
    } catch (err) {
      setLoading(false);
      handleAlert('error', err);
    }
  }

  const createProduct = async () => {
    cleanError();
    let hasErrors = false;
    if (!CheckValue('empty', productName)) {
      setProductNameError('Ingrese el nombre del producto');
      hasErrors = true;
    }
    if (!CheckValue('empty', productType)) {
      setProductTypeError('Ingrese el tipo de producto');
      hasErrors = true;
    }
    if (!CheckValue('empty', description)) {
      setDescriptionError('Ingrese la descripción del producto menor a 70 carácteres');
      hasErrors = true;
    }
    if (!CheckValue('money', price)) {
      setPriceError('Ingrese un precio válido, ejemplo: 1234 ó 1234.56');
      hasErrors = true;
    }
    const extraFieldsCopy = [...extraFields];
    extraFields.forEach((item, index) => {
      if (item.name.trim() === '') {
        extraFieldsCopy[index]['nameError'] = 'Campo vacío';
        hasErrors = true;
      }
      if (item.value.trim() === '') {
        extraFieldsCopy[index]['valueError'] = 'Campo vacío';
        hasErrors = true;
      }
    });
    setExtraFields(extraFieldsCopy);

    if (!hasErrors) {
      setLoading(true);
      const getUser = await Auth.currentAuthenticatedUser();
      if (props.edit) {
        try {
          const EDIT_PRODUCT = await API.put(
            'product-api',
            `/products/${props.editProduct.productRecordKey}/${props.editProduct.merchantRecordKey}`,
            {
              body: {
                productName: productName,
                productType: productType,
                description: description,
                price: price[price.length - 3] !== '.' ? `${price}.00` : price,
                inventoryable: inventoriable,
                ...(typeof file.data == 'object' && {
                  hasImage: true,
                }),
                extraFields: extraFields.map(item => ({ name: item.name, value: item.value })),
              },
            }
          );
          const config = {
            contentType: file.data.type,
            level: 'public',
          };
          if (typeof file.data == 'object') {
            const filePUT = await Storage.put(
              `products/${EDIT_PRODUCT.data[0].productRecordKey}/default.jpg`,
              file.data,
              config
            );
          }
          cleanForm();
          setLoading(false);
          // props.toggleSidePanel();
          props.reloadProducts('');
          props.handleClose();
          handleAlert('success', 'Registro Finalizado');
        } catch (err) {
          setLoading(false);
          handleAlert('error', err);
        }
      } else {
        try {
          const getGralProfile = await API.get(
            'profile-api',
            `/profile/${userKey}`
          );
          let merchant = null;
          if (getGralProfile.data[0].merchantRecordKey) {
            merchant = await getMerchantData(
              'merchant',
              getGralProfile.data[0].merchantRecordKey
            );
          } else if (
            getGralProfile.data[0].isEmployeeOfStore &&
            getGralProfile.data[0].isEmployeeActive
          ) {
            merchant = await getMerchantData(
              'store',
              getGralProfile.data[0].isEmployeeOfStore
            );
          } else if (
            getGralProfile.data[0].isManagerOfStore &&
            getGralProfile.data[0].isEmployeeActive
          ) {
            merchant = await getMerchantData(
              'store',
              getGralProfile.data[0].isManagerOfStore
            );
          }
          if (merchant === null) {
            handleAlert(
              'error',
              'Ocurrió un error al obtener la información del usuario'
            );
            history.push('/login');
          } else {
            const CREATE_PRODUCT = await API.post('product-api', '/products', {
              body: {
                merchantRecordKey: merchant.merchantRecordKey,
                productName: productName,
                productType: productType,
                description: description,
                price: price,
                inventoryable: inventoriable,
                hasImage: typeof file.data == 'object' && true,
                deactivated: false,
                extraFields: extraFields.map(item => ({ name: item.name, value: item.value })),
              },
            });
            const config = {
              contentType: file.data.type,
              level: 'public',
            };
            const filePUT = await Storage.put(
              `products/${CREATE_PRODUCT.data[0].productRecordKey}/default.jpg`,
              file.data,
              config
            );
            setFile({
              previewImage: '',
              data: '',
            });
            cleanForm();
            setLoading(false);
            props.reloadProducts('');
            handleAlert('success', 'Registro Finalizado');
          }
        } catch (err) {
          setLoading(false);
          handleAlert('error', 'Registro Fallido');
        }
      }
    }
  };

  const handleProblem = async () => {
    const description =
      'Problema con producto ' +
      props.editProduct.productName +
      ' con clave ' +
      props.editProduct.productRecordKey;
    const formData = new FormData();
    formData.append('name', props.name);
    formData.append('merchantID', props.merchantId);
    formData.append('problemType', 'Productos');
    formData.append('problem', description);
    formData.append('email', props.email);
    fetch(process.env.REACT_APP_ZAPIER_ZOHO, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        handleAlert('success', 'Tu problema se envió correctamente');
        props.toggleSidePanel();
      })
      .catch((error) => {
        handleAlert('error', error.response.data.error);
      });
  };

  const addExtraField = () => {
    const extraFieldsCopy = [...extraFields];
    extraFieldsCopy.push({
      name: '',
      value: '',
      nameError: '',
      valueError: '',
    });
    setExtraFields(extraFieldsCopy);
  }

  const deleteExtraField = (index) => {
    const extraFieldsCopy = [...extraFields];
    extraFieldsCopy.splice(index, 1);
    setExtraFields(extraFieldsCopy);
  }

  const editExtraField = (type, index, newValue) => {
    const extraFieldsCopy = [...extraFields];
    extraFieldsCopy[index][type] = newValue;
    extraFieldsCopy[index][`${type}Error`] = '';
    setExtraFields(extraFieldsCopy);
  }

  const classes = useStyles();
  return (
    <>
      {isLoading ? (
        <div className={`${classes.loadingContainer}`}>
          <div className={`${classes.loading} `}>
            <CircularProgress color='secondary' />
          </div>
        </div>
      ) : (
        <>
          <div className={`${classes.container} scrollBarHidden`}>
            <h1 className={classes.title}>
              {props.edit ? 'Editar Producto' : 'Nuevo Producto'}
            </h1>
            {showCrop ? (
              <div className={classes.cropContainer}>
                <ReactCrop
                  src={upImg}
                  onImageLoaded={onLoad}
                  crop={crop}
                  onChange={(c) => setCrop(c)}
                  onComplete={(c) => setCompletedCrop(c)}
                  style={{ maxWidth: 500, height: 'auto' }}
                />
                <div className={classes.hidden}>
                  <canvas
                    ref={previewCanvasRef}
                    // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                    style={{
                      width: 0,
                      height: 0,
                    }}
                  />
                </div>
                <PrimaryButton
                  type='button'
                  paddingHorizontal={25}
                  onClick={() => generateDownload(previewCanvasRef.current, completedCrop)}
                  primaryButtonStyle={classes.buttonStyle}
                >
                  Guardar Imagen
                </PrimaryButton>
              </div>
            ) : (
              <div>
                <div className={classes.imageAndFormContainer}>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <PrimaryInput
                        type='text'
                        value={productName}
                        onChange={handleChange}
                        name='productName'
                        placeholder='Nombre de Producto'
                        marginVertical={10}
                        autoFocus
                        error={productNameError}
                      />
                      <PrimaryInput
                        type='text'
                        value={productType}
                        onChange={handleChange}
                        name='productType'
                        placeholder='Tipo de Producto'
                        marginVertical={10}
                        error={productTypeError}
                      />
                      <PrimaryInput
                        type='text'
                        value={price}
                        onChange={handleChange}
                        name='price'
                        placeholder='Precio'
                        startAdornment={<text>$</text>}
                        marginVertical={10}
                        error={priceError}
                      />
                      <MultilineInput
                        type='text'
                        value={description}
                        onChange={handleChange}
                        name='description'
                        placeholder='Descripción'
                        minRows={2}
                        error={descriptionError}
                      />
                      <div>
                        <Checkbox
                          checked={inventoriable}
                          onClick={() => setInventoriable(!inventoriable)}
                          color='#434343'
                          style={{ marginLeft: 5 }}
                        />
                        <text className={classes.checkboxText}>Inventariable</text>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <div className={classes.imageAndButton}>
                        <div className={classes.imageContainer}>
                          {props.edit || file.previewImage ?
                            <img
                              src={file.previewImage}
                              className={classes.image}
                            />
                            :
                            <>
                              <img
                                src={noProduct}
                                className={classes.noImage}
                              />
                              <Typography className={classes.label}>Subir una imagen</Typography>
                              <Typography className={classes.greenlabel}>(Recomendado 720x720 px)</Typography>
                            </>
                          }
                        </div>
                        <FileInput
                          placeholder='Subir imagen'
                          onChange={cropImage}
                          accept='image/png,image/jpg,image/jpeg'
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className={classes.separator} />
                <div className={classes.extraFieldsContainer}>
                  {extraFields.length > 0 && extraFields.map((item, index) => (
                    <div key={index} className={classes.extraFieldsInputContainer}>
                      <div className={classes.extraFieldInput}>
                        <text className={classes.extraInputLabel}>
                          Nombre
                        </text>
                        <PrimaryInput
                          type='text'
                          value={item.name}
                          onChange={(e) => editExtraField('name', index, e.target.value)}
                          name={`name${index}`}
                          error={item.nameError}
                          placeholder='Color, Talla, Marca...'
                        />
                      </div>
                      <div className={classes.extraFieldInput}>
                        <text className={classes.extraInputLabel}>
                          Valor
                        </text>
                        <PrimaryInput
                          type='text'
                          value={item.value}
                          onChange={(e) => editExtraField('value', index, e.target.value)}
                          name={`value${index}`}
                          error={item.valueError}
                        />
                      </div>
                      <div>
                      <IconButton onClick={() => deleteExtraField(index)}>
                        <DeleteIcon color='error' />
                      </IconButton>
                      </div>
                    </div>
                  ))}
                </div>
                <div className={classes.addFieldButtonContainer}>
                  <PrimaryButton
                    type='button'
                    paddingHorizontal={25}
                    onClick={addExtraField}
                    primaryButtonStyle={classes.buttonStyle}
                    marginHorizontal='0'
                    color={theme.palette.primary.main}
                  >
                    Agregar Campo
                  </PrimaryButton>
                </div>
                <div className={classes.separator} />
                <div className={classes.buttons}>
                  {props.edit &&
                    <PrimaryButton
                      type='button'
                      paddingHorizontal={25}
                      onClick={() => handleDeactivate()}
                      primaryButtonStyle={classes.buttonStyle}
                      marginHorizontal='0'
                      color={props.editProduct.deactivated ? theme.palette.primary.main : theme.palette.red.main}
                    >
                      {props.editProduct.deactivated ? "Activar" : "Desactivar"}
                    </PrimaryButton>
                  }
                  {!props.editProduct.deactivated ?
                    <>
                      <SecondaryButton
                        type='button'
                        paddingHorizontal={25}
                        onClick={props.handleClose}
                        secondaryButtonStyle={classes.buttonStyle}
                        marginHorizontal='0'
                      >
                        Cancelar
                      </SecondaryButton>
                      <PrimaryButton
                        type='button'
                        paddingHorizontal={25}
                        onClick={createProduct}
                        primaryButtonStyle={classes.buttonStyle}
                        marginHorizontal='0'
                      >
                        Guardar
                      </PrimaryButton>
                    </>
                    :
                    null
                  }
                </div>
              </div>
            )}
            {from && props.edit && (
              <div className={classes.helpContainer}>
                <Typography
                  onClick={handleProblem}
                  className={classes.helpText}
                >
                  Tengo un problema con este producto
                </Typography>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default withSnackbar(ModalNewProduct);

const useStyles = makeStyles(theme => ({
  container: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '90vh',
    overflow: 'scroll',
  },
  title: {
    textAlign: 'center',
    ...theme.typography.title,
  },
  imageAndButton: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  imageContainer: {
    ...theme.globals.centerVertical,
    backgroundColor: '#EDEDED',
    borderRadius: 10,
    width: 170,
    height: 170,
    margin: 10,
  },
  image: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: 170,
    height: 170,
    borderRadius: 10,
    border: `2px solid #edecec`
  },
  noImage: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: 60,
    height: 60,
    borderRadius: 10,
  },
  checkboxText: {
    ...theme.typography.checkbox,
  },
  buttons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'center',
    },
  },
  loading: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingContainer: {
    width: 'auto',
    display: 'flex',
    maxHeight: '90vh',
    flexDirection: 'column',
  },
  helpContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
  },
  helpText: {
    ...theme.typography.mediumText,
    color: 'red',
    cursor: 'pointer',
  },
  buttonStyle: {
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  label: {
    ...theme.typography.mediumText,
  },
  greenlabel: {
    ...theme.typography.mediumText,
    color: theme.palette.primary.main,
    fontSize: 10,
  },
  hidden: {
    visibility: 'false',
  },
  cropContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      alignItems: 'center'
    },
  },
  separator: {
    marginTop: 10,
    marginBottom: 10,
    border: `1px solid ${theme.palette.lightGrey.main}`,
  },
  extraFieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  extraFieldsInputContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  extraInputLabel: {
    ...theme.typography.mediumText,
    marginLeft: 8,
  },
  addFieldButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
