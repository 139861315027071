import React, { useState } from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography, Grid, } from '@material-ui/core';
import { API, Auth } from 'aws-amplify';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentStore } from '../../../../redux/slices/storeSlice';
import { addOrders } from '../../../../redux/slices/ordersSlice';
import DefaultCard from '../../../common/defaultCard';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import Pagination from '@material-ui/lab/Pagination';
import PrimaryInput from '../../../common/primaryInput';

import '../../../../assets/css/main.css';
import DefaultSearch from '../../../common/deafultSearch';
import clipBoardIcon from '../../../../assets/images/clipboard_icon_green.svg';
import arrowBack from '../../../../assets/images/arrow_back_icon.svg';
import OrderDetails from './orderDetails'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: '#FAFAFA',
    padding: 0
  },
  buttonContainer: {
    display: 'flex',
    marginBottom: 15,
  },
  tableHeaderText: {
    ...theme.typography.smallText,
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #E0E0E0',
    height: 60,
    justifyContent: 'center'
  },
  tableRowText1: {
    ...theme.typography.smallText,
    fontWeight: '600',
    paddingTop: 30,
    paddingBottom: 30,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    height: 40,
    textAlign: 'center',
    justifyContent: 'center'
  },
  tableRowText2: {
    ...theme.typography.smallText,
    fontWeight: '600l',
    paddingTop: 30,
    paddingBottom: 30,
    display: 'flex',
    alignItems: 'center',
    height: 40,
    textAlign: 'center',
    justifyContent: 'center',
    backgroundColor: '#F9F9F9',
  },
  buttonStyle: {
    border: '1px solid #3B9A83',
    boxSizing: 'border-box',
    borderRadius: 5,
    width: 146,
    height: 38,
    margin: 2
  },
  buttonText: {
    ...theme.typography.extraSmallText,
    color: theme.palette.primary.main
  },
  plusIcon: {
    marginRight: 10
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  statusModal: {
    backgroundColor: 'whitesmoke',
    border: '2',
    borderColor: '#000',
    boxShadow: 'black',
    padding: 40,
    minHeight: '440px'
  },
  pointer: {
    cursor: 'pointer'
  },
  successButtonStyle: {
    border: '1px solid #3B9A83',
    boxSizing: 'border-box',
    borderRadius: 5,
    width: 146,
    height: 38,
    margin: 2,
    marginTop: 20,
    minWidth: '40%'
  },
  cancelButtonStyle: {
    border: '1px solid #DA7B7B',
    boxSizing: 'border-box',
    borderRadius: 5,
    width: 146,
    height: 38,
    margin: 2,
    marginTop: 20,
    minWidth: '40%'
  },
  successButtonText: {
    ...theme.typography.successText,

  },
  cancelButtonText: {
    ...theme.typography.successText,
    color: '#DA7B7B'
  },
  title: {
    ...theme.typography.paragraph,
    color: '#4F4F4F',
    marginTop: 20
  },
  loadingModal: {
    marginTop: '40%',
    backgroundColor: '#F6F6F6'
  },
  statusContainer: {
    width: '100%',
    maxHeight: '700px',
    background: '#F0F0F0',
    minHeight: '300px',
    padding: '15px 13px',
    border: '1px solid #E0E0E0',
    borderRadius: 5,
    overflow: 'hidden',
  },
  rowStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  statusTitle: {
    ...theme.typography.mediumText,
    lineHeight: "19px",
    marginLeft: "8px",
    color: "rgba(67, 67, 67, 0.6)"
  },
  colorDiv: {
    marginTop: '15px',
    height: '4px',
    width: '100%'
  },
  colorYellow: {
    backgroundColor: '#EDDD64'
  },
  colorOrange: {
    backgroundColor: '#F2C94C'
  },
  colorGreen: {
    backgroundColor: '#6EB89D'
  },
  colorRed: {
    backgroundColor: '#DA7B7B'
  },
  orderCard: {
    border: '2px solid #BDBDBD'
  },
  viewOrdersContainer: {
    backgroundColor: '#f0f0f0',
    marginBottom: "20px",
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: '1px solid #E0E0E0',
    borderRadius: 5,
  },
  viewOrdersText: {
    ...theme.typography.smallText,
    color: theme.palette.primary.main,
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    padding: '15px',
    backgroundColor: '#F0F0F0',
    border: '1px solid #E0E0E0',
    borderRadius: 5,
  },
  backButton: {
    marginRight: '20px'
  },
  backIcon: {
    marginRight: '10px'
  },
  deliveredOrdersTable: {
    padding: '24px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #E0E0E0',
    borderRadius: 5,
  },
  oddColumnBackground: {
    backgroundColor: '#F9F9F9'
  },
  tableRowText: {
    ...theme.typography.smallText,
    fontWeight: '600px',
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft: 20,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center'
  },
  canceledText: {
    ...theme.typography.extraSmallText,
    paddingTop: 3,
    color: 'white',
    backgroundColor: '#DA7B7B',
    borderRadius: '5px',
    textAlign: 'center',
    justifyContent: 'center',
    width: '100px',
    height: '20px',
    marginTop: 10,
  },
  deliveredText: {
    ...theme.typography.extraSmallText,
    fontWeight: '900',
    paddingTop: 3,
    color: 'white',
    backgroundColor: '#3B9A83',
    borderRadius: '5px',
    textAlign: 'center',
    justifyContent: 'center',
    width: '100px',
    height: '20px',
    marginTop: 10,
  },
  detailsContainer: {
    textAlign: 'center',
    justifyContent: 'center',
  },
  detailsButtonText: {
    ...theme.typography.smallText,
    color: theme.palette.primary.main,
    marginLeft: 10,
  },
  paginationTAB: {
    widht: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  separador: {
    width: '5vw'
  },
  ordersContainer: {
    width: '100%',
    height: '700px',
    overflow: 'scroll',
  },
  redText: {
    color: 'red',
    textAlign: 'right',
    fontSize: 16,
  },
  buttonStyle: {
    border: 0,
    margin: 15,
    backgroundColor: 'white',
  },
  filterConteiner: {
    border: '1px solid #BDBDBD',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingRight: 40,
    alignItems: 'center',
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      paddingRight: 20,
      paddingLeft: 20,
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  dateLabel: {
    ...theme.typography.paragraph,
    width: '100%',
    textAlign: 'center',
  },
}));

const perPage = 10
let currentSocket = null

function Tab3(props) {
  const ordersRedux = useSelector(state => state.orders.value);
  const dispatch = useDispatch();

  useEffect(() => {
    callApi(false, initialFromDate, initialToDate);
    return function cleanup() {
      disconnectSocket();
    }
  }, [])

  const classes = useStyles();
  let history = useHistory();

  const initialFromDate = `${moment().format('YYYY-MM-DD')}T00:00`;
  const initialToDate = `${moment().format('YYYY-MM-DD')}T23:59`;
  const [fromDate, setFromDate] = useState(initialFromDate);
  const [toDate, setToDate] = useState(initialToDate);

  const [isLoading, setLoading] = useState(false);
  const [isLoadingModal, setLoadingModal] = useState(false);
  const [ordersHistory, setOrdersHistory] = useState(false);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [selectedStatus, setSelectedStatus] = useState('');
  const [lastStatus, setLastStatus] = useState('');
  const [orders, setOrders] = useState([]);
  const [closed, setClosed] = useState([]);
  const [searchedWord, setSearchedWord] = useState('');
  const [selectedPage, setSelectedPage] = useState(1);
  const [orderDetail, setOrderDetail] = useState(false);

  let orderList = []
  let reconnect = true
  const pagination = {
    pages: 0
  }

  const callApi = async (history, from, to) => {
    setLoading(true);
    const timeStampFrom = moment.utc(from).valueOf()
    const timeStampTo = moment.utc(to).valueOf()
    if(timeStampFrom < timeStampTo) {
      try {
        const getInvoices = await API.get('invoice-api', '/invoices', {
          queryStringParameters: {
            userid: props.currentStore.id,
            status: history ? 'close' : 'open',
            isNeritoBusiness: true,
            startDate: timeStampFrom,
            endDate: timeStampTo,
          }
        });
        function compare(a, b) {
          if (a.createdAt < b.createdAt)
            return -1;
          if (a.createdAt > b.createdAt)
            return 1;
          return 0;
        }
        getInvoices.data.sort(compare);
        dispatch(addOrders(getInvoices.data));
        dispatch(setCurrentStore(props.currentStore.id));
        if (history) {
          setClosed(getInvoices.data)
          let pagenNumber = 1
          if (getInvoices.data.length > 10) {
            pagenNumber = Math.ceil(getInvoices.data.length / perPage)
          }
          pagination.pages = pagenNumber
        }
        setLoading(false)
      } catch (err) {
        handleAlert('error', err.message);
        setLoading(false);
      }
    } else {
      handleAlert('error', 'La fecha inicio debe ser menor a la fecha de fin')
      setLoading(false);
    }
  };

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const handleOpenStatusModal = order => {
    setSelectedOrder(order);
    setOrderDetail(!orderDetail);
  }

  const handleCloseStatusModal = () => {
    setOrderDetail(!orderDetail);
  }

  const handleChangeSearch = event => {
    const value = event.target.value;
    setSearchedWord(value)
  }

  const handleChange = (event) => {
    const { value, name } = event.target
    switch (name) {
      case 'fromDate':
        setFromDate(value)
        callApi(ordersHistory, value, toDate);
        break;
      case 'toDate':
        setToDate(value)
        callApi(ordersHistory, fromDate, value);
        break;
      default:
        handleAlert('error', `Input no válido ${name}`)
    }
  }

  const handlePagination = (event, value) => {
    setSelectedPage(value)
  }

  const renderStatusContainer = (status, filteredOrders, color) => {
    return (
      <div className={classes.statusContainer}>
        <Typography className={classes.statusTitle}>
          {`${status}  ${filteredOrders.length}`}
        </Typography>
        <div className={`${classes.colorDiv} ${color}`}></div>
        <div className={classes.ordersContainer}>
          {
            filteredOrders.map((order, index) => (
              <div key={index} onClick={() => handleOpenStatusModal(order)} >
                <DefaultCard
                  className={classes.orderCard}
                  order={order}
                  cardType={'cardOrder'}
                />
              </div>
            ))
          }
        </div>
      </div>
    )
  }

  const getTime = (created) => {
    const createdDate = moment
      .tz(created, moment.tz.guess(true))
      .format('DD/MM/YYYY hh:mm a');
    return createdDate
  }

  const renderOrders = () => {
    const search = closed.filter(item => searchedWord.length < 3 || JSON.stringify(item).toLowerCase().indexOf(searchedWord.toLowerCase()) !== -1);
    let pagenNumber = 1
    if (search.length > 10) {
      pagenNumber = Math.ceil(search.length / perPage)
    }
    pagination.pages = pagenNumber
    const element = search.slice(((selectedPage - 1) * perPage), (((selectedPage - 1) * perPage) + perPage)).map((item, index) => (
      <tr key={index} value={item.inventoryRecordKey}>
        <td className={classes.oddColumnBackground} align="center">
          <Typography className={item.orderStatus === 'canceled' ? classes.canceledText : classes.deliveredText} >
            {item.orderStatus === 'canceled' ? "CANCELADO" : "ENTREGADO"}
          </Typography>
        </td>
        <td>
          <Typography className={classes.tableRowText} >{getTime(item.createdAt)}</Typography>
        </td>
        <td className={classes.oddColumnBackground} align="center">
          <Typography className={classes.tableRowText}>{item.orderNo}</Typography>
        </td>
        <td>
          <Typography className={classes.tableRowText}>{item.lendFullName}</Typography>
        </td>
        <td className={classes.oddColumnBackground} align="center">
          <Button className={classes.detailsContainer} onClick={() => handleOpenStatusModal(item)}>
            <img width="18" height="18" src={clipBoardIcon} />
            <Typography className={classes.detailsButtonText}>Ver Detalles</Typography>
          </Button>
        </td>
      </tr>
    ))
    return element;
  }

  const isEnter = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      return false;
    }
  };

  const viewHistory = (value) => {
    setOrdersHistory(value);
    callApi(value, fromDate, toDate);
  }

  const disconnectSocket = async () => {
    dispatch(addOrders([]));
    dispatch(setCurrentStore(null));
  }

  const renderOrderDetails = () => {
    if (orderDetail) {
      return (
        <div classNanme="col-12">
          <OrderDetails open={orderDetail} cancel={handleCloseStatusModal} order={selectedOrder} from={props.from} merchantEmail={props.email} />
        </div>
      )
    }
    else {
      return null
    }
  }

  const handleProblem = async () => {
    const description = 'No se encuentran los pedidos';
    const formData = new FormData();
    formData.append('name', props.currentStore.storeName);
    formData.append('merchantID', props.currentStore.merchant);
    formData.append('problemType', 'Pedidos');
    formData.append('problem', description);
    formData.append('email', props.email);
    fetch(process.env.REACT_APP_ZAPIER_ZOHO, {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        handleAlert('success', 'Tu problema se envió correctamente')
      })
      .catch((error) => {
        handleAlert('error', 'Algo salió mal al enviar tu problema.');
      });
  }

  return (
    <div className={classes.root}>
      {isLoading ?
        <div className='gralinfo_form'>
          <CircularProgress color="secondary" />
        </div>
        :
        <div className={`${classes.container}`}>
          <div className={classes.filterConteiner}>
            <Grid container>
              <Grid item xs={12} sm={12} md={5} className={classes.row}>
                <Typography className={classes.dateLabel}>
                  Desde
                    </Typography>
                <PrimaryInput
                  onChange={handleChange}
                  value={fromDate}
                  id={'fromDate'}
                  type="datetime-local"
                  name={'fromDate'}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={5} className={classes.row}>
                <Typography className={classes.dateLabel}>
                  Hasta
                    </Typography>
                <PrimaryInput
                  onChange={handleChange}
                  value={toDate}
                  id={'toDate'}
                  type="datetime-local"
                  name={'toDate'}
                />
              </Grid>
            </Grid>
          </div>
          {!ordersHistory ?
            <div>
              {props.from ?
                <div>
                  <Button className={classes.buttonStyle} onClick={() => handleProblem()}>
                    <Typography className={classes.redText}>
                      No encuentro mi pedido
                    </Typography>
                  </Button>
                </div>
                : null
              }
              <div className={`${classes.viewOrdersContainer}`}>
                <Button onClick={() => viewHistory(true)}>
                  <Typography className={`${classes.viewOrdersText}`}> Ver historial de pedidos </Typography>
                </Button>
              </div>
              <div className={`${classes.rowStyle} overflow-auto`}>
                {renderStatusContainer(
                  "Ordenes pendientes",
                  ordersRedux.filter(order => order.orderStatus == 'received'),
                  classes.colorYellow)}
                <div className={classes.separador}></div>
                {renderStatusContainer(
                  "En preparación",
                  ordersRedux.filter(order => order.orderStatus == 'processing'),
                  classes.colorOrange)}
                <div className={classes.separador}></div>
                {renderStatusContainer(
                  "Listos para entregar",
                  ordersRedux.filter(order => order.orderStatus == 'ready'),
                  classes.colorGreen)}
              </div>
            </div>
            :
            <div>
              <div className={`${classes.searchContainer}`}>
                <Button className={classes.backButton} onClick={() => viewHistory(false)}>
                  <img className={classes.backIcon} width="15" height="15" src={arrowBack} />
                  <Typography className={`${classes.viewOrdersText}`}>Atras</Typography>
                </Button>
                <DefaultSearch
                  label="Buscar pedido"
                  value={searchedWord}
                  handleChange={handleChangeSearch}
                  keyPress={isEnter}
                />
              </div>
              <div className="mt-3 overflow-auto">
                <div className={classes.deliveredOrdersTable}>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>
                          <Typography className={classes.tableHeaderText}>Estatus</Typography>
                        </th>
                        <th >
                          <Typography className={classes.tableHeaderText}>Fecha</Typography>
                        </th>
                        <th>
                          <Typography className={classes.tableHeaderText}>No. Pedido</Typography>
                        </th>
                        <th>
                          <Typography className={classes.tableHeaderText}>Cliente</Typography>
                        </th>
                        <th>
                          <Typography className={classes.tableHeaderText}>Acciones</Typography>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderOrders()}
                    </tbody>
                  </table>
                  <div className={classes.paginationTAB}>
                    <Pagination count={pagination.pages} shape="rounded" onChange={handlePagination} />
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      }
      {renderOrderDetails()}
    </div>
  );
}

export default withSnackbar(Tab3);