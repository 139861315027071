import { createSlice } from '@reduxjs/toolkit';

export const orderSlice = createSlice({
  name: 'orders',
  initialState: {
    value: [],
  },
  reducers: {
    addOrders: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { addOrders } = orderSlice.actions;

export default orderSlice.reducer;