import { createSlice } from '@reduxjs/toolkit';

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    value: null,
  },
  reducers: {
    addSettings: (state, action) => {
      const item = {};
      action.payload.forEach(service => {
        item[service.settingRecordKey] = service.setup;
      });
      state.value = item;
    },
  },
});

export const { addSettings } = settingsSlice.actions;

export default settingsSlice.reducer;