const getUserByEmailAndPhone = async (email, phone, type = "newUser") => {
  try {
    const encodedPhone = encodeURIComponent(phone);
    const encodedEmail = encodeURIComponent(email);

    const result = await fetch(
      `${process.env.REACT_APP_USER_API}/analytics/getUserByEmail?email=${encodedEmail}&phone=${encodedPhone}&type=${type}`,
      {
        method: "GET",
        headers: {
          "x-api-key": process.env.REACT_APP_USER_API_KEY,
          "Content-Type": "application/json",
        },
      }
    );
    const res = await result.json();

    if (res.success) {
      return {
        success: true,
        data: res.data,
      };
    } else {
      return {
        success: false,
        msg: "Algo salio mal",
      };
    }
  } catch (err) {
    return {
      success: false,
      error: "Algo salio mal",
    };
  }
};

export default getUserByEmailAndPhone;
