import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '../../assets/css/main.css';
import {
  TextField,
  Checkbox,
  Typography,
  FormControlLabel,
} from '@material-ui/core';
import PrimaryButton from '../common/primaryButton/index.js';
import SecondaryButton from '../common/secondaryButton/index.js';
import image from '../../assets/images/upload-image.png';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withSnackbar } from 'notistack';
import moment from 'moment-timezone';
import { Auth, API, Storage } from 'aws-amplify';
import { API_TRANSACTION } from '../../lib/constants';
import { CSVLink } from 'react-csv';

const useStyles = makeStyles(theme =>({
  container: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '90vh',
    overflow: 'scroll',
  },
  loadingContainer: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '90vh',
  },
  title: {
    textAlign: 'center',
    ...theme.typography.title,
  },
  subtitle: {
    textAlign: 'center',
    ...theme.typography.subtitle,
  },
  checkboxText: {
    ...theme.typography.checkbox,
  },
  buttons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'center',
    },
  },
  rowStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  dateField: {
    border:0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  formDate: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  loading: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    ...theme.typography.button,
    colot: 'white',
  },
  buttonStyle: {
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

const ModalReport = (props) => {
  let history = useHistory();

  const [isLoading, setLoading] = useState(false);
  const initialFromDate = `${moment().format('YYYY-MM-DD')}T00:00`;
  const initialToDate = `${moment().format('YYYY-MM-DD')}T23:59`;
  const [fromDate, setFromDate] = useState(initialFromDate);
  const [toDate, setToDate] = useState(initialToDate);
  const [stores, setStores] = useState(props.stores);
  const [storesRecordKeys, setStoresRecordKeys] = useState([]);
  const [shouldShowDownloadCSV, setShouldShowDownloadCSV] = useState(false);
  const [csvReport, setCsvReport] = useState([]);
  const [csvReportFileName, setCsvReportFileName] = useState('REPORTE.csv');
  const [merchantId, setMerchant] = useState(props.merchantId);

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const handleFromDate = ({ target }) => setFromDate(target.value);

  const handleToDate = ({ target }) => setToDate(target.value);

  const getListStoreName = stores.map(({ id, storeName }) => ({
    storeRecordKey: id,
    storeName,
  }));

  const handleCheckBoxStores = (event) => {
    const { checked, value } = event.target;

    if (checked) {
      const updatedStoresRecordKeys = [...storesRecordKeys, value];
      setStoresRecordKeys(updatedStoresRecordKeys);
    } else {
      const filterPattern = (storeRecordKey) => storeRecordKey !== value;
      const updatedStoresRecordKeys = storesRecordKeys.filter(filterPattern);
      setStoresRecordKeys(updatedStoresRecordKeys);
    }
  };

  const handleCreateReport = async () => {
    try {
      setShouldShowDownloadCSV(false);
      setLoading(true);
      const tz = moment.tz.guess();
      const utcTimeFrom = moment(fromDate).tz(tz).utc().valueOf();
      const utcTimeTo = moment(toDate).tz(tz).utc().valueOf();
      const fromDateString = moment(utcTimeFrom).tz(tz).format('DD-MM-YYYY');
      const toDateString = moment(utcTimeTo).tz(tz).format('DD-MM-YYYY');

      const response = await API.get(
        API_TRANSACTION,
        '/transaction/branches/movements',
        {
          queryStringParameters: {
            merchantRecordKey: merchantId,
            storeRecordKeys: storesRecordKeys,
            startDate: utcTimeFrom,
            endDate: utcTimeTo,
          },
        }
      );

      const updateTransactions = response.data.map((transaction) => {
        const utcDate = transaction['FECHA Y HORA'];
        const dateString = moment(utcDate).tz(tz).format('DD-MM-YYYY HH:mm:ss');
        return { ...transaction, 'FECHA Y HORA': dateString };
      });

      setCsvReport(updateTransactions);
      setShouldShowDownloadCSV(true);
      setCsvReportFileName(`REPORTE_${fromDateString}_${toDateString}.csv`);
    } catch (error) {
      console.error('error:', error.message);
      setShouldShowDownloadCSV(false);
      handleAlert('error', 'Ocurrió un error al crear el reporte');
    } finally {
      setLoading(false);
    }
  };

  const classes = useStyles();
  return (
    <>
      {isLoading ? (
        <div className={`${classes.loadingContainer}`}>
          <div className={classes.loading}>
            <CircularProgress color='secondary' />
          </div>
        </div>
      ) : (
          <>
            <div className={`${classes.container} scrollBarHidden`}>
              <h1 className={classes.title}>Reporte de Sucursales</h1>
              <div className='col-auto mt-4 '>
                <h6 className={classes.subtitle}>Selecciona un rango de fecha</h6>
                <div>
                  <form className={classes.formDate} noValidate>
                  <label >
                    Desde:
                    <br></br>
                    <input
                      id='datetime-from'
                      name='fromDate'
                      value={fromDate}
                      onChange={handleFromDate}
                      label='Desde'
                      type='datetime-local'
                      className={classes.dateField}
                      max={`${moment().format('YYYY-MM-DD')}T00:00`}
                    />
                    </label>
                  </form>
                  <form className={classes.formDate} noValidate>
                    <label >
                        Hasta:  
                        <br></br>
                  <input
                     className={classes.dateField}
                      id='datetime-to'
                      name='toDate'
                      value={toDate}
                      min="2018-06-07T00:00" 
                      max={`${moment().format('YYYY-MM-DD')}T23:59`}
                      onChange={handleToDate}
                      label='Hasta'
                      type='datetime-local'
                      
                    />
                  </label>
                  </form>
                </div>
              </div>
              <div className='col-auto mt-4'>
                <h6 className={classes.subtitle}>
                  Selecciona las sucursales a incluir en el reporte:
            </h6>
                <div className='overflow-auto list-container scrollBarHidden'>
                  <ul className='store-list'>
                    {getListStoreName.map(({ storeRecordKey, storeName }) => (
                      <li>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={handleCheckBoxStores}
                              className={classes.checkBoxColor}
                              value={storeRecordKey}
                            />
                          }
                          label={<Typography variant='p'>{storeName}</Typography>}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className={classes.buttons}>
                <SecondaryButton
                  type='button'
                  paddingHorizontal={25}
                  secondaryButtonStyle={classes.buttonStyle}
                  onClick={props.handleClose}
                >
                  Cancelar
            </SecondaryButton>
                <PrimaryButton
                  type='button'
                  paddingHorizontal={25}
                  onClick={handleCreateReport}
                  disabled={shouldShowDownloadCSV && true}
                  primaryButtonStyle={classes.buttonStyle}
                >
                  Generar
            </PrimaryButton>
              </div>
              <div className={classes.squareDownloadBox}>
                {shouldShowDownloadCSV ? (
                  <PrimaryButton fullWidth>
                    <CSVLink
                      data={csvReport}
                      className='linkReport'
                      filename={csvReportFileName}
                      primaryButtonStyle={classes.buttonStyle}
                    >
                      DESCARGAR CSV
                </CSVLink>
                  </PrimaryButton>
                ) : isLoading ? (
                  <div className='styleCircularProgress'>
                    <CircularProgress color='secondary' />
                  </div>
                ) : null}
              </div>
            </div>
          </>
        )}
    </>
  );
};

export default withSnackbar(ModalReport);
