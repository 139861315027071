import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import PrimaryButton from '../../components/common/primaryButton';
import PrimarySelect from '../../components/common/primarySelect';
import CancelButton from '../../components/common/cancelButton';
import { withSnackbar } from 'notistack';
import 'react-image-crop/dist/ReactCrop.css';

const useStyles = makeStyles(theme => ({
    container: {
        width: 'auto',
        height: 'auto',
        maxHeight: '90vh',
    },
    title: {
        textAlign: 'center',
        ...theme.typography.title,
    },
    imageAndFormContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    image: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: 200,
        height: 200,
        margin: 10,
        borderRadius: 10,
    },
    checkboxText: {
        ...theme.typography.checkbox
    },
    buttons: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    loading: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    helpContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 10,
    },
    helpText: {
        ...theme.typography.mediumText,
        color: 'red',
        cursor: 'pointer',
    },
    imageContainer: {
        display: 'flex',
        flexDirection: 'column',
        ...theme.globals.center,
    },
    imageSize: {
        width: 180,
        height: 'auto',
    },
    spacer: { paddingTop: 20 },
    buttonGroupContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column-reverse',
        },
    },
    buttonContainer: {
        width: '100%',
        ...theme.globals.center,
    },
    fileImputContainer: {
        width: '100%',
        ...theme.globals.center
    },
    hidden: {
        visibility: 'false',
    },
    cropContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            alignItems: 'center'
        },
    }
}));

const ModalEliminar = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div>
                <Typography className={classes.title}>¿Estás seguro que deseas eliminar tu cuenta?</Typography>
            </div>
            <div className={classes.spacer} />
            <div>
                <div>
                    <div className={classes.fileImputContainer}>
                        Por favor describe la razón: <br></br>
                        <PrimarySelect
                            value={props.reason}
                            content={props.reasons.map((item, index) => {
                                return <option key={index} value={item}>{item}</option>;
                            })}
                            onChange={props.handleReasonChange}
                            id={'reasonSelect'}
                            name={'reasonSelect'}
                            error={props.error}
                        >
                        </PrimarySelect>
                    </div>
                </div>
                <div>
                    <div className={classes.buttonGroupContainer}>
                        <div className={classes.buttonContainer}>
                            <CancelButton
                                onClick={props.handleClose}
                                fullWidth
                            >
                                Cancelar
                            </CancelButton>
                        </div>
                        <div className={classes.buttonContainer}>
                            <PrimaryButton
                                onClick={props.handleAccept}
                                fullWidth
                            >
                                Continuar
                            </PrimaryButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withSnackbar(ModalEliminar);
