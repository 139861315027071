import React from 'react';
import { useEffect } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import {makeStyles} from "@material-ui/core/styles";

import '../../../assets/css/main.css';

const useStyles = makeStyles({
  divider: {
    borderBottom: '1px solid',
    marginRight: 16,
    marginLeft: 16,
  },
});

export default function DefaultSelect(props) {
  useEffect(()=>{
  },[])

  const classes = useStyles();

  return (
    <FormControl className={props.custom.classForm}>
        <InputLabel shrink className={props.custom.classLabel}>{props.custom.labelText}</InputLabel>
        <Select
          id={props.custom.id}
          name={props.custom.name}
          className={props.custom.className}
          value={props.custom.value}
          onChange={props.custom.handleChange}
          displayEmpty
        >
            {props.custom.placeholder && <MenuItem value="" disabled>
                {props.custom.placeholder}
            </MenuItem>}
            {props.custom.items.map(item => {
              if(item === '')
                return (<MenuItem className={classes.divider} value="" disabled/>)
                return (
                    <MenuItem value={item}>{item}</MenuItem>
                )
            })}
        </Select>
        <FormHelperText>{props.custom.error}</FormHelperText>
      </FormControl>
  );
}