module.exports = {
    API_PROFILE: 'profile-api',
    API_TRANSACTION: 'transaction-api',
    API_CREDIT: 'credit-api',
    API_EVENT: 'event-api',
    API_INVENTORY: 'inventory-api', 
    API_INVITATION: 'invitation-api',
    API_INVOICE: 'invoice-api',
    API_PAYMENT: 'payment-api',
    API_PRODUCT: 'product-api',
    API_SETTING: 'setting-api',
    API_STORE: 'store-api',
    API_INSHOP: 'inshop-api',
    API_SITI: 'siti-api',
    API_KEYS: 'keys-api',
    cp_length: 5,
    API_SPEI: 'speis-api',
    API_ANALYTICS: 'analytics-api',
    phone: '/^\+[0-9]{1,3}\d{10}$/.test(value.replace(/ /g, ""))',
    API_MERCHANT: 'merchant-api',
    API_NOTIFICATIONS_PUSH: 'notifications-push-api',
    API_WEBSOCKETS: 'websocket-api',
    API_INTEGRATIONS: 'integrations-api',
    API_LOCATIONS: 'locations-api',
    API_EMIDAMEX: 'emidamex-api',
    API_ZOHODESK: 'zohoDesk-api',
    API_ANCHORS: 'anchors-api',
    MONTHS: ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'],
    ABBREVIATED_DAYS: ['L','M','Mi','J','V','S','D'],
    SCHEDULE_CARD_COLOR: '#FAFAFA',
    GREEN: '#3B9A83', // Primary
    WHITE: '#FFFFFF',
    DARK_GREY: '#434343', // For texts
    LIGHT_GREY: '#9A9A9A', // Lines
    BACKGROUND_GREY: '#EDEDED', // SearchBar Background
    RED: '#EE715B', // Closed or cancel
    FONT_TITLE: {
      fontFamily: 'Montserrat Variable',
      fontSize: '24',
      fontWeight: '700',
      color: '#434343',
    },
    FONT_DEFAULT: {
      fontFamily: 'Montserrat Variable',
      fontSize: '16',
      fontWeight: '500',
      color: '#434343',
    },
    FONT_SMALLER: {
      fontFamily: 'Montserrat Variable',
      fontSize: '14',
      fontWeight: '500',
      color: '#434343',
    },
    BUTTON_PRIMARY: {
      fontFamily: 'Montserrat Variable',
      fontSize: '14',
      fontWeight: '700',
      color: '#FFFFFF',
      backgroundColor: '#3B9A83',
      borderRadius: '10',
    },
    BUTTON_SECONDARY: {
      fontFamily: 'Montserrat Variable',
      fontSize: '14',
      fontWeight: '700',
      color: '#3B9A83',
      BackgroundColor: '#FFFFFF',
      borderRadius: '10',
      border: 'solid 2px #3B9A83',
    },
    MAX_INACTIVITY_MINUTES: 29.75,
    MINUTES_BEFORE_INACTIVITY_LOGOUT: 0.25,
    REGEX_EMAIL: /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
    REGEX_RFC: /^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/,
    GENDERS: [
      {label: "Masculino", value: "male"},
      {label: "Femenino", value: "female"},
      {label: "No Binario", value: "nonbinary"},
    ],
    categories: [{label: "Abarrotes", value: "Abarrotes"},
    {label: "Artículos de oficina", value: "Artículos de oficina"}, 
    {label: "Automotriz", value: "Automotriz"},
    {label: "Belleza y cuidado personal", value: "Belleza y cuidado personal"},
      {label: "Comida a domicilio", value: "Comida a domicilio"},
      {label: "Consolas y videojuegos", value: "Consolas y videojuegos"},
      {label: "Deportes", value: "Deportes"},
      {label: "Electrodomésticos", value: "Electrodomésticos"},
      {label: "Electrónicos", value: "Electrónicos"},
      {label: "Farmacia", value: "Farmacia"},
      {label: "Herramientas", value: "Herramientas"},
      {label: "Infantil", value: "Infantil"},
      {label: "Mascotas", value: "Mascotas"},
      {label: "Moda", value: "Moda"},
      {label: "Muebles y decoración", value: "Muebles y decoración"},
      {label: "Papelería", value: "Papelería"},
      ],
    REGEX_LETTERS_AND_NUMBERS: /^[0-9a-zA-Z]*$/,
  };
  