const BusinessTypeItems = () => {
  return [
    { label: 'Selecciona una categoría', value: '', section: 'Default' },
    {
      label: 'Comercio al por menor de alimentos, bebidas y tabaco',
      value: 'Comercio al por menor de alimentos, bebidas y tabaco',
      section: 'Más frecuentes',
      code: 61,
    },
    {
      label:
        'Comercio al por menor de vehículos de motor, refacciones, combustibles y lubricantes',
      value:
        'Comercio al por menor de vehículos de motor, refacciones, combustibles y lubricantes',
      section: 'Más frecuentes',
      code: 468,
    },
    {
      label:
        'Comercio al por menor en tiendas de autoservicio y departamentales',
      value:
        'Comercio al por menor en tiendas de autoservicio y departamentales',
      section: 'Más frecuentes',
      code: 462,
    },
    {
      label: 'Agricultura, ganadería, aprovechamiento forestal, pesca y caza',
      value: 'Agricultura, ganadería, aprovechamiento forestal, pesca y caza',
      section: 'Orden Alfabético',
      code: 11,
    },
    {
      label:
        'Comercio al por menor de artículos de ferretería, tlapalería y vidrios',
      value:
        'Comercio al por menor de artículos de ferretería, tlapalería y vidrios',
      section: 'Orden Alfabético',
      code: 467,
    },
    {
      label:
        'Comercio al por menor de artículos de papelería, para el esparcimiento y otros artículos de uso personal',
      value:
        'Comercio al por menor de artículos de papelería, para el esparcimiento y otros artículos de uso personal',
      section: 'Orden Alfabético',
      code: 465,
    },
    {
      label: 'Comercio al por menor de artículos para el cuidado de la salud',
      value: 'Comercio al por menor de artículos para el cuidado de la salud',
      section: 'Orden Alfabético',
      code: 464,
    },
    {
      label:
        'Comercio al por menor de enseres domésticos, computadoras y artículos para la decoración de interiores',
      value:
        'Comercio al por menor de enseres domésticos, computadoras y artículos para la decoración de interiores',
      section: 'Orden Alfabético',
      code: 466,
    },
    {
      label:
        'Comercio al por menor de productos textiles, accesorios de vestir y calzado',
      value:
        'Comercio al por menor de productos textiles, accesorios de vestir y calzado',
      section: 'Orden Alfabético',
      code: 463,
    },
    {
      label: 'Construcción',
      value: 'Construcción',
      section: 'Orden Alfabético',
      code: 23,
    },
    {
      label: 'Dirección de corporativos y empresas',
      value: 'Dirección de corporativos y empresas',
      section: 'Orden Alfabético',
      code: 55,
    },
    {
      label:
        'Electricidad, agua y suministro de gas por ductos al consumidor final',
      value:
        'Electricidad, agua y suministro de gas por ductos al consumidor final',
      section: 'Orden Alfabético',
      code: 22,
    },
    {
      label: 'Industrias manufactureras',
      value: 'Industrias manufactureras',
      section: 'Orden Alfabético',
      code: 31,
    },
    {
      label:
        'Intermediación y comercio al por menor por medios masivos de comunicación y otros medios',
      value:
        'Intermediación y comercio al por menor por medios masivos de comunicación y otros medios',
      section: 'Orden Alfabético',
      code: 469,
    },
    {
      label: 'Otros servicios excepto actividades del gobierno',
      value: 'Otros servicios excepto actividades del gobierno',
      section: 'Orden Alfabético',
      code: 81,
    },
    {
      label:
        'Servicios de alojamiento temporal y de preparación de alimentos y bebidas',
      value:
        'Servicios de alojamiento temporal y de preparación de alimentos y bebidas',
      section: 'Orden Alfabético',
      code: 72,
    },
    {
      label:
        'Servicios de apoyo a los negocios y manejo de desechos y servicios de remediación',
      value:
        'Servicios de apoyo a los negocios y manejo de desechos y servicios de remediación',
      section: 'Orden Alfabético',
      code: 56,
    },
    {
      label:
        'Servicios de esparcimiento culturales y deportivos, y otros servicios recreativos',
      value:
        'Servicios de esparcimiento culturales y deportivos, y otros servicios recreativos',
      section: 'Orden Alfabético',
      code: 71,
    },
    {
      label: 'Servicios de salud y de asistencia social',
      value: 'Servicios de salud y de asistencia social',
      section: 'Orden Alfabético',
      code: 62,
    },
    {
      label: 'Servicios educativos',
      value: 'Servicios educativos',
      section: 'Orden Alfabético',
      code: 61,
    },
    {
      label: 'Servicios financieros y de seguros',
      value: 'Servicios financieros y de seguros',
      section: 'Orden Alfabético',
      code: 52,
    },
    {
      label:
        'Servicios inmobiliarios y de alquiler de bienes muebles e intangibles',
      value:
        'Servicios inmobiliarios y de alquiler de bienes muebles e intangibles',
      section: 'Orden Alfabético',
      code: 53,
    },
    {
      label: 'Servicios profesionales, científicos y técnicos',
      value: 'Servicios profesionales, científicos y técnicos',
      section: 'Orden Alfabético',
      code: 54,
    },
  ];
}

export default BusinessTypeItems;