import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import { Auth, API, Storage } from 'aws-amplify';
import { withSnackbar  } from 'notistack';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import getMerchantData from '../../../../lib/getMerchant';
import addPorductIcon from '../../../../assets/images/addProductIcon.png'
import noProduct from '../../../../assets/images/noProducts.svg'
import Checkbox from '@material-ui/core/Checkbox';
import defaultProductImage from '../../../../assets/images/defaultProductImage.svg'

const useStyles = makeStyles(theme => ({
    drawer: {
        flexShrink: 0,
        
    },
    addInventory:{
        minWidth: 500,
        padding: '0px 30px 0px 30px'
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        ...theme.mixins.toolbar,
        color: '#434343' 
    },
    drawerBody: {
        padding: 5,
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    drawerBodyNoPointer: {
        padding: 5,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign:'center'
    },
    cancelButtonStyle: {
        border: '1px solid #DA7B7B',
        boxSizing: 'border-box',
        borderRadius: 5,
        width: 146,
        height: 38,
        margin: 2
    },
    cancelButtonText: {
        ...theme.typography.extraSmallText,
        color: '#DA7B7B'
    },
    productImage: {
        width: 60,
        height: 50,
        objectFit: 'contain'
    },
    productInfo: {
        display: 'flex',
        flexDirection: 'column'
    },
    textCenter: {
        ...theme.typography.productName,
        marginBottom: 5,
        opacity: '.6'
    },
    price: {
        fontSize: 14,
        fontFamily: 'Oxanium',
        fontWeight: '500'
    },
    successButtonStyle: {
        border: '1px solid #3B9A83',
        boxSizing: 'border-box',
        borderRadius: 5,
        height: 38,
        margin: 2,
        marginRight: '10px',
        minWidth: '40%'
    },
    successButtonText: {
        ...theme.typography.successText,
    },
    warningButtonStyle: {
        border: '1px solid #EFAB23',
        boxSizing: 'border-box',
        borderRadius: 5,
        height: 38,
        margin: 2,
        minWidth: '40%'
    },
    warningButtonText: {
        ...theme.typography.warningText,
    },
    title:{
        ...theme.typography.paragraph,
        marginTop: '40px',

    },
    subtitle:{
        ...theme.typography.mediumText,
        marginTop: '10px',
        marginBottom: '25px',
        color: 'rgba(67, 67, 67, 0.6)'
    },
    checkboxColor: {
        color: '#3B9A83',
        backgroudn: '#3B9A83'
    },
    imgHolder: {
        display: 'flex',
        flexDirection: 'column',
        width: '300px',
        textAlign: 'center',
        margin: '60px 0px 60px 60px'
    },
    noProductText: {
        ...theme.typography.paragraph,
        marginTop: 30
        
    },
    noProductTextSub: {
        ...theme.typography.advice,
        opacity: '.6',
        fontWeight: '400'
    },
    noProductConteiner: {
        textAlign: 'center',
        justifyContent: 'center'
    }
}));

function TemporaryDrawer(props) {
    const userKey = useSelector(state => state.user.value);

    useEffect(()=>{
        getProductsList();
    },[])

    const classes = useStyles();
    let history = useHistory();

    const handleAlert = (variant, message) => {
        // variant could be success, error, warning, info, or default
        props.enqueueSnackbar( message, { variant });
    }

    const handleChangeCheckbox = (productRecordKey) => {
        const index = addInventoryList.indexOf(productRecordKey);
        if (index == -1) {
            addInventoryList.push(productRecordKey);
        }
        else {
            addInventoryList.splice(index, 1);
        }
        setList(addInventoryList)
    }

    const [loading, setLoading] = useState(false);
    const [productList, setProductsList] = useState([]);
    const [noMoreProducts, setNoMoreProducts] = useState(false);
    const [addInventoryList, setList] = useState([]);
    
    const getProductsList = async () => {
        setLoading(true)
        const getUser = await Auth.currentAuthenticatedUser();
        try {
            const getGralProfile = await API.get('profile-api', `/profile/${userKey}`);
            let merchant = null
            if (getGralProfile.data[0].merchantRecordKey) {
                merchant = await getMerchantData('merchant', getGralProfile.data[0].merchantRecordKey)
            } else if (getGralProfile.data[0].isEmployeeOfStore && getGralProfile.data[0].isEmployeeActive) {
                merchant = await getMerchantData('store', getGralProfile.data[0].isEmployeeOfStore)
            } else if (getGralProfile.data[0].isManagerOfStore && getGralProfile.data[0].isEmployeeActive) {
                merchant = await getMerchantData('store', getGralProfile.data[0].isManagerOfStore)
              }
            if (merchant === null) {
                handleAlert("error",'Ocurrió un error al obtener la información del usuario');
                history.push('/login')
            } else {
                const getProducts = await API.get('product-api', '/products', {
                    queryStringParameters: {
                        FilterExpression: `merchantRecordKey=:merchantRecordKey`,
                        ExpressionAttributeValues: `:merchantRecordKey=${merchant.merchantRecordKey}`
                    }
                });
                const products = getProducts.data;
                const validProductList = products.filter(({productRecordKey: key1}) => !props.inventory.some(({productRecordKey: key2}) => key1 === key2));
                const pImages = validProductList.map(item => Storage.get(`products/${item.productRecordKey}/default.jpg`));
                const p = await Promise.all(pImages);
                const upProductList = validProductList.map((value, index) => ({
                    ...value,
                    productImage: value.hasImage ? p[index] : defaultProductImage
                }));
                setProductsList(upProductList);
                upProductList.length === 0 ? 
                    setNoMoreProducts(true) 
                :
                    setNoMoreProducts(false);
                setLoading(false);
            }
        } catch (err) {
            handleAlert("Error",err);
            setLoading(false);
        }    
    }
    
    const addProductsToInventory = async (allProducts) => {
        const usingList = allProducts ? productList : addInventoryList
        setLoading(true)
        if (usingList.length > 0) {
            console.log(usingList)
            try {
                const addInventory = usingList.map((item, index) =>
                    API.post('inventory-api', '/inventory', {
                        body: {
                            entityId: props.storeKey,
                            entityKey: "store",
                            quantity: 0,
                            status: "disponible",
                            productId: allProducts ? item.productRecordKey : item
                        },
                    }));
                await Promise.all(addInventory);
                handleAlert('success', allProducts ? 'Productos Guardados con Éxito!' : 'Productos Seleccionados Guardados con Éxito!')
                setLoading(false)
                props.cancel()
            } catch (err) {
                handleAlert('warning', 'Error!')
                setLoading(false)
                props.cancel()
            }
        } else {
            handleAlert('warning', 'Seleccione uno o más productos')
            setLoading(false)
        }
    }

    const renderProductList = () => {
        if(productList.length === 0)
        {
            return (
                <Grid item lg={12} className={classes.drawerBodyNoPointer}>
                    <div >
                        <div className={classes.noProductConteiner}>
                            <div className={classes.imgHolder}>
                                <img className={classes.noProduct} src={noProduct}></img>
                                <b className={classes.noProductText}>No hay productos para agregar</b>
                                <b className={classes.noProductTextSub}> Ya agregaste todos tus productos al inventario o no haz dado de alta ningún producto en la sección de productos</b>
                            </div>
                        </div>
                    </div>
                </Grid>
            )
        }
        else
        {
            return  productList.map(product => (
                <Grid key={product.productRecordKey} item lg={12}  className={classes.drawerBody}>
                    <div >
                        <Grid container className="pt-3 pb-3">
                            <Grid item lg={4}>
                                <img className={classes.productImage} src={product.productImage} />
                            </Grid>
                            <Grid item lg={4} >
                                <div className={classes.productInfo}>
                                    <b className={classes.textCenter}>{product.productName}</b>
                                    <b className={classes.price}>{`$ ${product.price.replace(/[$,]+/g, '')}`}</b>
                                </div>
                            </Grid>
                            <Grid item lg={4} className="text-center">
                                <Checkbox 
                                    onChange={() => handleChangeCheckbox(product.productRecordKey)}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            ))
        }
    }

    const sideList = side => (
        <div className="col-12">
        {loading ?
            <div className='circulaLoading'>
                <CircularProgress color="secondary" />
            </div>
        :
            <div className={classes.addInventory}>
                <Typography className={`${classes.title}`}><img src={addPorductIcon} /> Agregar productos a inventario</Typography>
                <Typography className={`${classes.subtitle}`}>Selecciona los productos que quieras agregar a tu sucursal</Typography>
                <div className={`${classes.drawerHeader}`}>
                    <Button
                        disabled={noMoreProducts}
                        className={classes.successButtonStyle}
                        onClick={() => addProductsToInventory(false)}
                    >
                        <Typography className={classes.successButtonText}>AGREGAR PRODUCTOS SELECCIONADOS</Typography>
                    </Button>
                    <Button
                        disabled={noMoreProducts}
                        className={classes.warningButtonStyle}
                        onClick={() => addProductsToInventory(true)}
                    >
                        <Typography className={classes.warningButtonText}>AGREGAR TODO</Typography>
                    </Button>
                </div>
                <Divider />
                    {renderProductList()}
                <Divider />
                <div className="text-center pt-3">
                    <Button className={classes.cancelButtonStyle} onClick={props.cancel}>
                        <Typography className={classes.cancelButtonText}>Cancelar</Typography>
                    </Button>
                </div>
            </div>
        }   
    </div>
    );

    return (
        <div>
        <Drawer anchor="right" open={props.open} onClose={props.cancel}>
            {sideList('right')}
        </Drawer>
        </div>
    );
}
export default withSnackbar(TemporaryDrawer);

