export default function States() {
    return [
        { label: 'Selecciona un estado', section: 'Default' },
        { label: 'Aguascalientes', section: 'content' },
        { label: 'Baja California', section: 'content' },
        { label: 'Baja California Sur', section: 'content' },
        { label: 'Chihuahua', section: 'content' },
        { label: 'Chiapas', section: 'content' },
        { label: 'Campeche', section: 'content' },
        { label: 'Ciudad de México', section: 'content' },
        { label: 'Coahuila de Zaragoza', section: 'content' },
        { label: 'Colima', section: 'content' },
        { label: 'Durango', section: 'content' },
        { label: 'Guerrero', section: 'content' },
        { label: 'Guanajuato', section: 'content' },
        { label: 'Hidalgo', section: 'content' },
        { label: 'Jalisco', section: 'content' },
        { label: 'Michoacán de Ocampo', section: 'content' },
        { label: 'México', section: 'content' },
        { label: 'Morelos', section: 'content' },
        { label: 'Nayarit', section: 'content' },
        { label: 'Nuevo León', section: 'content' },
        { label: 'Oaxaca', section: 'content' },
        { label: 'Puebla', section: 'content' },
        { label: 'Quintana Roo', section: 'content' },
        { label: 'Querétaro', section: 'content' },
        { label: 'Sinaloa', section: 'content' },
        { label: 'San Luis Potosí', section: 'content' },
        { label: 'Sonora', section: 'content' },
        { label: 'Tabasco', section: 'content' },
        { label: 'Tlaxcala', section: 'content' },
        { label: 'Tamaulipas', section: 'content' },
        { label: 'Veracruz de Ignacio de la Llave', section: 'content' },
        { label: 'Yucatán', section: 'content' },
        { label: 'Zacatecas', section: 'content' }, 
    ]
} 
