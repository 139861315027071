import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ModalTimeout from './modalTimeout';
import ModalNewProduct from './modalNewProduct';
import ModalNewStore from './modalNewStore';
import ModalEliminar from './modalEliminar';
import ModalLegalDocuments from './modalLegalDocuments';
import ModalReport from './modalReport';
import ModalWelcome from './modalWelcome';
import ModalLogotipo from './modalLogotipo';
import ModalCookies from './modalCookies';
import ModalInventory from './modalInventory';
import ModalTransaction from './modalTransaction';
import ModalPLD from './modalPLD'
import ModalInfoUser from './modalInfoUser';
import ModalNeritoPay from '../neritoPay/registerNeritoPay/index';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3, 4),
  },
}));

const DefaultModal = (props) => {
  const classes = useStyles();

  const modalRouter = () => {
    switch (props.modalType) {
      case 'timeout':
        return (
          <ModalTimeout
            handleKeepSession={props.handleKeepSession}
            handleCloseSession={props.handleCloseSession}
          />
        );
      case 'newProduct':
        return (
          <ModalNewProduct
            handleClose={props.handleClose}
            reloadProducts={props.reloadProducts}
            editProduct={props.editProduct}
            edit={props.edit}
            from={props.from}
            email={props.merchantEmail}
            name={props.commercialName}
            merchantId={props.merchantId}
          />
        );
      case 'newStore':
        return (
          <ModalNewStore
            handleClose={props.handleClose}
            edit={props.edit}
            currentStore={props.currentStore}
            storeId={props.storeId}
            merchantId={props.merchantId}
            file={props.file}
          />
        );
      case 'terminos':
        return (
          <ModalLegalDocuments
            handleClose={props.handleClose}
            handleAccept={props.handleAccept}
            legalType={props.modalType}
          />
        );
      case 'privacidad':
        return (
          <ModalLegalDocuments
            handleClose={props.handleClose}
            handleAccept={props.handleAccept}
            legalType={props.modalType}
          />
        );
      case 'report':
        return (
          <ModalReport
            handleClose={props.handleClose}
            handleAccept={props.handleAccept}
            stores={props.stores}
            merchantId={props.merchantId}
          />
        );
      case 'welcome':
        return (
          <ModalWelcome
            handleClose={props.handleClose}
            handleAccept={props.handleAccept}
            welcomeType={props.modalType}
            content={props.content}
          />
        );
      case 'eliminar':
        return (
          <ModalEliminar
            open={props.open}
            handleClose={props.handleClose}
            handleAccept={props.handleAccept}
            reasons={props.reasons}
            reason={props.reason}
            handleReasonChange={props.handleReasonChange}
            error={props.error}
          />
        );
      case 'logotipo':
        return (
          <ModalLogotipo
            handleClose={props.handleClose}
            handleAccept={props.handleAccept}
            content={props.content}
            getImage={props.getImage}
            handleCloseProfileModal={props.handleCloseProfileModal}
            updateProfileImage={props.updateProfileImage}
            file={props.file}
            profilePicture={props.profilePicture}
          />
        );
      case 'cookies':
        return (
          <ModalCookies
            handleClose={props.handleClose}
          />
        );
      case 'inventario':
        return (
          <ModalInventory
            inventory={props.inventory}
            cancel={props.cancel}
            storeKey={props.storeKey}
          />
        );
      case 'transaction':
        return (
          <ModalTransaction
            transaction={props.transaction}
            handleClose={props.handleClose}
          />
        )
      case 'userInformation':
        return (
          <ModalInfoUser
            userInfo={props.userInfo}
            handleClose={props.handleClose}
          />
        )
      case 'PLD':
        return (
          <ModalPLD
            handleClose={props.handleClose}
          />
        )
      case 'NeritoPay':
        return (
          <ModalNeritoPay
            handleClose={props.handleClose}
            merchantId={props.merchantId}
            standBy={props.standBy}
          />
        )


      default:
        return null;
    }

  };

  return (
    <div>
      <Backdrop>
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          className={classes.modal}
          open={props.open}
          onClose={props.handleClose}
          closeAfterTransition
        >
          <Fade in={props.open}>
            <div className={classes.paper}>{modalRouter()}</div>
          </Fade>
        </Modal>
      </Backdrop>
    </div>
  );
};

export default DefaultModal;
