import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import theme from '../../../styles/theme';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { withSnackbar } from 'notistack';
import PrimaryButton from '../../common/primaryButton';
import PrimarySearchBar from '../../common/primarySearchBar';
import { API } from 'aws-amplify';
import { API_ANCHORS } from '../../../lib/constants';

const Anchors = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [anchors, setAnchors] = useState([]);
  const [shownAnchors, setShownAnchors] = useState([]);
  const [search, setSearch] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    getAnchorList();
  }, []);

  const getAnchorList = async () => {
    try {
      const response = await API.get(API_ANCHORS, '/anchors');
      setAnchors(response?.data?.anchors)
      setShownAnchors(response?.data?.anchors);
    } catch (err) {
      props.enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }

  const handleSearch = (event) => {
    const text = event.target.value.toLowerCase();
    setSearch(text);
    const anchorsFiltered = anchors.filter(item => {
      if (item.anchorRecordKey && item.anchorRecordKey.includes(text)) return true;
      if (item.name && item.name.toLowerCase().includes(text)) return true;
      if (item.paternalLastName && item.paternalLastName.toLowerCase().includes(text)) return true;
      if (item.maternalLastName && item.maternalLastName.toLowerCase().includes(text)) return true;
      return false;
    });
    setShownAnchors(anchorsFiltered);
    setPage(0);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const handleNavigation = (anchor) => {
    if (anchor) {
      history.push({
        pathname: '/admin/anchors/anchor',
        state: { anchor: anchor },
      });
    } else {
      history.push('/admin/anchors/anchor');
    }
  }

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <Typography className={classes.title}>
          Anclas
        </Typography>
        <PrimaryButton onClick={() => handleNavigation(null)}>
          Registrar Ancla
        </PrimaryButton>
      </div>
      <div className={classes.searchContainer}>
        <PrimarySearchBar
          label='Buscar'
          placeholder='Buscar'
          value={search}
          onChange={handleSearch}
          fullWidth
        />
      </div>
      <TableContainer className={classes.tableContainer}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>Rol</TableCell>
              <TableCell>Ciudad</TableCell>
              <TableCell>Afiliaciones</TableCell>
              <TableCell>Trx de Afiliaciones</TableCell>
              <TableCell align='center'>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? shownAnchors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : shownAnchors
            ).map((anchor) => (
              <TableRow key={anchor.anchorRecordKey}>
                <TableCell>
                  {anchor.anchorRecordKey}
                </TableCell>
                <TableCell>
                  {`${anchor.name} ${anchor.paternalLastName} ${anchor.maternalLastName}`}
                </TableCell>
                <TableCell>
                  {anchor.role}
                </TableCell>
                <TableCell>
                  {anchor.city}
                </TableCell>
                <TableCell>
                  {anchor.affiliations || 0}
                </TableCell>
                <TableCell>
                  {anchor.affiliationTransactions || 0}
                </TableCell>
                <TableCell align='center'>
                  <Tooltip title='Editar'>
                    <IconButton size='small' onClick={() => handleNavigation(anchor)}>
                      <EditIcon fontSize='small' htmlColor={theme.palette.warning.main} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, { label: 'Todos', value: -1 }]}
                count={shownAnchors.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}

export default withSnackbar(Anchors);

const useStyles = makeStyles(theme => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '40vh',
  },
  container: {
    padding: '30px',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    ...theme.typography.title
  },
  searchContainer: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  tableContainer: {
    marginTop: '20px',
  },
}));