import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Typography, Grid } from '@material-ui/core';
import docIcon from '../../../assets/images/doc_icon.svg';
import DefaultButton from '../../common/defaultButton';
import FileInput from '../fileInput';

const useStyles = makeStyles(theme => ({
  container: {
    ...theme.globals.center,
    padding: 10,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    width: '100%',
  },
  title: {
    ...theme.typography.mediumText,
    marginBottom: '10px',
  },
  card: {
    padding: 10,
    justifyContent: 'center',
  },
  uploadLabelContainer: {
    cursor: 'pointer',
    ...theme.typography.smallText,
    color: theme.palette.primary.main,
    borderRadius: 5,
    border: '1px solid #3d9a83',
    width: '50%',
    padding: '5px 15px 5px 15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px',
  },
  uploadLabel: {
    cursor: 'pointer',
    marginBottom: '0px !important',
  },
  btnVP: {
    ...theme.typography.extraSmallText,
    color: 'rgba(67, 67, 67, 0.6)',
    cursor: 'pointer',
    marginTop: 5,
  },
  btnEA: {
    ...theme.typography.extraSmallText,
    color: '#DA7B7B',
    cursor: 'pointer',
    marginLeft: 10,
    marginTop: 5,
  },
  cardContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  imageContainer: {
    ...theme.globals.center,
  },
  titleAndButtonsContainer: {
    ...theme.globals.centerVertical,
    width: '100%',
    padding: 10,
  },
  fileNameContainer: {
    width: '100%',
    paddingRight: 5,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textAlign: 'center',
  },
  fileName: {
    ...theme.typography.extraSmallText,
  },
}));

export default function CardDocument(props) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={12} sm={2} className={classes.imageContainer}>
          <img width='36' height='36' src={docIcon} />
        </Grid>
        <Grid item xs={12} sm={10}>
          <div className={classes.titleAndButtonsContainer}>
            <Typography className={`${classes.title} `} gutterBottom>
              {props.title}
            </Typography>
            <FileInput
              type='file'
              name={props.fileType}
              id={props.fileType}
              onChange={props.getFile}
              width={130}
            />
            {props.file ? (
              <div className={classes.fileNameContainer}>
                <text className={classes.fileName}>{props.file.name}</text>
              </div>
            ) : (
              <div style={{ height: 27 }}></div>
            )}
            <div className={classes.actionsContainer}>
              {props.file ? (
                <a
                  className={classes.btnVP}
                  href={URL.createObjectURL(props.file)}
                  download={props.file.name}
                >
                  Descargar
                </a>
              ) : (
                <Typography className={classes.btnVP}>Descargar</Typography>
              )}
              <Typography
                className={classes.btnEA}
                onClick={() => props.handleDelete(props.fileType)}
              >
                Eliminar Archivo
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
