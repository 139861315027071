import React from 'react';
import { useEffect } from 'react';
import { API } from 'aws-amplify';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../../../../assets/css/main.css';
import DefaultSelect from '../../../common/defaultSelect';
import { Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import * as R from 'ramda';
import { FONT_DEFAULT } from '../../../../lib/constants.js'

import PrimaryInput from '../../../common/primaryInput';
import PrimarySelect from '../../../common/primarySelect';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 10px 0px 10px',
  },
  columnTitle: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 14,
    color: '#333333'
  },
  columnSubTitle: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    color: 'rgba(67, 67, 67, 0.6)'
  },
  rowSpacing: {
    marginTop: 20
  },
  IvaLegend: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 12,
    textAlign: 'center',
    color: '#828282',
    marginBottom: 10
  },
  buttonStyle: {
    border: '1px solid #3B9A83',
    boxSizing: 'border-box',
    borderRadius: 5,
    width: 146,
    height: 38,
    margin: 2
  },
  buttonText: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 10,
    color: '#3B9A83'
  },
  buttonContainer: {
    marginTop: 60
  }
});

function Tab5(props) {
  useEffect(() => { }, [])
  const columnIdentifiers = ['chargedClient', 'chargedCommerce', 'paidCommerce']
  const rowIdentifiers = ['withdraw', 'deposit', 'pay', 'online']
  const textLabels = ['Comisión por Retiro', 'Comisión por Depósito', 'Comisión por Pago en Sitio', 'Comisión por Pago en Línea',]
  const commissionTypes = ['Monto Fijo', 'Porcentaje', 'Monto Fijo y Porcentaje']

  const classes = useStyles();

  const [isLoading, setLoading] = React.useState(false);
  const [commissions, setCommissions] = React.useState(
    props.merchant.commissions ||
    {
      'chargedClient': {
        'withdraw': { 'amount': 0, 'type': 'fixed' },
        'deposit': { 'amount': 0, 'type': 'fixed' },
        'pay': { 'amount': 0, 'type': 'fixed' },
        'online': { 'amount': 0, 'type': 'fixed' },
      },
      'chargedCommerce': {
        'withdraw': { 'amount': 0, 'type': 'fixed' },
        'deposit': { 'amount': 0, 'type': 'fixed' },
        'pay': { 'amount': 0, 'type': 'fixed' },
        'online': { 'amount': 0, 'type': 'fixed' },
      },
      'paidCommerce': {
        'withdraw': { 'amount': 0, 'type': 'fixed' },
        'deposit': { 'amount': 0, 'type': 'fixed' },
        'pay': { 'amount': 0, 'type': 'fixed' },
        'online': { 'amount': 0, 'type': 'fixed' },
      },
    }
  );

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const hasOnline = (column) => {
    const hasOnline = R.hasPath([columnIdentifiers[column], 'online'], commissions)
    if (!hasOnline) {
      const newCommissions = {
        ...commissions
      }
      newCommissions[columnIdentifiers[column]] = {
        ...newCommissions[columnIdentifiers[column]],
        'online': { 'amount': 0, 'type': 'fixed' },
      }
      setCommissions(newCommissions)
      return newCommissions
    }
    return null
  }

  const handleChange = (event) => {
    let { name, value } = event.target;
    const ai = name.split("_");
    let newCommissions = hasOnline(ai[1]) || Object.assign({}, commissions)
    let updatedField = null;
    if (ai.length === 3) {
      if (ai[0] === 'input') {
        updatedField = 'amount'
        value = parseFloat(value)
      } else if (ai[0] === 'secondinput') {
        updatedField = 'percent'
        value = parseFloat(value)
      } else if (ai[0] === 'select') {
        updatedField = 'type'
        if (value === 'Monto Fijo') {
          value = 'fixed'
        } else if (value === 'Porcentaje') {
          value = 'percent'
        } else if (value === 'Monto Fijo y Porcentaje') {
          value = 'mixed'
        }
      }
      if(typeof value === 'string') {
        newCommissions[columnIdentifiers[ai[1]]][rowIdentifiers[ai[2]]][updatedField] = value;
        newCommissions[columnIdentifiers[ai[1]]][rowIdentifiers[ai[2]]]['percent'] = 0;
      }
      else if (updatedField && updatedField !== null && (value >= 0 || value === 'fixed' || value === 'percent' || value === 'mixed')) {
        newCommissions[columnIdentifiers[ai[1]]][rowIdentifiers[ai[2]]][updatedField] = Math.floor(value * 100) / 100.0;
      } else if (isNaN(value)){
        newCommissions[columnIdentifiers[ai[1]]][rowIdentifiers[ai[2]]][updatedField] = 0;
      }
    }
    setCommissions(newCommissions)
  };

  const updateCommissions = async () => {
    setLoading(true)
    const newCommissions = checkEmpty();
    try {
      const response = await API.post("merchant-api", "/merchant", {
        body: {
          merchantRecordKey: props.merchant.merchantRecordKey,
          commissions: newCommissions
        },
      });
      if (response && response.success) {
        handleAlert('success', 'Comisiones Actualizadas')
      } else {
        handleAlert("error", response.error);
        props.handleCallRequest(false, null);
      }
    } catch (err) {
      handleAlert('error', err)
    } finally {
      setLoading(false)
    }
  }

  const checkEmpty = () => {
    const newCommission = commissions;
    columnIdentifiers.map((col) => {
      rowIdentifiers.map((row) => {
        if(newCommission[col][row].amount === '')
          newCommission[col][row].amount = 0;
        if(newCommission[col][row].percent === '')
          newCommission[col][row].percent = 0;
      })
    })
    return newCommission;
  }

  const rowComponentFee = (colNumber, rowNumber) => {
    const inputIdentifier = `input_${colNumber}_${rowNumber}`
    const secondInputIdentifier = `secondinput_${colNumber}_${rowNumber}`
    const selectIdentifier = `select_${colNumber}_${rowNumber}`
    const fee = R.path([columnIdentifiers[colNumber], rowIdentifiers[rowNumber]], commissions) || { 'amount': 0, 'type': 'fixed' }
    let currentSelectValue = fee.type === 'fixed'
      ? 'Monto Fijo'
      : fee.type === 'percent'
        ? 'Porcentaje'
        : 'Monto Fijo y Porcentaje'
    return (
      <div className={`row ${classes.rowSpacing}`}>
        <div className="col-5 col-sm-5 col-lg-5 col-xl-5 align-bottom">
          <Typography className={classes.columnSubTitle}>{textLabels[rowNumber]}</Typography>
        </div>
        <div className="col-3 col-sm-3 col-lg-3 col-xl-3 align-bottom">
          <FormControl className="inputForm">
            <PrimaryInput
            marginVertical={10}
              placeholder={fee.type === 'percent' ? 'Porcentaje' : 'Monto Fijo'}
              id={inputIdentifier}
              type="number"
              name={inputIdentifier}
              className="defaultInputTextSmall"
              onChange={handleChange}
              value={fee.amount}
            />
          </FormControl>
          {fee.type === 'mixed' && <FormControl className="inputForm">
            <PrimaryInput
            marginVertical={10}
              placeholder='Porcentaje'
              id={secondInputIdentifier}
              type="number"
              name={secondInputIdentifier}
              className="defaultInputTextSmall"
              onChange={handleChange}
              value={fee.percent}
            />
          </FormControl>}
        </div>
        <div className="col-4 col-sm-4 col-lg-4 col-xl-4 align-bottom">
          <PrimarySelect
            value={currentSelectValue}
            content={commissionTypes.map((item) => {
              return <option value={item}>{item}</option>;
            })}
            fullWidth
            marginVertical={10}
            onChange={handleChange}
            name={selectIdentifier} 
          />
        </div>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      {isLoading ?
        <div className='gralinfo_form'>
          <CircularProgress color="secondary" />
        </div>
        :
        <div className={classes.container}>
          <div className="row">
            <div className="col-12 col-sm-12 col-lg-6 col-xl-6">
              <Typography className={classes.columnTitle}>Comisiones Cobradas al Cliente</Typography>
              {rowComponentFee(0, 0)}
              {rowComponentFee(0, 1)}
            </div>
            <div className="col-12 col-sm-12 col-lg-6 col-xl-6">
              <Typography className={classes.columnTitle}>Comisiones Pagadas al Comercio</Typography>
              {rowComponentFee(2, 0)}
              {rowComponentFee(2, 1)}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-lg-6 col-xl-6">
              <Typography className={classes.columnTitle}>Comisiones Cobradas al Comercio</Typography>
              {rowComponentFee(1, 2)}
              {rowComponentFee(1, 3)}
            </div>
          </div>
          <div className={`row justify-content-end align-items-end ${classes.buttonContainer}`}>
            <div className="column">
              <Typography className={classes.IvaLegend}>Los montos no incluyen IVA.</Typography>
              <Button className={classes.buttonStyle} onClick={updateCommissions}>
                <Typography className={classes.buttonText}>Guardar</Typography>
              </Button>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default withSnackbar(Tab5)