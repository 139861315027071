import React from "react";
import {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {withSnackbar} from "notistack";
import {API, Auth} from "aws-amplify";
import CircularProgress from "@material-ui/core/CircularProgress";

import "../../../../assets/css/main.css";
import DefaultInput from "../../../common/defaultInput";
import DefaultSelect from "../../../common/defaultSelect";
import States from "../../../../lib/locations/states";
import StatesCities from "../../../../lib/locations/statesCities";
import AvailableCountries from "../../../../lib/locations/availableCountries";
import CheckValue from "../../../../lib/formatValidations";
import {cp_length} from "../../../../lib/constants";
import { Typography } from "@material-ui/core";
import SecondarySelect from '../../../common/secondarySelect';
import fetch from "node-fetch";

const useStyles = makeStyles(theme =>({
  root: {
    padding: 24,
  },
  title: {
    marginLeft: '20px',
    marginTop: '20px',
    marginBottom: '10px',
    ...theme.typography.smallText,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.gray.main,
    mixBlendMode: 'normal',
  },
  subtitle: {
    ...theme.typography.smallText,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.gray.main,
    mixBlendMode: 'normal',
  },
  firstRowStyle: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  rowStyle: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 25,
    flexWrap: "wrap",
  },
  F1: {
    flex: 1,
  },
  F2: {
    flex: 2,
  },
  menu: {
    padding: 20
  }
}));

function Tab3(props) {
  useEffect(() => {
    if (props.ExecutingRequest) {
      callApi();
    }
  }, [props.ExecutingRequest]);

  useEffect(() => {
    getNeighborhoods(props.merchantProfile.zipCode);
  }, []);

  const classes = useStyles();

  const AvailableCountriesList = AvailableCountries();
  const StatesList = States();
  const [Cities, setCities] = React.useState(
    props.merchantProfile.stateName
      ? StatesCities(props.merchantProfile.stateName)
      : []
  );
  const [Neighborhoods, setNeighborhoods] = React.useState([]);

  const [isLoading, setLoading] = React.useState(false);

  const [legalAddress, setLegalAddress] = React.useState(
    props.merchantProfile.legalAddress ? props.merchantProfile.legalAddress : ""
  );
  const [countryName, setCountryName] = React.useState(
    props.merchantProfile.countryName ? props.merchantProfile.countryName : ""
  );
  const [stateName, setStateName] = React.useState(
    props.merchantProfile.stateName ? props.merchantProfile.stateName : ""
  );
  const [city, setCity] = React.useState(
    props.merchantProfile.city ? props.merchantProfile.city : ""
  );
  const [neighborhood, setNeighborhood] = React.useState(
    props.merchantProfile.neighborhood ? props.merchantProfile.neighborhood : ""
  );
  const [zipCode, setZipCode] = React.useState(
    props.merchantProfile.zipCode ? props.merchantProfile.zipCode : ""
  );
  const [locality, setLocality] = React.useState(
    props.merchantProfile.locality ? props.merchantProfile.locality : ""
  );
  const [border, setBoder] = React.useState(
    props.merchantProfile.border ? props.merchantProfile.border : false
  );

  const [legalAddressError, setLegalAddressError] = React.useState("");
  const [countryNameError, setCountryNameError] = React.useState("");
  const [stateNameError, setStateNameError] = React.useState("");
  const [cityError, setCityError] = React.useState("");
  const [neighborhoodError, setNeighborhoodError] = React.useState("");
  const [zipCodeError, setZipCodeError] = React.useState("");

  const handleChange = (event) => {
    const {name, value} = event.target;
    switch (name) {
      case "legalAddress":
        setLegalAddress(value);
        break;
      case "countryName":
        setCountryName(value);
        break;
      case "stateName":
        setStateName(value);
        setCity("");
        setCities(StatesCities(value));
        break;
      case "city":{
        setCity(value);
        handleCityStateChange(value, stateName);
      }
        break;
      case "neighborhood":
        setNeighborhood(value);
        break;
      case "zipCode":
        value.length === 5 && checkZipCode(value);
        setZipCode(value);
        break;
      default:
        console.log("Input no reconocido", name);
    }
  };

  const handleCityStateChange = (city, stateName) => {
    API.get('store-api',`/store/border?city=${city}&state=${stateName}`).then((resp) => {
      if(resp && resp.data){
        const msg= '*La ciudad o municipio seleccionado se encuentra en frontera. Nerito se apegará a los lineamientos de Estímulos fiscales para la región fronteriza norte sobre el Impuesto al Valor Agregado.';
        setCityError(msg);
        setBoder(true);
      } else {
        setCityError('');
      }
    });
  }

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, {variant});
  };

  const cleanError = () => {
    setLegalAddressError("");
    setCountryNameError("");
    setStateNameError("");
    setCityError("");
    setNeighborhoodError("");
    zipCodeError !== "Por favor ingrese un código postal válido" && setZipCodeError("");
  };

  const isChanged = () => {
    if (props.merchantProfile.legalAddress !== legalAddress) {
      return true;
    }
    if (props.merchantProfile.countryName !== countryName) {
      return true;
    }
    if (props.merchantProfile.stateName !== stateName) {
      return true;
    }
    if (props.merchantProfile.city !== city) {
      return true;
    }
    if (props.merchantProfile.neighborhood !== neighborhood) {
      return true;
    }
    if (props.merchantProfile.zipCode !== zipCode) {
      return true;
    }
    return false;
  };

  const callApi = async () => {
    cleanError();
    let hasErros = false;
    if (!CheckValue("empty", legalAddress)) {
      hasErros = true;
      setLegalAddressError("Por favor ingrese la dirección de la empresa");
    }
    if (!CheckValue("empty", countryName)) {
      hasErros = true;
      setCountryNameError("Por favor seleccione un país");
    }
    if (!CheckValue("empty", stateName)) {
      hasErros = true;
      setStateNameError("Por favor seleccione un estado");
    }
    if (!CheckValue("empty", city)) {
      hasErros = true;
      setCityError("Por favor seleccione una ciudad o municipio");
    }
    if (!CheckValue("empty", neighborhood)) {
      hasErros = true;
      setNeighborhoodError("Por favor seleccione una colonia");
    }
    if (!CheckValue("zipcode", zipCode)) {
      hasErros = true;
      setZipCodeError("Por favor ingrese un código postal válido");
    }

    if (hasErros) {
      setLoading(false);
      props.handleCallRequest(false, null);
    } else {
      if (isChanged()) {
        setLoading(true);
        try {
          const body = {
            merchantRecordKey: props.merchantProfile.merchantRecordKey,
            businessConstitution: "FISICA",
            legalAddress,
            countryName,
            stateName,
            city,
            neighborhood,
            zipCode,
            locality,
            border,
            merchantStatus: "pending",
            updateProfile: true,
          };
          const response = await API.post("merchant-api", "/merchant", {
            body,
          });
          if (response && response.success) {
            fetch(process.env.REACT_APP_ZAPIER_DATAFILLED, {
              method: 'POST',
              body: JSON.stringify({
                pending: 'PENDIENTE',
                email: Auth.user.attributes.email,
                phoneNumber: Auth.user.attributes.phone_number,
                leadSource: 'PERFIL TERMINADO EN SISTEMA',
                company: props.merchantProfile.businessCommercialName,
                firstName: props.merchantProfile.legalRepresentativeName,
                lastName: props.merchantProfile.legalRepresentativeLastName,
                website: props.merchantProfile.website || 'NINGUNO',
                industry: props.merchantProfile.businessType,
                street: legalAddress,
                city: city,
                neighborhood: neighborhood,
                state: stateName,
                zipCode: zipCode,
                country: countryName,
                description: `userRecordKey: ${props.merchantProfile.userRecordKey}`,
              })
            })
              .then(response => response.json())
              .then(data => {})
              .catch((error) => {console.error('Error:', error)});
            handleAlert("success", "Guardado con éxito.");
            props.setCompleted(false);
            await props.handleCallRequest(true, 3);
            setLoading(false);
          } else {
            console.log(response);
            handleAlert("error", response.error);
            props.handleCallRequest(false, null);
            setLoading(false);
          }
        } catch (err) {
          console.log("Error: ", err);
          setLoading(false);
          handleAlert("error", err);
          props.handleCallRequest(false, null);
        }
      } else {
        setLoading(false);
        props.handleCallRequest(true, 3);
      }
    }
  };

  const getNeighborhoods = async (zipCode) => {
    try {
      const getZipCodes = await API.get('locations-api', '/locations/zipCodes', {
        queryStringParameters: {
          zipCode: zipCode,
        }
      });
      const filteredNeighborhoods = getZipCodes['data'].map(match => match['cl']);
      setNeighborhoods(filteredNeighborhoods);
    } catch (error) {
      console.error('Error: ', error);
    }
  }

  const checkZipCode = async (zipCode) => {
    try {
      const getZipCodes = await API.get('locations-api', '/locations/zipCodes', {
        queryStringParameters: {
          zipCode: zipCode,
        }
      });
      if (getZipCodes.data.length === 0) {
        setZipCodeError('Por favor ingrese un código postal válido');
        setStateName('');
        setCity('');
        setNeighborhood('');
        setCityError('');
      } else {
        const filteredState = getZipCodes['data'][0]['es'];
        const filteredCity = getZipCodes['data'][0]['mp'] || getZipCodes['data'][0]['cd'];
        const filteredNeighborhoods = getZipCodes.data.map(match => match['cl']);
        const filteredLocality = getZipCodes['data'][0]['lo'];
        setZipCodeError('');
        setCityError('');
        setStateNameError('');
        setNeighborhoodError('');
        setStateName(filteredState);
        setCities(StatesCities(filteredState));
        setCity(filteredCity);
        setNeighborhoods(filteredNeighborhoods);
        setNeighborhood(filteredNeighborhoods[0]);
        setLocality(filteredLocality);
        handleCityStateChange(filteredCity, filteredState);
      }
    } catch (error) {
      console.error('Error: ', error);
    }
  }

  return (
    <div className={classes.root}>
      {isLoading ? (
        <div className="gralinfo_form">
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <>
        <div>
          <Typography className={classes.title}>DIRECCIÓN LEGAL</Typography>
          <div className="row pb-2 ml-1">
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-3">
            <SecondarySelect 
              value={countryName}
              onChange={handleChange}
              id='countryName'
              name='countryName'
              label='País'
              content={AvailableCountriesList.map((item) => {
                return (
                  <>
                    <option value={item.value}>{item.label}</option>
                  </>
                );
              })}
              error={countryNameError}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-3">
              <DefaultInput
                custom={{
                  handleChange,
                  value: legalAddress,
                  classLabel: "labelInputStyleForm",
                  labelText: "Dirección Fiscal",
                  id: "legalAddress",
                  type: "text",
                  name: "legalAddress",
                  maxLength: 50,
                  className: "defaultInputTextForm col-12",
                  placeholder: "Blvd. Gómez Farias 555",
                  error: legalAddressError,
                }}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-3">
              <DefaultInput
                custom={{
                  handleChange,
                  value: zipCode,
                  classLabel: "labelInputStyleForm",
                  labelText: "Código Postal",
                  id: "zipCode",
                  type: "text",
                  name: "zipCode",
                  className: "defaultInputTextForm col-12",
                  placeholder: "85000",
                  maxLength: '7',
                  maxLength: 7,
                  error: zipCodeError,
                }}
              />    
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-3">
            <SecondarySelect 
              value={stateName}
              onChange={handleChange}
              id='stateName'
              name='stateName'
              label='Estado/Provincia/Región'
              content={StatesList.map((item) => {
                return (
                  <>
                    <option value={item.value}>{item.label}</option>
                  </>
                );
              })}
              error={stateNameError}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-3">
            <SecondarySelect 
              value={city}
              onChange={handleChange}
              id='city'
              name='city'
              label='Ciudad o Municipio'
              content={Cities.map((item) => {

                return (
                  <>
                    <option value={item}>{item}</option>
                  </>
                );
              })}
              error={cityError}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-3">
            <SecondarySelect 
              value={neighborhood}
              onChange={handleChange}
              id='neighborhood'
              name='neighborhood'
              label='Colonia'
              content={Neighborhoods.map((item) => {
                return (
                  <>
                    <option value={item}>{item}</option>
                  </>
                );
              })}
              error={neighborhoodError}
              />
            </div>
          </div>
        </div>
        </>
      )}
    </div>
  );
}
export default withSnackbar(Tab3);
