import React,{useState} from 'react';
import { withSnackbar } from 'notistack';
import { Auth } from 'aws-amplify';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';

import '../../assets/css/main.css';
import theme from '../../styles/theme';

import CognitoErrors from '../../lib/cognitoErrors';
import ThirdHeader from '../../components/headers/thirdHeader';
import PrimaryInput from '../../components/common/primaryInput';
import PrimaryButton from '../../components/common/primaryButton';
import SecondaryButton from '../../components/common/secondaryButton';
import image from '../../assets/images/merchantBackground.png';
import { makeStyles } from '@material-ui/core/styles';
import { useCookies } from 'react-cookie';
import GetUser from '../../lib/questionnaire/getUser';

const useStyles = makeStyles(theme =>({
  background: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      minWidth: '100vw',
      backgroundImage: `url(${image})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white',
      paddingLeft: '5%',
      paddingRight: '5%',
      paddingTop: '2%',
      paddingBottom: '2%',
      borderRadius: 20,
      minWidth: 320,
    },
    spacer1: { width: '100%', height: 20 },
    spacer2: { width: '100%', height: 40 },
    textLink: {
      ...theme.typography.link,
      paddingTop: 10,
      color: '#3B9A83',
    },
}));

const Login = (props) => {
    const classes = useStyles()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [cookies, setCookie, removeCookie] = useCookies(['lastAction']);

    const handleChange = (event) => {
        const { name, value } = event.target;
        if(name === 'email'){
          setEmail(value)
        } 
        else{
          setPassword(value)
        }
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    }

    const handleToggleRegister = (event) => {
      event.preventDefault();
      props.history.push({
        pathname: '/register',
      });
    }

    const handleToggleForgotPassword = (event) => {
        event.preventDefault();
        props.history.push({
          pathname: '/forgotPassword',
        });
    }

    const handleAlert = (variant, message) => {
        props.enqueueSnackbar(message, { variant });
    };

    const _login = async (e) => {
        e.preventDefault();

        if (email.length === 0) {
          handleAlert('error', 'Ingresa tu correo electrónico');
        } else if (password.length === 0) {
          handleAlert('error', 'Ingresa tu contraseña');
        } else {
            setIsLoading(true)
          try {
            const user = await Auth.signIn(email.trim(), password);
            const status = await GetUser(user.attributes.sub)
            if (
              user.challengeName &&
              user.challengeName === 'NEW_PASSWORD_REQUIRED'
            ) {
              props.history.push({
                pathname: '/newPassword',
                state: { username: email.trim(), password },
              });
            } else {
              Auth.currentSession()
                .then((data) => {
                  if (user) {
                    if (
                      user.attributes['custom:usertype'] === 'merchant' ||
                      user.attributes['custom:usertype'].indexOf('manager') !== -1
                    ) {
                      removeCookie('user', { path: '/' });
                      props.history.push('/dashboard');
                    } else if (user.attributes['custom:usertype'] === 'admin') {
                      removeCookie('user', { path: '/' });
                      props.history.push('/admin/dashboard');
                    }
                    else if (user.attributes['custom:usertype'] === 'user') {

                      if(status.success){
                        setCookie('user', {
                          key: user.attributes.sub,
                          acceptManagesData: status.data.acceptManagesData,
                          acceptInterestedBuyer:status.data.acceptInterestedBuyer || false,
                          type: user.attributes['custom:usertype']
                        }, { path: '/' });
                        props.history.push('/',{phone: status.data.phone, email: status.data.email});
                      }
                    }  
                    else {
                      handleAlert('info', 'Usuario restringido');
                      setIsLoading(false)
                    }
                  } else {
                    handleAlert('info', 'ERROR 400');
                    setIsLoading(false)
                  }
                })
                .catch((err) => {
                  console.log(err);
                  handleAlert('warning', err);
                  setIsLoading(false)
                });
            }
          } catch (error) {
            if (error.code === 'UserNotConfirmedException') {
              props.history.push({
                pathname: '/verify',
                state: { email: email.trim(), password },
              });
            } else {
              setIsLoading(false)
              console.log('VERIFY-ERROR', error, error.message);
              handleAlert('error', CognitoErrors(error.message));
            }
          }
        }
    };

  return (
    <div className={classes.background}>
        {isLoading ? (
          <div className='styleCircularProgress'>
            <CircularProgress color='secondary' />
          </div>
        ) : (
          <div className={classes.container}>
            <ThirdHeader />
            <div className={classes.spacer1} />
            <PrimaryInput
              id='email'
              type='text'
              name='email'
              className='defaultInputEmail'
              placeholder='Email'
              value={email}
              onChange={handleChange}
              fullWidth
            />
            <PrimaryInput
              id='pwd'
              type={showPassword ? 'text' : 'password'}
              name='password'
              className='defaultInputPassword'
              placeholder='Contraseña'
              value={password}
              onChange={handleChange}
              fullWidth
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    className='visibilityicon eyeIcon'
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <div className={classes.spacer2} />
            <PrimaryButton type='submit' onClick={_login} fullWidth>
              Iniciar Sesión
            </PrimaryButton>
            <SecondaryButton
              onClick={(e) => handleToggleRegister(e)}
              fullWidth
            >
              Crear Cuenta
            </SecondaryButton>

            <Typography
              onClick={(e) => handleToggleForgotPassword(e)}
              className={classes.textLink}
            >
              Olvidé mi contraseña
            </Typography>
          </div>
        )}
      </div>
  )
}

export default withSnackbar(Login);