import order from '../assets/images/receipt.svg';
import product from '../assets/images/product.svg';
import store from '../assets/images/store.svg';
import employee from '../assets/images/employee.svg';

const Items = () => {
  return [
    {
      label: ' Tengo un problema con mis pedidos.',
      action: `'/stores', 2`,
      imageURL: order,
    },
    {
      label: ' Tengo un problema con mis productos.',
      action: '/products',
      imageURL: product,
    },
    {
      label: ' Tengo un problema con mis sucursales.',
      action: '/stores',
      imageURL: store,
    },
    {
      label: ' Tengo un problema con mis empleados.',
      action: `'/stores', 3`,
      imageURL: employee,
    },
  ];
};

export default Items;
