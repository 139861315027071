import React, { useState } from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { API } from 'aws-amplify';
import { FONT_DEFAULT } from '../../../lib/constants.js'
import PrimaryButton from '../../common/primaryButton';
import PrimaryInput from '../../common/primaryInput';
import { Typography, Grid, } from '@material-ui/core';
import '../../../assets/css/main.css';
import moment from 'moment-timezone';
import theme from '../../../styles/theme';
import { CSVLink, CSVDownload } from "react-csv";

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flex: 1,
    backgroundColor: '#faf9f9',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  faqContainer: {
    ...theme.globals.centerVertical,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  filterConteiner: {
    border: '1px solid #BDBDBD',
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    paddingRight: 40,
    alignItems: 'center',
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      paddingRight: 20,
      paddingLeft: 20,
    },
  },
  faqItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'flex-start',
    borderRadius: 10,
    backgroundColor: theme.palette.white.main,
    boxShadow: theme.shadows[5],
    width: '35%',
    marginLeft: 70,
    paddingLeft: 70,
    paddingRight: 50,
    paddingTop: 20,
    paddingBottom: 20,

  },
  buttonStyle: {
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  itemText: {
    ...theme.typography.paragraph,
    color: theme.palette.gray.main,
  },
  totalsLabel: {
    ...theme.typography.paragraph,
    width: '100%',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    flex: 8,
    marginLeft: 30,
    marginRight: 30,
    marginBottom: 40,
    marginTop: 40
  },
  title: {
    ...FONT_DEFAULT,
    fontSize: 24,
    marginLeft: 30,
    marginTop: 30,
    fontWeight: 'bold',
  }
});

var threeMonthsAgo = new Date();
threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
const currentDate = new Date();
const initialFromDate = `${moment().format('YYYY-MM-DD')}T00:00`
const initialToDate = `${moment().format('YYYY-MM-DD')}T23:59`

export default function Reportes(props) {
  useEffect(() => {
    getNewToken()
  }, []);
  
  const classes = useStyles();
  const [fromDate, setFromDate] = useState(initialFromDate);
  const [toDate, setToDate] = useState(initialToDate);
  const [tickets, setTickets] = useState([]);

  const handleChange = (event) => {
    const { value, name } = event.target
    switch (name) {
      case 'fromDate':
        setFromDate(value)
        break;
      case 'toDate':
        setToDate(value)
        break;
      default:
        handleAlert('error', `Input no válido ${name}`)
    }
  }

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  let tokenType = '';
  var accessToken = '';
  const getTickets = async () => {
    const responseTickets = await API.get(
      'zohoDesk-api',
      '/zohodesk/Tickets/getTickets', {
      queryStringParameters: {
        token_type: tokenType,
        access_token: accessToken
      }
    })
    setTickets(responseTickets.data);
    return responseTickets.data
  }

  const getNewToken = async () => {
    const responseRefreshToken = await API.get(
      'zohoDesk-api',
      '/zohodesk/Tickets/RefreshToken')
    accessToken = responseRefreshToken.access_token
    tokenType = responseRefreshToken.token_type
    getTickets();
    return responseRefreshToken
  }

  const bringReport = async (reportType) => {
    try {
      const sitiapi = await API.get('siti-api', `/siti/report`, {
        queryStringParameters: {
          reportType
        }
      });
      const link = sitiapi.data.url
      window.location.assign(link);
    } catch (error) {
    }
  };

  const prettyLink = {
    color: '#fff'
  };

  return (
    <>
      <div >
        <div className={classes.filterConteiner}>
          <Grid container>
            <Grid item xs={12} sm={12} md={5} className={classes.row}>
              <Typography className={classes.totalsLabel}>
                Desde
              </Typography>
              <PrimaryInput
                onChange={handleChange}
                value={fromDate}
                id={'fromDate'}
                type="datetime-local"
                name={'fromDate'}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5} className={classes.row}>
              <Typography className={classes.totalsLabel}>
                Hasta
              </Typography>
              <PrimaryInput
                onChange={handleChange}
                value={toDate}
                id={'toDate'}
                type="datetime-local"
                name={'toDate'}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <div className={`${classes.content} scrollBarHidden`}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div
              className={classes.faqItemContainer}
            >
              <div />
              <Typography  >
                Catálogo mínimo
              </Typography>
              <PrimaryButton
                children='Descargar'
              >
              </PrimaryButton>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.faqItemContainer} >
              <Typography  >
                Reclasificaciones
              </Typography>
              <PrimaryButton
                children='Descargar'
              >
              </PrimaryButton>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.faqItemContainer} >
              <Typography  >
                Estados financieros
              </Typography>
              <PrimaryButton
                children='Descargar'
              >
              </PrimaryButton>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.faqItemContainer} >
              <Typography  >
                Información de comisionistas
              </Typography>
              <PrimaryButton
                children='Descargar'
                onClick={() => bringReport("r26")}
              >
              </PrimaryButton>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.faqItemContainer} >
              <Typography  >
                Reclamaciones
              </Typography>
              <PrimaryButton
                children='Descargar'
              >
                <CSVLink
                  style={prettyLink}
                  data={tickets}
                  filename="reclamaciones.CSV">
                  Descargar
                </CSVLink>
              </PrimaryButton>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}