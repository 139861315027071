import React from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { API } from 'aws-amplify';
import { useHistory } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import TabEmpleados from './tabEmpleados';
import '../../../../assets/css/main.css'
import Tab1 from './tab1'
import Tab5 from './tab5'
import {FONT_DEFAULT} from '../../../../lib/constants.js'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
  
function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flex: 1,
    marginTop: 0,
    backgroundColor: '#F6F6F6',
    flexDirection: 'column',
    // width: 'calc(100vw - 87px)',
    height: 'calc(100vh - 58px)'
  },
  container: {
    flex: 8,
    marginLeft: 30,
    marginRight: 30,
    backgroundColor: '#F6F6F6',
  },
  merchantHeader: {
    display: 'flex',
    flexDirection: 'row',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    marginLeft: 25, 
    marginTop: 20,
    marginBottom: 20,
    width: '80%'
  },
  title: {
    ...FONT_DEFAULT,
    fontWeight: 'bold',
    fontSize: 24,
    display: 'flex',
    alignItems: 'left',
  },
  merchantStatus: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    marginLeft: 25, 
    marginTop: 20,
    marginBottom: 20,
    width: '10%'
  },
  conatinerTitleTabs: {
    flexDirection: 'row',
    backgroundColor: '#F6F6F6',
  },
  titleTabs: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 12,
    display: 'inline-block',
    alignItems: 'left',
    lineHeight: 3,
    color: '#828282',
  },
  titleTabs2: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 12,
    display: 'inline',
    alignItems: 'center',
    lineHeight: 3,
    color: '#4F4F4F',
    marginLeft: 5,
  },
  separador: {
    backgroundColor: '#BDBDBD',
    position: 'relative',
    zIndex: 0,
    height: 1,
    opacity: '.6'
  },
  merchantPanel: {
    margin: 20,
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    border: '1px solid #E0E0E0',
    width: '95%',
    alignSelf:'center',
  },
  containerForm: {
    backgroundColor: '#F6F6F6',
    borderRadius: 0,
    height: '100%',
  },
  statusStyle1: {
    minWidth: '100%',
    height: 45,
    background: '#54D7A8', 
    borderRadius: 5,
    alignItems: 'center',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 10,
    marginBottom: 10
  },
  statusStyle2: {
    minWidth: '100%',
    height: 25,
    background: '#FD8197',
    borderRadius: 19,
    alignItems: 'center',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 20
  },
  statusStyle3: {
    minWidth: '100%',
    height: 25,
    background: '#ADBBEE',
    borderRadius: 19,
    alignItems: 'center',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 20
  },
  statusStyle4: {
    minWidth: '100%',
    height: 25,
    background: '#CBDB6D',
    borderRadius: 19,
    alignItems: 'center',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 20
  },
  statusText: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 14,
    color: '#FFFFFF',
  },
});

export default function Details(props) {
  useEffect(()=>{
    getuserprofile()
  },[])

  const classes = useStyles();
  let history = useHistory();

  const [isLoading, setLoading] = React.useState(true);
  const [currentTab, setTab] = React.useState(0);
  const [merchant, setMerchant] = React.useState(null);

  const getuserprofile = async () => {
    setLoading(true)
    try {
        const m = await API.get('merchant-api', `/merchant?merchantRecordKey=${props.location.state.merchantRecordKey}`);
        const merchantProfile = await API.get('profile-api', `/profile/${m.data.userRecordKey}`);
        console.log(merchantProfile.data[0].identityReqBody)
        m.data.identityFields =  {
            identityValidationStep: merchantProfile.data[0].identityValidationStep,
            identityUri: merchantProfile.data[0].identityUri,
            identityValidationStatus: merchantProfile.data[0].identityValidationStatus,
        };
        setMerchant(m.data)
        setLoading(false)
    } catch (err) {
        console.error(err);
        setLoading(false)
    }
  }

  const buildStatus = (status) => {
    switch (status) {
        case 'approved':
            return <div className={classes.statusStyle1}>
                <Typography className={classes.statusText}>Activo</Typography>
            </div>
        case 'rejected':
            return <div className={classes.statusStyle2}>
                <Typography className={classes.statusText}>Rechazado</Typography>
            </div>
        case 'progress':
            return <div className={classes.statusStyle3}>
                <Typography className={classes.statusText}>En Proceso</Typography>
            </div>
        default:
            return <div className={classes.statusStyle4}>
                <Typography className={classes.statusText}>Pendiente</Typography>
            </div>
      }
  }

  const handleChangeTab = (event, newValue) => {
    setTab(newValue)
  }

  return (
    <div className={classes.root}>
        {isLoading ?
            <div className='gralinfo_form'>
                <CircularProgress color="secondary" />
            </div>
        : 
            <>
                <div className={classes.container}>
                    <div className={classes.merchantHeader}>
                      <div className={classes.titleContainer}>
                          <Typography className={classes.title}>{merchant.businessCommercialName}</Typography>
                      </div>
                      <div className={classes.merchantStatus}>
                          {buildStatus(merchant.merchantStatus)}
                      </div>
                    </div>
                    <div className={classes.containerForm}>
                        <AppBar position="static"  color="transparent" className={"merchantTabs"}>
                            <Tabs
                                value={currentTab}
                                onChange={handleChangeTab}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                                >
                                <Tab label="Perfil" {...a11yProps(0)} />
                                <Tab label="Empleados" {...a11yProps(1)} />
                                <Tab label="Comisiones" {...a11yProps(2)} />
                            </Tabs>
                            <div className={classes.separador}></div>
                        </AppBar> 
                        <TabPanel className={classes.merchantPanel} value={currentTab} index={0}>
                            <Tab1 merchant={merchant} getuserprofile={getuserprofile}/>
                        </TabPanel>
                        <TabPanel className={classes.merchantPanel} value={currentTab} index={1}>
                            <TabEmpleados merchantRecordKey={merchant.merchantRecordKey} />
                        </TabPanel>
                        <TabPanel className={classes.merchantPanel} value={currentTab} index={2}>
                            <Tab5 merchant={merchant} getuserprofile={getuserprofile}/>
                        </TabPanel>
                    </div>
                </div>
            </>
        }
    </div>
  );
}