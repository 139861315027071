import wrong from '../assets/images/cross.svg';
import human from '../assets/images/human.svg';
const SettingsOpciones = () => {
  return [
    {
      label: ' Eliminar cuenta',
      action: 'gotoModal',
       imageURL: wrong,
    },
    {
      label: ' Editar cuenta',
      action: '/profile',
       imageURL: human,
    }
  ];
};

export default SettingsOpciones;
