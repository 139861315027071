import React, { useState,useEffect } from 'react'
import { makeStyles} from '@material-ui/core/styles';
import image from '../../assets/images/merchantBackground.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import ThirdHeader from '../../components/headers/thirdHeader';
import { withSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import PrimaryInput from '../../components/common/primaryInput';
import PrimaryButton from '../../components/common/primaryButton';
import SecondarySelect from '../../components/common/secondarySelect';
import DefaultInput from '../../components/common/defaultInput';
import {
  REGEX_EMAIL,
  REGEX_RFC,
  GENDERS,
  REGEX_LETTERS_AND_NUMBERS,
} from "../../lib/constants";
import { useLocation } from "react-router-dom";

import StatesCities from '../../lib/locations/statesCities';
import States from '../../lib/locations/states';
import GetUser from '../../lib/questionnaire/getUserByEmailAndPhone';
import EditUser from '../../lib/questionnaire/editUser';

const UserRegister = (props) => {
  const classes = styles();
  const loc = useLocation();
  const date = new Date();
  const minDate = `${date.getFullYear() - 16}-${('0'+ (date.getMonth()+1)).slice(-2)}-${('0'+date.getDate()).slice(-2)}`
  const State = States()

  const [isLoading, setIsLoading] = useState(true) //debe ser true

  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('');
  const [birthdate, setBirthdate] = useState(minDate)
  const [gender, setGender] = useState(0)
  const [rfc, setRfc] = useState('');
  const [address, setAddress] = useState('')
  const [state, setState] = useState('');
  const [city, setCity] = useState();
  const [cities, setCities] = useState([]);
  const [enableCities, setEnableCities] = useState(false);
  const [organization, setOrganization] = useState('')
  const [type, setType] = useState('user')
  useEffect(() => {
    getUserEmail()
  }, [])

  const getUserEmail = async () => {
    const responseEmail = await GetUser(loc.state.email,loc.state.phone_number)
    if(responseEmail.success){
      setType('lead')
      setName(`${responseEmail.data[0].name} ${responseEmail.data[0].secondName}`)
      setLastName(`${responseEmail.data[0].paternalLastName} ${responseEmail.data[0].maternalLastName}`)
      setEmail(loc.state.email)
      setOrganization(responseEmail.data[0].organizationRecordKey)
      setIsLoading(!isLoading)
    }
    else {
      setType('user')
      setIsLoading(!isLoading)
    }
  }

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const handleErrors = () => {
    let error = {}
    if (name === '') {
      error.name = "Favor de ingresar su Nombre"
    }

    if (lastName === '') {
      error.lastName = "Favor de ingresar su Apellido"
    }

    if(rfc){
      if (!REGEX_RFC.test(rfc)) {
          error.rfc = "Favor de ingresar un RFC válido"
      }
    }

    if (email === '') {
      error.email = "Favor de ingresar un Correo Electrónico"
    }

    if (!REGEX_EMAIL.test(email)) {
      error.emailSyntax = "Favor de ingresar un Correo Electrónico válido"
    }

    if (state === '') {
      error.state = "Favor de seleccionar un Estado"
    }

    if (city === '') {
      error.city = "Favor de seleccionar una Ciudad"
    }

    if (address === '') {
      error.legalAddress = "Favor de ingresar una Dirección"
    }

    if (birthdate === '') {
      error.birthdate = "Favor de ingresar su Fecha de Nacimiento"
    }

    if (gender === '') {
      error.gender = "Favor de seleccionar su Género"
    }

    return error
  }

  const saveInformation = async (event) => {
    event.preventDefault();
    const error = handleErrors()
    if (Object.keys(error).length === 0) {
      const item = {
        name: name,
        lastName: lastName,
        birthdate:birthdate,
        rfc: rfc,
        phone: loc.state.phone_number,
        email: email,
        gender: gender,
        state: state,
        city: city,
        address: address,
        acceptManagesData: false,
        organization: type === 'lead' ? organization : null
      }
      const statusUser = await EditUser(loc.state.key, item);

      if (statusUser.success) {
        handleAlert('success', "Usuario creado exitosamente")
        props.history.push({
          pathname: '/login',
        });
      }
      else {
        handleAlert('error', statusUser.error);
      }
    }
    else {
      for (const value in error) {
        handleAlert('error', error[value]);
      }
    }
  }

  const handleOnChange = (event) => {
    const { name, value } = event.target

    switch (name) {
      case 'name':
        setName(value)
      break;

      case 'lastName':
        setLastName(value)
      break;

      case 'birthdate':
        setBirthdate(value)
      break;

      case 'email':
        setEmail(value)
      break;

      case 'states':
        setState(value)
        const cities = StatesCities(value)
        setCities(cities)
        setEnableCities(!enableCities)
      break;

      case 'city':
        setCity(value)
      break;

      case 'address':
        setAddress(value)
      break;

      case 'gender':
        setGender(value)
      break;

      case 'rfc':
      if (REGEX_LETTERS_AND_NUMBERS.test(value)) {
        setRfc(value)
      }
      break;
    }
  }

  return (
    <div>
      <div className={classes.background}>
        {isLoading ? (
          <div className='styleCircularProgress'>
            <CircularProgress color='secondary' />
          </div>
        ) : (
          <div className={classes.container}>
            <ThirdHeader />
            <Typography className={classes.title}> Información personal </Typography>

            <div className={classes.form}>
              <PrimaryInput
                id='name'
                type='text'
                name='name'
                className='defaultInputName'
                placeholder='Nombre(s)'
                value={name}
                onChange={handleOnChange}
                fullWidth={true}
              />

              <PrimaryInput
                id='email'
                type='text'
                name='email'
                className='defaultInputEmail'
                placeholder='Correo Electrónico'
                value={email}
                onChange={handleOnChange}
                fullWidth={true}
              />

              <PrimaryInput
                id='rfc'
                type='text'
                name='rfc'
                className='defaultInputRfc'
                placeholder='RFC con Homoclave'
                value={rfc}
                onChange={handleOnChange}
                fullWidth={true}
              />

              <PrimaryInput
                id='address'
                type='text'
                name='address'
                className='defaultInputAddress'
                placeholder='Dirección'
                value={address}
                onChange={handleOnChange}
                fullWidth={true}
              />

              <PrimaryInput
                id='lastName'
                type='text'
                name='lastName'
                className='defaultInputLastName'
                placeholder='Apellido(s)'
                value={lastName}
                onChange={handleOnChange}
                fullWidth={true}
              />

              <div className={classes.birthdayContainer}>
                <div className={classes.birthdate}>
                  <DefaultInput
                    custom={{
                      handleChange:handleOnChange,
                      value: birthdate,
                      classLabel: 'labelInputStyleForm',
                      labelText: 'Fecha de Nacimiento',
                      id: 'birthdate',
                      type: 'date',
                      name: 'birthdate',
                      className: 'defaultInputTextForm col-12',
                      placeholder: 'dd/mm/yyyy',
                      min: "1950-01-01",
                      max: minDate
                    }}
                  />
                </div>

                <div className={classes.birthdate}>
                  <SecondarySelect
                    value={gender}
                    onChange={handleOnChange}
                    id='gender'
                    name='gender'
                    label='Género'
                    content={GENDERS.map((item) => {
                      return (
                        <>
                          <option value={item.value}>{item.label}</option>
                        </>
                      );
                    })}
                    width = '50%'
                    fullWidth
                  />
                </div>
              </div>

              <SecondarySelect
                value={state}
                onChange={handleOnChange}
                id='state'
                name='states'
                label='Estado'
                content={State.map((item) => {
                  return (
                    <>
                      <option value={item.value}>{item.label}</option>
                    </>
                  );
                })}
              />

              <SecondarySelect
                value={city}
                onChange={handleOnChange}
                id='city'
                name='city'
                label='Ciudad'
                content={cities.map((item) => {
                  return (
                    <>
                      <option value={item}>{item}</option>
                    </>
                  );
                })}
                disabled = {!enableCities}
              />
            </div>

            <PrimaryButton
              type='submit'
              className='default_button'
              onClick={saveInformation}
              marginVertical={20}
              fullWidth
            >
              Continuar
            </PrimaryButton>

          </div>
        )}
      </div>
    </div>
  );
}

export default withSnackbar(UserRegister)

const styles = makeStyles(theme =>({
  background: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    minWidth: '100vw',
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',

    padding: '2% 3%',
    borderRadius: 20,
    width: '60%',
    
  },
  title: {
    fontFamily: 'Montserrat Variable',
    fontSize: 24,
    fontWeight: 700,
    textTransform: 'none',
    color: '#434343',
    marginTop: 20,
    marginBottom: 20,
  },
  email: {
    color: '#434343',
  },
  form: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 20,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr'
    },
  },
  birthdayContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '5%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    },
  },
  birthdate: {
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  }
}))