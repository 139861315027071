const sortBy = (list, orientation, key) => {
  try {
    if (orientation === 'DESC' || orientation === 'ASC') {
      const sortedList = list.sort(function (a, b) {
        if (orientation === 'DESC') {
          if (a[key] > b[key]) {
            return -1;
          }
          if (a[key] < b[key]) {
            return 1;
          }
          return 0;
        } else {
          if (a[key] < b[key]) {
            return -1;
          }
          if (a[key] > b[key]) {
            return 1;
          }
          return 0;
        }
      });
      return sortedList
    } else {
      console.log('Invalid orientation');
      return list
    }
  } catch (err) {
    console.log('Sort Error: ', err);
    return list
  }
}

export default sortBy;