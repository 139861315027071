import React, { useEffect, useState } from 'react';
import '../../assets/css/main.css';
import { Redirect, Route, Switch, useHistory, } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import NavMenu from '../../components/sideMenus/navMenu';
import DashBoard from '../../components/dashboard';
import FantasyGS from '../../components/fantasygiftshop';
import Inventory from '../../components/inventory';
import Profile from '../../components/profile';
import SettingsMenu from '../../components/settingsMenu';
import Store from '../../components/stores';
import StoreDetails from '../../components/stores/details';
import Product from '../../components/products';
import AdminDashBoard from '../../components/admin/dashboard';
import AdminMerchants from '../../components/admin/merchants';
import AdminMerchantsDetails from '../../components/admin/merchants/details';
import AdminTransactions from '../../components/admin/transactions';
import AdminSpei from '../../components/admin/spei';
import AdminWalletUsers from '../../components/admin/walletUsers';
import AdminWalletUsersDetails from '../../components/admin/walletUsers/details';
import AdminInShop from '../../components/admin/inShop';
import MerchantLeads from '../../components/merchantLeads';
import TermsAndConditions from '../termsAndConditions';
import PrivacyNotice from '../privacyNotice';
import Services from '../../components/admin/services';
import Emidamex from '../../components/admin/emidamex'; // Emidamex
import Help from '../../components/help';
import { makeStyles } from '@material-ui/core/styles';
import image from '../../assets/images/merchantBackground.png';
import { useIdleTimer } from 'react-idle-timer';
import { MAX_INACTIVITY_MINUTES, MINUTES_BEFORE_INACTIVITY_LOGOUT, } from '../../lib/constants';
import { Auth, } from 'aws-amplify';
import { useCookies } from 'react-cookie';
import { withSnackbar } from 'notistack';
import DefaultModal from '../../components/modal';
import AdminReportes from '../../components/admin/reportes';
import UsersPLD from '../../components/admin/pld/users';
import UserPLDDetails from '../../components/admin/pld/userDetails';
import Operations from '../../components/admin/operations';
import NeritoPay from '../../components/neritoPay/index';
import NeritoPayStores from '../../components/neritoPay/neritoPayStores';
import Analitics from '../../components/analitics/index';
import DataUsage from '../../components/questionnaire/dataUsage';
import Questionnaire from '../../components/questionnaire/questionnaire';
import SharingIsCaring from '../../components/sharingIsCaring/index';
import Billing from '../../components/admin/billing/index';
import RetentionsBilling from '../../components/admin/billing/retentions';
import Anchors from '../../components/admin/anchors/index';
import Anchor from '../../components/admin/anchors/anchor';

const useStyles = makeStyles(theme => ({
  background: {
    minWidth: '100vw',
    minHeight: '100vh',
    ...theme.globals.center,
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  container: {
    width: '90%',
    minHeight: '90vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.white.main,
    borderRadius: 20,
    boxShadow: '0 10px 15px grey',
  },
}));

const Home = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const inactivityTimeout = 1000 * 60 * MAX_INACTIVITY_MINUTES;
  const keepSessionTimeout = 1000 * 60 * MINUTES_BEFORE_INACTIVITY_LOGOUT;
  const [cookies, setCookie, removeCookie] = useCookies(['lastAction']);
  const [inactivityLogout, setInactivityLogout] = useState(null);
  const [showInactivityModal, setShowInactivityModal] = useState(false);
  const acceptManageData = props.location.state ? props.location.state.acceptManagesData : false
  const acceptInterestedBuyer = props.location.state ? props.location.state.acceptInterestedBuyer : false

  useEffect(() => {
    if (props.location.state) {
      setCookie(
        "user",
        {
          ...cookies.user,
          acceptManagesData: props.location.state.acceptManagesData,
          acceptInterestedBuyer: props.location.state.acceptInterestedBuyer
        },
        { path: "/" }
      );
    }

  }, [props.location.state]);

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const handleOnIdle = () => {
    const timeout = setTimeout(() => logOut(), keepSessionTimeout)
    setShowInactivityModal(true)
    setInactivityLogout(timeout)
  }

  const logOut = () => {
    Auth.signOut({ global: true })
      .then(() => {
        removeCookie('lastAction');
        handleAlert('success', 'Se cerró sesión por inactividad.')
        history.replace('/login');
      })
      .catch(() => {
        history.replace('/login');
      });
  }

  const keepSession = () => {
    clearTimeout(inactivityLogout);
    setInactivityLogout(null);
    setShowInactivityModal(false);
    setCookie('lastAction', Date.now(), { path: '/' });
  }

  const closeSession = () => {
    clearTimeout(inactivityLogout);
    logOut();
  }

  const handleOnAction = () => {
    if (checkSessionActive()) {
      closeSession();
    }
    else {
      if (!showInactivityModal && !inactivityLogout) {
        setCookie('lastAction', Date.now(), { path: '/' });
      }
    }
  }

  const checkSessionActive = () => {
    const lastAction = cookies.lastAction || null;
    if (lastAction) {
      const now = Date.now();
      const inactivityTime = now - lastAction;
      if (inactivityTime > (inactivityTimeout + keepSessionTimeout))
        return true;
      else
        return false;
    }
    else
      return false;
  }

  // useIdleTimer({
  //   timeout: inactivityTimeout,
  //   onIdle: handleOnIdle,
  //   onAction: handleOnAction,
  // })
  return !checkSessionActive() ? (
    <>
      {/* <div className={classes.background}> */}
      {/* <div className={classes.container}> */}
      <NavMenu
        removeCookie={() => removeCookie('lastAction')}
      />
      {
        cookies.user?.type ?
          <>
            <Switch>
              {
                (acceptManageData === true && acceptInterestedBuyer === true) || (cookies.user.acceptManagesData === true && cookies.user.acceptInterestedBuyer === true) ?
                  <Route path='/' component={Questionnaire} />
                  :
                  <Route path='/' component={DataUsage} />
              }
            </Switch>
          </>
          :
          <>
            <Switch>
              <Route path='/dashboard' component={DashBoard} />
              <Route path='/profile' component={Profile} />
              <Route path='/settingsMenu' component={SettingsMenu} />
              <Route path='/stores' component={Store} />
              <Route path='/storeDetails' component={StoreDetails} />
              <Route path='/products' component={Product} />
              <Route path='/merchantLeads' component={MerchantLeads} />
              <Route path='/neritoPay' component={NeritoPay} />
              <Route path='/analitics' component={Analitics} />
              <Route path='/neritoPayStores' component={NeritoPayStores} />
              <Route path='/sharingIsCaring' component={SharingIsCaring} />
              <Route path='/admin/dashboard' component={AdminDashBoard} />
              <Route path='/admin/merchants' component={AdminMerchants} />
              <Route path='/admin/reportes' component={AdminReportes} />
              <Route path='/admin/billing/retentions' component={RetentionsBilling} />
              <Route path='/admin/billing' component={Billing} />
              <Route path='/admin/anchors/anchor' component={Anchor} />
              <Route path='/admin/anchors' component={Anchors} />
              <Route
                path='/admin/merchantDetails'
                component={AdminMerchantsDetails}
              />
              <Route path='/admin/fantasyGS' component={FantasyGS} />
              <Route path='/admin/Inventory' component={Inventory} />
              <Route
                path='/admin/transactions'
                component={AdminTransactions}
              />
              <Route path='/admin/spei' component={AdminSpei} />
              <Route
                path='/admin/walletUsers'
                component={AdminWalletUsers}
              />
              <Route
                path='/admin/walletUsersDetails'
                component={AdminWalletUsersDetails}
              />
              <Route path='/admin/inShop' component={AdminInShop} />
              {/* <Route
                  path='/termsAndConditions'
                  exact
                  component={TermsAndConditions}
                /> */}
              {/* `<Route
                  path='/privacyNotice'
                  exact
                  component={PrivacyNotice}
                />` */}
              <Route path='/admin/services' component={Services} />
              <Route path='/admin/emidamex' component={Emidamex} />
              <Route path='/admin/operations' component={Operations} />
              <Route path='/admin/pldConsole' component={UsersPLD} />
              <Route path='/admin/usersDetails' component={UserPLDDetails} />
              <Route path='/help' component={Help} />
              <Redirect from='/' to='/dashboard' />
            </Switch>
          </>
      }
      {/* </div> */}
      {/* </div> */}
      <DefaultModal
        modalType='timeout'
        open={showInactivityModal}
        handleOpen={() => setShowInactivityModal(true)}
        handleClose={keepSession}
        handleKeepSession={keepSession}
        handleCloseSession={closeSession}
      />
    </>
  ) : null;
}

export default withSnackbar(Home);