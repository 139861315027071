import React from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import '../../../assets/css/main.css';

const useStyles = override => {
  const styles = makeStyles({
    buttonStyle: {
      backgroundColor: 'transparent',
      color: '#DA7B7B',
      fontSize: 12,
      border: '2px solid #DA7B7B',
      bordeColor: '#DA7B7B',
      cursor: 'pointer',
      width: 170,
      height: 40,
      borderRadius: 5,
      ...override,
    },
  });

  return styles();
};

export default function DefaultButton(props) {
  useEffect(()=>{
  },[])

  const classes = useStyles(props.override);

  return (
    <Button 
      className={classes.buttonStyle} 
      onClick={props.handleAction}
      disabled={props.disabled ? true : false}
    >
      {props.buttonText}
    </Button>
  );
}