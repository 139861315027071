import React from 'react';
import { useEffect, useState } from 'react';
import { withSnackbar } from 'notistack';
import {
  Input,
  Typography,
  AppBar,
  MenuItem,
  Menu,
  Modal,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const MovementsStats = (props) => {

  const classes = useStyles();

  const setMoneyFormat = (number) => {
    if (number != 'N/A') {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return number
    }
  }

  return (
    <div className={classes.mainContainer}>
      <div className={classes.borderContainer}>
        {props.stats.map((stat => (
          <div className={classes.totalsContainer}>
            <Typography className={classes.totalsLabel}>{stat.label}</Typography>
            <Typography className={classes.totalsValueAlt}>${setMoneyFormat(stat.value)}</Typography>
          </div>
        )))
        }
      </div>
    </div>
  )
}
const useStyles = makeStyles(theme => ({

  totalsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign:'center',
    padding: '20px',
    flexWrap: 'wrap',
  },
  borderContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    border: '1px solid',
    borderRadius: '10px',
    width: 'auto', // props
  },
  mainContainer: {
    // display: 'flex',
    // flexDirection: 'row',
    alignItems: 'center',
    // padding: '10px',
    width: '100%',
  }

}));

export default MovementsStats;