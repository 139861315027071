import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ChevronIcon from '@material-ui/icons/ChevronRight';
import { useHistory } from 'react-router-dom';
import '../../assets/css/main.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography, Grid } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { Auth, API } from 'aws-amplify';
import { API_PROFILE } from '../../lib/constants';
import getMerchantData from '../../lib/getMerchant';
import Items from '../../lib/settingsOpciones.js';
import DefaultModal from '../modal';

const useStyles = makeStyles(theme => ({
    container: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 30,
        paddingLeft: '25%',
        paddingRight: '25%',
        paddingBottom: 0,
        overflow: 'hidden',
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 30,
            paddingRight: 30,
        },
    },
    rowStyle: {
        display: 'flex',
        flexDirection: 'row',
    },
    columnStyle: {
        display: 'flex',
        flexDirection: 'column',
        marginInline: '30px',
    },
    contactButton: {
        color: '#3B9A83',
        border: 0,
        backgroundColor: '#FAFAFA',
        ...theme.typography.subtitle,
    },
    center: {
        ...theme.globals.center,
    },
    title: {
        ...theme.typography.title,
        color: theme.palette.gray.main,
        paddingBottom: 20,
    },
    subtitle: {
        ...theme.typography.subtitle,
        opacity: 0.6,
        marginTop: 20,
    },
    arrowIcon: {
        marginLeft: 10,
        cursor: 'pointer',
        marginTop: 10,
    },
    instructions: {
        ...theme.typography.paragraph,
        opacity: 0.6,
    },
    contacts: {
        ...theme.typography.paragraph,
        opacity: 0.6,
        marginTop: 20,
        maarginBottom: 10,
    },
    buttonContainer: {
        marginTop: 50,
    },
    card: {
        backgroundColor: '#ECEAEA',
        borderRadius: 25,
        display: 'flex',
        flexDirection: 'row',
        width: 600,
    },
    buttonStyle: {
        border: 0,
        margin: 25,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: 'inherit',
        backgroundColor: '#ECEAEA',
    },
    buttonText: {
        ...theme.typography.subtitle,
        color: theme.palette.white.main
    },
    buttonStyleInput: {
        border: 0,
        margin: 25,
        justifyContent: 'space-between',
        width: 'inherit',
        backgroundColor: '#ECEAEA',
        textAlign: 'left',
    },
    inputStyle: {
        width: '-webkit-fill-available',
        backgroundColor: '#ECEAEA',
        borderRadius: 10,
    },
    buttonTextSend: {
        ...theme.typography.subtitle,
        color: '#3B9A83',
        marginTop: 10,
    },
    faqContainer: {
        ...theme.globals.centerVertical,
    },
    faqItemContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: 10,
        backgroundColor: theme.palette.white.main,
        boxShadow: theme.shadows[5],
        width: '100%',
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 20,
        paddingBottom: 20,
    },
    itemText: {
        ...theme.typography.paragraph,
        color: theme.palette.gray.main,
    },
    inputContainer: {
        ...theme.globals.centerVertical,
        borderRadius: 10,
        backgroundColor: theme.palette.white.main,
        boxShadow: theme.shadows[5],
        width: '100%',
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 20,
        paddingBottom: 20,
        marginBottom: 50,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        overflowX: 'hidden',
        padding: 10,
    },
    chevronIcon: {
        color: theme.palette.gray.main,
    },
    images: {
        width: 70,
        height: 70,
        borderRadius: 30,
    },
}));

const reasons = [
    'Seleccionar',
    'El sistema tiene errores',
    'El sistema tarda mucho en cargar',
    'Tiene una interfaz complicada',
    'No cumplió con lo que esperaba',
    'El registro es muy complicado',
    'Encontré una mejor alternativa',
    'Otro',
];

const Help = (props) => {
    const userKey = useSelector(state => state.user.value);

    useEffect(() => {
        getGralProfile();
    }, []); // eslint-disable-line

    const classes = useStyles();
    let history = useHistory();

    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState();
    const [merchant, setMerchant] = useState();
    const [merchantEmail, setMerchantEmail] = useState();
    const [commercialName, setCommercialName] = useState();
    const [showModal, setShowModal] = useState(false);
    const [reason, setReason] = useState(reasons[0]);
    const [errorReason, setErrorReason] = useState('');

    const getGralProfile = async () => {
        setLoading(true);
        const getUser = await Auth.currentAuthenticatedUser();
        try {
            const getGralProfile = await API.get(
                API_PROFILE,
                `/profile/${userKey}`
            );
            let merchant = null;
            if (getGralProfile.data[0].merchantRecordKey) {
                merchant = await getMerchantData(
                    'merchant',
                    getGralProfile.data[0].merchantRecordKey
                );
            } else if (
                getGralProfile.data[0].isEmployeeOfStore &&
                getGralProfile.data[0].isEmployeeActive
            ) {
                merchant = await getMerchantData(
                    'store',
                    getGralProfile.data[0].isEmployeeOfStore
                );
            } else if (
                getGralProfile.data[0].isManagerOfStore &&
                getGralProfile.data[0].isEmployeeActive
            ) {
                merchant = await getMerchantData(
                    'store',
                    getGralProfile.data[0].isManagerOfStore
                );
            }
            setUser(getGralProfile.data[0].userRecordKey);
            setMerchant(merchant.merchantRecordKey);
            setMerchantEmail(merchant.legalRepresentativeEmail);
            setCommercialName(merchant.businessCommercialName);
        } catch (err) {
            console.log(err);
            handleAlert('Error', err.message);
        }
        setLoading(false);
    };

    const handleShowModal = () => {
        setShowModal(true);
    };
    const goToOption = (option, to) => {
        if (option === 'gotoModal') {
            handleShowModal()
        } else {
            history.push({ pathname: option, from: 'help', to: to });
        }
    };

    const deleteUser = async () => {
        try {
            if (reason === 'Seleccionar') {
                setErrorReason('Favor de elegir una opción')
            } else {
                const session = await Auth.currentSession();
                const token = session.getAccessToken().getJwtToken();
                await API.del(API_PROFILE, `/profile/${user}`, {
                    queryStringParameters: {
                        token: token,
                        reason: reason,
                    },
                });
                handleAlert('success', 'Se elimino la cuenta correctamente.');
                history.replace('/login');
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleAlert = (variant, message) => {
        props.enqueueSnackbar(message, { variant });
    };

    const handleReasonChange = (e) => {
        setReason(e.target.value);
        setErrorReason('');
    }

    return (
        <>
            {loading ? (
                <div className='styleCircularProgress'>
                    <CircularProgress color='secondary' />
                </div>
            ) : (
                <>
                    {showModal && (<DefaultModal
                        open={showModal}
                        modalType='eliminar'
                        handleClose={() => setShowModal(false)}
                        handleAccept={() => deleteUser()}
                        reasons={reasons}
                        reason={reason}
                        handleReasonChange={(e) => handleReasonChange(e)}
                        error={errorReason}
                    ></DefaultModal>)}
                    <div className={classes.container}>
                        <Typography className={classes.title}>
                            Ajustes de cuenta:
                        </Typography>
                        <div className={`${classes.content} scrollBarHidden`}>
                            <Grid container spacing={2}>
                                {Items().map((item) => {
                                    return (
                                        <Grid item xs={12}>
                                            <div
                                                className={classes.faqItemContainer}
                                                onClick={() => goToOption(item.action)}
                                            >
                                            <div />
                                                <img
                                                    src={item.imageURL}
                                                    className={classes.images}
                                                    alt=''
                                                />
                                                <Typography className={classes.itemText}>
                                                    {item.label}
                                                </Typography>
                                                <ChevronIcon className={classes.chevronIcon} />
                                            </div>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default withSnackbar(Help);
