import React from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { API } from 'aws-amplify';
import { useHistory } from "react-router-dom";
import { Auth } from 'aws-amplify';
import Typography from '@material-ui/core/Typography';
import {FONT_DEFAULT} from '../../../lib/constants.js'

import '../../../assets/css/main.css';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flex: 1,
    backgroundColor: '#faf9f9',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  cardsContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  cardContainer: {
    backgroundColor: '#6EB89D',
    width: 250,
    height: 60,
    borderRadius: 12.5,
    marginLeft: 20, 
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer'
  },
  cardText: {
    ...FONT_DEFAULT,
    fontWeight: 'bold',
    color: '#F6F6F6'
  },
  title: {
    ...FONT_DEFAULT,
    fontSize: 24,
    marginLeft: 30, 
    marginTop: 30,
    fontWeight: 'bold',
  }
});

export default function Dashboard(props) {
  useEffect(()=>{
    callApi();
  },[])

  const classes = useStyles();
  let history = useHistory();

  const [merchants, setMerchants] = React.useState(0);
  const [stores, setStores] = React.useState(0);

  const callApi = async () => {
    try {
      const getUser = await Auth.currentAuthenticatedUser();
      if (getUser.attributes === undefined) {
        history.push('/login')
      } else {
          if (getUser.signInUserSession.idToken.payload["cognito:groups"][0] !== 'admin') {
            history.push('/dashboard')
          } else if (getUser.signInUserSession.idToken.payload["cognito:groups"][0] === 'employee' || getUser.signInUserSession.idToken.payload["cognito:groups"][0] === 'user') {
            history.push('/login')
          } else {
            let pendingMerchants = 0
            const ms = await API.get('merchant-api', `/merchant/list`);
            ms.data.forEach(element => {
              if (element.merchantStatus) {
                if (element.merchantStatus === 'progress') {
                  pendingMerchants++
                }
              }
            });
            setMerchants(pendingMerchants)
          }
      }
    } catch(e) {
      history.push('/login')
    }
  };

  const goToMerchants = () => {
    history.push('/admin/merchants')
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>Dashboard</Typography>
      <div className={classes.cardsContainer}>
        <div className={classes.cardContainer} onClick={() => goToMerchants()}>
          <Typography className={classes.cardText}>Merchant por validar: {merchants}</Typography>
        </div>
        <div className={classes.cardContainer}>
          <Typography className={classes.cardText}>Documentos pendientes: {stores}</Typography>
        </div>
      </div>
    </div>
  );
}