import React from 'react';
import { withSnackbar  } from 'notistack';
import {Auth} from 'aws-amplify';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';

import '../../assets/css/main.css';
import CognitoErrors from '../../lib/cognitoErrors'
import ThirdHeader from "../../components/headers/thirdHeader";

  
class newPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: null, 
            oldPassword: null, 
            password: '', 
            confirmedpassword: '', 
            showPassword1: false, 
            showPassword2: false,
            isLoading: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleAlert = this.handleAlert.bind(this);
    }
    componentDidMount () {
        this.setState({isLoading: true})
        this.setState({username: this.props.location.state.username, oldPassword: this.props.location.state.password}, () => {
            this.setState({isLoading: false})
        })

    }

    handleAlert = (variant, message) => {
        this.props.enqueueSnackbar( message, { variant });
    };

    _gotoSecured = async () => {
        this.setState({isLoading: true});
        const {
            username,
            oldPassword,
            password,
            confirmedpassword,
        } = this.state;
        if (password !== confirmedpassword) {
            this.setState({isLoading: false});
            this.handleAlert('error', 'Contraseñas diferentes')
            return;
        }
        try {
            const user = await Auth.signIn(username, oldPassword);
            Auth.completeNewPassword(user, password)
            this.handleAlert('success', 'Contraseña actualizada')
            this.props.history.push('/login');
        } catch (error) {
            this.setState({isLoading: false})
            console.log('ERROR', error);
            this.handleAlert('error', CognitoErrors(error.message))
        }
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleClickShowPassword(number) {
        if (number === 1) {
            this.setState({showPassword1: !this.state.showPassword1})
        } else if (number === 2) {
            this.setState({showPassword2: !this.state.showPassword2})
        }
    };
    
    handleMouseDownPassword(event) {
        event.preventDefault();
    };
 
    render() {
        const { password, confirmedpassword, showPassword1, showPassword2, isLoading} = this.state;
        return (
            <div>
            <div className="containerWithBackground">
                
                {isLoading ?
                    <div className='styleCircularProgress'>
                        <CircularProgress color="secondary" />
                    </div>
                : 
                <div className="bg-white col-11 col-sm-9 col-lg-6 col-xl-4 rounded">
                    <div className="col-12 col-sm-12 col-lg-12 col-xl-12 text-center">
                        <ThirdHeader />
                        <Typography className="title">
                            Cambiar contraseña
                        </Typography>
                        <Typography className="messageTex">Tu contraseña actual es temporal</Typography>
                        <Typography className="messageTex">deberás cambiarla para seguir usando la aplicación</Typography>
                        <FormControl className="inputForm col-12 col-sm-12 col-lg-12 col-xl-12">
                            <InputLabel className="labelInputStyle">Contraseña</InputLabel>
                            <Input
                                id="pwd"
                                type={showPassword1 ? 'text' : 'password'}
                                name="password"
                                className="defaultInputPassword"
                                placeholder="******"
                                value={password}
                                onChange={this.handleChange}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={(e) => this.handleClickShowPassword(1, e)}
                                    onMouseDown={this.handleMouseDownPassword}
                                    >
                                    {showPassword1 ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                                }
                            />
                        </FormControl>
                        <FormControl className="inputForm col-12 col-sm-12 col-lg-12 col-xl-12">
                            <InputLabel className="labelInputStyle">Confirmar Contraseña</InputLabel>
                            <Input
                                id="cpwd"
                                type={showPassword2 ? 'text' : 'password'}
                                name="confirmedpassword"
                                className="defaultInputPassword"
                                placeholder="******"
                                value={confirmedpassword}
                                onChange={this.handleChange}
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={(e) => this.handleClickShowPassword(2, e)}
                                    onMouseDown={this.handleMouseDownPassword}
                                    >
                                    {showPassword2 ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                                }
                            />
                        </FormControl>
                        <div className="padding_top_20">
                            <Button type="submit" className="default_button" onClick={this._gotoSecured}>Continuar</Button>
                        </div>
                        <div className="padding_top_20" />
                    </div>
                </div>
                }
            </div>
            </div>
        );
    }
}

export default withSnackbar (newPassword);