import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';

import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';
// Supports weights 100-900
import '@fontsource-variable/montserrat';

const awsmobile = JSON.parse(process.env.REACT_APP_AWS_MOBILE);
Amplify.configure(awsmobile);

const Root = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);

ReactDOM.render(<Root />, document.getElementById('root'));

const script_google = document.createElement("script");
script_google.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API}&libraries=places`;
script_google.async = true;
document.head.appendChild(script_google);

const script_mati = document.createElement("script");
script_mati.src = 'https://web-button.mati.io/button.js';
script_mati.async = true;
document.head.appendChild(script_mati);

const script_google_analytics =  document.createElement("script");
script_google_analytics.async = true;
script_google_analytics.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS}`
document.head.appendChild(script_google_analytics);

const script_google_analytics_function = document.createElement('script');
const gtagFunction = document.createTextNode(`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments)};gtag('js', new Date());gtag('config', '${process.env.REACT_APP_GOOGLE_ANALYTICS}');`);
script_google_analytics_function.appendChild(gtagFunction);
document.head.appendChild(script_google_analytics_function);

//ReactDOM.render(<App/>, document.getElementById('root'));
serviceWorker.unregister();
//registerServiceWorker();