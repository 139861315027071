const editUser = async (userRecordKey,item) => {
  try {
  
    const result = await fetch(`${process.env.REACT_APP_USER_API}/analytics/editUser`, {
        method: "PUT",
        headers:{
          'x-api-key': process.env.REACT_APP_USER_API_KEY,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          userRecordKey: userRecordKey,
          item: item,
        })
    })
    const res = await result.json()
    
    if(res.success){
        return {
          success: true,
        }
      }
      else {
        return {
          success: false,
          msg: 'Algo salio mal'
        }
    }
  } catch (err) {
    return {
      success: false,
      error: 'Algo salio mal',
    }
  }
  }
  
  export default editUser;