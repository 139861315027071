import React from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import Login from './pages/login';
import Register from './pages/register';
import Verify from './pages/verify';
import Confirmation from './pages/confirmation';
import NewPassword from './pages/newPassword';
import UserInformation from './pages/userScreen/userRegister';
import Home from './pages/home';
import ForgotPassword from './pages/forgotPassword';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import theme from './styles/theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <Router>
          <Switch>
            <Route path='/login' exact component={Login}/>
            <Route path='/register' exact component={Register}/>
            <Route path='/verify' exact component={Verify}/>
            <Route path='/userInformation' exact component={UserInformation}/>
            <Route path='/confirmation' exact component={Confirmation}/>
            <Route path='/newpassword' exact component={NewPassword}/>
            <Route path='/forgotPassword' exact component={ForgotPassword}/>
            <Route path='/' component={Home}/>
          </Switch>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
