import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import '../../assets/css/main.css';
import { withSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { Auth, API, Storage } from 'aws-amplify';
import IconButton from '@material-ui/core/IconButton';
import { FormatListBulleted,Apps } from '@material-ui/icons';
import { CircularProgress,Typography,Button,Grid,Tooltip } from '@material-ui/core';

import removeAccents from '../../lib/removeAccents';

import DefaultModal from '../modal';
import DefaultCard from './../common/defaultCard';
import PrimaryButton from '../common/primaryButton';
import getMerchantData from '../../lib/getMerchant';
import SecondaryButton from '../common/secondaryButton';
import PrimarySearchBar from '../common/primarySearchBar';
import noProducts from '../../assets/images/noProducts.svg';
import defaultProductImage from '../../assets/images/noProductImage.png';

const Product = (props) => {
  const userKey = useSelector(state => state.user.value);

  useEffect(() => {
    searchProduct(null);
  }, []);

  let history = useHistory();

  const [isLoading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [shownProducts, setShownProducts] = useState([]);
  const [editing, setEditing] = useState(false);
  const [editProduct, setEditProduct] = useState({});
  const [merchantId, setMerchant] = useState([]);
  const [merchantEmail, setMerchantEmail] = useState();
  const [commercialName, setCommercialName] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showDeactivated, setShowDeactivated] = useState(false);
  const [listOrder, setListOrder] = useState(false)

  const handleSearchChange = (event) => {
    const search = removeAccents(event.target.value).toLowerCase();
    const filteredProducts = products.filter(
      (product) =>
      product.fullTextForSearching.includes(search)
    );
    setShownProducts(filteredProducts);
  };

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const getFullTextForSearch = (product) => {
    const fullTextForSearching = `${product.productName.trim()} ${product.description.trim()} ${product.productType.trim()}`;
    return removeAccents(fullTextForSearching).toLowerCase();
  }

  const searchProduct = async (searchParam) => {
    setLoading(true);
    let merchant = null;
    try {
      const getUser = await Auth.currentAuthenticatedUser();
      const getGralProfile = await API.get(
        'profile-api',
        `/profile/${userKey}`
      );
      if (getGralProfile.data[0].merchantRecordKey) {
        merchant = await getMerchantData(
          'merchant',
          getGralProfile.data[0].merchantRecordKey
        );
      } else if (
        getGralProfile.data[0].isEmployeeOfStore &&
        getGralProfile.data[0].isEmployeeActive
      ) {
        merchant = await getMerchantData(
          'store',
          getGralProfile.data[0].isEmployeeOfStore
        );
      } else if (
        getGralProfile.data[0].isManagerOfStore &&
        getGralProfile.data[0].isEmployeeActive
      ) {
        merchant = await getMerchantData(
          'store',
          getGralProfile.data[0].isManagerOfStore
        );
      }
      if (merchant === null) {
        handleAlert(
          'error',
          'Ocurrió un error al obtener la información del usuario'
        );
        history.push('/login');
      }
      setMerchant(merchant.merchantRecordKey);
      setMerchantEmail(merchant.legalRepresentativeEmail);
      setCommercialName(merchant.businessCommercialName);
    } catch (err) {
      handleAlert('Error', err);
      setLoading(false);
    }
    if (searchParam === null || searchParam === '') {
      try {
        const getProducts = await API.get('product-api', '/products', {
          queryStringParameters: {
            FilterExpression: `merchantRecordKey=:merchantRecordKey`,
            ExpressionAttributeValues: `:merchantRecordKey=${merchant.merchantRecordKey}`,
          },
        });
        const pImages = getProducts.data.map((item) =>
          Storage.get(`products/${item.productRecordKey}/default.jpg`)
        );
        const p = await Promise.all(pImages);
        const upProductList = getProducts.data.map((value, index) => {
          const fullTextForSearching = value.fullTextForSearching
            ? value.fullTextForSearching
            : getFullTextForSearch(value);
          return {
            ...value,
            productImage: value.hasImage ? p[index] : defaultProductImage,
            fullTextForSearching,
          }
        });
        setProducts(upProductList);
        setShownProducts(upProductList);
        setLoading(false);
      } catch (err) {
        handleAlert('Error', err);
        setLoading(false);
      }
    } else {
      try {
        const getProducts = await API.get('product-api', '/products', {
          queryStringParameters: {
            FilterExpression: `merchantRecordKey=:merchantRecordKey AND 
                            (contains(productName,:search) OR
                            contains(description,:search) OR
                            contains(productType,:search))`,
            ExpressionAttributeValues: `:merchantRecordKey=${merchant.merchantRecordKey},:search=${searchParam}`,
          },
        });
        const pImages = getProducts.data.map((item) =>
          Storage.get(`products/${item.productRecordKey}/default.jpg`)
        );
        const p = await Promise.all(pImages);
        const upProductList = getProducts.data.map((value, index) => ({
          ...value,
          productImage: p[index],
        }));
        setProducts(upProductList);
        setLoading(false);
      } catch (err) {
        handleAlert('Error', err);
        setLoading(false);
      }
    }
  };

  const handleToggleSidePanel = () => {
    setShowModal(false);
    if (editing) setEditing(false);
  };

  const handleToggleEditClick = async (item, e) => {
    setEditProduct(item);
    setEditing(true);
    setShowModal(true);
    console.log(item.productImage);
  };

  const handleProblem = async () => {
    const description = 'No se encuentran los productos';
    const formData = new FormData();
    formData.append('name', commercialName);
    formData.append('merchantID', merchantId);
    formData.append('problemType', 'Productos');
    formData.append('problem', description);
    formData.append('email', merchantEmail);
    fetch(process.env.REACT_APP_ZAPIER_ZOHO, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('SUCCESS', data);
        handleAlert('success', 'Tu problema se envió correctamente');
      })
      .catch((error) => {
        console.log('ERR', error);
        handleAlert('error', error.response.data.error);
      });
  };

  const priceFormat = (number) => {
    const options = { style: 'currency', currency: 'USD' };
    const numberFormat = new Intl.NumberFormat('en-US', options);
    return numberFormat.format(number)
  }

  const renderProducts = () => {
    const newListProducts = shownProducts.filter((product) => showDeactivated ? product.deactivated : !product.deactivated);
    const products = newListProducts.map(product => {
      if(listOrder){
        return (
          <div className = {classes.productListContainer}>
            <aside className = {classes.productInfoContainer}>
              <img className={classes.productImage} src = {product.productImage}/>
              <aside>
                <p className={classes.subtitle}>{product.productName}</p>
                <p>{product.description}</p>
              </aside>
            </aside>
            <p className={classes.subtitle}>{priceFormat(product.price)}</p>
          </div>
        ) 
      }
      else {
        return (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            xl={2}
            onClick={(e) => handleToggleEditClick(product, e)}
          >
            <div className={classes.cardContainer}>
              <DefaultCard
                productImage={product.productImage}
                separador={props.separador}
                productName={product.productName}
                description={product.description}
                productType={product.productType}
                price={product.price}
                cardType={'cardProduct'}
              />
            </div>
          </Grid>
        )
      }
    })
    return products;
  };

  const classes = useStyles(props);
  return (
    <div className={classes.container}>
      <Grid container>
        <Grid container className={classes.titleContainer}>
          <Grid item xs={12} sm={8}>
            <Typography className={classes.title}>Mis Productos</Typography>
          </Grid>
          <Grid item xs={12} sm={4} className={classes.buttonContainer}>
            <SecondaryButton
              onClick={() => setShowDeactivated(!showDeactivated)}
              type='button'
              paddingHorizontal={25}
              secondaryButtonStyle={classes.primaryButtonStyle}
            >
              {showDeactivated ? "Productos Activos" : "Productos Desactivados"}
            </SecondaryButton>
            <PrimaryButton
              onClick={() => setShowModal(true)}
              type='button'
              paddingHorizontal={25}
              primaryButtonStyle={classes.primaryButtonStyle}
            >
              Agregar Productos
            </PrimaryButton>
          </Grid>
        </Grid>
        <DefaultModal
          open={showModal}
          handleClose={handleToggleSidePanel}
          modalType='newProduct'
          reloadProducts={searchProduct}
          editProduct={editProduct}
          edit={editing}
          from={props.location.from}
          email={merchantEmail}
          name={commercialName}
          merchantId={merchantId}
        />
        <div className={classes.searchContainer}>
          <PrimarySearchBar
            type='search'
            placeholder='Buscar'
            autoFocus
            onChange={(e) => handleSearchChange(e)}
            width = "95%"
          />
          <Tooltip title = {listOrder ? 'Ver por cuadricula' : 'Ver por lista'}>
            <IconButton onClick={() => setListOrder(!listOrder)}>
              {listOrder ? <Apps color='primary' /> : <FormatListBulleted color='primary'/>}
            </IconButton>
          </Tooltip>
          {props.location.from ? (
            <div>
              <Button
                className={classes.buttonStyle}
                onClick={() => handleProblem()}
              >
                <Typography className={classes.redText}>
                  No encuentro mi producto
                </Typography>
              </Button>
            </div>
          ) : null}
        </div>
      </Grid>

      {isLoading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress color='secondary' />
        </div>
      ) : products.length > 0 ? (
        <div className={`${classes.content} scrollBarHidden`}>
          <Grid container spacing={2} direction={!listOrder ? 'row' : 'column'}>
            {renderProducts()}
          </Grid>
        </div>
      ) : (
        <div className={classes.empty}>
          <img src={noProducts} alt='noProducts' />
          <Typography>No hay productos.</Typography>
          <PrimaryButton
            onClick={() => setShowModal(true)}
            type='button'
            paddingHorizontal={25}
            primaryButtonStyle={classes.primaryButtonStyle}
          >
            Agregar un producto
          </PrimaryButton>
        </div>
      )}
    </div>
  );
};

export default withSnackbar(Product);

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    overflow: 'hidden',
  },
  subtitle: {
    ...theme.typography.paragraph,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.lightGrey.main}`,
    marginBottom: 15,
  },
  title: {
    ...theme.typography.title,
    margin: 0,
    [theme.breakpoints.only('xs')]: {
      textAlign: 'center',
    },
  },
  buttonStyle: {
    border: 0,
    margin: 15,
    backgroundColor: 'white',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      justifyContent: 'center',
      flexDirection: 'column',
    },
  },
  primaryButtonStyle: {
    width: 'auto',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  searchContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between'
  },
  redText: {
    color: 'red',
    textAlign: 'right',
    fontSize: 16,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    overflowX: 'hidden',
    padding: 10,
  },
  cardContainer: {
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    cursor: 'pointer',
    transition: 'box-shadow 0.3s ease-in-out',
    '&:hover': {
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.75)',
    },
  },
  loadingContainer: {
    margin: 'auto',
    paddingTop: '50px',
    ...theme.globals.center,
  },
  empty: {
    height: '100%',
    width: '100%',
    ...theme.globals.centerVertical,
  },
  productListContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',

    transition: 'box-shadow 0.3s ease-in-out',
    '&:hover': {
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.75)',
    },
    marginBottom: '2%',
    alignItems: 'center',
    paddingRight: '1%'
  },
  productInfoContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 15
  },
  productImage: {
    maxWidth: 100,
    objectFit: 'cover',
  },
}));
