import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {
  CircularProgress,
  Typography,
  Modal,
  Button,
  Grid,
} from '@material-ui/core';
import CheckValue from '../../../../lib/formatValidations';
import DefaultInput from '../../../common/defaultInput';
import DeafultSearch from '../../../common/deafultSearch';
import DefaultButton from '../../../common/defaultButton';
import DefaultCancelButton from '../../../common/defultCancelButton';
import { API } from 'aws-amplify';
import AddProductMenu from './addInventory';
import '../../../../assets/css/main.css';
import addProducts from '../../../../assets/images/addProducts.svg';
import addInventoryIcon from '../../../../assets/images/addInventoryIcon.svg';
import trashCanIcon from '../../../../assets/images/trashCanIcon.svg';

import noProducts from '../../../../assets/images/noProducts.svg';
import PrimarySearchBar from '../../../common/primarySearchBar';
import PrimaryButton from '../../../common/primaryButton';

import DefaultModal from '../../../modal';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
    marginRight: 10,
  },
  tableContainer: {
    display: 'flex',
    backgroundColor: '#FFFFFF',
    borderRadius: 0,
    flexDirection: 'row',
    marginLeft: 30,
    marginRight: 30,
  },
  buttonContainer: {
    display: 'flex',
    marginTop: 10,
    marginBottom: 30,
  },
  tableGridContainer: {
    width: 1233,
    marginLeft: 20,
    marginRight: 20,
    marginTop: 20,
    marginBottom: 20,
  },
  tableGridContainerSmallScreen: {
    width: 720,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 20,
    marginBottom: 20,
  },
  actionButonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center',
  },
  actionButon1: {
    cursor: 'pointer',
    marginRight: 10,
  },
  actionButon2: {
    cursor: 'pointer',
    marginLeft: 10,
  },
  tableHeaderText: {
    ...theme.typography.smallText,
    display: 'flex',
    alignItems: 'center',
    borderTop: '1px solid #E0E0E0',
    borderBottom: '1px solid #E0E0E0',
    height: 60,
    justifyContent: 'center',
  },
  tableRowContainer: {
    height: 40,
  },
  tableRowText1: {
    ...theme.typography.smallText,
    paddingBottom: 10,
    paddingTop: 10,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
  tableRowText1Pointer: {
    ...theme.typography.smallText,
    fontWeight: '600px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    paddingBottom: 10,
    paddingTop: 10,
  },
  tableRowText2: {
    ...theme.typography.smallText,
    fontWeight: '600px',
    paddingBottom: 10,
    paddingTop: 10,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
  tableRowText3: {
    ...theme.typography.productName,
    paddingBottom: 10,
    paddingTop: 10,
    paddingLeft: 20,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'left',
  },
  addInventoryButtonStyle: {
    border: '1px solid #3B9A83',
    boxSizing: 'border-box',
    borderRadius: 5,
    width: 170,
    height: 40,
    margin: 0,
  },
  successButtonStyle: {
    border: '1px solid #3B9A83',
    boxSizing: 'border-box',
    borderRadius: 5,
    width: 146,
    height: 38,
    margin: 2,
    marginTop: 50,
    minWidth: '40%',
  },
  cancelButtonStyle: {
    border: '1px solid #DA7B7B',
    boxSizing: 'border-box',
    borderRadius: 5,
    width: 146,
    height: 38,
    margin: 2,
    marginTop: 50,
    minWidth: '40%',
  },
  successButtonText: {
    ...theme.typography.successText
  },
  cancelButtonText: {
    ...theme.typography.errorText,
    color: '#DA7B7B',
  },
  plusIcon: {
    marginRight: 5,
  },
  paper: {
    position: 'absolute',
    width: 497,
    height: 'auto',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 4 14 rgba(0, 0, 0, 0.15)',
    borderRadius: 5,
    textAlign: 'center',
  },
  mainContainerRegister: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: 5,
  },
  modalButtons: {
    marginBottom: 20,
    marginTop: 20,
  },
  title: {
    ...theme.typography.mediumText,
    marginTop: 20,
  },
  quantityModal: {
    backgroundColor: '#FFFFFF',
    border: '2',
    borderColor: '#000',
    boxShadow: 'black',
    padding: 40,
  },
  modalTitle: {
    fontSize: 18,
    marginBottom: 40,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  deleteModal: {
    width: '450px',
  },
  scrollableTable: {
    overflow: 'auto',
  },
  backgrounOddColumn: {
    backgroundColor: '#F6F6F6',
  },
  backgrounOddColumn2: {
    backgroundColor: 'white',
  },
  addInventoryButton: {
    Width: '30px',
    Height: '30px',
    minWidth: '30px',
    minHeight: '30px',
    marginRight: '0px',
    marginLeft: '15px',
  },
  deleteProductButton: {
    Width: '30px',
    Height: '30px',
    minWidth: '30px',
    minHeight: '30px',
    marginRight: '15px',
    marginLeft: '0px',
  },
  tableRowCurrency: {
    ...theme.typography.productName,
    paddingBottom: 10,
    paddingTop: 10,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'flex-end',
    marginRight: 10,
  },
  empty: {
    paddingTop: '50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  center: {
    ...theme.globals.center,
  },
  primaryButtonStyle: {
    width: 'auto',
    [theme.breakpoints.only('xs')]: {
      width: '100%',
    },
  },
  modalButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

function Tab2(props) {
  useEffect(() => {
    loadInventory();
  }, []);

  const classes = useStyles();

  const [isLoading, setLoading] = useState(true);
  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const [search, setSearch] = useState();
  const [selectedProduct, setSelectedProduct] = useState({});
  const [newQuantity, setNewQuantity] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [productMenu, setProductMenu] = useState(false);
  const [inventory, setInventory] = useState([]);
  const [selectedInventory, setSelectedInventory] = useState({
    productName: '',
    inventoryRecordKey: '',
  });

  const [newQuantityError, setNewQuantityError] = useState('');

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const handleOpenQuantityModal = (selected) => {
    if (selected.product.inventoryable) {
      setSelectedProduct(selected);
      setOpenModal(true);
    }
  };

  const handleOpenDeleteModal = (inventory) => {
    setSelectedInventory({
      productName: inventory.product.productName,
      inventoryRecordKey: inventory.inventoryRecordKey,
    });
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setSelectedInventory({
      productName: '',
      inventoryRecordKey: '',
    });
    setOpenDeleteModal(false);
  };

  const loadInventory = async () => {
    try {
      const getInventory = await API.get('inventory-api', `/inventory`, {
        queryStringParameters: {
          entityId: props.store,
          entityKey: 'store',
        },
      });
      const getProducts = await API.get('product-api', '/products', {
        queryStringParameters: {
          FilterExpression: 'merchantRecordKey=:merchantRecordKey',
          ExpressionAttributeValues: `:merchantRecordKey=` + props.merchant,
        },
      });
      const inventoryList = getInventory.data.map((item) => {
        return {
          inventoryRecordKey: item.inventoryRecordKey,
          quantity: item.quantity,
          status: item.status,
          product: getProducts.data.find(
            (element) => element.productRecordKey === item.productId
          ),
        };
      }).filter((element) => !element.product.deactivated);
      setInventory(inventoryList);
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  const updateInventory = async (action) => {
    setLoading(true);
    let hasErrors = false;
    if (!CheckValue('integer', newQuantity)) {
      setNewQuantityError('Ingrese números enteros');
      hasErrors = true;
    }
    if (!hasErrors) {
      try {
        await API.post('inventory-api', '/inventory', {
          body: {
            action: action,
            entityId: props.store,
            entityKey: 'store',
            quantity: parseInt(newQuantity),
            status: selectedProduct.status,
            productId: selectedProduct.product.productRecordKey,
          },
        });
        loadInventory();
        setOpenModal(false);
        handleAlert('success', 'Actualizado con Éxito!');
      } catch (err) {
        handleAlert('warning', err);
        console.log(err);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const deleteProduct = async () => {
    setDeleteLoading(true);
    try {
      await API.del(
        'inventory-api',
        `/inventory/${selectedInventory.inventoryRecordKey}`
      );
      loadInventory();
      handleCloseDeleteModal();
      setDeleteLoading(false);
      handleAlert('success', 'Producto eliminado del inventario.');
    } catch (err) {
      handleAlert('warning', err.response.data.error);
      console.log(err);
      setDeleteLoading(false);
    }
  };

  const cleanErrors = () => {
    setNewQuantityError('');
  };

  const renderInventory = () => {
    const element = inventory.map((item) => (
      <tr value={item.inventoryRecordKey}>
        <td className={classes.backgrounOddColumn}>
          <Typography className={classes.tableRowText1Pointer}>
            {item.product.inventoryable ? item.quantity : 'N/A'}
          </Typography>
        </td>
        <td>
          <Typography
            className={classes.tableRowText3}
          >{`${item.product.productName}: ${item.product.description}`}</Typography>
        </td>
        <td className={classes.backgrounOddColumn}>
          <Typography
            className={classes.tableRowCurrency}
          >{`$ ${Number.parseFloat(item.product.price.replace(/[$,]+/g, ''))
            .toFixed(2)
            .toLocaleString('es-MX')}
            `}</Typography>
        </td>
        <td>
          <Typography className={classes.tableRowText2}>
            {item.product.productType}
          </Typography>
        </td>
        {item.product.inventoryable ? (
          item.quantity > 0 ? (
            <td className={classes.backgrounOddColumn}>
              <Typography className={classes.tableRowText1}>
                Disponible
              </Typography>
            </td>
          ) : (
            <td className={classes.backgrounOddColumn}>
              <Typography className={classes.tableRowText1}>Agotado</Typography>
            </td>
          )
        ) : (
          <td className={classes.backgrounOddColumn}>
            <Typography className={classes.tableRowText1}>N/I</Typography>
          </td>
        )}
        <td className={`${classes.backgrounOddColumn2} text-center`}>
          <Button
            className={classes.addInventoryButton}
            onClick={() => handleOpenQuantityModal(item)}
          >
            <img
              width='22'
              height='22'
              src={addInventoryIcon}
              className={classes.actionIcon}
            />
          </Button>
          <Button
            className={classes.deleteProductButton}
            onClick={() => handleOpenDeleteModal(item)}
          >
            <img
              width='22'
              height='22'
              src={trashCanIcon}
              className={classes.actionIcon}
            />
          </Button>
        </td>
      </tr>
    ));
    return element;
  };

  const handleAddInventory = () => {
    setProductMenu(!productMenu);
    if (productMenu) {
      loadInventory();
    }
  };

  const handleChange = (event) => {
    setNewQuantity(event.target.value);
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        {isLoading ? (
          <div className='gralinfo_form'>
            <CircularProgress color='secondary' />
          </div>
        ) : inventory.length > 0 ? (
          <div
            className='mt-3 overflow-auto'
          >
            <Grid container
            // className={classes.center}
            >
              <Grid item xs={12} sm={3}>
                <PrimaryButton onClick={() => handleAddInventory()} primaryButtonStyle={classes.primaryButtonStyle}>
                  {/* <div className={classes.searchButton}>
                <img width="18" height="18" src={addProducts} className={classes.plusIcon} />
                
              </div> */}
                  Agregar Inventario
                </PrimaryButton>
              </Grid>
              <Grid item xs={12} sm={9}>
                <PrimarySearchBar placeholder='Buscar producto' fullwidth />
              </Grid>
            </Grid>
            <table className='table mt-4'>
              <thead>
                <tr>
                  <th>
                    <Typography className={classes.tableHeaderText}>
                      Unidades
                    </Typography>
                  </th>
                  <th>
                    <Typography className={classes.tableHeaderText}>
                      Descripción
                    </Typography>
                  </th>
                  <th>
                    <Typography className={classes.tableHeaderText}>
                      Precio
                    </Typography>
                  </th>
                  <th>
                    <Typography className={classes.tableHeaderText}>
                      Categoría
                    </Typography>
                  </th>
                  <th>
                    <Typography className={classes.tableHeaderText}>
                      Estatus
                    </Typography>
                  </th>
                  <th>
                    <Typography className={classes.tableHeaderText}>
                      Acciones
                    </Typography>
                  </th>
                </tr>
              </thead>
              <tbody>{renderInventory()}</tbody>
            </table>
          </div>
        ) : (
          <div className={classes.empty}>
            <img src={noProducts} alt='noProducts' />
            <Typography>No hay productos.</Typography>
            <PrimaryButton type='button' onClick={() => handleAddInventory()}>Agregar inventario</PrimaryButton>
          </div>
        )}
        {productMenu ? (
          <DefaultModal
            modalType='inventario'
            open={true}
            inventory={inventory.map((item) => item.product)}
            cancel={handleAddInventory}
            storeKey={props.store}
          />
          // <div classNanme='col-12'>
          //   <AddProductMenu
          //     open={true}
          //     inventory={inventory.map((item) => item.product)}
          //     cancel={handleAddInventory}
          //     storeKey={props.store}
          //   />
          // </div>
        ) : null}

        <Modal
          open={openModal}
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
          className={classes.modal}
        >
          <div className={classes.quantityModal}>
            <h2 className={classes.modalTitle}>Modificar Inventario</h2>
            <DefaultInput
              custom={{
                handleChange,
                value: newQuantity,
                labelText: '',
                classLabel: 'labelInputStyleForm',
                id: 'newQuantity',
                type: 'text',
                name: 'newQuantity',
                className: `defaultInputTextForm col-12`,
                placeholder: 'Cantidad',
                error: newQuantityError,
              }}
            />
            <br />
            <div className={classes.modalButtonsContainer}>
              <div>
                <Button
                  className={classes.successButtonStyle}
                  onClick={() => updateInventory('add')}
                >
                  <Typography className={classes.successButtonText}>
                    Agregar +
                  </Typography>
                </Button>
                <Button
                  className={classes.successButtonStyle}
                  onClick={() => updateInventory('substract')}
                >
                  <Typography className={classes.successButtonText}>
                    Restar -
                  </Typography>
                </Button>
              </div>
              <Button
                className={classes.cancelButtonStyle}
                onClick={() => setOpenModal(!openModal)}
              >
                <Typography className={classes.cancelButtonText}>
                  Cancelar
                </Typography>
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
          className={classes.modal}
        >
          <div className={classes.deleteModal}>
            {isDeleteLoading ? (
              <div className='gralinfo_form'>
                <CircularProgress color='secondary' />
              </div>
            ) : (
              <div className={`${classes.quantityModal} row`}>
                <h2 className={`${classes.modalTitle} text-justify`}>
                  ¿Desea eliminar el producto "{selectedInventory.productName}"
                  del inventario?
                </h2>
                <div className='col-6'>
                  <DefaultCancelButton
                    handleAction={handleCloseDeleteModal}
                    buttonText={'Cancelar'}
                  />
                </div>
                <div className='col-6'>
                  <DefaultButton
                    handleAction={() => deleteProduct()}
                    buttonText={'Eliminar'}
                  />
                </div>
              </div>
            )}
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default withSnackbar(Tab2);
