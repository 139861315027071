import { ABBREVIATED_DAYS } from '../lib/constants'

export default function CheckSchedule(schedules) {
    const result = {isOpen: false, start: '00:00', end: '00:00'}
    try {
        const d = new Date();
        const n = d.getDay()-1;
        const currentDay = ABBREVIATED_DAYS[n < 0 ? 6 : n]
        const prevDay = ABBREVIATED_DAYS[n < 0 ? 5 : (n === 0 ? 6 : n-1)]
        let numericStart = 0
        let numericEnd = 0
        const current = parseFloat(`${new Date().getHours()}.${new Date().getMinutes()}`)

        //checking preDay 
        schedules.forEach(schedule => {
            if (schedule.days && schedule.days.indexOf(prevDay) !== -1) {
                if (schedule['start'] > schedule['end']) {
                    result.start = schedule['start']
                    result.end = schedule['end']
                    numericEnd = parseFloat(schedule['end'].replace(':', '.'))
                }
            }
        })

        // if schedule of prevDay affect current day numericEnd is diferent of 0
        if (numericEnd !== 0) {
            if (numericStart <= current && numericEnd > current) {
                result.isOpen = true
                return result
            } 
        }

        //checking currentDay 
        schedules.forEach(schedule => {
            if (schedule.days && schedule.days.indexOf(currentDay) !== -1) {
                if (schedule['start'] > schedule['end']) {
                    numericStart = parseFloat(schedule['start'].replace(':', '.'))
                    numericEnd = 23.59
                } else {
                    numericStart = parseFloat(schedule['start'].replace(':', '.'))
                    numericEnd = parseFloat(schedule['end'].replace(':', '.'))
                }
                result.start = schedule['start']
                result.end = schedule['end']
            }
        })

        if (numericStart <= current && numericEnd > current) {
            result.isOpen = true
            return result
        } 

        return result
    } catch (err) {
        return result
    }
};