import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import { Auth, API, Storage } from 'aws-amplify';
import { withSnackbar } from 'notistack';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import getMerchantData from '../../lib/getMerchant';
import addPorductIcon from '../../assets/images/addProductIcon.png'
import noProduct from '../../assets/images/noProducts.svg'
import Checkbox from '@material-ui/core/Checkbox';
import defaultProductImage from '../../assets/images/defaultProductImage.svg'
import SecondaryButton from '../common/secondaryButton'
import PrimaryButton from '../common/primaryButton';
import PrimaryInput from '../common/primaryInput';
import MultilineInput from '../common/multilineInput';
import theme from '../../styles/theme';
import FileInput from '../common/fileInput';

const ModalPLD = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false)

  return (
    <>
      {loading ?
        <div className={`${classes.loadingContainer}`}>
          <div className={`${classes.loading} `}>
            <CircularProgress color='secondary' />
          </div>
        </div>
        :
        <div className={classes.container}>
          <Typography className={classes.title}>
            REVISIÓN
          </Typography>
          <Typography className={classes.instructions}>
            Por favor escriba un comentario justificando la razón de la desactivación  del usuario.
          </Typography>
          <MultilineInput
          >
          </MultilineInput>
          <div className={classes.evidencia}>
            <Typography className={classes.instructions}>
              Si es posible, adjunte evidencia:
            </Typography>
            <div className={classes.buttons}>
              <PrimaryInput>
              </PrimaryInput>
              <FileInput />
              {/* <SecondaryButton>
                Adjuntar archivo
              </SecondaryButton> */}
            </div>
          </div>
          <div className={classes.buttons}>
            <PrimaryButton
              fullwidth
              // type='cancel'
              onClick={props.handleClose}
              // color={theme.palette.red}
              primaryButtonStyle={classes.buttonStyle}
            >
              Cancelar
            </PrimaryButton>
            <PrimaryButton
              fullwidth
            >
              Enviar
            </PrimaryButton>
          </div>
        </div>
      }
    </>
  );
}

export default withSnackbar(ModalPLD);

const useStyles = makeStyles(theme => ({
  root: { width: 'auto', display: 'row' },
  container: {
    // width: 'auto',
    display: 'flex',
    maxHeight: '90vh',
    // overflow: 'scroll',
    flexDirection: 'column',
    maxWidth: 400
  },
  loadingContainer: {
    width: 'auto',
    display: 'flex',
    maxHeight: '90vh',
    flexDirection: 'column',
  },
  loading: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonStyle: {
    // justifyContent: 'space-between',
    backgroundColor: '#EE715B',
    borderColor: '#EE715B'
  },
  title: {
    textAlign: 'center',
    ...theme.typography.title,
  },
  buttons: {
    width: '100%',
    display: 'flex',
    size: 'lg',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column ',
      justifyContent: 'center',
    },
  },
  evidencia: {
    width: '100%',
    display: 'flex',
    // size: 'lg',
    flexDirection: 'column',
    // justifyContent: 'space-evenly',
    // alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'center',
    },
  },
  instructions: {
    ...theme.typography.paragraph,
  },
}));