export default function Countries() {
    return [ 
        { label: 'Selecciona una nacionalidad', section: 'Default' },
        { label: 'Afganistán', section: 'content' },
        { label: 'Albania', section: 'content' },
        { label: 'Alemania', section: 'content' },
        { label: 'Andorra', section: 'content' },
        { label: 'Angola', section: 'content' },
        { label: 'Antigua y Barbuda', section: 'content' },
        { label: 'Arabia Saudita', section: 'content' },
        { label: 'Argelia', section: 'content' },
        { label: 'Argentina', section: 'content' },
        { label: 'Armenia', section: 'content' },
        { label: 'Australia', section: 'content' },
        { label: 'Austria', section: 'content' },
        { label: 'Azerbaiyán', section: 'content' },
        { label: 'Bahamas', section: 'content' },
        { label: 'Bangladés', section: 'content' },
        { label: 'Barbados', section: 'content' },
        { label: 'Baréin', section: 'content' },
        { label: 'Bélgica', section: 'content' },
        { label: 'Belice', section: 'content' },
        { label: 'Benín', section: 'content' },
        { label: 'Bielorrusia', section: 'content' },
        { label: 'Birmania', section: 'content' },
        { label: 'Bolivia', section: 'content' },
        { label: 'Bosnia y Herzegovina', section: 'content' },
        { label: 'Botsuana', section: 'content' },
        { label: 'Brasil', section: 'content' },
        { label: 'Brunéi', section: 'content' },
        { label: 'Bulgaria', section: 'content' },
        { label: 'Burkina Faso', section: 'content' },
        { label: 'Burundi', section: 'content' },
        { label: 'Bután', section: 'content' },
        { label: 'Cabo Verde', section: 'content' },
        { label: 'Camboya', section: 'content' },
        { label: 'Camerún', section: 'content' },
        { label: 'Canadá', section: 'content' },
        { label: 'Catar', section: 'content' },
        { label: 'Chad', section: 'content' },
        { label: 'Chile', section: 'content' },
        { label: 'China', section: 'content' },
        { label: 'Chipre', section: 'content' },
        { label: 'Ciudad del Vaticano', section: 'content' },
        { label: 'Colombia', section: 'content' },
        { label: 'Comoras', section: 'content' },
        { label: 'Corea del Norte', section: 'content' },
        { label: 'Corea del Sur', section: 'content' },
        { label: 'Costa de Marfil', section: 'content' },
        { label: 'Costa Rica', section: 'content' },
        { label: 'Croacia', section: 'content' },
        { label: 'Cuba', section: 'content' },
        { label: 'Dinamarca', section: 'content' },
        { label: 'Dominica', section: 'content' },
        { label: 'Ecuador', section: 'content' },
        { label: 'Egipto', section: 'content' },
        { label: 'El Salvador', section: 'content' },
        { label: 'Emiratos Árabes Unidos', section: 'content' },
        { label: 'Eritrea', section: 'content' },
        { label: 'Eslovaquia', section: 'content' },
        { label: 'Eslovenia', section: 'content' },
        { label: 'España', section: 'content' },
        { label: 'Estados Unidos', section: 'content' },
        { label: 'Estonia', section: 'content' },
        { label: 'Etiopía', section: 'content' },
        { label: 'Filipinas', section: 'content' },
        { label: 'Finlandia', section: 'content' },
        { label: 'Fiyi', section: 'content' },
        { label: 'Francia', section: 'content' },
        { label: 'Gabón', section: 'content' },
        { label: 'Gambia', section: 'content' },
        { label: 'Georgia', section: 'content' },
        { label: 'Ghana', section: 'content' },
        { label: 'Granada', section: 'content' },
        { label: 'Grecia', section: 'content' },
        { label: 'Guatemala', section: 'content' },
        { label: 'Guyana', section: 'content' },
        { label: 'Guinea', section: 'content' },
        { label: 'Guinea-Bisáu', section: 'content' },
        { label: 'Guinea Ecuatorial', section: 'content' },
        { label: 'Haití', section: 'content' },
        { label: 'Honduras', section: 'content' },
        { label: 'Hungría', section: 'content' },
        { label: 'India', section: 'content' },
        { label: 'Indonesia', section: 'content' },
        { label: 'Irak', section: 'content' },
        { label: 'Irán', section: 'content' },
        { label: 'Irlanda', section: 'content' },
        { label: 'Islandia', section: 'content' },
        { label: 'Islas Marshall', section: 'content' },
        { label: 'Islas Salomón', section: 'content' },
        { label: 'Israel', section: 'content' },
        { label: 'Italia', section: 'content' },
        { label: 'Jamaica', section: 'content' },
        { label: 'Japón', section: 'content' },
        { label: 'Jordania', section: 'content' },
        { label: 'Kazajistán', section: 'content' },
        { label: 'Kenia', section: 'content' },
        { label: 'Kirguistán', section: 'content' },
        { label: 'Kiribati', section: 'content' },
        { label: 'Kuwait', section: 'content' },
        { label: 'Laos', section: 'content' },
        { label: 'Lesoto', section: 'content' },
        { label: 'Letonia', section: 'content' },
        { label: 'Líbano', section: 'content' },
        { label: 'Liberia', section: 'content' },
        { label: 'Libia', section: 'content' },
        { label: 'Liechtenstein', section: 'content' },
        { label: 'Lituania', section: 'content' },
        { label: 'Luxemburgo', section: 'content' },
        { label: 'Macedonia del Norte', section: 'content' },
        { label: 'Madagascar', section: 'content' },
        { label: 'Malasia', section: 'content' },
        { label: 'Malaui', section: 'content' },
        { label: 'Maldivas', section: 'content' },
        { label: 'Malí', section: 'content' },
        { label: 'Malta', section: 'content' },
        { label: 'Marruecos', section: 'content' },
        { label: 'Mauricio', section: 'content' },
        { label: 'Mauritania', section: 'content' },
        { label: 'México', section: 'content' },
        { label: 'Micronesia', section: 'content' },
        { label: 'Moldavia', section: 'content' },
        { label: 'Mónaco', section: 'content' },
        { label: 'Mongolia', section: 'content' },
        { label: 'Montenegro', section: 'content' },
        { label: 'Mozambique', section: 'content' },
        { label: 'Namibia', section: 'content' },
        { label: 'Nauru', section: 'content' },
        { label: 'Nepal', section: 'content' },
        { label: 'Nicaragua', section: 'content' },
        { label: 'Níger', section: 'content' },
        { label: 'Nigeria', section: 'content' },
        { label: 'Noruega', section: 'content' },
        { label: 'Nueva Zelanda', section: 'content' },
        { label: 'Omán', section: 'content' },
        { label: 'Países Bajos', section: 'content' },
        { label: 'Pakistán', section: 'content' },
        { label: 'Palaos', section: 'content' },
        { label: 'Panamá', section: 'content' },
        { label: 'Papúa Nueva Guinea', section: 'content' },
        { label: 'Paraguay', section: 'content' },
        { label: 'Perú', section: 'content' },
        { label: 'Polonia', section: 'content' },
        { label: 'Portugal', section: 'content' },
        { label: 'Reino Unido de Gran Bretaña e Irlanda del Norte', section: 'content' },
        { label: 'República Centroafricana', section: 'content' },
        { label: 'República Checa', section: 'content' },
        { label: 'República del Congo', section: 'content' },
        { label: 'República Democrática del Congo', section: 'content' },
        { label: 'República Dominicana', section: 'content' },
        { label: 'República Sudafricana', section: 'content' },
        { label: 'Ruanda', section: 'content' },
        { label: 'Rumanía', section: 'content' },
        { label: 'Rusia', section: 'content' },
        { label: 'Samoa', section: 'content' },
        { label: 'San Cristóbal y Nieves', section: 'content' },
        { label: 'San Marino', section: 'content' },
        { label: 'San Vicente y las Granadinas', section: 'content' },
        { label: 'Santa Lucía', section: 'content' },
        { label: 'Santo Tomé y Príncipe', section: 'content' },
        { label: 'Senegal', section: 'content' },
        { label: 'Serbia', section: 'content' },
        { label: 'Seychelles', section: 'content' },
        { label: 'Sierra Leona', section: 'content' },
        { label: 'Singapur', section: 'content' },
        { label: 'Siria', section: 'content' },
        { label: 'Somalia', section: 'content' },
        { label: 'Sri Lanka', section: 'content' },
        { label: 'Suazilandia', section: 'content' },
        { label: 'Sudán', section: 'content' },
        { label: 'Sudán del Sur', section: 'content' },
        { label: 'Suecia', section: 'content' },
        { label: 'Suiza', section: 'content' },
        { label: 'Surinam', section: 'content' },
        { label: 'Tailandia', section: 'content' },
        { label: 'Tanzania', section: 'content' },
        { label: 'Tayikistán', section: 'content' },
        { label: 'Timor Oriental', section: 'content' },
        { label: 'Togo', section: 'content' },
        { label: 'Tonga', section: 'content' },
        { label: 'Trinidad y Tobago', section: 'content' },
        { label: 'Túnez', section: 'content' },
        { label: 'Turkmenistán', section: 'content' },
        { label: 'Turquía', section: 'content' },
        { label: 'Tuvalu', section: 'content' },
        { label: 'Ucrania', section: 'content' },
        { label: 'Uganda', section: 'content' },
        { label: 'Uruguay', section: 'content' },
        { label: 'Uzbekistán', section: 'content' },
        { label: 'Vanuatu', section: 'content' },
        { label: 'Venezuela', section: 'content' },
        { label: 'Vietnam', section: 'content' },
        { label: 'Yemen', section: 'content' },
        { label: 'Yibuti', section: 'content' },
        { label: 'Zambia', section: 'content' },
        { label: 'Zimbabue', section: 'content' },
    ]
}