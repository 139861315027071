import { API } from 'aws-amplify';

async function getMerchantData(by, identityId) {
    let merchant = null
    try {
        switch (by) {
            case 'merchant':
                merchant = await API.get('merchant-api', `/merchant?merchantRecordKey=${identityId}`);
                if (merchant.data) {
                    return merchant.data
                } else {
                    return null
                }
            case 'store':
                const stores = await API.get('store-api', '/store', {
                    queryStringParameters: {
                        store: identityId,
                        isFor: 'merchant'
                    }
                  });
                  if (stores.data.length > 0) {
                    merchant = await API.get('merchant-api', `/merchant?merchantRecordKey=${stores.data[0].merchant}`);
                    if (merchant.data) {
                        return merchant.data
                    } else {
                        return null
                    }
                  }
            default:
                return null
        }
    } catch (err) {
        return null
    }
};

export default getMerchantData;