import { API } from 'aws-amplify';
import { API_TRANSACTION } from '../../lib/constants';

const getTransactionsByDate = async(from, to,store) => {
    try{
        const result = await API.get(API_TRANSACTION, '/transaction/moves', {
            queryStringParameters: {
                startDate: from,
                endDate: to,
                store: store,
            }
        })
        return {
            success: result.success,
            data: result.data
        }
    }
    catch(error) {
        throw new Error(error);
    }
}

export default getTransactionsByDate