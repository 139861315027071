import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { Auth, API, Storage } from 'aws-amplify';
import PrimaryButton from '../common/primaryButton';
import {
  CircularProgress,
  Grid,
  Paper,
  Typography,
  Checkbox,
} from '@material-ui/core';
import defaultStoreIcon from './../../assets/images/default_store_icon.svg';
import noStoreImage from '../../assets/images/default_store_icon_1.svg';

const SharingIsCaring = (props) => {
  const userKey = useSelector(state => state.user.value);
  let history = useHistory();
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [hasSharing, setHasSharing] = useState(false);
  const [merchantRecordKey, setMerchantRecordKey] = useState('');
  const [stores, setStores] = useState([]);
  const [affiliatedStores, setAffiliatedStores] = useState([]);
  const [nonAffiliatedStores, setNonAffiliatedStores] = useState([]);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    try {
      setLoading(true);
      const userInfo = await Auth.currentAuthenticatedUser();
      const currentUser = userInfo.attributes;
      if (currentUser === undefined) {
        handleAlert('error', 'Ah ocurrido un error al cargar los datos del usuario');
        history.push('/login');
      } else {
        const resProfile = await API.get('profile-api', `/profile/${userKey}`);
        const userProfile = resProfile.data[0];
        const resMerchant = await API.get('merchant-api', `/merchant?merchantRecordKey=${userProfile.merchantRecordKey}`);
        const merchantProfile = resMerchant.data;
        if (merchantProfile.sharingIsCaring) {
          setHasSharing(true);
          const resStores = await API.get('store-api', '/store', {
            queryStringParameters: {
              merchant: merchantProfile.merchantRecordKey,
              isFor: 'merchant',
            },
          });
          const storeList = resStores.data.Items;
          const imagesP = storeList.map((store) =>
            Storage.get(`store/${store.id}/default.jpg`)
          );
          const images = await Promise.all(imagesP);
          const storesWithImages = storeList.map((store, index) => {
            return {
              ...store,
              storeImage: images[index],
              checked: false,
            };
          });

          const affStores = storesWithImages.filter(store => store.sharingIsCaring);
          const nonAffStores = storesWithImages.filter(store => !store.sharingIsCaring);

          setStores(storeList);
          setAffiliatedStores(affStores);
          setNonAffiliatedStores(nonAffStores);
        } else {
          setHasSharing(false);
        }
        setMerchantRecordKey(userProfile.merchantRecordKey);
      }
      setLoading(false);
    } catch (err) {
      handleAlert('error', 'No es posible acceder a esta opción');
    }
  }

  const affiliateCommerce = async (affiliate) => {
    try {
      setLoading(true);
      await API.put('sharing-api', '/sharing/affiliate', {
        body: {
          merchantRecordKey: merchantRecordKey,
          affiliate,
        },
      });
      await getUser();
    } catch (err) {
      handleAlert('error', 'Algo salió mal');
      setLoading(false);
    }
  }

  const affiliateStores = async (affiliate) => {
    try {
      setLoading(true);
      const tempStores = affiliate ? nonAffiliatedStores : affiliatedStores;
      const stores = tempStores.filter(item => item.checked);
      const promises = stores.map(item => {
        return API.put('sharing-api', '/sharing/affiliate', {
          body: {
            merchantRecordKey: merchantRecordKey,
            affiliate: affiliate,
            storeRecordKey: item.id,
          },
        });
      })
      await Promise.all(promises);
      await getUser();
      setLoading(false);
    } catch (err) {
      handleAlert('error', 'Algo salió mal');
      setLoading(false);
    }
  }

  const handleSelectStore = async (store, affiliated) => {
    if (affiliated) {
      const affCopy = [...affiliatedStores];
      const index = affCopy.findIndex(item => item.id === store);
      affCopy[index]['checked'] = !affCopy[index]['checked'];
      setAffiliatedStores(affCopy);
    } else {
      const nonAffCopy = [...nonAffiliatedStores];
      const index = nonAffCopy.findIndex(item => item.id === store);
      nonAffCopy[index]['checked'] = !nonAffCopy[index]['checked'];
      setNonAffiliatedStores(nonAffCopy);
    }
  }

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const storeCard = (store, affiliated) => {
    return (
      <Grid
        key={store.id}
        item
        xs={12}
        sm={6}
        md={4}
        lg={3}
        xl={2}
      >
        <Paper className={`${classes.cardContainerActive} ${classes.cardContainer}`}>
          <Grid container>
            <Grid item xs={12} sm={2} className={classes.cardImageContainer}>
              <img
                className={classes.cardImage}
                src={defaultStoreIcon}
              />
            </Grid>
            <Grid item xs={12} sm={8} className={classes.cardTitle}>
              <Typography className={classes.cardTitleText}>
                {store.storeName}
              </Typography>
              <Typography className={classes.cardSubtitle}>
                {`${store.schedules[0].start} - ${store.schedules[0].end}`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2} className={classes.cardTitle}>
              <Checkbox
                checked={store.checked}
                onChange={() => handleSelectStore(store.id, affiliated)}
                color="rgba(67, 67, 67, 0.6)"
                style={{ marginRight: '25px' }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }

  const SharingInformation = () => {
    return (
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <text className={classes.title}>Afiliate en
            <text className={classes.greenTitle}> Sharing is Caring</text>
          </text>
        </div>
        <div className={classes.contentContainer}>
          <div className={classes.halfContainer}>
            <text className={classes.subtitle}>¿Quieres ganar dinero haciendo transacciones con <text className={classes.greenTitle}>Nerito</text>?</text>
            <text className={classes.info}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Sed vitae vehicula ipsum, vitae dapibus diam. Sed vulputate euismod pellentesque.
              Nullam ultrices pulvinar posuere. Nulla eget dui molestie, lacinia est id, sagittis odio.
              Sed eget rhoncus diam, sed volutpat quam. Aenean sit amet lacus ac est euismod ultrices.
              Sed vel nulla ut ligula sollicitudin gravida in in purus. Donec rutrum neque mauris.
              Quisque ut purus et felis efficitur vehicula nec ut purus.
            </text>
            <text className={classes.info}>Para mas información visita <text className={classes.greenTitle}>nerito.mx</text></text>
          </div>
          <div className={classes.halfContainer}>
            <PrimaryButton
              color="#3B9A83"
              onClick={() => affiliateCommerce(true)}>
              Afiliarme en Sharing is caring
            </PrimaryButton>
          </div>
        </div>
      </div>
    );
  }

  const SharingMenu = () => {
    return (
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <text className={`${classes.title} ${classes.greenTitle}`}>
            Sharing Is Caring
          </text>
          <PrimaryButton
            color="#EE715B"
            onClick={() => affiliateCommerce(false)}>
            Desafiliar Empresa
          </PrimaryButton>
        </div>
        {stores.length < 1 ? (
          <div className={classes.empty}>
            <img src={noStoreImage} alt='noStoreImage' />
            <Typography>No hay sucursales.</Typography>
          </div>
        ) : (
          <>
            <div className={classes.titleContainer}>
              <text className={classes.title}>
                Tiendas afiliadas
              </text>
              <PrimaryButton
                color="#3B9A83"
                onClick={() => affiliateStores(false)}>
                {`Desafiliar Tienda(s)`}
              </PrimaryButton>
            </div>
            <div className={classes.storesContainer}>
              <div className={`${classes.contentContainer} scrollBarHidden`}>
                <Grid container spacing={2}>
                  {affiliatedStores.map((store) => storeCard(store, true))}
                </Grid>
              </div>
            </div>
            <div className={classes.titleContainer}>
              <text className={classes.title}>
                Tiendas NO afiliadas
              </text>
              <PrimaryButton
                color="#3B9A83"
                onClick={() => affiliateStores(true)}>
                {`Afiliar Tienda(s)`}
              </PrimaryButton>
            </div>
            <div className={classes.storesContainer}>
              <div className={`${classes.contentContainer} scrollBarHidden`}>
                <Grid container spacing={2}>
                  {nonAffiliatedStores.map((store) => storeCard(store, false))}
                </Grid>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress color='secondary' />
      </div>
    );
  }

  return (
    <div>
      {hasSharing ? <SharingMenu /> : <SharingInformation />}
    </div>
  );
}

export default withSnackbar(SharingIsCaring);

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 0,
    overflow: 'hidden',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 30,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.lightGrey.main}`,
    marginBottom: 15,
  },
  title: {
    ...theme.typography.title,
    paddingTop: 10,
    paddingBottom: 10,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  greenTitle: {
    color: theme.palette.primary.main,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  subtitle: {
    ...theme.typography.subtitle,
    paddingTop: 10,
    paddingBottom: 10,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  halfContainer: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
  },
  info: {
    ...theme.typography.paragraph,
    paddingRight: 10,
    fontWeight: 600,
  },
  loadingContainer: {
    margin: 'auto',
    paddingTop: '50px',
    ...theme.globals.center,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 30,
  },
  cardContainer: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    cursor: 'pointer',
    transition: 'box-shadow 0.3s ease-in-out',
    '&:hover': {
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.75)',
    },
  },
  cardContainerActive: {
    backgroundColor: 'white'
  },
  cardImageContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      justifyContent: 'center'
    },
  },
  cardImage: {
    height: 50,
    width: 50,
    borderRadius: 50,
  },
  cardTitle: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    paddingLeft: 10,
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    },
  },
  cardTitleText: {
    ...theme.typography.cardTitle,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  empty: {
    margin: 'auto',
    alignItems: 'center',
    paddingTop: '50px',
    textAlign: 'center',
  },
}));