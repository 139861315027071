import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Storage } from 'aws-amplify';
import { useHistory } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { API } from 'aws-amplify';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PrimaryButton from '../../common/primaryButton/index.js';
// import '..*../../assets/css/main.css
import defaultUser from '../../../assets/images/default-user.png';
import actionsIcon from '../../../assets/images/mdi_edit2.png';
import { FONT_DEFAULT } from '../../../lib/constants.js';
import IconFailed from '../../../assets/images/iconFailed.png'
import IconVerified from '../../../assets/images/iconVerified.png'
import TextField from '@material-ui/core/TextField';
import moment from 'moment-timezone';
import DefaultModal from '../../modal';
import theme from '../../../styles/theme';
import MenuItem from '@material-ui/core/MenuItem';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default function Details(props) {
  useEffect(() => {
    getuserprofile()
  }, [])

  const classes = useStyles();
  let history = useHistory();

  const [isLoading, setLoading] = useState(true);
  const [currentTab, setTab] = useState(0);
  const [user, setUser] = useState(props.location.state.user);
  const [profilePicture, setProfilePicture] = useState(defaultUser);
  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };
  const initialFromDate = `${moment().format('YYYY-MM-DD')}T00:00`;
  const initialToDate = `${moment().format('YYYY-MM-DD')}T23:59`;
  const perPage = 10;

  const [fromDate, setFromDate] = useState(initialFromDate);
  const [toDate, setToDate] = useState(initialToDate);
  const [showModal, setShowModal] = useState(false);

  const [deactivateUser, setDeactivateUser] = useState(false);
  const [selectedType, setSelectedType] = useState('Selecciona una opción');
  const transTypes = [
    'TODOS',
    'ORDEN',
    'FONDOS',
    'DEPOSITO EN TIENDA',
    'ENVIO DE DINERO',
    'PAGO DE COPERACHA',
    'PAGO DE PRESTAMO',
    'PAGO EN TIENDA',
    'PRESTAMO ACEPTADO',
    'REEMBOLSO',
    'REGALO',
    'RETIRO EN TIENDA',
  ];

  const getIdentityStatus = () => {
    console.info(props.user);
    return (
      <>
        <div className={classes.labelRowDocument1}>
          {/* <img width="13" height="13" src={props.user.identityValidationStep === 'VALID' ? IconVerified : IconFailed} className={classes.actionButons} /> */}
          <Typography className={classes.columnText}>Identificación Oficial</Typography>
          <PrimaryButton>
            Ver documentación
          </PrimaryButton>
        </div>
        {/* <a href={props.user.identityUri} className={classes.previewText}>Ver documento en Mati</a> */}

        {/* <div className={classes.labelRowDocument1}>
                    <img width="13" height="13" src={props.user.identityValidationStatus === 'reviewNeeded' ? IconFailed : IconVerified} className={classes.actionButons} />
                    <Typography className={classes.columnText}>Validaciones de Mati</Typography>
                </div>
                <a href={props.user.identityUri} className={classes.previewText}>Ver en Mati</a> */}
      </>
    );
  }

  // const checkboxChange = () => {
  //   setLoading(true)
  //   try {
  //     API.put('profile-api', `/profile/${user.userRecordKey}`, {
  //       body: {
  //         blockImmediatelyUpdateBalance: userCanUpdateBalance
  //       },
  //     })
  //     setUserCanUpdateBalance(!userCanUpdateBalance);
  //     setLoading(false)
  //     handleAlert('success', !userCanUpdateBalance ? 'Recibir depósitos automáticos: Habilitado' : 'Recibir depósitos automáticos: Deshabilitado')
  //   }
  //   catch (error) {
  //     setLoading(false)
  //     handleAlert('error', error.message)
  //   }
  // }

  const getuserprofile = async () => {
    setLoading(true)
    try {
      const pImage = await Storage.get(
        `profiles/${user.userRecordKey}/default.jpg`,
      );
      if (user.hasAvatar)
        setProfilePicture(pImage);
      setLoading(false)
    } catch (err) {
      console.error(err);
      setLoading(false)
    }
  }

  const buildStatus = (status) => {
    if (status)
      return (
        <div className={classes.statusStyle1}>
          <Typography className={classes.statusText}>Activo</Typography>
        </div>
      )
    else
      return (
        <div className={classes.statusStyle2}>
          <Typography className={classes.statusText}>Inactivo</Typography>
        </div>
      )
  }

  const handleChangeTab = (event, newValue) => {
    setTab(newValue)
  }

  const setMoneyFormat = (number) => {
    if (number != 'N/A') {
      return '$ ' + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return number
    }
  }

  const handleChange = (event) => {
    const { value, name } = event.target
    switch (name) {
      case 'fromDate':
        setFromDate(value)
        // callApi(value, toDate)
        break;
      case 'toDate':
        setToDate(value)
        // callApi(fromDate, value)
        break;
      case 'type':
        setSelectedType(value)
        // callApi(fromDate, value)
        break;
      default:
        handleAlert('error', `Input no válido ${name}`)
    }
  }

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className={classes.root}>
      {isLoading ?
        <div className='gralinfo_form'>
          <CircularProgress color="secondary" />
        </div>
        :
        <>
          <DefaultModal
            open={showModal}
            modalType='PLD'
            handleClose={() => setShowModal(false)}
          >
          </DefaultModal>
          <div className={classes.container}>
            <div className={classes.titleContainer}>
              <Typography className={classes.title}>{user.firstName} {user.lastName}</Typography>
              <PrimaryButton
                onClick={handleShowModal}
                primaryButtonStyle={classes.buttonStyle}>
                Desactivar Usario
              </PrimaryButton>
            </div>
            <div className={classes.columnsContainer}>
              <div className={classes.columnStyle}>
                <Typography className={classes.columnTitle}>Perfil del Usuario</Typography>
                <div className={classes.labelRow}>
                  <Typography className={classes.columnText}>Nombre: </Typography>
                  {user.identityWalletUser ?
                    <Typography className={classes.columnValueText}>{user.identityWalletUser.name}</Typography>
                    :
                    <Typography className={classes.columnValueText}>{user.firstName} {user.lastName}</Typography>
                  }
                </div>
                <div className={classes.labelRow}>
                  <Typography className={classes.columnText}>Nacionalidad: </Typography>
                  <Typography className={classes.columnValueText}>{user.identityWalletUser && user.identityWalletUser.nationality}</Typography>
                </div>
                <div className={classes.labelRow}>
                  <Typography className={classes.columnText}>Fecha de Nacimiento: </Typography>
                  <Typography className={classes.columnValueText}>{user.identityWalletUser && user.identityWalletUser.dateOfBirth}</Typography>
                </div>
                <div className={classes.labelRow}>
                  <Typography className={classes.columnText}>Género: </Typography>
                  <Typography className={classes.columnValueText}>{user.identityWalletUser && user.identityWalletUser.gender}</Typography>
                </div>
                <div className={classes.labelRow}>
                  <Typography className={classes.columnText}>CURP: </Typography>
                  <Typography className={classes.columnValueText}>{user.identityWalletUser && user.identityWalletUser.curp}</Typography>
                </div>
                <div className={classes.labelRow}>
                  <Typography className={classes.columnText}>Teléfono: </Typography>
                  <Typography className={classes.columnValueText}>{user.phoneNumber}</Typography>
                </div>
                <div className={classes.labelRow}>
                  <Typography className={classes.columnText}>Email: </Typography>
                  <Typography className={classes.columnValueText}>{user.email}</Typography>
                </div>
              </div>
              <div className={classes.columnStyle}>
                <div className={classes.columnStyle2}>
                  <Typography className={classes.columnTitle}>Validación de Mati</Typography>
                  {getIdentityStatus()}
                </div>
              </div>
            </div>
            <div>
              <div className={classes.titleContainer}>
                <Typography className={classes.columnTitle}>Movimientos</Typography>
              </div>
              <div className={classes.topContainer}>
                <div className={classes.filterConteiner + ' col-12'}>
                  <TextField className={classes.filterInput + ' col-sm-12 col-lg-4'}
                    id="datetime-from"
                    name="fromDate"
                    value={fromDate}
                    onChange={handleChange}
                    label="Desde"
                    type="datetime-local"
                    defaultValue="2017-05-24T10:30"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                  <TextField className={classes.filterInput + ' col-sm-12 col-lg-4'}
                    id="datetime-to"
                    name="toDate"
                    value={toDate}
                    onChange={handleChange}
                    label="Hasta"
                    type="datetime-local"
                    defaultValue="2017-05-24T10:30"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  />
                  <TextField className={classes.filterInput + ' col-sm-12 col-lg-4'}
                    id="type"
                    name="type"
                    value={selectedType}
                    onChange={handleChange}
                    label="Tipo"
                    select
                    defaultValue="Seleccione una opción"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                  >
                    {transTypes.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                {/* <div className='col-auto btn-download lbl-total '>
                    <Button className={`${classes.downloadCsvButtonStyle} tooltip`} onClick={() => downloadCsv()} disabled={transactions.length <= 0}>
                      <p className={classes.buttonText}>Descargar movimientos</p>
                    </Button>
                  </div> */}
              </div>
              <div className={`row + ${classes.topContainer}`}>
                <div className={'col-auto lbl-total ' + classes.totalsConteiner}>
                  <Typography className={classes.totalsLabel}>Pagos:</Typography>
                  <Typography className={classes.totalsValue}>{setMoneyFormat(200)}</Typography>
                </div>
                <div className={'col-auto lbl-total ' + classes.totalsConteiner}>
                  <Typography className={classes.totalsLabel}>Depósitos:</Typography>
                  <Typography className={classes.totalsValue}>{setMoneyFormat(200)}</Typography>
                </div>
                <div className={'col-auto lbl-total ' + classes.totalsConteiner}>
                  <Typography className={classes.totalsLabel}>Retiros:</Typography>
                  <Typography className={classes.totalsValue}>{setMoneyFormat(200)}</Typography>
                </div>
                <div className={'col-auto lbl-total ' + classes.totalsConteiner}>
                  <Typography className={classes.totalsLabel}>Compras:</Typography>
                  <Typography className={classes.totalsValue}>{setMoneyFormat(200)}</Typography>
                </div>
                <div className={'col-auto lbl-total ' + classes.totalsConteiner}>
                  <Typography className={`${classes.totalsLabel}`}>BALANCE TOTAL:</Typography>
                  <Typography className={`${classes.totalsValue}`}>{setMoneyFormat(200)}</Typography>
                </div>
              </div>
              <table className="table mt-4">
                <thead>
                  <tr>
                    <th>
                      <Typography className={classes.tableHeaderText}>ID</Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderText}>Tipo</Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderText}>Emisor</Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderText}>Receptor</Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderText}>Cantidad</Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderText}>Fecha</Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderText}>Hora</Typography>
                    </th>
                    <th>
                      <Typography className={classes.tableHeaderText}>Asunto</Typography>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </>
      }
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flex: 1,
    marginTop: 0,
    backgroundColor: theme.palette.white.main,
  },
  container: {
    flex: 8,
    marginLeft: 30,
    marginRight: 30,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 25,
    // marginBottom: 25,
    paddingLeft: 20,
    justifyContent: 'space-between'
  },
  title: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 24,
    display: 'flex',
    alignItems: 'center',
    color: '#434343'
  },
  titleTabs: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 12,
    display: 'inline-block',
    alignItems: 'left',
    lineHeight: 3,
    color: '#828282'
  },
  containerForm: {
    backgroundColor: '#FFFFFF',
    borderRadius: 0,
    height: '90%',
    backgroundColor: '#F6F6F6',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  statusStyle1: {
    width: 80,
    height: 25,
    background: '#54D7A8',
    borderRadius: 5,
    alignItems: 'center',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 20
  },
  buttonStyle: {
    justifyContent: 'space-between',
    backgroundColor: '#EE715B',
    borderColor: '#EE715B'
  },
  statusStyle2: {
    width: 80,
    height: 25,
    background: '#FD8197',
    borderRadius: 19,
    alignItems: 'center',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 20
  },
  statusText: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 12,
    color: '#FFFFFF',
  },
  profilePicture: {
    width: 36,
    height: 36,
    borderRadius: '100%',
    marginRight: 21,
  },
  actions: {
    marginRight: 0,
    marginLeft: 'auto',
  },
  actionsLabel: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 12,
    color: 'rgba(67, 67, 67, 0.6)',
  },
  actionsIcon: {
    widht: '20px',
    height: '20px',
    marginLeft: '16px',
  },
  userPanel: {
    marginTop: 20,
    borderRadius: 5,
    border: '1px solid #E0E0E0',
    backgroundColor: 'white',
    width: '95%',
    alignSelf: 'center'
  },
  containerForm2: {
    backgroundColor: '#BDBDBD',
    position: 'relative',
    zIndex: 0,
    height: 1,
    flexDirection: "row",
    opacity: '.6'
  },
  columnsContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    paddingLeft: 20,
    paddingRight: 20,
  },
  columnStyle: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  columnStyle2: {
    display: 'flex',
    flex: 2,
    flexDirection: 'column'
  },
  columnTitle: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 18,
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    color: '#434343'
  },
  columnText: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
    marginBottom: 5
  },
  columnValueText: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 16,
    marginLeft: 7,
    marginBottom: 5,
    textAlign: 'right'
  },
  labelRow: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 20,
    width: 400,
    justifyContent: 'space-between',
  },
  labelRowDocument1: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20
  },
  actionButons: {
    marginRight: 5
  },
  previewText: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 12,
    color: '#6B7899',
    cursor: 'pointer'
  },
  topContainer: {
    border: '1px solid #434343',
    borderRadius: 10,
    margin: 10,
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  rowStyle: {
    display: 'flex',
    flexDirection: 'row'
  }, 
  totalsConteiner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  totalsValue:{
    ...theme.typography.subtitle,
    fontSize: 22,
  },
  totalsLabel: {
    ...theme.typography.paragraph,
    fontWeight: '500',
    fontSize: 20,
  },
  filterInput: {
    padding: 10,
  }
});