const NumberOfEmployeesItems = () => {
  return [
    { label: 'Selecciona una categoría', value: '', section: 'Default' },
    { label: '1 a 20', value: '1 a 20', section: 'Content', },
    { label: '21 a 40', value: '21 a 40', section: 'Content', },
    { label: '41 a 60', value: '41 a 60', section: 'Content', },
    { label: '61 a 80', value: '61 a 80', section: 'Content', },
    { label: '81 a 100', value: '81 a 100', section: 'Content', },
    { label: 'Más de 100', value: 'Más de 100', section: 'Content', },
  ]
};

export default NumberOfEmployeesItems;
