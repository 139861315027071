import moment from 'moment-timezone';

const TIMEOUT = 60;
const DELAY = 10;

const getSeconds = (time) => {
  const now = moment.utc(moment.now()).valueOf();
  return Math.floor(moment.duration(now - time).asSeconds());
}

const sleep = () => {
  return new Promise(resolve => setTimeout(resolve, DELAY * 1000));
}

const waitForIt = async (getUser) => {
  try {
    const initTime = moment.utc(moment.now()).valueOf();
    while (getSeconds(initTime) < TIMEOUT) {
      const merchant = await getUser();
      if (merchant.isCommissionAgent) {
        return merchant
      }
      await sleep(DELAY);
    }
    if (!global.cancelQR) {
      throw new Error('Timeout');
    } else {
      throw new Error('CancelQR');
    }
  } catch (err) {
    throw new Error(err.message || err);
  }
}

export default waitForIt;