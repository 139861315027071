import React from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { Auth, API, Storage } from 'aws-amplify';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography, Grid } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import DefaultCard from '../../../common/defaultCard';
import DefaultInput from '../../../common/defaultInput';
import DefaultButton from '../../../common/defaultButton';
import CustomMatiButton from '../../../common/matiButton';
import axios from 'axios';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import { useSelector } from 'react-redux';

import '../../../../assets/css/main.css';
import CheckValue from '../../../../lib/formatValidations';

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  };
}

const useStyles = makeStyles(theme =>({
  container: {
    ...theme.globals.center,
    padding: 24,
  },
  profileContainer: {
    width: '100%',
  },
  firstRowStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  rowStyle: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 40,
    flexWrap: 'wrap',
  },
  subRowStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  columStyle: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  F1: {
    flex: 1,
  },
  F2: {
    flex: 2,
  },
  title: {
    ...theme.typography.mediumText,
    color: '#000000',
  },
  leyendContainer: {
    position: 'absolute',
    bottom: 130,
  },
  leyendtext: {
    ...theme.typography.mediumText,
    color: '#828282',
  },
  paper: {
    position: 'absolute',
    backgroundColor: '#FAFAFA',
    boxShadow: '0 4 14 rgba(0, 0, 0, 0.15)',
    borderRadius: 19,
    textAlign: 'center',
  },
  previewPicture: {
    maxWidth: 600,
    maxHeight: 600,
  },
  twoColumnStyle: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    paddingLeft: '15px',
    paddingRight: '15px',
  },
  title: {
    ...theme.typography.paragraph,
    color: '#000000',
    textTransform: 'uppercase',
  },
  subtitle: {
    marginTop: '5px',
    marginBottom: '5px',
    ...theme.typography.paragraph,
    color: '#000000',
  },
  normalText: {
    ...theme.typography.mediumText,
    color: 'gray',
  },
  boldText: {
    ...theme.typography.smallText,
    fontStyle: 'bold',
    color: 'gray',
  },
  innerContainer: {
    paddingLeft: '15px',
    paddingTop: '15px',
  },
  verified: {
    color: '#6EB89D',
    fontSize: 16,
  },
  notVerified: {
    display: 'none',
  },
}));

function Tab3(props) {
  const settings = useSelector(state => state.settings.value);

  useEffect(() => {
    if (props.ExecutingRequest) {
      callApi();
    }
  }, [props.ExecutingRequest]);

  const classes = useStyles();

  const availableTypes = ['pdf', 'png', 'jpg', 'jpeg'];

  const [isLoading, setLoading] = React.useState(false);
  const [CDFile, setFileCd] = React.useState(null);
  const [ACFile, setFileAc] = React.useState(null);
  const [IOFile, setFileIo] = React.useState(null);
  const [CPFile, setFileCp] = React.useState(null);

  const [linkEmail, setLinkEmail] = React.useState('');
  const [sendingEmail, setSendingEmail] = React.useState(false);

  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [previewImage, setPreviewImage] = React.useState(null);
  const [previewPdf, setPreviewPdf] = React.useState(null);
  const [identityVerified, setIdentityVerified] = React.useState(
    props.merchantProfile.identityValidationStep === 'VALID'
  );
  const [isUploading, setUploading] = React.useState(false);
  const [progressUpload, setProgress] = React.useState(0);
  const { service_mati_verify_web, service_contract } = settings || {};

  const handleOpen = (document) => {
    if (document) {
      if (document.type.split('/')[1].toLowerCase() === 'pdf') {
        setPreviewImage(null);
        setPreviewPdf(URL.createObjectURL(document));
      } else {
        setPreviewImage(URL.createObjectURL(document));
        setPreviewPdf(null);
      }
      setOpen(true);
    }
  };

  const handleClose = () => {
    setPreviewImage(null);
    setOpen(false);
  };

  const handleDelete = (fileName) => {
    setPreviewImage(null);
    setPreviewPdf(null);
    switch (fileName) {
      case 'fileCd':
        setFileCd(null);
        break;
      case 'fileAc':
        setFileAc(null);
        break;
      case 'fileIo':
        setFileIo(null);
        break;
      case 'fileCp':
        setFileCp(null);
        break;
      default:
        handleAlert('error', 'input no reconocido');
    }
  };

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const getFile = (e) => {
    let hasError = false;
    if (
      e.target.files[0].type === '' ||
      e.target.files[0].type.indexOf('/') === -1 ||
      availableTypes.indexOf(
        e.target.files[0].type.split('/')[1].toLowerCase()
      ) === -1
    ) {
      handleAlert('error', 'Tipo de archivo inválido');
      hasError = true;
    }

    if (e.target.files[0].size > 100 * 1000 * 1024) {
      handleAlert('error', 'Documento demasiado grande');
      hasError = true;
    }

    if (!hasError) {
      switch (e.target.name) {
        case 'fileCd':
          setFileCd(e.target.files[0]);
          break;
        case 'fileAc':
          setFileAc(e.target.files[0]);
          break;
        case 'fileIo':
          setFileIo(e.target.files[0]);
          break;
        case 'fileCp':
          setFileCp(e.target.files[0]);
          break;
        default:
          handleAlert('error', 'input no reconocido');
      }
    }
  };
  const uploadProgress = (progressEvent) => {
    setUploading(true);
    setProgress(100 * (progressEvent.loaded / progressEvent.total));
  };
  const uploadFiles = async (file, name) => {
    try {
      const uploadUrl = await API.get('merchant-api', '/merchant/uploads', {
        queryStringParameters: {
          documentName: name,
          documentType: file.type,
          merchantRecordKey: props.merchantProfile.merchantRecordKey,
        },
      });
      if (uploadUrl.url) {
        await axios.put(uploadUrl.url, file, {
          headers: { 'Content-Type': file.type },
          onUploadProgress: uploadProgress,
        });
      } else {
        handleAlert('error', 'No se pudo subir el documento');
      }
    } catch (err) {
      console.log('Error: ', err);
      setLoading(false);
      handleAlert('error', err.message);
      props.handleCallRequest(false, null);
    }
  };

  const callApi = async () => {
    if (CDFile && ACFile && IOFile && CPFile) {
      setLoading(true);
      try {
        let uploading = false;
        if (CDFile) {
          uploading = true;
          await uploadFiles(CDFile, 'ComprobanteDomicilio');
        }
        if (ACFile) {
          uploading = true;
          await uploadFiles(ACFile, 'ActaConstitutiva');
        }
        if (IOFile) {
          uploading = true;
          await uploadFiles(IOFile, 'IdentificacionOficial');
        }
        if (CPFile) {
          uploading = true;
          await uploadFiles(CPFile, 'CartaPoder');
        }
        if (uploading) {
          await API.post('merchant-api', '/merchant', {
            body: {
              merchantRecordKey: props.merchantProfile.merchantRecordKey,
              merchantStatus: service_contract ? 'pending' : 'progress',
            },
          });
          props.setCompleted(false);
        }
        setUploading(false);

        await props.handleCallRequest(true, service_contract ? 4 : 'END');
        setLoading(false);
      } catch (err) {
        console.log('Error: ', err);
        setLoading(false);
        handleAlert('error', err);
        props.handleCallRequest(false, null);
      }
    } else {
      handleAlert('error', 'Sube todos los archivos');
      props.handleCallRequest(false, null);
    }
  };

  const verifyIdentity = (detail) => {
    setIdentityVerified(true);
  };
  const urlToEmail = async () => {
    if (!CheckValue('email', linkEmail)) {
      handleAlert('error', 'Por favor ingrese un correo electrónico válido');
    } else {
      setSendingEmail(true);
      try {
        await API.post('merchant-api', '/merchant/sendlink', {
          body: {
            targetEmail: linkEmail,
            matiLink: `https://signup.getmati.com/?merchantToken=${process.env.REACT_APP_MATI_CLIENT_ID}&metadata={"userRecordKey":"${props.merchantProfile.userRecordKey}"}`,
          },
        });
        handleAlert('success', `Enlace enviado a ${linkEmail}`);
      } catch (err) {
        console.log('Error: ', err);
        handleAlert('error', err);
      } finally {
        setSendingEmail(false);
      }
    }
  };
  const urlToClipboard = () => {
    const clip = document.createElement('input');
    clip.style = 'position: absolute; left: -9000px; top: -9000px;';
    clip.value = `https://signup.getmati.com/?merchantToken=${process.env.REACT_APP_MATI_CLIENT_ID}&metadata={"userRecordKey":"${props.merchantProfile.userRecordKey}"}`;
    document.body.appendChild(clip);
    clip.select();
    document.execCommand('copy');
    document.body.removeChild(clip);
  };
  const body = (
    <div style={modalStyle} className={classes.paper}>
      {previewImage && (
        <img
          src={previewImage}
          alt='preview'
          className={classes.previewPicture}
        />
      )}
      {previewPdf && (
        <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js'>
          <div style={{ height: '600px' }}>
            <Viewer fileUrl={previewPdf} />
          </div>
        </Worker>
      )}
    </div>
  );

  return (
    <div className={classes.container}>
      {isLoading ? (
        <div className='gralinfo_form'>
          {isUploading ? (
            <CircularProgress
              color='secondary'
              variant='determinate'
              value={progressUpload.toFixed(0)}
            />
          ) : (
            <CircularProgress color='secondary' />
          )}
        </div>
      ) : (
        <>
          <div className={classes.profileContainer}>
            {service_mati_verify_web && (
              <div className={` col-12 col-sm-l2 col-md-6 col-lg-6 col-xl-6`}>
                <Typography className={classes.title}>
                  Documentación del Representante Legal
                </Typography>
                <Typography className={classes.normalText}>
                  Para completar la validación del perfil empresarial, es
                  necesario que el representante legal lleva a cabo una
                  validación de identidad mediante la plataforma{' '}
                  <a href='getmati.io' target='_blank'>
                    Mati.io
                  </a>
                </Typography>
                <Typography className={classes.normalText}>
                  El servicio de Mati permite tomar una fotografía y subir la
                  documentación reglamentaria tal como una identificación
                  oficial o pasaporte.
                </Typography>

                <div className={classes.innerContainer}>
                  <Typography className={classes.subtitle}>
                    A. Yo soy Representante Legal
                  </Typography>
                  <Typography className={classes.normalText}>
                    Haz click en el botón y sigue las instrucciones hasta
                    completar el proceso.
                  </Typography>
                  <CustomMatiButton
                    metadata={{
                      userRecordKey: props.merchantProfile.userRecordKey,
                    }}
                    handleFinished={verifyIdentity}
                  />
                  <Typography
                    className={
                      identityVerified ? classes.verified : classes.notVerified
                    }
                  >
                    {' '}
                    &check; Verificado
                  </Typography>
                  <Typography className={classes.subtitle}>
                    B. Otra persona es Representante Legal
                  </Typography>
                  <Typography className={classes.normalText}>
                    Puedes enviarla por correo o hacerle llegar la direccion URL
                  </Typography>
                  <Typography className={classes.normalText}>
                    Correo de la persona Representante Legal
                  </Typography>
                  <DefaultInput
                    custom={{
                      handleChange: (event) => setLinkEmail(event.target.value),
                      value: linkEmail,
                      labelText: '',
                      id: 'linkEmail',
                      type: 'text',
                      name: 'linkEmail',
                      placeholder: 'correo@sitio.com',
                    }}
                  />
                  <DefaultButton
                    size='small'
                    handleAction={urlToEmail}
                    buttonText='Enviar'
                    disabled={sendingEmail}
                  />
                  <DefaultButton
                    size='small'
                    handleAction={urlToClipboard}
                    buttonText='Copiar'
                  />
                </div>
              </div>
            )}
            <Grid container 
						spacing={2}
						>
              <Grid item xs={12}>
                <Typography className={classes.title}>
                  Documentación de la Empresa
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultCard
                  title='1. Comprobante de Domicilio'
                  file={CDFile}
                  fileType={'fileCd'}
                  getFile={getFile}
                  handleOpen={handleOpen}
                  handleDelete={handleDelete}
                  cardType={'cardDocument'}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultCard
                  title='2. Acta Constitutiva'
                  file={ACFile}
                  fileType={'fileAc'}
                  getFile={getFile}
                  handleOpen={handleOpen}
                  handleDelete={handleDelete}
                  cardType={'cardDocument'}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultCard
                  title='3. Identificación Oficial'
                  file={IOFile}
                  fileType={'fileIo'}
                  getFile={getFile}
                  handleOpen={handleOpen}
                  handleDelete={handleDelete}
                  cardType={'cardDocument'}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultCard
                  title='4. Poderes del Representate'
                  file={CPFile}
                  fileType={'fileCp'}
                  getFile={getFile}
                  handleOpen={handleOpen}
                  handleDelete={handleDelete}
                  cardType={'cardDocument'}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.leyendtext}>
                  Formatos Aceptados: Pdf, png, jpg, jpeg. | Tamáño Máximo: 5MB
                </Typography>
              </Grid>
            </Grid>
          </div>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='simple-modal-title'
            aria-describedby='simple-modal-description'
          >
            {body}
          </Modal>
        </>
      )}
    </div>
  );
}

export default withSnackbar(Tab3);
