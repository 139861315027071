import React from 'react';
import { withSnackbar  } from 'notistack';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import '../../assets/css/main.css';
import email_picture from '../../assets/images/email_picture.png';
import ThirdHeader from "../../components/headers/thirdHeader";

import PrimaryInput from '../../components/common/primaryInput';
import PrimaryButton from '../../components/common/primaryButton';
import SecondaryButton from '../../components/common/secondaryButton';
import image from '../../assets/images/merchantBackground.png';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    background: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      minWidth: '100vw',
      backgroundImage: `url(${image})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white',
      paddingLeft: '5%',
      paddingRight: '5%',
      paddingTop: '2%',
      paddingBottom: '2%',
      borderRadius: 20,
      minWidth: 320,
    },
    spacer1: { width: '100%', height: 20 },
    spacer2: { width: '100%', height: 40 },
  });
  
class Confirmation extends React.Component {
    constructor(props) {
        super(props);
        this.handleAlert = this.handleAlert.bind(this);
    }
    componentDidMount () {

    }

    handleAlert = (variant, message) => {
        this.props.enqueueSnackbar( message, { variant });
    };

    handleToggleLogin = (e) => {
        e.preventDefault();
        this.props.history.push({
            pathname: '/dashboard'
        });
    }
 
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.background}>
                <div className={classes.container}>
                        <ThirdHeader />
                        <Typography style={{
                            fontFamily: 'Montserrat Variable',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            fontSize: 24,
                            color: '#4F4F4F',
                            paddingBottom: 50
                        }}>
                            Confirmación
                        </Typography>
                        <Typography style={{
                            fontFamily: 'Montserrat Variable',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            fontSize: 14,
                            color: '#696969',
                        }}>Para llevar a cabo algunas acciones es necesario</Typography>
                        <Typography style={{
                            fontFamily: 'Montserrat Variable',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            fontSize: 14,
                            color: '#696969',
                        }}>validar tu correo electrónico, hemos enviado un</Typography>
                        <Typography style={{
                            fontFamily: 'Montserrat Variable',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            fontSize: 14,
                            color: '#696969',
                        }}>correo de confirmación a tu bandeja de entrada.</Typography>
                            <img src={email_picture} width={91.67} height={64.67} alt="email_picture"/>

                            <PrimaryButton 
                            ref={ element => (this.send = element) } 
                            type="submit" className="default_button" 
                            onClick={this.handleToggleLogin} 
                            fullWidth>
                                Entendido
                            </PrimaryButton>

                        <div className="rowStyle centerItems padding_top_40">
                        <Typography style={{
                            fontFamily: 'Montserrat Variable',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            fontSize: 12,
                            color: '#696969',
                        }}>¿No recibiste tu correo de confirmación?</Typography>
                        <Typography style={{
                            fontFamily: 'Montserrat Variable',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            fontSize: 12,
                            color: '#6EB89D',
                            paddingLeft: 5,
                            cursor: 'pointer'
                        }}>Reenviar email</Typography>
                        </div>
                </div>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(withSnackbar (Confirmation));