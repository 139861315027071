import React,{useState} from 'react';
import { Auth, API, Storage } from 'aws-amplify';
import { withSnackbar } from "notistack";
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import DefaultInput from '../../../common/defaultInput'
import DefaultButton from '../../../common/defaultButton';
import DefaultCancelButton from '../../../common/defultCancelButton';
import CognitoErrors from '../../../../lib/cognitoErrors';
import CheckValue from '../../../../lib/formatValidations';
import CircularProgress from '@material-ui/core/CircularProgress';

const VerifyEmail = (props) => {
    const [isLoading, setLoading] = useState(false);
    const [addEmail, setAddEmail] = useState(true);
    const [email, setEmail] = useState('');
    const [verificationCode, setVerificationCode] = useState('');

    // Errors
    const [emailError, setEmailError] = useState('');
    const [verificationCodeError, setVerificationCodeError] = useState('');
     
    const classes = useStyles();

    const handleAlert = (variant, message) => {
        props.enqueueSnackbar( message, { variant });
    };

    const handleChange = (event) =>{
        const {name,value} = event.target;
        switch(name){
            case 'email':
                setEmail(event.target.value);
                break;
            case 'verificationCode':
                setVerificationCode(event.target.value);
            default:
                break;
        }
    }
    
    const sendCode = async () => {
        setLoading(true);
        setEmailError('');
        let hasErrors = false;
        if(!CheckValue("email",email)){
            setEmailError('Ingrese email con formato correcto (email@dominio.com)');
            hasErrors = true;
        }
        if(!hasErrors){
            try {
                const userCognito = await Auth.currentAuthenticatedUser();
                await Auth.updateUserAttributes(userCognito, {
                  email: email,
                });
                const updatedUser = await Auth.currentAuthenticatedUser({
                  bypassCache: true,
                });
                await Auth.verifyCurrentUserAttribute('email');
                handleAlert('success', 'Correo enviado exitosamente');
                setAddEmail(false);
            } catch (error) {
                handleAlert('error', CognitoErrors(error.message));
            } finally {
                setLoading(false);
            }
        }
        else
            setLoading(false);
    }

    const verifyCode = async () => {
        setLoading(true);
        setVerificationCodeError('');
        let hasErrors = false;
        if(!CheckValue("verificationCode", verificationCode)){
            setVerificationCodeError('El código de verificación debe ser de 6 dígitos');
            hasErrors = true;
        }
        if(!hasErrors){
            try {
                await Auth.verifyCurrentUserAttributeSubmit('email', verificationCode);
                const user = await Auth.currentAuthenticatedUser({
                    bypassCache: true,
                });
                handleAlert('success', '¡Operación exitosa!');
                props.handleCloseEmailModal();
            } catch (error) {
                handleAlert('error', CognitoErrors(error.message));
            } finally {
                setLoading(false);
            }
        }
        else
            setLoading(false);
    }

    return(
        <div className={`${classes.modalStyle} col-11 col-sm-9 col-md-7 col-lg-5 col-xl-4 bg-white rounded row`}>
            {
                isLoading ?
                    <div className={`${classes.styleCircularProgress}`}>
                        <CircularProgress color="secondary" />
                    </div>
                :
                    addEmail ?
                        <div className="col-12 row pr-4 pl-5 pt-5 pb-5">
                            <Typography className={`${classes.title} col-12 text-center`}>Agregar Email</Typography>
                            <DefaultInput custom={{
                                handleChange,
                                value: email,
                                classForm: "inputForm",
                                classLabel: "labelInputStyleForm",
                                labelText: "",
                                id: "email",
                                type: "text",
                                name:"email",
                                className: ` col-12 defaultInputTextForm mt-5`,
                                placeholder:"Email",
                                error: emailError
                            }}/>
                            <div className="col-6 text-center mt-5">
                                <DefaultCancelButton 
                                handleAction={() => props.handleCloseEmailModal()} 
                                buttonText={"Cancelar"} 
                                />
                            </div>
                            <div className="col-6 text-center mt-5">
                                <DefaultButton 
                                handleAction={() => sendCode()} 
                                    buttonText={"Agregar"} 
                                />
                            </div> 
                        </div>
                    :
                        <div className="col-12 row pr-4 pl-5 pt-5 pb-5">
                             <Typography className={`${classes.title} col-12 text-center`}>Hemos enviado un mensaje a {email}</Typography>
                             <Typography className={`${classes.title} col-12 text-center`}>Por favor ingresa el código a continuación:</Typography>
                             <DefaultInput custom={{
                                handleChange,
                                value: verificationCode,
                                classForm: "inputForm",
                                classLabel: "labelInputStyleForm",
                                labelText: "",
                                id: "verificationCode",
                                type: "text",
                                name:"verificationCode",
                                className: `defaultInputTextForm col-12 mt-5`,
                                placeholder:"Código de Verificación",
                                error: verificationCodeError
                            }}/>
                            <div className="col-12 text-center mt-5">
                                <DefaultButton 
                                handleAction={() =>verifyCode()} 
                                    buttonText={"Verificar código"} 
                                />
                            </div> 
                        </div>
            }
        </div>  
    );
}

const useStyles = makeStyles(theme => ({
    modalStyle: {
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        height: '340px'
    },
    styleCircularProgress:{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    },
    title: {
        ...theme.typography.cardTitle,
    }
}));


export default withSnackbar(VerifyEmail);