import React from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { API } from 'aws-amplify';

import '../../../../assets/css/main.css';
import DefaultInput from '../../../common/defaultInput';
import DefaultSelect from '../../../common/defaultSelect';
import CheckValue from '../../../../lib/formatValidations';
import { CircularProgress, Typography , Tooltip } from '@material-ui/core';
import SecondarySelect from '../../../common/secondarySelect';
import commercialBusinessTypeItems from '../../../../lib/commercialBusinessTypes';
import NumberOfEmployeesItems from '../../../../lib/numberOfEmployees';
import ClabeValidator from '../../../../lib/clabeValidator';
import InfoIcon from '../../../../assets/images/icons8-info.svg';

const useStyles = makeStyles(theme =>({
  container: {
    padding: 24,
  },
  title: {
    marginLeft: '20px',
    marginTop: '20px',
    marginBottom: '10px',
    ...theme.typography.smallText,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.gray.main,
    mixBlendMode: 'normal',
  },
  subtitle: {
    marginRight: '20px',
    ...theme.typography.smallText,
    color: '#B4B4B4',
  },
  firstRowStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  rowStyle: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 25,
    flexWrap: 'wrap',
  },
  F1: {
    flex: 1,
  },
  F2: {
    flex: 2,
  },
  menu: {
    padding: 20,
  },
}));

function Tab2(props) {
  useEffect(() => {
    if (props.ExecutingRequest) {
      callApi();
    }
  }, [props.ExecutingRequest]);

  const classes = useStyles();

  const [isLoading, setLoading] = React.useState(false);

  const [businessCommercialName, setBusinessCommercialName] = React.useState(
    props.merchantProfile.businessCommercialName
      ? props.merchantProfile.businessCommercialName
      : ''
  );
  const [numberOfEmployees, setNumberOfEmployees] = React.useState(
    props.merchantProfile.numberOfEmployees
      ? props.merchantProfile.numberOfEmployees
      : ''
  );
  const [businessType, setBusinessType] = React.useState(
    props.merchantProfile.businessType ? props.merchantProfile.businessType : ''
  );
  const [businessTypeCode, setBusinessTypeCode] = React.useState(
    props.merchantProfile.businessTypeCode ? props.merchantProfile.businessTypeCode : 0
  );
  const [website, setWebsite] = React.useState(
    props.merchantProfile.website ? props.merchantProfile.website : ''
  );
  const [accountNumber, setAccountNumber] = React.useState(
    props.merchantProfile.accountNumber ? props.merchantProfile.accountNumber : ''
  );
  const [invoiceEmail, setInvoiceEmail] = React.useState(
    props.merchantProfile.invoiceEmail ? props.merchantProfile.invoiceEmail : ''
  );
  const [invoiceEmailError, setInvoiceEmailError] = React.useState('');
  const [accountNumberError, setAccountNumberError] = React.useState('');
  const [
    businessCommercialNameError,
    setBusinessCommercialNameError,
  ] = React.useState('');
  const [numberOfEmployeesError, setNumberOfEmployeesError] = React.useState(
    ''
  );
  const [businessTypeError, setBusinessTypeError] = React.useState('');
  const [websiteError, setWebsiteError] = React.useState('');

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'businessCommercialName':
        setBusinessCommercialName(value);
        break;
      case 'numberOfEmployees':
        setNumberOfEmployees(value);
        break;
      case 'businessType':
        const code = commercialBusinessTypeItems()[commercialBusinessTypeItems().findIndex(i => i.value === value)]['code'];
        setBusinessType(value);
        setBusinessTypeCode(code);
        break;
      case 'website':
        setWebsite(value);
        break;
      case 'accountNumber':
        setAccountNumber(value);
        break;
      case 'invoiceEmail':
        setInvoiceEmail(value);
        break;
      default:
        console.log('Input no reconocido', name);
    }
  };

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const cleanError = () => {
    setBusinessCommercialNameError('');
    setNumberOfEmployeesError('');
    setBusinessTypeError('');
    setWebsiteError('');
    setAccountNumberError('');
    setInvoiceEmailError('');
  };

  const isChanged = () => {
    if (
      props.merchantProfile.businessCommercialName !== businessCommercialName
    ) {
      return true;
    }
    if (props.merchantProfile.numberOfEmployees !== numberOfEmployees) {
      return true;
    }
    if (props.merchantProfile.businessType !== businessType) {
      return true;
    }
    if (props.merchantProfile.website !== website) {
      return true;
    }
    if (props.merchantProfile.accountNumber !== accountNumber && !props.serviceInvoicePostPay) {
      return true;
    }
    if (props.merchantProfile.invoiceEmail !== invoiceEmail && !props.serviceInvoicePostPay) {
      return true;
    }
    return false;
  };

  const callApi = async () => {
    cleanError();
    let hasErros = false;
    if (!CheckValue('empty', businessCommercialName)) {
      hasErros = true;
      setBusinessCommercialNameError(
        'Por favor ingrese el nombre de la empresa'
      );
    }
    if (!CheckValue('empty', numberOfEmployees)) {
      hasErros = true;
      setNumberOfEmployeesError('Por favor seleccione una opción');
    }
    if (!CheckValue('empty', businessType)) {
      hasErros = true;
      setBusinessTypeError('Por favor seleccione una opción');
    }
    if (CheckValue('empty', website)) {
      if (!CheckValue('website', website)) {
        hasErros = true;
        setWebsiteError('Por favor ingrese un sitio web válido');
      }
    }
    if (ClabeValidator(accountNumber) && !props.serviceInvoicePostPay) {
      setAccountNumberError(ClabeValidator(accountNumber));
      hasErros = true;
    }
    if (!CheckValue("email", invoiceEmail) && !props.serviceInvoicePostPay) {
      hasErros = true;
      setInvoiceEmailError("Por favor ingrese un correo electrónico válido");
    }

    if (hasErros) {
      setLoading(false);
      props.handleCallRequest(false, null);
    } else {
      if (isChanged()) {
        setLoading(true);
        try {
          const body = {
            merchantRecordKey: props.merchantProfile.merchantRecordKey,
            businessConstitution: 'FISICA',
            businessCommercialName,
            numberOfEmployees,
            businessType,
            businessTypeCode,
            website,
            merchantStatus: 'pending',
            updateProfile: true,
          };
          if (!props.serviceInvoicePostPay) {
            body['accountNumber'] = accountNumber;
            body['invoiceEmail'] = invoiceEmail;
          }
          const response = await API.post('merchant-api', '/merchant', {
            body,
          });
          if (response && response.success) {
            handleAlert('success', 'Guardado con éxito.');
            props.setCompleted(false);
            await props.handleCallRequest(true, 2);
            setLoading(false);
          } else {
            console.log(response);
            handleAlert('error', response.error);
            props.handleCallRequest(false, null);
            setLoading(false);
          }
        } catch (err) {
          console.log('Error: ', err);
          setLoading(false);
          handleAlert('error', err);
          props.handleCallRequest(false, null);
        }
      } else {
        setLoading(false);
        props.handleCallRequest(true, 2);
      }
    }
  };

  return (
    <div className={classes.container}>
      {isLoading ? (
        <div className='gralinfo_form'>
          <CircularProgress color='secondary' />
        </div>
      ) : (
        <>
          <div>
            <Typography className={classes.title}>
              DATOS DE LA EMPRESA
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
              <Typography className={classes.subtitle}>Nombre Comercial
                  <Tooltip title="Pseudónimo usado por empresas distinto al que fue registrado">
                    <img width="13" height="13" fill='#B4B4B4' src={InfoIcon}></img>
                  </Tooltip>
                </Typography>
                <DefaultInput
                  custom={{
                    handleChange,
                    value: businessCommercialName,
                    classLabel: 'labelInputStyleForm',
                    id: 'businessCommercialName',
                    type: 'text',
                    maxLength: 60,
                    name: 'businessCommercialName',
                    className: 'defaultInputTextForm col-12',
                    placeholder: 'Nombre Comercial',
                    error: businessCommercialNameError,
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                // className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 p-3'
              >
                <SecondarySelect
                  value={businessType}
                  onChange={handleChange}
                  id='businessType'
                  name='businessType'
                  label='Giro Comercial'
                  default={commercialBusinessTypeItems().filter(item => item.section === 'Default').map((item) => {
                    return (
                      <>
                        <option value={item.value}>{item.label}</option>
                      </>
                    );
                  })}
                  topOptions={commercialBusinessTypeItems().filter(item => item.section === 'Más frecuentes').map((item) => {
                    return (
                      <>
                        <option value={item.value}>{item.label}</option>
                      </>
                    );
                  })}
                  alphabeticalOrder={commercialBusinessTypeItems().filter(item => item.section === 'Orden Alfabético').map((item) => {

                    return (
                      <>
                        <option value={item.value}>{item.label}</option>
                      </>
                    );
                  })}
                  error={businessTypeError}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SecondarySelect
                  value={numberOfEmployees}
                  onChange={handleChange}
                  id='numberOfEmployees'
                  name='numberOfEmployees'
                  label='Número de empleados'
                  content={NumberOfEmployeesItems().map((item) => {

                    return (
                      <>
                        <option value={item.value}>{item.label}</option>
                      </>
                    );
                  })}
                  error={numberOfEmployeesError}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DefaultInput
                  custom={{
                    handleChange,
                    value: website,
                    classForm: 'inputForm col-12',
                    classLabel: 'labelInputStyleForm',
                    labelText: 'Página Web',
                    id: 'website',
                    type: 'text',
                    name: 'website',
                    className: 'defaultInputTextForm col-12',
                    placeholder: 'https://www.nerito.com',
                    error: websiteError,
                  }}
                />
              </Grid>
              {!props.serviceInvoicePostPay && (
                <>
                <Grid item xs={12} sm={6}>
                  <DefaultInput
                    custom={{
                      handleChange,
                      value: accountNumber,
                      classLabel: 'labelInputStyleForm',
                      labelText: 'Cuenta Clabe',
                      id: 'accountNumber',
                      type: 'text',
                      name: 'accountNumber',
                      className: 'defaultInputTextForm col-12',
                      placeholder: '',
                      error: accountNumberError,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DefaultInput
                    custom={{
                      handleChange,
                      value: invoiceEmail,
                      classLabel: 'labelInputStyleForm',
                      labelText: 'Correo de Facturación',
                      id: 'invoiceEmail',
                      type: 'text',
                      name: 'invoiceEmail',
                      className: 'defaultInputTextForm col-12',
                      placeholder: '',
                      error: invoiceEmailError,
                    }}
                  />
                </Grid>
                </>
              )}
            </Grid>
          </div>
        </>
      )}
    </div>
  );
}

export default withSnackbar(Tab2);
