import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import DefaultInput from '../common/defaultInput';
import DefaultButton from '../common/defaultButton';
import DefaultSelect from '../common/defaultSelect';
import '../../assets/css/main.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { withSnackbar } from 'notistack';
import garbage from '../../assets/images/garbage.svg';
import fetch from 'node-fetch';
import CheckValue from '../../lib/formatValidations';
import PrimaryButton from '../common/primaryButton';
import SecondaryButton from '../common/secondaryButton';
import CloseButton from '../common/closeButton';
import { Grid } from '@material-ui/core';
import PrimaryInput from '../common/primaryInput';
import PrimarySelect from '../common/primarySelect';
import businessTypesList from '../../lib/businessTypesList.js';
import businessAreasList from '../../lib/businessAreasList.js';
import businessPositionsList from '../../lib/businessPositionsList.js';
import MultilineInput from '../common/multilineInput';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 0,
    overflow: 'hidden',
  },
  title: {
    ...theme.typography.title,
    color: theme.palette.gray.main,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    overflowX: 'hidden',
    padding: 10,
  },
  rowStyle: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,
  },
  columnStyle: {
    display: 'flex',
    flexDirection: 'row',
  },
  contactButton: {
    color: '#3B9A83',
    border: 0,
    backgroundColor: '#FAFAFA',
    ...theme.typography.button,
    marginTop: 20,
  },
  subtitle: {
    ...theme.typography.subtitle,
    color: theme.palette.gray.main,
  },
  removeIcon: {
    marginLeft: 10,
    cursor: 'pointer',
  },
  instructions: {
    ...theme.typography.paragraph,
    color: theme.palette.gray.main,
  },
  contacts: {
    ...theme.typography,
    color: theme.palette.gray.main,
  },
  buttonContainer: {
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
    ...theme.globals.center,
  },
  businessTypeContainer: {
    ...theme.globals.center,
    alignItems: 'flex-start',
    paddingLeft: 5,
    [theme.breakpoints.down('xs')]: {
      ...theme.globals.centerVertical,
      paddingRight: 5,
    },
  },
  otherLabel: {
    ...theme.typography.paragraph,
    paddingRight: 5,
    color: theme.palette.gray.main,
  },
  otherButtonContainer: {
    ...theme.globals.center,
    backgroundColor: theme.palette.background.main,
    borderRadius: 10,
    marginTop: 5,
    marginBottom: 5,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  cancelIcon: {
    color: theme.palette.primary.main,
    height: 26,
    width: 20,
  },
  deleteIconContainer: {
    backgroundColor: theme.palette.background.main,
    ...theme.globals.center,
    borderRadius: 10,
    padding: 5,
    margin: 5,
  },
  eraseContactText: {
    ...theme.typography.paragraph,
    color: theme.palette.red.main,
    paddingRight: 5,
  },
  deleteIcon: {
    color: theme.palette.red.main,
    height: 26,
    width: 20,
  },
  addIcon: {
    color: theme.palette.primary.main,
    height: 26,
    width: 20,
    paddingBottom: 5,
  },
}));

const MerchantLeads = (props) => {
  useEffect(() => {}, []); // eslint-disable-line

  const classes = useStyles();
  let history = useHistory();

  const [businessName, setBusinessName] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [businessSelected, setBusinessSelected] = useState('');
  const [otherBusinessType, setOtherBusinessType] = useState('');
  const [businessState, setBusinessState] = useState('');
  const [businessCity, setBusinessCity] = useState('');
  const [businessDirection, setBusinessDirection] = useState('');
  const [comments, setComments] = useState('');
  const [count, setCount] = useState(1);
  const [addHidden, setAddHidden] = useState(false);
  const [businessNameError, setBusinessNameError] = useState('');
  const [businessTypeError, setBusinessTypeError] = useState('');
  const [otherBusinessTypeError, setOtherBusinessTypeError] = useState('');
  const [businessStateError, setBusinessStateError] = useState('');
  const [contacts, setContacts] = useState([
    {
      name: '',
      area: '',
      position: '',
      selectedPosition: '',
      phone: '',
      email: '',
      errors: {
        errorName: '',
        errorArea: '',
        errorPosition: '',
        errorSelectedPosition: '',
        errorPhone: '',
        errorEmail: '',
      },
    },
  ]);

  const types = [
    'Restaurante',
    'Gasolinera',
    'Retail',
    'Farmacia',
    'Bancos',
    'Otro',
  ];

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'businessName':
        setBusinessName(value);
        break;
      case 'businessType':
        setBusinessType(value);
        setBusinessSelected(value);
        break;
      case 'otherBusinessType':
        setOtherBusinessType(value);
        if (businessType === 'Otro') setBusinessSelected(value);
        break;
      case 'businessState':
        setBusinessState(value);
        break;
      case 'businessCity':
        setBusinessCity(value);
        break;
      case 'businessDirection':
        setBusinessDirection(value);
        break;
      case 'comments':
        setComments(value);
        break;
      default:
        break;
    }
  };

  const updateContact = (event, index) => {
    const newContacts = [...contacts];
    const { name, value } = event.target;
    console.log('eddy ' + name + value);
    newContacts[index][name] = value;
    setContacts(newContacts);
  };

  const addContact = (isAdd, index = 0) => {
    const newContacts = [...contacts];
    if (isAdd && count <= 5) {
      setCount(count + 1);
      newContacts.push({
        name: '',
        area: '',
        position: '',
        selectedPosition: '',
        phone: '',
        email: '',
        errors: {
          errorName: '',
          errorArea: '',
          errorPosition: '',
          errorSelectedPosition: '',
          errorPhone: '',
          errorEmail: '',
        },
      });
      setContacts(newContacts);
      if (count === 5) {
        setAddHidden(true);
      }
    } else if (!isAdd) {
      setCount(count - 1);
      newContacts.splice(index, 1);
      setContacts(newContacts);
      if (count <= 6) {
        setAddHidden(false);
      }
    }
  };

  const checkHasErrors = () => {
    let hasErrors = false;
    const contactsErrors = contacts.map((contact, index) =>
      checkHasErrorsContacts(index)
    );
    if (contactsErrors.includes(true, 0)) {
      hasErrors = true;
    }
    if (!CheckValue('empty', businessName)) {
      hasErrors = true;
      setBusinessNameError('Por favor ingrese el nombre de la empresa');
    } else {
      hasErrors = false;
      setBusinessNameError('');
    }
    if (!CheckValue('empty', businessType)) {
      hasErrors = true;
      setBusinessTypeError('Por favor seleccione el tipo de la empresa');
    } else {
      hasErrors = false;
      setBusinessTypeError('');
    }
    if (businessType === 'Otro' && !CheckValue('empty', otherBusinessType)) {
      hasErrors = true;
      setOtherBusinessTypeError('Por favor ingrese el tipo de la empresa');
    } else {
      hasErrors = false;
      setOtherBusinessTypeError('');
    }
    if (!CheckValue('empty', businessState)) {
      hasErrors = true;
      setBusinessStateError('Por favor ingrese el estado de la empresa');
    } else {
      hasErrors = false;
      setBusinessStateError('');
    }
    return hasErrors;
  };

  const checkHasErrorsContacts = (index) => {
    let hasErrors = false;
    const errorContact = [...contacts];
    if (!CheckValue('empty', contacts[index].name)) {
      hasErrors = true;
      errorContact[index].errors.errorName =
        'Por favor ingrese el nombre del contacto.';
    } else {
      hasErrors = false;
      errorContact[index].errors.errorName = '';
    }
    if (!CheckValue('empty', contacts[index].area)) {
      hasErrors = true;
      errorContact[index].errors.errorArea =
        'Por favor seleccione el área del contacto.';
    } else {
      hasErrors = false;
      errorContact[index].errors.errorArea = '';
    }
    if (!CheckValue('empty', contacts[index].position)) {
      hasErrors = true;
      errorContact[index].errors.errorPosition =
        'Por favor seleccione la posición del contacto.';
    } else {
      hasErrors = false;
      errorContact[index].errors.errorPosition = '';
    }
    if (!CheckValue('empty', contacts[index].selectedPosition)) {
      hasErrors = true;
      errorContact[index].errors.errorSelectedPosition =
        'Por favor ingrese la posición del contacto.';
    } else {
      hasErrors = false;
      errorContact[index].errors.errorSelectedPosition = '';
    }
    if (!CheckValue('phone', '+52' + contacts[index].phone)) {
      hasErrors = true;
      errorContact[index].errors.errorPhone =
        'Por favor ingrese un teléfono válido de 10 digitos.';
    } else {
      hasErrors = false;
      errorContact[index].errors.errorPhone = '';
    }
    if (!CheckValue('email', contacts[index].email)) {
      hasErrors = true;
      errorContact[index].errors.errorEmail =
        'Por favor ingrese un correo electrónico válido.';
    } else {
      hasErrors = false;
      errorContact[index].errors.errorEmail = '';
    }
    return hasErrors;
  };

  const createLead = async () => {
    const hasErrors = checkHasErrors();
    console.log('Tipo de negocio: ', businessSelected);
    if (!hasErrors) {
      const business = {
        businessName,
        businessType,
        businessDirection,
        businessCity,
        businessState,
        comments,
      };
      const body = contacts.map((contact) => ({
        ...business,
        ...contact,
      }));

      fetch(process.env.REACT_APP_ZAPIER, {
        method: 'POST',
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((data) => {
          handleAlert('success', 'Solicitud Exitosa');
        })
        .catch((error) => {
          console.error('Error:', error);
          handleAlert('error', 'Solicitud no Exitosa');
        });
    } else {
      handleAlert('error', 'Llena los campos correctamente');
    }
  };

  const contactsInfo = () => {
    const contactsInputs = contacts.map((contact, index) => (
      <Grid container xs={12}>
        <Grid item xs={12}>
          <Typography className={classes.contacts}>
            {`Contacto ${index + 1}`}
            {index === 0 && ' *'}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <PrimaryInput
            onChange={(event) => updateContact(event, index)}
            value={contact.name}
            placeholder='Nombre de contacto'
            id={'contactName' + index}
            name='name'
            type='text'
            error={contact.errors.errorName}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PrimaryInput
            onChange={(event) => updateContact(event, index)}
            value={contact.phone}
            placeholder='(662)555-5555'
            id={'contactPhone' + index}
            name='phone'
            type='number'
            error={contact.errors.errorPhone}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PrimarySelect
            value={contact.area}
            onChange={(event) => updateContact(event, index)}
            id={'contactArea' + index}
            name='area'
            content={businessAreasList().map((item) => {
              return <option value={item.value}>{item.label}</option>;
            })}
            error={contact.errors.errorArea}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={6}>
          {contact.position !== 'Otro' ? (
            <PrimarySelect
              value={contact.position}
              onChange={(event) => updateContact(event, index)}
              id={'contactPosition' + index}
              name='position'
              content={businessPositionsList().map((item) => {
                return <option value={item.value}>{item.label}</option>;
              })}
              error={contact.errors.errorPosition}
              fullWidth
            />
          ) : (
            <div className={classes.businessTypeContainer}>
              <div
                className={classes.otherButtonContainer}
                onClick={() => {
                  const event = {
                    target: {
                      name: 'position',
                      value: '',
                    },
                  };
                  {
                    updateContact(event, index);
                  }
                }}
              >
                <Typography className={classes.otherLabel}>Otro</Typography>
                <CancelIcon className={classes.cancelIcon} />
              </div>
              <PrimaryInput
                onChange={(event) => updateContact(event, index)}
                value={contact.selectedPosition}
                placeholder='¿Cuál es la posición?'
                id={'contactSelectedPosition' + index}
                name='selectedPosition'
                type='text'
                error={contact.errors.errorSelectedPosition}
                fullWidth
              />
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <PrimaryInput
            onChange={(event) => updateContact(event, index)}
            value={contact.email}
            placeholder='Email'
            id={'contactEmail' + index}
            name='email'
            type='email'
            error={contact.errors.errorEmail}
          />
        </Grid>
        {index > 0 && (
          <Grid item xs={12} md={6}>
            <div
              onClick={() => addContact(false, index)}
              className={classes.deleteIconContainer}
            >
              <Typography className={classes.eraseContactText}>
                Borrar contacto
              </Typography>
              <DeleteIcon className={classes.deleteIcon} />
            </div>
          </Grid>
        )}
      </Grid>
    ));
    return contactsInputs;
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>Recomienda Nerito</Typography>
      <Typography className={classes.instructions}>
        ¿Te gusta Nerito y quieres invitar a un conocido? Déjanos sus datos y
        nos pondremos en contacto con él.
      </Typography>
      <Typography className={classes.instructions}>
        Nota: al enviar este formulario, el propietario podrá ver su nombre y
        dirección de correo electrónico.
      </Typography>
      <Typography className={classes.instructions}>* Obligatorio</Typography>
      <div className={`${classes.content} scrollBarHidden`}>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.subtitle}>
              Información de empresa
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <PrimaryInput
              onChange={handleChange}
              value={businessName}
              placeholder='Nombre del negocio *'
              id='businessName'
              name='businessName'
              type='text'
              error={businessNameError}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {businessType !== 'Otro' ? (
              <PrimarySelect
                value={businessType}
                onChange={handleChange}
                id='businessType'
                name='businessType'
                content={businessTypesList().map((item) => {
                  return <option value={item.value}>{item.label}</option>;
                })}
                fullWidth
                error={businessTypeError}
              />
            ) : (
              <div className={classes.businessTypeContainer}>
                <div
                  className={classes.otherButtonContainer}
                  onClick={() => setBusinessType('')}
                >
                  <Typography className={classes.otherLabel}>Otro</Typography>
                  <CancelIcon className={classes.cancelIcon} />
                </div>
                <PrimaryInput
                  onChange={handleChange}
                  value={businessName}
                  placeholder='¿Qué tipo de negocio es? *'
                  id='otherBusinessType'
                  name='otherBusinessType'
                  type='text'
                  error={otherBusinessTypeError}
                  fullWidth
                />
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <PrimaryInput
              onChange={handleChange}
              value={businessState}
              placeholder='Estado *'
              id='businessState'
              name='businessState'
              type='text'
              error={businessStateError}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PrimaryInput
              onChange={handleChange}
              value={businessCity}
              placeholder='Ciudad o Municipio'
              id='businessCity'
              name='businessCity'
              type='text'
              // error={productNameError}
            />
          </Grid>
          <Grid item xs={12}>
            <MultilineInput
              onChange={handleChange}
              value={businessDirection}
              placeholder='Dirección (en caso de conocerla)'
              id='businessDirection'
              name='businessDirection'
              type='text'
              minRows={1}
              fullInputWidth
              // error={productNameError}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.subtitle}>
              Información de contacto
            </Typography>
          </Grid>
          {contactsInfo()}
          <Grid item xs={12}>
            <SecondaryButton
              type='button'
              className={classes.contactButton}
              onClick={() => addContact(true)}
              hidden={addHidden}
            >
              <AddIcon className={classes.addIcon}/>
              Agregar contacto
            </SecondaryButton>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.subtitle}>
              Comentarios y observaciones
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <MultilineInput
              onChange={handleChange}
              value={comments}
              placeholder='Escribe aquí'
              id='comments'
              name='comments'
              type='text'
              minRows={1}
              fullInputWidth
              //error: productNameError
            />
          </Grid>
        </Grid>
      </div>
      <div className={classes.buttonContainer}>
        <PrimaryButton onClick={() => createLead()} paddingHorizontal={61}>
          Enviar
        </PrimaryButton>
      </div>
    </div>
  );
};

export default withSnackbar(MerchantLeads);
