import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DefaultModal from '../../modal';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  container: {
    ...theme.globals.center,
    paddingTop: 20,
    textAlign: 'center',
    flexDirection: 'column',


  },
  footText: {
    ...theme.typography.smallText,
    lineHeight: 1,
    color: '#919191',
  },
  link: {
    color: theme.palette.primary.main,
  },
}));

const Footer = (props) => {
  useEffect(() => { }, []);
  const [openModalPrivacy, setOpenModalPrivacy] = useState(false);

  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        <Typography className={classes.footText}>
          Esta información es necesaria para validar tu identidad. Nunca la
          usaremos sin tu autorización.
        </Typography>
        <Typography className={classes.footText}>
          Para más información consulta nuestra{' '}
          <span
            className={classes.link}
            onClick={() => setOpenModalPrivacy(true)}
          >
            Política de Privacidad
          </span>
        </Typography>
      </div>
      <DefaultModal
        open={openModalPrivacy}
        handleClose={() => setOpenModalPrivacy(false)}
        modalType='privacidad'
      />
    </>
  );
};

export default Footer;
