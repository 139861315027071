import { createSlice } from '@reduxjs/toolkit';

export const storeSlice = createSlice({
  name: 'store',
  initialState: {
    value: null,
  },
  reducers: {
    setCurrentStore: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setCurrentStore } = storeSlice.actions;

export default storeSlice.reducer;