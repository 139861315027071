import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme=> ({
  cardComponent: {
    width: '100%',
    cursor: 'pointer',
    backgroundColor: '#FFFFFF',
  },
  mapImageContainer: {
    borderRadius: 5,
    maxHeight: '150px',
    overflow: 'hidden',
  },
  mapImage: {
    width: '100%',
    height: 'auto',
  },
  storeName: {
    ...theme.typography.paragraph,
    color: theme.palette.gray.main,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingRight: 10,
  },
  phone: {
    ...theme.typography.mediumText,
    color: theme.palette.gray.main,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingRight: 10,
  },
  address: {
    ...theme.typography.extraSmallText,
    color: theme.palette.gray.main,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingRight: 10,
  },
  statusStyle: {
    background: '#FD8197',
    width: '100%',
    borderRadius: 20,
    alignItems: 'center',
    color: '#FFFFFF',
    width: 70,
    justifyContent: 'center',
    height: 20,
    position: 'absolute',
    top: 10,
    marginTop: 10,
    marginLeft: 10,
  },
  statusOpen: {
    background: '#6EB89D',
    fontSize: 8,
  },
  statusClosed: {
    background: '#FD8197',
  },
  statusText: {
    fontSize: 10,
    fontWeight: 700,
    paddingTop: 2,
  },
  storeprofilepic: {
    borderRadius: '100%',
    height: 60,
    width: 60,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  imageAndTextContainer: {
    width: '100%',
    height: '100%',
    padding: 10,
  },
  imageContainer: {
    width: '100%',
    ...theme.globals.center,
  },
  textContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      ...theme.globals.centerVertical,
    },
  },
}));

export default function CardWithImage(props) {
  const classes = useStyles();

  const buildStatus = (status) => {
    switch (status) {
      case 'ABIERTO':
        return (
          <div className={`${classes.statusStyle} ${classes.statusOpen}`}>
            <Typography className={classes.statusText}>{status}</Typography>
          </div>
        );
      case 'CERRADO':
        return (
          <div className={`${classes.statusStyle} ${classes.statusClosed}`}>
            <Typography className={classes.statusText}>{status}</Typography>
          </div>
        );
      default:
        return (
          <div className={`${classes.statusStyle} `}>
            <Typography className={classes.statusText}>{status}</Typography>
          </div>
        );
    }
  };

  return (
    <Card className={classes.cardComponent}>
      <Grid container className={classes.mapImageContainer}>
        <div className='col-3 align-self-center justify-content-center text-center'>
          {props.isOpen ? buildStatus('ABIERTO') : buildStatus('CERRADO')}
        </div>

        <img src={props.snapshot} className={classes.mapImage} />
      </Grid>
      <Grid container className={classes.imageAndTextContainer}>
        <Grid item xs={12} md={4} lg={3} className={classes.imageContainer}>
          <img
            src={props.storeprofilepic}
            className={classes.storeprofilepic}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={9} className={classes.textContainer}>
          <Grid item xs={12}>
            <Typography className={classes.storeName}>
              {props.storeName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.phone}>
              {`+52 ${props.phone}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.address}>{props.address}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}
