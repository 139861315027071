import React from 'react';
import { useEffect, useState } from 'react';
import { withSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import BalancesView from './balancesView'
import TransactionsView from './transctionsView'
import {
  Typography,
} from '@material-ui/core';


const Emidamex = (props) => {
  const classes = useStyles();
  const components = [
    BalancesView,
    TransactionsView
  ];
  const [index, setIndex] = useState(0);
  const CurrentComponent = components[index];

  const onViewChange = (index) => {
    setIndex(index);
  }
  return (
    <div className={classes.mainContainer}>
      <Typography className={classes.title}>Consola Emidamex</Typography>
      <div className={classes.viewContainer}>
        <CurrentComponent onViewChange={onViewChange} enqueueSnackbar={props.enqueueSnackbar} closeSnackbar={props.closeSnackbar} />
      </div>
    </div>

  )
}

const useStyles = makeStyles(theme => ({

  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px',
    overflowX: 'auto',
  },
  title: {
    ...theme.typography.title
  },
  viewContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    overflowX: 'auto',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  balanceContainer: {
    justifyContent: 'space-between',
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '40%'
  },
  inputsButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '30%'
  },
  depositButton: {
    paddingTop: '50px',
    width: '100%',

  },
  transactionsButton: {
    paddingTop: '50px',
    width: '100%',
  },
  movementsStatsContainer: {
    height: '30%',
    marginBottom: '10px'
  },
  tableContainer: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    width: '60%',
    backgroundColor: '#E4E3E3',
    borderRadius: '10px',
    overflowX: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '50%',
      minWidth: '100%',
      minHeight: '50%'
    },
  },
  table: {
    // textAlign: 'center',
    alignItems: 'flex-start',
    width: '100%',
  },
  tableHeader: {
    alignItems: 'flex-start',

  },
  tableRegister: {
    border: '1px solid #dddddd',
    textaAlign: 'left',
    padding: '8px'
  },




}));

export default withSnackbar(Emidamex);