import React from 'react';
import { useEffect, useState } from 'react';
import { withSnackbar } from 'notistack';
import { API, Auth } from 'aws-amplify';
import { API_EMIDAMEX } from '../../../lib/constants';
import {
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MovementsStats from '../../common/movementsStats';
import PrimaryButton from '../../common/primaryButton';
import PrimaryInput from '../../common/primaryInput';
import PrimarySelect from '../../common/primarySelect';
import moment from 'moment-timezone';
import CircularProgress from '@material-ui/core/CircularProgress';
import Pagination from '@material-ui/lab/Pagination';
import Checkbox from '@material-ui/core/Checkbox';
import CircleChecked from '@material-ui/icons/CheckCircleOutline';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';

const BalanseView = ({ onViewChange, enqueueSnackbar }) => {

  moment.locale('es')

  useEffect(() => {
    getBalances()
  }, [])

  const types = [{ label: 'Seleccione una opción' }, { type: 'recharge', label: 'Recarga celular' }, { type: 'service', label: 'Pago de servicios' }]
  const typesFilter = [{ type: 'all', label: 'Todos' }, { type: 'recharge', label: 'Recarga celular' }, { type: 'service', label: 'Pago de servicios' }]
  const [balanceServices, setBalanceServices] = useState(0);
  const [balanceRecharges, setBalanceRecharges] = useState(0);
  const [deposits, setDeposits] = useState([]);
  const [loading, setLoading] = useState(true)
  const [date, setDate] = useState('');
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [amount, setAmount] = useState('');
  const [type, setType] = useState('');
  const [typeFilter, setTypeFilter] = useState('all');
  const [selectedPage, setSelectedPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [showDeposits, setShowDeposits] = useState([]);
  const [checkBoxEnable, setCheckBoxEnable] = useState(false);
  const perPage = 11

  let balanceRechargeC = 0;
  let balanceServicesC = 0;

  const getBalances = async () => {
    setInterval(await requestBalance(), 600000);
    getDeposits()
  }

  const requestBalance = async () => {
    const responseService = await API.get(
      API_EMIDAMEX,
      '/emidamex/services/BalanceServices', {
      queryStringParameters: {
        from: 'request'
      }
    })
    setBalanceServices(responseService.balance)
    balanceServicesC = responseService.balance;
    if (responseService.balance < 1000) {
      handleAlert('error', responseService.message);
    }

    const responseRecharge = await API.get(
      API_EMIDAMEX,
      '/emidamex/recharges/BalanceRecharges', {
      queryStringParameters: {
        from: 'request'
      }
    })
    setBalanceRecharges(responseRecharge.balance)
    balanceRechargeC = responseRecharge.balance;
    if (responseRecharge.balance < 1000) {
      handleAlert('error', responseRecharge.message);
    }
  }

  const saveDeposit = async () => {
    if (date === '' || amount === '' || type === types[0].label || type === '') {
      handleAlert('error', 'Algún campo se encuentra vacío.');
    } else {
      const user = await Auth.currentSession();
      const response = await API.post(
        API_EMIDAMEX,
        '/emidamex/globalActions/saveDeposit', {
        body: {
          values: {
            depositDate: date,
            depositAmount: amount,
            depositType: type
          },
          userRecordKey: user.accessToken.payload.sub
        }
      })
      handleAlert(response.type, response.message);
      getDeposits()
      setDate('');
      setAmount('');
      setType(types[0].label);
    }
  }

  const getDeposits = async () => {
    const responseRecharges = await API.get(
      API_EMIDAMEX,
      '/emidamex/globalActions/getDeposits', {
      queryStringParameters: {
        type: 'recharge'
      }
    })
    const responseServices = await API.get(
      API_EMIDAMEX,
      '/emidamex/globalActions/getDeposits', {
      queryStringParameters: {
        type: 'service'
      }
    })
    const deposits = [...responseRecharges.Items, ...responseServices.Items].sort(
      (firstItem, secondItem) => Date.parse(firstItem.depositDate) - Date.parse(secondItem.depositDate))

    const rechargeDeposits = deposits.filter(deposit => deposit.type === 'recharge');
    const serviceDeposits = deposits.filter(deposit => deposit.type === 'service');
    const transactions = await getTransactions();
    const rechargeTransactions = transactions.filter(transaction => transaction.type === 'recharge');
    const serviceTransactions = transactions.filter(transaction => transaction.type === 'service');
    const rechargesInRange = await getInRange(rechargeDeposits, rechargeTransactions);
    const servicesInRange = await getInRange(serviceDeposits, serviceTransactions);
    const newDeposits = [...rechargesInRange, ...servicesInRange].sort(
      (firstItem, secondItem) => Date.parse(secondItem.depositDate) - Date.parse(firstItem.depositDate))

    setPages(Math.ceil(newDeposits.length / perPage))
    setDeposits(newDeposits);
    setLoading(false)
  }

  const getInRange = async (deposits, transactions) => {
    let aux = []

    for (let i = 0; i <= deposits.length - 1; i++) {
      let amountUsed = 0;

      for (let j = 0; j <= transactions.length - 1; j++) {
        //substring the date 
        const newDate = transactions[j].transactionDateTime.substring(0, 10);
        //formt the date to YYYY-MM-DD
        const formatNewDate = moment(newDate).format('YYYY-MM-DD');
        //check if there is a deposit ahead
        if (deposits[i + 1]) {
          //filter by dates
          if ((Date.parse(formatNewDate) < Date.parse(deposits[i + 1].depositDate)) && (Date.parse(formatNewDate) >= Date.parse(deposits[i].depositDate))) {
            // if is a number add the amounts
            if (typeof transactions[j].totalPay === 'number') {
              amountUsed = parseFloat(amountUsed) + parseFloat(transactions[j].totalPay)
              const a = transactions.splice(j, 1)
              j--
            }
          }
        } else {
          //if not exist more deposits add all
          if (Date.parse(formatNewDate) >= Date.parse(deposits[i].depositDate)) {
            if (typeof transactions[j].totalPay === 'number') {
              amountUsed = parseFloat(amountUsed) + parseFloat(transactions[j].totalPay)
            }
          }
        }
      }
      deposits[i] = { ...deposits[i], amountUsed: amountUsed }
      aux.push(deposits[i]);
    }
    return aux
  }

  const getTransactions = async () => {
    const responseRecharge = await API.get(
      'emidamex-api',
      '/emidamex/globalActions/getAllTransactions', {
      queryStringParameters: {
        type: 'recharge'
      }
    })

    const responseService = await API.get(
      'emidamex-api',
      '/emidamex/globalActions/getAllTransactions', {
      queryStringParameters: {
        type: 'service'
      }
    })
    let aux = [...responseRecharge.Items, ...responseService.Items]
    return aux
  }

  const formatDate = (date, index) => {
    const newDate = date.split('-').reverse().join('/');
    return newDate
  }

  const formatMoney = (number) => {
    const decimalIsZero = number => number.toString().split('.')[2] === '0';
    const numberFixed = parseFloat(number).toFixed(2);
    return decimalIsZero(numberFixed)
      ? `${parseFloat(numberFixed)}`
      : `${numberFixed}`;
  }

  const handleChange = (e) => {
    switch (e.target.name) {
      case 'depositDate':
        setDate(e.target.value)
        break;
      case 'depositAmount':
        setAmount(e.target.value)
        break;
      case 'depositType':
        setType(e.target.value)
        break;
      case 'depositDateTo':
        if (e.target.value > dateFrom) {
          setDateTo(e.target.value)
          setShowDeposits(filterRange(dateFrom, e.target.value, typeFilter))
        } else {
          setDateTo('')
          handleAlert('error', 'Fecha menor a la de inicio');
        }
        break;
      case 'depositDateFrom':
        setDateFrom(e.target.value)
        setShowDeposits(filterRange(e.target.value, dateTo, typeFilter))
        break;
      case 'typeFilter':
        setTypeFilter(e.target.value)
        setShowDeposits(filterRange(dateFrom, dateTo, e.target.value))
        break;
      default:
        break;
    }
  }

  const filterRange = (from, dateTo, type) => {
    let depositsFiltered = []
    if (dateTo === '') {
      console.log(from)
      depositsFiltered = deposits.filter(deposit => deposit.depositDate >= from)
    } else if (from === '') {
      depositsFiltered = deposits.filter(deposit => deposit.depositDate <= dateTo)
    } else if (from != '' && dateTo != '') {
      if (type != 'all') {
        depositsFiltered = deposits.filter(deposit => (deposit.depositDate >= from && deposit.depositDate < dateTo) && (deposit.type === type))
      } else (
        depositsFiltered = deposits.filter(deposit => deposit.depositDate >= from && deposit.depositDate < dateTo)
      )
    }
    if (type != 'all') {
      if (depositsFiltered.length != 0) {
        depositsFiltered = depositsFiltered.filter(deposit => deposit.type === type)
      } else {
        depositsFiltered = deposits.filter(deposit => deposit.type === type)
      }
    }

    return depositsFiltered
  }

  const deleteDeposits = async ({ idDeposit, createdAt }) => {
    const responseDelete = await API.del(
      'emidamex-api',
      `/emidamex/globalActions/deleteDeposit`, {
      queryStringParameters: {
        idDeposit,
        createdAt
      }
    })
    handleAlert(responseDelete.type, responseDelete.message);
    getDeposits();
  }

  const handleAlert = (variant, message) => {
    enqueueSnackbar(message, { variant });
  };

  const handlePagination = (event, value) => {
    setSelectedPage(value)
  }

  const classes = useStyles();

  return (
    <div className={classes.generalContainer}>
      <div className={classes.balanceContainer}>
        <div className={classes.movementsStatsContainer}>
        <Typography className={classes.titleAddDeposit}>Depósitos</Typography>
          <MovementsStats
            stats={[{ label: 'Saldo actual de recargas', value: balanceRecharges }, { label: 'Saldo actual de servicios', value: balanceServices },]}
          />
          <div className={classes.viewBalanceAndTransactions}>
            <PrimaryButton
              fullWidth
              onClick={() => requestBalance()}
            >
              Actualizar balance
            </PrimaryButton>
            <PrimaryButton
              fullWidth
              onClick={() => onViewChange(1)}
            >
              Ver transacciones
            </PrimaryButton>
          </div>
        </div>
        <div className={classes.inputsTitle}>
          <Typography className={classes.titleAddDeposit}>Agregar depósito</Typography>
          <div className={classes.inputsContainer}>
            <div className={classes.inputDate}>
              <Typography className={classes.inputTitle}>Fecha del depósito</Typography>
              <PrimaryInput
                value={date}
                type='date'
                onChange={handleChange}
                id={'depositDate'}
                name={'depositDate'}
              >
              </PrimaryInput>
              <Typography className={classes.inputTitle}>Cantidad del depósito</Typography>
              <PrimaryInput
                value={amount}
                onChange={handleChange}
                id={'depositAmount'}
                name={'depositAmount'}>
              </PrimaryInput>
            </div>
            <div className={classes.inputButton}>
              <div className={classes.accountInput}>
                <Typography className={classes.inputTitle}>Cuenta</Typography>
                <PrimarySelect
                  value={type}
                  content={types.map((item, index) => {
                    return <option key={index} value={item.type}>{item.label}</option>;
                  })}
                  onChange={handleChange}
                  id={'depositType'}
                  name={'depositType'}
                >
                </PrimarySelect>
              </div>
              <div className={classes.saveButton}>
                <PrimaryButton
                  fullWidth
                  onClick={saveDeposit}
                >
                  Agregar depósito
                </PrimaryButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.filterTable}>
        <div>
          <Typography className={classes.inputTitle}>Fecha de inicio</Typography>
          <PrimaryInput
            value={dateFrom}
            type='date'
            onChange={handleChange}
            id={'depositDateFrom'}
            name={'depositDateFrom'}>
          </PrimaryInput>
        </div>
        <div>
          <div className={classes.titleFilter}>
            <Checkbox
              icon={<CircleUnchecked />}
              checkedIcon={<CircleChecked />}
              checked={checkBoxEnable}
              onChange={() => setCheckBoxEnable(!checkBoxEnable)}
              color="rgba(67, 67, 67, 0.6)"
              style={{ padding: '0', paddingRight: '5px' }}
            />
            <Typography className={classes.inputTitle}>Fecha de fin</Typography>
          </div>
          <PrimaryInput
            value={dateTo}
            type='date'
            disabled={checkBoxEnable ? false : true}
            onChange={handleChange}
            id={'depositDateTo'}
            name={'depositDateTo'}>
          </PrimaryInput>
        </div>
        <div>
          <Typography className={classes.inputTitle}>Cuenta</Typography>
          <PrimarySelect
            value={typeFilter}
            content={typesFilter.map((item, index) => {
              return <option key={index} value={item.type}>{item.label}</option>;
            })}
            onChange={handleChange}
            id={'typeFilter'}
            name={'typeFilter'}
          >
          </PrimarySelect>
        </div>
      </div>
      <div className={classes.paginationContainer}>
        <div className={classes.tableContainer}>
          {loading ?
            <div className={classes.loading}>
              <CircularProgress />
            </div>
            :
            <>
              <table className={classes.table}>
                <thead>
                  <tr className={classes.tableHeader}>
                    <th>Fecha del depósito desde </th>
                    <th>Cantidad depositada</th>
                    <th>Cantidad gastada por usuarios</th>
                    <th>Diferencia</th>
                    <th>Cuenta</th>
                  </tr>
                </thead>
                <tbody>
                  {showDeposits.length === 0 ? deposits.slice(((selectedPage - 1) * perPage), (((selectedPage - 1) * perPage) + perPage)).map((deposit, index) => (
                    <tr key={index}>
                      <td className={classes.tableRegister}>{formatDate(deposit.depositDate)}</td>
                      <td className={classes.tableRegister}>${formatMoney(deposit.depositAmount)}</td>
                      <td className={classes.tableRegister}>${formatMoney(deposit.amountUsed)}</td>
                      <td className={classes.tableRegister}>${formatMoney(parseFloat(deposit.depositAmount) - parseFloat(deposit.amountUsed))}</td>
                      <td className={classes.tableRegister}>{deposit.type === 'recharge' ? 'Recarga celular' : 'Pago de servicio'}</td>
                      <td className={classes.tableRegisterButton}>
                        <PrimaryButton
                          onClick={() => deleteDeposits(deposit)}
                        >
                          Eliminar
                        </PrimaryButton>
                      </td>
                    </tr>
                  )) :
                    showDeposits.slice(((selectedPage - 1) * perPage), (((selectedPage - 1) * perPage) + perPage)).map((deposit, index) => (
                      <tr key={index}>
                        <td className={classes.tableRegister}>{formatDate(deposit.depositDate)}</td>
                        <td className={classes.tableRegister}>${formatMoney(deposit.depositAmount)}</td>
                        <td className={classes.tableRegister}>${formatMoney(deposit.amountUsed)}</td>
                        <td className={classes.tableRegister}>${formatMoney(parseFloat(deposit.depositAmount) - parseFloat(deposit.amountUsed))}</td>
                        <td className={classes.tableRegister}>{deposit.type === 'recharge' ? 'Recarga celular' : 'Pago de servicio'}</td>
                        <td className={classes.tableRegisterButton}>
                          <PrimaryButton
                            onClick={() => deleteDeposits(deposit)}
                          >
                            Eliminar
                          </PrimaryButton>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </>
          }

        </div>
        <div className={classes.paginationFooter}>
          <div className={classes.pagination}>
            <Pagination count={pages} shape="rounded" onChange={handlePagination} />
          </div>
          <div className={classes.buttonPagination}>
            <PrimaryButton
              onClick={() => { setLoading(true); getDeposits() }}
            >
              Actualizar depositos
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  generalContainer: {
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    padding: '30px',
    justifyContent: 'space-around',
    overflowX: 'auto',
  },
  balanceContainer: {
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'flex-end',
    height: 'auto',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%'
  },
  inputsTitle:{
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  inputsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'baseline',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  inputDate: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '50%',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  inputButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '50%',
    height: '100%',
    marginBottom: '10px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  inputTitle: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    }
  },
  titleAddDeposit:{
    ...theme.typography.subtitle,
    marginBottom: '10px'
  },
  accountInput: {
    height: '50%',
    width: '100%'
  },
  saveButton: {
    height: '50%',
    paddingTop: '18px'
  },
  inputsButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '30%'
  },
  depositButton: {
    paddingTop: '50px',
    width: '100%',
  },
  transactionsButton: {
    paddingTop: '50px',
    width: '100%',
  },
  movementsStatsContainer: {
    height: '30%',
    width: '40%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    }
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    width: '100%',
    borderRadius: '10px',
    overflowX: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: '50%',
      minWidth: '100%',
      minHeight: '50%',
      marginTop: '15px'
    },
  },
  loading: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  table: {
    // textAlign: 'center',
    alignItems: 'flex-start',
    width: '100%',
  },
  tableHeader: {
    alignItems: 'flex-start',

  },
  tableRegister: {
    textaAlign: 'left',
    padding: '8px'
  },
  tableRegisterButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px'
  },
  pagination: {
    marginTop: '15px',
    widht: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  paginationContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  viewBalanceAndTransactions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      flexDirection: 'column',
    },
  },
  paginationFooter: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',

  },
  pagination: {
    display: 'flex',
    flexDirection: 'row',
    width: '55%',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  buttonPagination: {
    display: 'flex',
    flexDirection: 'row',
    width: '40%',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  filterTable: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '50%',
    marginTop: '15px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  titleFilter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },




}));

export default withSnackbar(BalanseView);