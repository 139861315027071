import { createSlice } from '@reduxjs/toolkit';

export const socketSlice = createSlice({
  name: 'socket',
  initialState: {
    value: null,
  },
  reducers: {
    addSocket: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { addSocket } = socketSlice.actions;

export default socketSlice.reducer;