import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import '../../assets/css/main.css';
import {
  CircularProgress,
  Typography,
  Button,
  Grid,
} from '@material-ui/core';
import DefaultCard from './../common/defaultCard';
import { Auth, API, Storage } from 'aws-amplify';
import { withSnackbar } from 'notistack';
import getMerchantData from '../../lib/getMerchant';
import moment from 'moment-timezone';
import defaultStoreIcon from './../../assets/images/default_store_icon.svg';
import {
  API_PROFILE,
  API_STORE,
} from '../../lib/constants';
import CheckSchedule from '../../lib/storeIsOpen';
import PrimarySearchBar from '../common/primarySearchBar';
import PrimaryButton from '../common/primaryButton';
import SecondaryButton from '../common/secondaryButton';
import DefaultModal from '../modal';
import removeAccents from '../../lib/removeAccents';

import noStoreImage from '../../assets/images/default_store_icon_1.svg';

const useStyles = makeStyles(theme =>({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 0,
    overflow: 'hidden',
  },
  buttonGroupContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'center',
    },
  },
  buttonContainer: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      width: '100%',
    },
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.lightGrey.main}`,
    marginBottom: 15,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  titleContainer: {
    width: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  title: {
    ...theme.typography.title,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  searchBar: {
    display: 'flex',
    backgroundColor: '#EDEDED',
    paddingLeft: 20,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 33,
    boxSizing: 'border-box',
    borderRadius: 10,
  },
  containerForm: {
    padding: 20,
    flexDirection: 'row',
    display: 'flex',
    flex: 1,
    padding: 20,
    marginTop: 20,
  },
  F1: {
    ...theme.typography.mediumText,
    display: 'flex',
    flex: 1,
    padding: 10,
  },
  F1Right: {
    ...theme.typography.mediumText,
    display: 'flex',
    flex: 1,
    padding: 10,
    justifyContent: 'flex-end',
  },
  F2: {
    ...theme.typography.mediumText,
    display: 'flex',
    flex: 2,
    padding: 20,
  },
  FX: {
    ...theme.typography.mediumText,
    display: 'flex',
    flex: 5,
    padding: 10,
  },
  openLabel: {
    ...theme.typography.mediumText,
    color: theme.palette.primary.main,
    display: 'flex',
    flex: 1,
    padding: 20,
  },
  closedLabel: {
    ...theme.typography.mediumText,
    color: '#DA7B7B',
    display: 'flex',
    flex: 1,
    padding: 20,
  },
  containerForm2: {
    backgroundColor: '#E0E0E0',
    position: 'relative',
    marginTop: 20,
    zIndex: 1,
    height: 1,
    flexDirection: 'row',
  },
  moveToRight: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    marginLeft: 20,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    overflowX: 'hidden',
    padding: 10,
  },
  centerModal: {
    height: '100%',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardModal: {
    minHeight: 400,
    width: 800,
    paddingLeft: 50,
    paddingRight: 50,
    backgroundColor: 'white',
    paddingTop: 30,
    paddingBottom: 20,
    borderRadius: 5,
    border: '1px solid #E0E0E0',
  },
  spaceHorizontal: {
    paddingRight: 10,
  },
  spaceVertical: {
    paddingBottom: 10,
  },
  green: {
    color: '#3B9A83',
  },
  actionButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  actionButton: {
    minWidth: '240px',
    maxWidth: '25git0px',
    marginRight: '20px',
    padding: '0px 20px 0px 20px',
    border: '1px solid #3B9A83',
  },
  actionButton2: {
    marginRight: '0px',
    width: '300px',
  },
  actionButtonText: {
    ...theme.typography.smallText,
    color: theme.palette.primary.main,
    marginLeft: '10px',
  },
  searchButton: {
    width: '100%',
  },
  modalTitle: {
    ...theme.typography.paragraph,
    fontWeight: '800',
    marginBottom: 30,
  },
  modalSubtitle: {
    ...theme.typography.mediumText,
    marginBottom: 30,
    marginTop: 30,
  },
  verticalMainSecctionModal: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  headerSecctionModal: {
    display: 'flex',
    flex: 2,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid #E0E0E0',
  },
  bodySecctionModal: {
    display: 'flex',
    flex: 8,
    flexDirection: 'row',
    height: '100%',
  },
  footerSecctionModal: {
    display: 'flex',
    flex: 2,
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: '10px',
  },
  bodyVerticalSecctionModal: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  bodyVerticalSecctionHeaderModal: {
    display: 'flex',
    flex: 1,
    justifyContent: 'left',
    alignItems: 'center',
  },
  bodyVerticalSecctionBodyModal: {
    display: 'flex',
    flex: 9,
    flexDirection: 'column',
    alignItems: 'left',
    paddingLeft: 30,
    marginBottom: 30,
  },
  checkBoxColor: { color: '#00e676', backgroundColor: 'white' },
  squareDownloadBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'left',
    height: 40,
    width: 134,
  },
  redText: {
    color: 'red',
    textAlign: 'right',
    fontSize: 16,
  },
  buttonStyle: {
    border: 0,
    margin: 15,
    backgroundColor: 'white',
  },
  cardContainer: {
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    cursor: 'pointer',
    transition: 'box-shadow 0.3s ease-in-out',
    '&:hover': {
      boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.75)',
    },
  },
  empty: {
    paddingTop: '50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  loadingContainer: {
    margin: 'auto',
    paddingTop: '50px',
    ...theme.globals.center,
  },
  primaryButtonStyle: {
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  secondaryButtonStyle: {
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const Store = (props) => {
  const userKey = useSelector(state => state.user.value);

  useEffect(() => {
    searchStore();
  }, []); // eslint-disable-line

  const classes = useStyles();
  let history = useHistory();

  const initialFromDate = `${moment().format('YYYY-MM-DD')}T00:00`;
  const initialToDate = `${moment().format('YYYY-MM-DD')}T23:59`;

  const [isLoading, setLoading] = useState(false);
  const [createStore, setCreateStore] = useState(false);
  const [stores, setStores] = useState([]);
  const [shownStores, setShownStores] = useState([]);
  const [merchantId, setMerchant] = useState([]);
  const [merchantEmail, setMerchantEmail] = useState();
  const [commercialName, setCommercialName] = useState();
  const [snapshots, setSnapshots] = React.useState([]);
  const [storeprofilepics, setStoreProfilePics] = React.useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDeactivated, setShowDeactivated] = useState(false);
  const [merchantPicture, setProfilePicture] = useState();
  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const searchStore = async () => {
    setLoading(true);
    const getUser = await Auth.currentAuthenticatedUser();
    let isToDetails = false;
    try {
      const getGralProfile = await API.get(
        API_PROFILE,
        `/profile/${userKey}`
      );
      let merchant = null;
      if (getGralProfile.data[0].merchantRecordKey) {
        merchant = await getMerchantData(
          'merchant',
          getGralProfile.data[0].merchantRecordKey
        );
      } else if (
        getGralProfile.data[0].isEmployeeOfStore &&
        getGralProfile.data[0].isEmployeeActive
      ) {
        merchant = await getMerchantData(
          'store',
          getGralProfile.data[0].isEmployeeOfStore
        );
        isToDetails = true;
      } else if (
        getGralProfile.data[0].isManagerOfStore &&
        getGralProfile.data[0].isEmployeeActive
      ) {
        merchant = await getMerchantData(
          'store',
          getGralProfile.data[0].isManagerOfStore
        );
        isToDetails = true;
      }
      if (!isToDetails && merchant === null) {
        handleAlert(
          'error',
          'Ocurrió un error al obtener la información del usuario'
        );
        history.push('/login');
      } else if (!isToDetails) {
        if (
          getGralProfile.data[0].hasAvatar ||
          getGralProfile.data[0].isManagerOfStore
        ) {
          const pImage = await Storage.get(
            `profiles/${merchant.merchantRecordKey}/default.jpg`
          );
          setProfilePicture(pImage);
        }
        setMerchant(merchant.merchantRecordKey);
        setMerchantEmail(merchant.legalRepresentativeEmail);
        setCommercialName(merchant.businessCommercialName);
        const getStores = await API.get(API_STORE, '/store', {
          queryStringParameters: {
            merchant: merchant.merchantRecordKey,
            isFor: 'merchant',
          },
        });
        if (getStores.data && getStores.data.Items) {
          const imagesP = getStores.data.Items.map((store) =>
            Storage.get(`store/${store.id}/default.jpg`)
          );
          const pImages = await Promise.all(imagesP);
          setStoreProfilePics(pImages);
          const snapshotsP = getStores.data.Items.map((store) =>
            Storage.get(`store/${store.id}/loc.jpg`)
          );
          const links = await Promise.all(snapshotsP);
          setSnapshots(links);
          const storesWithImages = getStores.data.Items.map((store, index) => {
            return {
              ...store,
              storeImage: pImages[index],
              storeSnapshot: links[index],
            };
          });
          setStores(storesWithImages);
          setShownStores(storesWithImages);
        }
        setLoading(false);
      } else {
        history.push({
          pathname: '/storeDetails',
          state: {
            storeId: getGralProfile.data[0].isManagerOfStore,
            merchantId: merchant.merchantRecordKey,
            isManager: true,
          },
        });
      }
    } catch (err) {
      handleAlert('Error', err.message);
      setLoading(false);
    }
  };

  const handleNewStoreBtn = () => {
    setCreateStore(!createStore);
  };

  const handleSearchChange = (e) => {
    const search = removeAccents(e.target.value).toLowerCase();
    const filteredStores = stores.filter((store) =>
      removeAccents(store.storeName).toLowerCase().includes(search)
    );
    setShownStores(filteredStores);
  };

  const renderStores = () => {
    const element = shownStores.filter((store) => showDeactivated ? !store.published : store.published).map((store, index) => {
      var isOpen = false;
      if (store.schedules) {
        const scheduleResult = CheckSchedule(store.schedules);
        isOpen = scheduleResult.isOpen;
      }
      return (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            onClick={() => goToDetails(store.id)}
          >
            <div className={classes.cardContainer}>
              <DefaultCard
                storeName={store.storeName}
                address={store.address}
                phone={store.phone}
                isOpen={isOpen}
                geoLocation={store.geoLocation}
                cardType={'cardWithImage'}
                snapshot={store.storeSnapshot}
                storeprofilepic={
                  store.hasAvatar
                    ? store.storeImage
                    : merchantPicture
                    ? merchantPicture
                    : defaultStoreIcon
                }
              />
            </div>
          </Grid>
      );
    });
    if(element.length > 0){
      return element
    } else{
      return (
      <div className={classes.empty}>
        <img src={noStoreImage} alt='noStoreImage' />
        <Typography>No hay sucursales {showDeactivated ? 'desactivadas' : 'activas' }</Typography>
        </div>
      )
    }
  };

  const goToDetails = (storeId) => {
    history.push({
      pathname: '/storeDetails',
      state: { storeId, merchantId, merchantPicture, },
      from: props.location.from,
      to: props.location.to,
      email: merchantEmail,
    });
  };

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  const handleShowStoreModal = () => {
    searchStore();
    setCreateStore(false);
  };

  const handleProblem = async () => {
    const description = 'No se encuentran las sucursales';
    const formData = new FormData();
    formData.append('name', commercialName);
    formData.append('merchantID', merchantId);
    formData.append('problemType', 'Sucursales');
    formData.append('problem', description);
    formData.append('email', merchantEmail);
    fetch(process.env.REACT_APP_ZAPIER_ZOHO, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        handleAlert('success', 'Tu problema se envió correctamente');
      })
      .catch((error) => {
        handleAlert('error', error.response.data.error);
      });
  };

  return (
    <div className={classes.container}>
      <DefaultModal
        open={showModal}
        handleClose={handleShowModal}
        modalType='report'
        stores={stores}
        merchantId={merchantId}
      ></DefaultModal>
      <DefaultModal
        open={createStore}
        handleClose={() => handleShowStoreModal()}
        modalType='newStore'
      ></DefaultModal>
      <div className={classes.headerContainer}>
        <div className={classes.titleContainer}>
          <Typography className={classes.title}>Mis sucursales {!showDeactivated ? "activas" : "desactivadas"}</Typography>
        </div>
        <div className={classes.buttonGroupContainer}>
          <div className={classes.buttonContainer}>
          <SecondaryButton
              onClick={() => setShowDeactivated(!showDeactivated)}
              type='button'
              paddingHorizontal={25}
              secondaryButtonStyle={classes.primaryButtonStyle}
            >
              {showDeactivated ? "Sucursales Activas" : "Sucursales Desactivadas"}
            </SecondaryButton>
            <SecondaryButton
              type='button'
              paddingHorizontal={25}
              onClick={handleShowModal}
              secondaryButtonStyle={classes.secondaryButtonStyle}
            >
              Reporte de Sucursales
            </SecondaryButton>
          </div>
          <div className={classes.buttonContainer}>
            <PrimaryButton
              onClick={handleNewStoreBtn}
              type='button'
              paddingHorizontal={25}
              primaryButtonStyle={classes.primaryButtonStyle}
            >
              Agregar Sucursal
            </PrimaryButton>
          </div>
        </div>
      </div>
      <div className='row '>
        <div className='col-lg-12 col-sm-12'>
          <PrimarySearchBar
            type='search'
            placeholder='Buscar'
            autoFocus
            onChange={(e) => handleSearchChange(e)}
          />
          {props.location.from && props.location.to ? null : props.location
              .from && !props.location.to ? (
            <div>
              <Button
                className={classes.buttonStyle}
                onClick={() => handleProblem()}
              >
                <Typography className={classes.redText}>
                  No encuentro mi sucursal
                </Typography>
              </Button>
            </div>
          ) : null}
        </div>
      </div>
      {isLoading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress color='secondary' />
        </div>
      ) : stores.length > 0 ? (
        <div className={`${classes.content} scrollBarHidden`}>
          <Grid container spacing={2}>
            {renderStores()}
          </Grid>
        </div>
      ) : (
        <div className={classes.empty}>
          <img src={noStoreImage} alt='noStoreImage' />
          <Typography>No hay sucursales.</Typography>
          <PrimaryButton type='button' onClick={handleNewStoreBtn}>
            Agregar una sucursal
          </PrimaryButton>
        </div>
      )}
    </div>
  );
};

export default withSnackbar(Store);
