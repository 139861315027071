import React from 'react';
import { withSnackbar } from 'notistack';
import { Auth } from 'aws-amplify';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import '../../assets/css/main.css';
import CognitoErrors from '../../lib/cognitoErrors';
import ThirdHeader from '../../components/headers/thirdHeader';

import PrimaryInput from '../../components/common/primaryInput';
import PrimaryButton from '../../components/common/primaryButton';
import SecondaryButton from '../../components/common/secondaryButton';
import image from '../../assets/images/merchantBackground.png';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  background: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    minWidth: '100vw',
    backgroundImage: `url(${image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    paddingLeft: '5%',
    paddingRight: '5%',
    paddingTop: '2%',
    paddingBottom: '2%',
    borderRadius: 20,
    minWidth: 320,
  },
  title: {
    fontFamily: 'Montserrat Variable',
    fontSize: 24,
    fontWeight: 700,
    textTransform: 'none',
    color: '#434343',
    marginTop: 20,
    marginBottom: 20,
  },
  paragraph: {
    fontFamily: 'Montserrat Variable',
    fontSize: 16,
    fontWeight: 700,
    minWidth: 200,
    maxWidth: 400,
    color: '#434343',
    textAlign: 'justify',
    marginTop: 10,
    marginBottom: 10,
  },
  email: {
    color: '#434343',
  },
  spacer1: { width: '100%', height: 20 },
  spacer2: { width: '100%', height: 40 },
});

class Verify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      username: this.props.location.state.email,
      password: this.props.location.state.password,
      isLoading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleAlert = this.handleAlert.bind(this);
  }

  handleAlert = (variant, message) => {
    this.props.enqueueSnackbar(message, { variant });
  };

  _login = async () => {
    const { username, password } = this.state;
    const user = await Auth.signIn(username, password);
    Auth.currentSession()
      .then((data) => {
        if (user) {
          if (
            user.attributes['custom:usertype'] === 'merchant' ||
            user.attributes['custom:usertype'].indexOf('manager') !== -1
          ) {
            this.handleAlert('success', 'Registro Finalizado');
            this.props.history.push('/dashboard');

          } else if (user.attributes['custom:usertype'] === 'admin') {
            this.handleAlert('success', 'Registro Finalizado');
            this.props.history.push('/admin/dashboard');
          } else {

            this.props.history.push({
              pathname: '/userInformation',
              state: { 
                email: user.attributes.email, 
                password,
                phone_number:user.attributes.phone_number,
                key: user.attributes.sub 
              },
            });
            this.setState({ isLoading: false });
          }
        } else {
          this.handleAlert('info', 'ERROR 400');
        }
      })
      .catch((err) => {
        console.log(err);
        CognitoErrors(err.message);
        this.setState({ isLoading: false });
      });
  };
  _gotoSecured = async () => {
    const { code, username } = this.state;
    this.setState({ isLoading: true });
    if (code === '') {
      return;
    }
    try {
      await Auth.confirmSignUp(username, code);
      this.handleAlert('success', 'Verificado');
      this._login();
    } catch (error) {
      this.setState({ isLoading: false });
      console.log('VERIFY-ERROR', error);
      this.handleAlert('error', CognitoErrors(error.message));
    }
  };
  _resendCode = async () => {
    const username = this.state.username;
    if (username === '') {
      return;
    }
    try {
      await Auth.resendSignUp(username);
      this.handleAlert('info', 'Código Reenviado con éxito');
    } catch (error) {
      console.log('VERIFY-ERROR', error);
      this.handleAlert('error', CognitoErrors(error.message));
    }
  };

  handleChange(e) {
    const { name, value } = e.target;
    if (value.length <= 6) {
      if (value === '' || /^\d+$/.test(value)) {
        let finalValue = value;
        this.setState({ [name]: finalValue });
      }
    }
  }

  _handleAlert = (variant, message) => {
    this.props.enqueueSnackbar(message, { variant });
  };

  _handleProblem = async () => {
    const username = this.state.username;
    const description = 'No se puede iniciar sesión';
    const formData = new FormData();
    formData.append('name', 'n/a');
    formData.append('merchantID', 'n/a');
    formData.append('problemType', 'Activación');
    formData.append('problem', description);
    formData.append('email', username);
    fetch(process.env.REACT_APP_ZAPIER_ZOHO, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('SUCCESS', data);
        this._handleAlert('success', 'Tu problema se envió correctamente');
      })
      .catch((error) => {
        console.log('ERR', error);
        this._handleAlert('error', error);
      });
  };

  render() {
    const { classes } = this.props;

    const { code, username, isLoading } = this.state;
    return (
      <div>
        <div className={classes.background}>
          {isLoading ? (
            <div className='styleCircularProgress'>
              <CircularProgress color='secondary' />
            </div>
          ) : (
            <div className={classes.container}>
              <ThirdHeader />
              <Typography className={classes.title}>
                Código de Activación
              </Typography>
              <Typography className={classes.paragraph}>
                Hemos enviado un correo al correo electrónico proporcionado:{' '}
                <span style={{ color: '#3B9A83' }}>{username}</span> Recuerda revisar tu bandeja de correo no deseado. Por favor
                ingresa el código a continuación:
              </Typography>
              <PrimaryInput
                className='verifyCodeInput'
                id='code'
                name='code'
                type='number'
                onChange={this.handleChange}
                value={code}
                fullWidth
                marginVertical={20}
                centerInputText
                // inputProps={{
                //   min: 0,
                //   style: {
                //     textAlign: 'center',
                //     borderBottom: 'solid #333333',
                //     fontSize: 50,
                //   },
                // }}
              />
              <PrimaryButton
                type='submit'
                className='default_button'
                onClick={this._gotoSecured}
                marginVertical={20}
                fullWidth
              >
                Continuar
              </PrimaryButton>

              <div className='rowStyle centerItems padding_top_10'>
                <Typography className='subText1'>
                  ¿No recibiste tu código?
                </Typography>
                <Typography
                  className='subText1Pointer'
                  onClick={this._resendCode}
                >
                  Reenviar Mensaje
                </Typography>
              </div>
              <div className='rowStyle centerItems padding_bottom_40'>
                <Typography className='subText1'>¿Tienes problemas?</Typography>
                <Typography
                  className='subText1Pointer'
                  onClick={() => this._handleProblem()}
                >
                  Contáctanos
                </Typography>
              </div>

              <div className='rowStyle padding_top_10'>
                <Typography className='subText2'>
                  Cambiar número de teléfono
                </Typography>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withSnackbar(Verify));
