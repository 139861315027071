import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  CircularProgress,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import PrimaryButton from '../../common/primaryButton';
import PrimaryInput from '../../common/primaryInput';

const Billing = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [bills, setBills] = useState([]);
  const [shownBills, setShownBills] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    const defaultDates = getDefaultDates();
    getBills(defaultDates);
  }, []);

  const getDefaultDates = () => {
    setLoading(true);
    const today = new Date();
    const start = new Date(today.getFullYear(), today.getMonth(), 1);
    const end = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    setStartDate(start);
    setEndDate(end);
    return { startDate: start, endDate: end };
  }

  const getBills = async (defaultDates) => {
    try {
      setLoading(true);
      const dates = defaultDates ? defaultDates : { startDate, endDate };
      const timestamps = { startDate: dates.startDate.getTime(), endDate: dates.endDate.getTime() };
      if (timestamps.startDate > timestamps.endDate) {
        props.enqueueSnackbar('La fecha final no puede ser anterior a la inicial', { variant: 'warning' });
        return;
      }
      const params = new URLSearchParams(timestamps);
      const response = await fetch(`${process.env.REACT_APP_BILLING_API}?${params.toString()}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          "x-api-key": process.env.REACT_APP_BILLING_API_KEY,
        }
      });
      const responseJSON = await response.json();
      const newBills = responseJSON.data.bills;
      setBills(newBills);
      setShownBills(newBills);
    } catch (err) {
      props.enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }

  const translateType = (type) => {
    if (type === 'retentions') return 'R';
    else return 'I';
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const handleStartDateChange = (event) => {
    const newDate = new Date(event.target.value);
    newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
    setStartDate(newDate);
  }

  const handleEndDateChange = (event) => {
    const newDate = new Date(event.target.value);
    newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
    setEndDate(newDate);
  }

  const getDownloadLink = async (id, type) => {
    try {
      const params = new URLSearchParams({ billingRecordKey: id, fileType: type });
      const response = await fetch(`${process.env.REACT_APP_BILLING_API}/file?${params.toString()}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          "x-api-key": process.env.REACT_APP_BILLING_API_KEY,
        }
      });
      const responseJSON = await response.json();
      if (!responseJSON.success) {
        throw new Error(responseJSON.error);
      } else {
        const fileURL = responseJSON.data.url;
        downloadFile(fileURL);
      }
    } catch (err) {
      if (err.message === 'File not found') {
        props.enqueueSnackbar('No existe este archivo', { variant: 'warning' });
      } else {
        props.enqueueSnackbar(err.message, { variant: 'error' });
      }
    }
  }

  const downloadFile = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.click();
    link.remove();
  }

  const Row = (props) => {
    return (
      <TableRow>
        <TableCell component='th' scope='row' align='center'>
          {props.item.fileNo}
        </TableCell>
        <TableCell align='center'>
          {new Date(props.item.createdAt).toLocaleDateString('sv')}
        </TableCell>
        <TableCell align='center'>
          {translateType(props.item.type)}
        </TableCell>
        <TableCell align='center'>
          {props.item.nameIssuer}
        </TableCell>
        <TableCell align='center'>
          {props.item.nameReceiver}
        </TableCell>
        <TableCell align='center'>
          {`$${props.item.total.toFixed(2)}`}
        </TableCell>
        <TableCell align='center'>
          <Button
            variant='contained'
            color='primary'
            size='small'
            onClick={() => getDownloadLink(props.item.billingRecordKey, 'xml')}
          >
            XML
          </Button>
        </TableCell>
        <TableCell align='center'>
          <Button
            variant='contained'
            color='secondary'
            size='small'
            onClick={() => getDownloadLink(props.item.billingRecordKey, 'pdf')}
          >
            PDF
          </Button>
        </TableCell>
      </TableRow>
    )
  }

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <Typography className={classes.title}>
          Facturación
        </Typography>
        <PrimaryButton onClick={() => history.push('/admin/billing/retentions')}>
          Generar Factura de Retenciones
        </PrimaryButton>
      </div>
      <div className={classes.datesContainer}>
        <div className={classes.dateInputContainer}>
          <Typography>
            Fecha Inicio
          </Typography>
          <PrimaryInput
            value={startDate.toLocaleDateString('sv')}
            type='date'
            onChange={handleStartDateChange}
            id={'startDate'}
            name={'startDate'}
          />
        </div>
        <div className={classes.dateInputContainer}>
          <Typography>
            Fecha Final
          </Typography>
          <PrimaryInput
            value={endDate.toLocaleDateString('sv')}
            type='date'
            onChange={handleEndDateChange}
            id={'endDate'}
            name={'endDate'}
          />
        </div>
        <PrimaryButton onClick={() => getBills()}>
          Aplicar
        </PrimaryButton>
      </div>
      <TableContainer className={classes.tableContainer}>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell align='center'>ID</TableCell>
              <TableCell align='center'>Fecha</TableCell>
              <TableCell align='center'>Tipo</TableCell>
              <TableCell align='center'>Emisor</TableCell>
              <TableCell align='center'>Receptor</TableCell>
              <TableCell align='center'>Total</TableCell>
              <TableCell align='center'>XML</TableCell>
              <TableCell align='center'>PDF</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shownBills.length === 0 && (
              <TableRow>
                <TableCell align='center' colSpan={8} className={classes.emptyContainer}>
                  <div className={classes.emptyContainer}>
                    No hay registros.
                  </div>
                </TableCell>
              </TableRow>
            )}
            {(rowsPerPage > 0
              ? shownBills.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : shownBills
            ).map((bill) => (
              <Row
                key={bill.billingRecordKey}
                item={bill}
              />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, { label: 'Todos', value: -1 }]}
                count={shownBills.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}

export default withSnackbar(Billing);

const useStyles = makeStyles(theme => ({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '40vh',
  },
  container: {
    padding: '30px',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    ...theme.typography.title
  },
  datesContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '20px',
  },
  dateInputContainer: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '20px',
  },
  tableContainer: {
    marginTop: '20px',
  },
  emptyContainer: {
    marginTop: '60px',
    marginBottom: '60px',
  },
  separator: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));