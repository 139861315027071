import React,{useState} from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import { API } from 'aws-amplify';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import '../../../../assets/css/main.css';
import IconFailed from '../../../../assets/images/iconFailed.png'
import IconVerified from '../../../../assets/images/iconVerified.png'
import {FONT_DEFAULT} from '../../../../lib/constants.js'

function getModalStyle() {
    return {
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
    };
}

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        width: '100%'
    },
    columnsContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row'
    },
    columnStyle: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
    },
    columnStyle2: {
        display: 'flex',
        flex: 2,
        flexDirection: 'column'
    },
    columnTitle: {
        color: '#FFFFFF',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 16,
        marginBottom: 10,
        display: 'flex',
        alignItems: 'center',
        color: '#52A386'
    },
    columnText: {
        ...FONT_DEFAULT,
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 12,
        marginBottom: 5
    },
    columnValueText: {
        ...FONT_DEFAULT,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 12,
        marginLeft: 7,
        marginBottom: 5
    },
    labelRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    labelRowDocument1: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 20
    },
    actionButons: {
        marginRight: 5
    },
    previewText: {
        ...FONT_DEFAULT,
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 12,
        color: '#6B7899',
        cursor: 'pointer'
    },
});

function Tab1(props) {
    useEffect(() => {
    }, [])

    const classes = useStyles();

    const [isLoading, setLoading] = useState(false);
    const [user, setUser] = useState(props.user);
    const [userCanUpdateBalance, setUserCanUpdateBalance]= useState(!props.user.blockImmediatelyUpdateBalance)
    const handleAlert = (variant, message) => {
        props.enqueueSnackbar(message, { variant });
    };

    const getIdentityStatus = () => {
        console.info(props.user);
        return (
            <>
                <div className={classes.labelRowDocument1}>
                    <img width="13" height="13" src={props.user.identityValidationStep === 'VALID' ? IconVerified : IconFailed} className={classes.actionButons} />
                    <Typography className={classes.columnText}>Identificación Oficial</Typography>
                </div>
                <a href={props.user.identityUri} className={classes.previewText}>Ver documento en Mati</a>

                {/* <div className={classes.labelRowDocument1}>
                    <img width="13" height="13" src={props.user.identityValidationStatus === 'reviewNeeded' ? IconFailed : IconVerified} className={classes.actionButons} />
                    <Typography className={classes.columnText}>Validaciones de Mati</Typography>
                </div>
                <a href={props.user.identityUri} className={classes.previewText}>Ver en Mati</a> */}
            </>
        );
    }

    const checkboxChange = () =>{
        setLoading(true)
        try{
            API.put('profile-api', `/profile/${user.userRecordKey}`, {
                body: {
                    blockImmediatelyUpdateBalance: userCanUpdateBalance
                },
            })
            setUserCanUpdateBalance(!userCanUpdateBalance);
            setLoading(false)
            handleAlert('success', !userCanUpdateBalance? 'Recibir depósitos automáticos: Habilitado' : 'Recibir depósitos automáticos: Deshabilitado' )
        }
        catch(error){
            setLoading(false)
            handleAlert('error',error.message)
        }
    } 

    return (
        <div className={classes.root}>
            {isLoading ?
                <div className='gralinfo_form'>
                    <CircularProgress color="secondary" />
                </div>
                :
                <div className={classes.columnsContainer}>
                    <div className={classes.columnStyle}>
                        <Typography className={classes.columnTitle}>Perfil del Usuario</Typography>
                        <div className={classes.labelRow}>
                            <Typography className={classes.columnText}>Nombre: </Typography>
                            {user.identityWalletUser ?
                                <Typography className={classes.columnValueText}>{user.identityWalletUser.name}</Typography>   
                            :
                                <Typography className={classes.columnValueText}>{user.firstName} {user.lastName}</Typography>
                            }
                            
                        </div>
                        <div className={classes.labelRow}>
                            <Typography className={classes.columnText}>Nacionalidad: </Typography>
                            <Typography className={classes.columnValueText}>{user.identityWalletUser && user.identityWalletUser.nationality}</Typography>
                        </div>
                        <div className={classes.labelRow}>
                            <Typography className={classes.columnText}>Fecha de Nacimiento: </Typography>
                            <Typography className={classes.columnValueText}>{user.identityWalletUser && user.identityWalletUser.dateOfBirth}</Typography>
                        </div>
                        <div className={classes.labelRow}>
                            <Typography className={classes.columnText}>Género: </Typography>
                            <Typography className={classes.columnValueText}>{user.identityWalletUser && user.identityWalletUser.gender}</Typography>
                        </div>
                        <div className={classes.labelRow}>
                            <Typography className={classes.columnText}>CURP: </Typography>
                            <Typography className={classes.columnValueText}>{user.identityWalletUser && user.identityWalletUser.curp}</Typography>
                        </div>
                        <div className={classes.labelRow}>
                            <Typography className={classes.columnText}>Teléfono: </Typography>
                            <Typography className={classes.columnValueText}>{user.phoneNumber}</Typography>
                        </div>
                        <div className={classes.labelRow}>
                            <Typography className={classes.columnText}>Correo Electrónico: </Typography>
                            <Typography className={classes.columnValueText}>{user.email}</Typography>
                        </div>
                    </div>
                    <div className={classes.columnStyle}>
                        <div className={classes.columnStyle2}>
                            <Typography className={classes.columnTitle}>Validación de Mati</Typography>
                            {getIdentityStatus()}
                        </div>
                    </div>
                    <div className={classes.columnStyle}>
                        <div className={classes.columnStyle2}>
                            <Typography className={classes.columnTitle}>Conekta</Typography>
                            <div className={classes.labelRow}>
                                <Typography className={classes.columnValueText}></Typography>
                            </div>
                            <div className={`${classes.labelRow} checkboxLabelMargin`}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                        className={classes.columnText}
                                        checked={userCanUpdateBalance}
                                        onChange={checkboxChange}
                                        />
                                    }
                                    label={'Acreditación Inmediata: '}
                                    labelPlacement="start"
                                    className="checkboxLabel"
                                />
                            </div>
                        </div>
                        {/* <div className={classes.columnStyle2}>
                            <Typography className={classes.columnTitle}>Cuentas SPEI</Typography>
                        </div> */}
                    </div>
                </div>}
        </div>
    );
}

export default withSnackbar(Tab1)