import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme =>({
  container: {
    backgroundColor: (props) => ( props.color ||theme.palette.red.main),
    color: theme.palette.white.main,
    border: (props) => `3px solid ${( props.color ||theme.palette.red.main)}`,
    borderRadius: 10,
    paddingLeft: (props) => (props.paddingHorizontal || 10),
    paddingRight: (props) => (props.paddingHorizontal || 10),
    paddingTop: 5,
    paddingBottom: 5,
    marginLeft: (props) => (props.marginHorizontal || 5),
    marginRight: (props) => (props.marginHorizontal || 5),
    marginTop: (props) => (props.marginVertical || 5),
    marginBottom: (props) => (props.marginVertical || 5),
    width: (props) => (props.fullWidth ? '100%' : 'auto'),
    minWidth: props => props.minWidth || null,
    alignItems: (props) => props.alignItems || 'center', // align vertically
    boxShadow: theme.shadows[5],
    ...theme.typography.button,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

}));

const CancelButton = (props) => {
  const classes = useStyles(props);

  return (
    <>
      <button
        type={props.type || 'button'}
        onClick={props.onClick}
        className={`${props.primaryButtonStyle || null} ${classes.container}`}
        disabled={props.disabled}
      >
        <text>
        {props.children}
        </text>
      </button>
    </>
  );
};

export default CancelButton;
