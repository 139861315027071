import React, { useState } from 'react';
import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ThemeProvider, Typography } from '@material-ui/core';
import { API } from 'aws-amplify';
import Modal from '@material-ui/core/Modal';
import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import '../../../../assets/css/main.css';
import DefaultButton from '../../../common/defaultButton'
import PrimaryButton from '../../../common/primaryButton';
import IconDocument from '../../../../assets/images/iconDocument.png'
import IconFailed from '../../../../assets/images/iconFailed.png'
import IconPreview from '../../../../assets/images/iconPreview.png'
import IconVerified from '../../../../assets/images/iconVerified.png'
import { Link } from 'react-router-dom';
import DefaultCancelButton from '../../../common/defultCancelButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import checkIncompleteLegalDocs from '../../../../lib/checkIncompleteLegalDocs';
import { FONT_DEFAULT } from '../../../../lib/constants.js'
import theme from '../../../../styles/theme'

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  };
}

function Tab1(props) {
  useEffect(() => {
  }, [])

  const classes = useStyles();

  const [modalStyle] = useState(getModalStyle);
  const [isLoading, setLoading] = useState(false);
  const [legalPerson, setLegalPerson] = useState(props.merchant.businessConstitution);
  const [previewPdf, setPreviewPdf] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [open, setOpen] = useState(false);
  const [openComment, setOpenComment] = useState(null);
  const [comment, setComment] = useState('');
  const [openApproveIncomplete, setOpenApproveIncomplete] = useState(false);
  const [pendingDocuments, setPendingDocuments] = useState([]);
  const [acceptIncomplete, setAcceptIncomplete] = useState(false);
  const mapDocumentTitles = {
    'officialIdentification': 'Identificación Oficial',
    'proofOfAddress': 'Comprobante de Domicilio',
    'constitutiveAct': 'Acta Constitutiva',
    'powerLetter': 'Poderes del Representate',
    'IdentificacionOficial': 'Identificación Oficial',
    'ComprobanteDomicilio': 'Comprobante de Domicilio',
    'ActaConstitutiva': 'Acta Constitutiva',
    'CartaPoder': 'Poderes del Representate'
  }

  const documentStatus = {
    'APPROVED': 'Aprobado',
    'PENDING': 'Pendiente revisión',
    'REJECTED': 'Rechazado',
    'NOTSENT': 'Sin Enviar',
  }

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const getFile = async (document) => {
    try {
      const doc = await API.get('merchant-api', `/merchant/doc?merchantRecordKey=${props.merchant.merchantRecordKey}&documenttype=${document}`);
      if (doc.data !== undefined) {
        if (doc.data.type.indexOf('pdf') !== -1) {
          setPreviewImage(null)
          setPreviewPdf(doc.data.url)
          setOpen(true);
        } else {
          setPreviewPdf(null)
          setPreviewImage(doc.data.url)
          setOpen(true);
        }
      } else {
        handleAlert('error', 'Ocurrió un error al cargar el documento')
      }
    } catch (err) {
      handleAlert('error', 'Ocurrió un error al cargar el documento')
    }
  }

  const handleClose = () => {
    setPreviewPdf(null)
    setPreviewImage(null)
    setOpen(false);
    setOpenComment(null)
    setComment('')
  };

  const handleComment = (event) => {
    setComment(event.target.value)
  };

  const handleUpdateState = async (document, status) => {
    setLoading(true)
    if (document !== 'global') {
      let legalDocuments = [...props.merchant.legalDocuments]
      legalDocuments = legalDocuments.reverse()
      let found = false
      legalDocuments.forEach((ld) => {
        if (!found && ld.documenttype === document) {
          found = true
          ld.documentStatus = status
          if (status === 'REJECTED') {
            ld.adminNotes = comment
          };
        }
      })
      legalDocuments = legalDocuments.reverse()
      try {
        await API.post('merchant-api', '/merchant', {
          body: {
            merchantRecordKey: props.merchant.merchantRecordKey,
            legalDocuments
          },
        });
        setComment('')
        status !== 'REJECTED' && handleAlert('success', 'Actualizado con Éxito!')
        props.getuserprofile()
        setLoading(false)
      } catch (err) {
        setComment('')
        setLoading(false)
        handleAlert('error', err)
      }
    } else {
      let adminComment = ''
      if (status === 'REJECTED') {
        adminComment = comment
      };
      try {
        await API.post('merchant-api', '/merchant', {
          body: {
            merchantRecordKey: props.merchant.merchantRecordKey,
            merchantStatus: status.toLowerCase(),
            adminComment,
            showApproved: true
          },
        });
        setComment('')
        handleAlert('success', 'Actualizado con Éxito!')
        props.getuserprofile()
        setLoading(false)
      } catch (err) {
        setComment('')
        setLoading(false)
        handleAlert('error', err)
      }
    }
  };

  const handleAprove = (document) => {
    if (document !== 'global')
      handleUpdateState(document, 'APPROVED')
    else
      checkDocumentStatus(document)
  };

  const checkDocumentStatus = (document) => {
    const merchant = props.merchant;
    const incompleteLegalDocs = checkIncompleteLegalDocs(merchant, 'admin');
    if (incompleteLegalDocs.length > 0) {
      setPendingDocuments(incompleteLegalDocs);
      setOpenApproveIncomplete(true)
    } else
      handleUpdateState(document, 'APPROVED');
  }

  const handleReject = (document) => {
    if (comment === '') {
      setOpenComment(document)
    } else {
      setOpenComment(null)
      handleUpdateState(document, 'REJECTED')
      document !== 'global' && handleUpdateState('global', 'PROGRESS')
    }
  };

  const handleProgress = (document) => {
    handleUpdateState(document, 'PROGRESS')
  };

  const getIdentityStatus = () => {
    return (
      <>
        <div className={classes.labelRowDocument1}>
          <img width="13" height="13" src={props.merchant.identityFields.identityValidationStep === 'VALID' ? IconVerified : IconFailed} className={classes.actionButons} />
          <Typography className={classes.columnText}>Validación de Identidad</Typography>
        </div>
        <a href={props.merchant.identityFields.identityUri} target="_blank" className={classes.previewText}>Ver en Mati</a>

        <div className={classes.labelRowDocument1}>
          <img width="13" height="13" src={props.merchant.identityFields.identityValidationStatus === 'reviewNeeded' ? IconFailed : IconVerified} className={classes.actionButons} />
          <Typography className={classes.columnText}>Todas las validaciones de seguridad</Typography>
        </div>
        <a href={props.merchant.identityFields.identityUri} target="_blank" className={classes.previewText}>Ver en Mati</a>
      </>
    );
  }
  const buildDocumentComponents = (document) => {
    let legalDocuments = []
    if (props.merchant.legalDocuments !== undefined) {
      legalDocuments = [...props.merchant.legalDocuments]
    }
    legalDocuments = legalDocuments.reverse()
    let foundStatus = null
    legalDocuments.forEach((ld) => {
      if (foundStatus === null && ld.documenttype === document) {
        foundStatus = ld.documentStatus
      }
    })

    switch (foundStatus) {
      case 'REJECTED':
        return (
          <>
            <div className={classes.labelRowDocument1}>
              <img width="13" height="13" src={IconFailed} className={classes.actionButons} />
              <Typography className={classes.columnText}>{mapDocumentTitles[document]}</Typography>
            </div>
            <div className={classes.labelRowDocument2}>
              <img width="10" height="13" src={IconPreview} className={classes.actionButons} />
              <Typography className={classes.previewText} onClick={() => getFile(document)}>Ver Documento</Typography>
              <Typography className={classes.rejectText} onClick={() => handleReject(document)}>Rechazar</Typography>
              <Typography className={classes.separatorStyle}>|</Typography>
              <Typography className={classes.aceptText} onClick={() => handleAprove(document)}>Aceptar</Typography>
            </div>
          </>
        )
      case 'APPROVED':
        return (
          <>
            <div className={classes.labelRowDocument1}>
              <img width="13" height="13" src={IconVerified} className={classes.actionButons} />
              <Typography className={classes.columnText}>{mapDocumentTitles[document]}</Typography>
            </div>
            <div className={classes.labelRowDocument2}>
              <img width="10" height="13" src={IconPreview} className={classes.actionButons} />
              <Typography className={classes.previewText} onClick={() => getFile(document)}>Ver Documento</Typography>
              <Typography className={classes.rejectText} onClick={() => handleReject(document)}>Rechazar</Typography>
              <Typography className={classes.separatorStyle}>|</Typography>
              <Typography className={classes.aceptText} onClick={() => handleAprove(document)}>Aceptar</Typography>
            </div>
          </>
        )
      case 'PENDING':
        return (
          <>
            <div className={classes.labelRowDocument1}>
              <img width="10" height="13" src={IconDocument} className={classes.actionButons} />
              <Typography className={classes.columnText}>{mapDocumentTitles[document]}</Typography>
            </div>
            <div className={classes.labelRowDocument2}>
              <img width="10" height="13" src={IconPreview} className={classes.actionButons} />
              <Typography className={classes.previewText} onClick={() => getFile(document)}>Ver Documento</Typography>
              <Typography className={classes.rejectText} onClick={() => handleReject(document)}>Rechazar</Typography>
              <Typography className={classes.separatorStyle}>|</Typography>
              <Typography className={classes.aceptText} onClick={() => handleAprove(document)}>Aceptar</Typography>
            </div>
          </>
        )
      default:
        return (
          <>
            <div className={classes.labelRowDocument1}>
              <img width="10" height="13" src={IconDocument} className={classes.actionButons} />
              <Typography className={classes.columnText}>{mapDocumentTitles[document]}</Typography>
            </div>
          </>
        )
    }
  }

  const bodyComment = (
    <div style={modalStyle} className={classes.paperComment}>
      <Typography className={classes.titleCommentModal}>Razón de Rechazo</Typography>
      <Typography className={classes.textCommentModal}>Por favor escriba un comentario justificando la razón de rechazo del documento. Este comentario se enviará al cliente.</Typography>
      <TextareaAutosize aria-label="minimum height" className={classes.commentArea} rowsMin={5} rowsMax={5} value={comment} onChange={handleComment} />
      <div className={classes.sendContainer}>
        <DefaultButton handleAction={() => handleReject(openComment)} buttonText={"Enviar"} disabled={comment === ''} />
      </div>
    </div>
  )

  const approveModalBody = (
    <div className={`${classes.modalStyle} col-11 col-sm-9 col-md-7 col-lg-5 col-xl-4 bg-white rounded row p-3`}>
      <Typography className="col-12 text-center mt-5">Estas aceptando algo incompleto</Typography>
      <Typography className="col-12 text-center">
        ¿Deseas aceptar este comercio aunque tenga documentación inconclusa?
      </Typography>
      <div className="col-12 text-center mt-4">
        {pendingDocuments.map(doc =>
          <div className="col-12 mt-2 row align-items-center">
            <Typography className="col-6">{doc.docType}</Typography>
            <Typography className={`col-6 ${doc.status === 'REJECTED'
              ? classes.statusRejected
              : classes.statusDefault
              }`}>{documentStatus[doc.status]}</Typography>
          </div>
        )}
      </div>
      <div className="col-12 mt-4 ml-4">
        <FormControlLabel
          control={
            <Checkbox
              checked={acceptIncomplete}
              onChange={() => setAcceptIncomplete(!acceptIncomplete)}
              color="#6EB89D"
            />
          }
          label="Estoy seguro"
        />
      </div>
      <div className="col-6 text-center mt-1 mb-4">
        <DefaultCancelButton
          handleAction={() => setOpenApproveIncomplete(false)}
          override={{
            width: 120,
          }}
          buttonText={"Cancelar"}
        />
      </div>
      <div className="col-6 text-center mt-1 mb-4">
        <DefaultButton
          disabled={!acceptIncomplete}
          handleAction={() => handleUpdateState('global', 'APPROVED')}
          buttonText={"Aceptar"}
        />
      </div>
    </div>
  )

  const bodyPreview = (
    <div style={modalStyle} className={classes.paper}>
      {previewImage && <img src={previewImage} alt="preview" className={classes.previewPicture} />}
      {previewPdf &&
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
          <div style={{ height: '600px' }}>
            <Viewer fileUrl={previewPdf} />
          </div>
        </Worker>
      }
    </div>
  );

  const Buttons = () => {
    return (
      <div>
        <div className={classes.labelRowProfile}>
          <PrimaryButton color={theme.palette.red.main} onClick={() => handleReject('global')}>Rechazar</PrimaryButton>
          <PrimaryButton color={'#ADBBEE'} onClick={() => handleProgress('global')}>En proceso</PrimaryButton>
          <PrimaryButton color={theme.palette.primary.main} onClick={() => handleAprove('global')}>Aceptar</PrimaryButton>
          {/* <Typography className={classes.rejectText} onClick={() => handleReject('global')}>Rechazar</Typography>
          <Typography className={classes.separatorStyle}>|</Typography>
          <Typography className={classes.progressText} onClick={() => handleProgress('global')}>En proceso</Typography>
          <Typography className={classes.separatorStyle}>|</Typography>
          <Typography className={classes.aceptText} onClick={() => handleAprove('global')}>Aceptar</Typography> */}
        </div>
      </div>
    )
  }

  return (
    <div className={classes.root}>
      {isLoading ?
        <div className='gralinfo_form'>
          <CircularProgress color="secondary" />
        </div>
        :
        <>
          <div className={classes.columnsContainer}>
            {legalPerson === 'MORAL' &&
              <>
                <div className={classes.columnStyle}>
                  <Typography className={classes.columnTitle}>Información de la Empresa</Typography>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Denominación o Razón Social: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.businessName}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>RFC: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.RFC}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Nombre Comercial: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.businessCommercialName}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Número de Empleados: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.numberOfEmployees}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Giro Comercial: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.businessType}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Página Web: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.website}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Teléfono: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.phone}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Correo Electrónico: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.email}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Código Postal: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.zipCode}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Dirección Fiscal: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.legalAddress}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Ciudad: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.city}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Estado: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.stateName}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>País: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.countryName}</Typography>
                  </div>
                  {/* <div className={classes.labelRowProfile}>
                    <Typography className={classes.rejectText} onClick={() => handleReject('global')}>Rechazar</Typography>
                    <Typography className={classes.separatorStyle}>|</Typography>
                    <Typography className={classes.progressText} onClick={() => handleProgress('global')}>En proceso</Typography>
                    <Typography className={classes.separatorStyle}>|</Typography>
                    <Typography className={classes.aceptText} onClick={() => handleAprove('global')}>Aceptar</Typography>
                  </div> */}
                </div>
                <div className={classes.columnStyle}>
                  <Typography className={classes.columnTitle}>Representante Legal</Typography>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Nombre: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.legalRepresentativeName}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Apellido Paterno: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.legalRepresentativeLastName}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Apellido Materno: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.legalRepresentativeSurName}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Fecha de Nacimiento: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.legalRepresentativeBirthdate}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Género: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.legalRepresentativeGender}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Nacionalidad: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.legalRepresentativeNationality}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>CURP: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.legalRepresentativeCURP}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>RFC: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.legalRepresentativeRFC}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Cargo en la Empresa: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.legalRepresentativeRole}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Teléfon: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.legalRepresentativeLandPhone}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Teléfono Celular: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.legalRepresentativeCellPhone}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Correo Electrónico: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.legalRepresentativeEmail}</Typography>
                  </div>
                </div>
                <div className={classes.columnStyle}>
                  <div className={classes.columnStyle2}>
                    <Typography className={classes.columnTitle}>Identidad</Typography>
                    {getIdentityStatus()}
                  </div>
                  <div className={classes.columnStyle2}>
                    <Typography className={`${classes.columnTitle} mt-4`}>Documentación</Typography>
                    {buildDocumentComponents('ComprobanteDomicilio')}
                    {buildDocumentComponents('IdentificacionOficial')}
                    {buildDocumentComponents('ActaConstitutiva')}
                    {buildDocumentComponents('CartaPoder')}
                  </div>
                </div>
              </>
            }
            {legalPerson === 'FISICA' &&
              <>
                <div className={classes.columnStyle}>
                  <Typography className={classes.columnTitle}>Información de la Fiscal</Typography>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Nombre Comercial: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.businessCommercialName}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Número de Empleados: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.numberOfEmployees}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Giro Comercial: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.businessType}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Dirección Fiscal: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.legalAddress}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Ciudad: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.city}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Nombre(s): </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.legalRepresentativeName}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Apellido(s): </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.legalRepresentativeLastName}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>CURP: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.legalRepresentativeCURP}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Fecha de Nacimiento: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.legalRepresentativeNationality}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Nacionalidad: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.legalRepresentativeRFC}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>RFC: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.legalRepresentativeRFC}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Correo Electrónico: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.legalRepresentativeEmail}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Celular: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.legalRepresentativeCellPhone}</Typography>
                  </div>
                  <div className={classes.labelRow}>
                    <Typography className={classes.columnText}>Código Postal: </Typography>
                    <Typography className={classes.columnValueText}>{props.merchant.zipCode}</Typography>
                  </div>
                  {/* <div className={classes.labelRowProfile}>
                    <Typography className={classes.rejectText} onClick={() => handleReject('global')}>Rechazar</Typography>
                    <Typography className={classes.separatorStyle}>|</Typography>
                    <Typography className={classes.progressText} onClick={() => handleProgress('global')}>En proceso</Typography>
                    <Typography className={classes.separatorStyle}>|</Typography>
                    <Typography className={classes.aceptText} onClick={() => handleAprove('global')}>Aceptar</Typography>
                  </div> */}
                </div>
                <div className={classes.columnStyle2}>
                  <div className={classes.columnStyle2}>
                    <Typography className={classes.columnTitle}>Identidad</Typography>
                    {getIdentityStatus()}
                  </div>
                  <div className={classes.columnStyle2}>
                    <Typography className={classes.columnTitle}>Documentación</Typography>
                    {buildDocumentComponents('ComprobanteDomicilio')}
                    {buildDocumentComponents('IdentificacionOficial')}
                  </div>
                </div>
              </>
            }
          </div>
          <div className={classes.buttonsContainer}>
            <Buttons />
          </div>
        </>}
      <Modal
        open={open | openComment !== null}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {open ? bodyPreview : bodyComment}
      </Modal>
      <Modal
        open={openApproveIncomplete}
        onClose={() => setOpenApproveIncomplete(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {approveModalBody}
      </Modal>
    </div>
  );
}

export default withSnackbar(Tab1)

const useStyles = makeStyles({
  root: {
    flex: 1,
    // width: '90%'
  },
  columnsContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  columnStyle: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  columnStyle2: {
    display: 'flex',
    flex: 2,
    flexDirection: 'column'
  },
  columnTitle: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 16,
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    color: '#52A386',
  },
  columnText: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 12,
    marginBottom: 5
  },
  columnValueText: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 12,
    marginLeft: 7,
    marginBottom: 5
  },
  labelRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  labelRowDocument1: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20
  },
  labelRowDocument2: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 20
  },
  labelRowProfile: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20
  },
  actionButons: {
    marginRight: 5
  },
  previewText: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 12,
    color: '#6B7899',
    cursor: 'pointer'
  },
  rejectText: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 12,
    color: '#DA7B7B',
    marginRight: 5,
    marginLeft: 10,
    cursor: 'pointer'
  },
  aceptText: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 12,
    color: '#BDBDBD',
    marginLeft: 5,
    cursor: 'pointer'
  },
  progressText: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 12,
    color: '#ADBBEE',
    marginLeft: 5,
    marginRight: 5,
    cursor: 'pointer'
  },
  separatorStyle: {
    color: '#BDBDBD'
  },
  paper: {
    position: 'absolute',
    backgroundColor: '#FAFAFA',
    boxShadow: '0 4 14 rgba(0, 0, 0, 0.15)',
    borderRadius: 19,
    textAlign: 'center'
  },
  paperComment: {
    width: 407,
    height: 250,
    position: 'absolute',
    backgroundColor: '#FAFAFA',
    boxShadow: '0 4 14 rgba(0, 0, 0, 0.15)',
    borderRadius: 19,
    textAlign: 'center'
  },
  previewPicture: {
    maxWidth: 600,
    maxHeight: 600
  },
  commentArea: {
    width: 356,
    maxWidth: 356,
    minWidth: 356,
    maxHeight: 81,
    minHeight: 81,
    marginTop: 10
  },
  sendContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 10,
    marginRight: 20
  },
  titleCommentModal: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 16,
    textAlign: 'center',
    color: '#333333',
    marginTop: 10
  },
  textCommentModal: {
    ...FONT_DEFAULT,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    display: 'flex',
    alignItems: 'left',
    color: '#828282',
    marginTop: 10,
  },
  modalStyle: {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
  statusRejected: {
    ...FONT_DEFAULT,
    color: 'white',
    fontWeight: '900',
    fontSize: '12px',
    textAlign: 'center',
    padding: '3px 0px',
    borderRadius: 3,
    backgroundColor: '#EE715B',
  },
  statusDefault: {
    ...FONT_DEFAULT,
    color: 'white',
    fontWeight: '900',
    fontSize: '12px',
    textAlign: 'center',
    padding: '3px 0px',
    borderRadius: 3,
    backgroundColor: '#F2C94C',
  },
  buttonsContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end'
  }
});