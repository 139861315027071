import React, { useState, useEffect, useRef, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '../../assets/css/main.css';
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup
} from '@material-ui/core';
import PrimaryInput from '../common/primaryInput';
import PrimaryButton from '../common/primaryButton/index.js';
import SecondaryButton from '../common/secondaryButton/index.js';
import CheckValue from '../../lib/formatValidations';
import { Auth, API, Storage } from 'aws-amplify';
import { useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withSnackbar } from 'notistack';
import * as R from 'ramda';
import { API_PROFILE, API_STORE, categories } from '../../lib/constants';
import {
  StoresMap,
  getLocData,
  onDragEnd,
  createMapSnapshot,
} from '../common/storesMap';
import DefaultSchedule from '../common/storeSchedule';
import FileInput from '../common/fileInput';
import Autocomplete from 'react-google-autocomplete';
import Typography from '@material-ui/core/Typography';
import ClabeValidator from '../../lib/clabeValidator';
import PrimarySelect from '../common/primarySelect';
import noStore from '../../assets/images/noStore.svg';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const useStyles = makeStyles(theme => ({
  root: { width: 'auto', display: 'row' },
  container: {
    width: 'auto',
    display: 'flex',
    maxHeight: '90vh',
    overflow: 'scroll',
    flexDirection: 'column',
  },
  loadingContainer: {
    width: 'auto',
    display: 'flex',
    maxHeight: '90vh',
    flexDirection: 'column',
  },
  title: {
    textAlign: 'center',
    ...theme.typography.title,
  },
  imageAndFormContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  imageAndButton: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  methodContainer: {
    display: 'flex',
    flexDirection: 'column',
    //alignItems: 'center'
  },
  categoriesContainer: {
    display: 'grid',
    gridTemplateColumns: "repeat(3, 1fr)",
    margin: 10,
  },
  imageContainer: {
    ...theme.globals.centerVertical,
    backgroundColor: '#EDEDED',
    borderRadius: 10,
    width: 170,
    height: 170,
    margin: 10,
  },
  image: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: 170,
    height: 170,
    borderRadius: 10,
  },
  noImage: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: 60,
    height: 60,
    borderRadius: 10,
  },
  checkboxText: {
    ...theme.typography.checkbox,
  },
  buttons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'center',
    },
  },
  scheduleList: {
    maxHeight: 220,
  },
  plusScheduleButton: {
    ...theme.typography.mediumText,
    fontWeight: '700',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    paddingLeft: 10,
    fontSize: 12,
  },
  loading: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonStyle: {
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  autonomousContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  autonomousInfoContainer: {
    width: '100%',
  },
  autonomousTitle: {
    ...theme.typography.mediumText,
    fontWeight: '900',
    textAlign: 'center'
  },
  warning: {
    ...theme.typography.mediumText,
    fontWeight: '900',
    textAlign: 'center',
    color: 'red'
  },
  searchAddress: {
    ...theme.typography.mediumText,
    fontWeight: '700',
    color: '#434343',
    cursor: 'pointer',
    paddingLeft: 10,
    fontSize: 16,
  },
  addressTitle: {
    ...theme.typography.title,
    color: '#434343',
    cursor: 'pointer',
    paddingLeft: 10,
    textAlign: 'center',
    fontSize: 20,
  },
  map: {
    marginTop: 0,
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'center',
      alignItems: 'center'
    },
  },
  label: {
    ...theme.typography.mediumText,
  },
  greenlabel: {
    ...theme.typography.mediumText,
    color: theme.palette.primary.main,
    fontSize: 10,
  },
  hidden: {
    visibility: 'false',
  },
  cropContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      alignItems: 'center'
    },
  },
  radioButton: {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
  }
}));

const ModalNewStore = (props) => {
  const userKey = useSelector(state => state.user.value);
  const settings = useSelector(state => state.settings.value);

  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: '%',
    width: 50,
    height: 50,
    x: 25,
    y: 25,
    aspect: 1 / 1
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [showCrop, setShowCrop] = useState(false);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const cropImage = (e) => {
    setShowCrop(true)
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  function generateDownload(canvas, crop) {
    if (!crop || !canvas) {
      return;
    }
    canvas.toBlob(
      (blob) => {
        const previewUrl = window.URL.createObjectURL(blob);
        setFile({
          previewImage: URL.createObjectURL(blob),
          data: blob,
        });
        setHasUploaded(true);
        setShowCrop(false);
      },
      'image/png',
      1
    );
  }

  const {
    service_invoice_post_pay,
    service_edit_inshop_actions,
    service_payment_methods_store, 
    service_nerito_pay,
  } = settings || {};

  const [isLoading, setLoading] = useState(false);
  const [storeName, setStoreName] = useState(
    props.edit ? props.currentStore.storeName : ''
  );
  const [fiscalAddress, setFiscalAddress] = useState(
    props.edit ? props.currentStore.address : ''
  );
  const [telephoneNumber, setTelephoneNumber] = useState(
    props.edit ? props.currentStore.phone : ''
  );
  const [hasAvatar, setHasAvatar] = useState(props.edit ? props.currentStore.hasAvatar : false);
  const [hasUploaded, setHasUploaded] = useState(false);
  const [shortAddress, setShortAddress] = useState(props.edit ? props.currentStore.shortAddress : '');
  const [zipCode, setZipCode] = useState(props.edit ? props.currentStore.zipCode : '');
  const [neighborhood, setNeighborhood] = useState(props.edit ? props.currentStore.neighborhood : '');
  const [city, setCity] = useState(props.edit ? props.currentStore.city : '');
  const [stateLoc, setStateLoc] = useState(props.edit ? props.currentStore.stateName : '');
  const [country, setCountry] = useState(props.edit ? props.currentStore.country : '');
  const [autonomous, setAutonomous] = useState(props.edit ? props.currentStore.autonomous : false);
  const [contactName, setContactName] = useState(props.edit ? props.currentStore.contactName : '');
  const [contactPhoneNumber, setContactPhoneNumber] = useState(props.edit ? props.currentStore.contactPhone : '');
  const [contactEmail, setContactEmail] = useState(props.edit ? props.currentStore.contactEmail : '');
  const businessTypes = [{ label: 'Régimen Fiscal', value: '' }, { label: 'Moral', value: 'Moral' }, { label: 'Física', value: 'Física' }];
  const [businessConstitution, setBusinessConstitution] = useState(props.edit ? props.currentStore.businessConstitution : '');
  const [rfc, setRfc] = useState(props.edit ? props.currentStore.rfc : '');
  const [accountNumber, setAccountNumber] = useState(props.edit ? props.currentStore.accountNumber : '');
  const [pickup, setPickup] = useState(props.edit ? props.currentStore.pickup : true);
  const [delivery, setDelivery] = useState(props.edit ? props.currentStore.delivery : false);
  const [prepaid, setPrepaid] = useState(props.edit ? props.currentStore.prepaid : false);
  const [paymentByDelivery, setPaymentByDelivery] = useState(props.edit ? props.currentStore.paidDelivery : false);
  const [cashWithDrawal, setCashWithDrawal] = useState(props.edit ? props.currentStore.cashWithDrawal : false)
  const [cashDeposit, setCashDeposit] = useState(props.edit ? props.currentStore.cashDeposit : false)


  const [file, setFile] = useState(
    props.edit
      ? props.file
      : { previewImage: '', data: '' }
  );
  const [schedules, setSchedules] = useState(
    props.edit
      ? props.currentStore.schedules
      : [{ days: [], start: '', end: '' }]
  );
  const [moreSchedules, setMoreSchedules] = useState(true);

  /*Errors*/
  const [storeNameError, setStoreNameError] = useState('');
  const [fiscalAddressError, setFiscalAddressError] = useState('');
  const [telephoneNumberError, setTelephoneNumberError] = useState('');
  const [contactNameError, setContactNameError] = useState('');
  const [contactPhoneNumberError, setContactPhoneNumberError] = useState('');
  const [contactEmailError, setContactEmailError] = useState('');
  const [businessConstitutionError, setBusinessConstitutionError] = useState('');
  const [rfcError, setRfcError] = useState('');
  const [accountNumberError, setAccountNumberError] = useState('');
  const [scheduleFromError, setScheduleFromError] = useState([]);
  const [scheduleToError, setScheduleToError] = useState([]);

  const [mapPosition, setMapPosition] = useState(props.edit && props.currentStore.latitude ? {
    lat: props.currentStore.latitude,
    lng: props.currentStore.longitude,
  } : {
    lat: 29.0892,
    lng: -110.96129,
  });
  const [markerPosition, setMarkerPosition] = useState(props.edit && props.currentStore.latitude ? {
    lat: props.currentStore.latitude,
    lng: props.currentStore.longitude,
  } : {
    lat: 29.0892,
    lng: -110.96129,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'storeName':
        setStoreName(value);
        break;
      case 'fiscalAddress':
        setFiscalAddress(value);
        break;
      case 'telephoneNumber':
        setTelephoneNumber(value);
        break;
      case 'pickup':
        setPickup(!pickup);
        break;
      case 'delivery':
        setDelivery(!delivery);
        break;
      case 'autonomous':
        setAutonomous(!autonomous);
        break;
      case 'contactName':
        setContactName(value);
        break;
      case 'contactPhoneNumber':
        setContactPhoneNumber(value);
        break;
      case 'contactEmail':
        setContactEmail(value);
        break;
      case 'businessConstitution':
        setBusinessConstitution(value);
        break;
      case 'rfc':
        setRfc(value);
        break;
      case 'accountNumber':
        setAccountNumber(value);
        break;
      case 'prepaid':
        setPrepaid(!prepaid);
        break;
      case 'paymentByDelivery':
        setPaymentByDelivery(!paymentByDelivery);
        break;
      case 'cashWithdrawal':
        setCashWithDrawal(value === "true" ? true : false);
        break;
      case 'cashDeposit':
        setCashDeposit(value === "true" ? true : false);
        break;
      default:
        break;
    }
  };

  const cleanError = () => {
    setStoreNameError('');
    setFiscalAddressError('');
    setTelephoneNumberError('');
    setScheduleToError([]);
    setScheduleFromError([]);
    setContactEmailError('');
    setContactNameError('');
    setContactPhoneNumberError('');
    setBusinessConstitutionError('');
    setAccountNumberError('');
    setRfcError('');
  };

  const cleanForm = () => {
    setStoreName('');
    setFiscalAddress('');
    setTelephoneNumber('');
    setSchedules([{ days: [], start: '', end: '' }]);
    setMoreSchedules(true);
    setCity('');
    setStateLoc('');
    setCountry('');
    setMapPosition({ lat: 29.0892, lng: -110.96129 });
    setMarkerPosition({ lat: 29.0892, lng: -110.96129 });
    setContactEmail('');
    setContactName('');
    setContactPhoneNumber('');
    setBusinessConstitution('');
    setAccountNumber('');
    setRfc('');
    setPrepaid(false)
    setPaymentByDelivery(false)
  };

  const handleAlert = (variant, message) => {
    props.enqueueSnackbar(message, { variant });
  };

  const updateSchedule = (type, value, position) => {
    const newSchedules = [...schedules];
    if (type === 'days') {
      const dayPosition = newSchedules[position][type].indexOf(value);
      if (dayPosition !== -1) {
        newSchedules[position][type].splice(dayPosition, 1);
      } else {
        newSchedules[position][type].push(value);
      }
      let usedDays = [];
      newSchedules.forEach((element) => {
        usedDays = usedDays.concat(element.days);
      });
      const finalUsedDays = R.uniq(usedDays);
      setMoreSchedules(finalUsedDays.length < 7);
    } else {
      newSchedules[position][type] = value;
    }
    setSchedules(newSchedules);
  };

  const createNewStore = async () => {
    cleanError();
    let hasErrors = false;
    if (!CheckValue('empty', storeName)) {
      setStoreNameError('Ingrese el nombre de la sucursal');
      hasErrors = true;
    }
    if (!CheckValue('empty', city)) {
      handleAlert(
        'warning',
        'Por favor selecciona una dirección válida en el mapa.'
      );
      hasErrors = true;
    }
    if (!CheckValue('phone', `+52${telephoneNumber}`)) {
      setTelephoneNumberError('Introduzca su número telefónico a 10 dígitos');
      hasErrors = true;
    }
    if (autonomous && !service_invoice_post_pay) {
      if (!CheckValue('empty', contactName)) {
        setContactNameError('Ingrese el nombre del contacto');
        hasErrors = true;
      }
      if (!CheckValue('phone', `+52${contactPhoneNumber}`)) {
        setContactPhoneNumberError('Introduzca su número telefónico a 10 dígitos');
        hasErrors = true;
      }
      if (!CheckValue('email', contactEmail)) {
        setContactEmailError('Ingrese el correo electrónicos con el formato correct');
        hasErrors = true;
      }
      if (!CheckValue('empty', businessConstitution)) {
        setBusinessConstitutionError('Ingrese el regimen fiscal');
        hasErrors = true;
      }
      if (!CheckValue('rfc', rfc)) {
        setRfcError('Ingrese el RFC');
        hasErrors = true;
      }
      if (ClabeValidator(accountNumber)) {
        setAccountNumberError(ClabeValidator(accountNumber));
        hasErrors = true;
      }
    }
    const newScheduleFromError = [];
    const newScheduleToError = [];
    schedules.forEach((schedule) => {
      if (schedule['days'].length === 0) {
        newScheduleFromError.push('');
        setScheduleFromError(newScheduleFromError);
        handleAlert(
          'warning',
          'Todos los horarios deben tener por lo menos un día activo, por favor actualice los horarios o elimine los no necesarios.'
        );
        hasErrors = true;
      } else if (!CheckValue('hour', schedule['start'])) {
        newScheduleFromError.push('Ingrese el horario con formato 24hrs');
        setScheduleFromError(newScheduleFromError);
        hasErrors = true;
      } else if (!CheckValue('hour', schedule['end'])) {
        newScheduleToError.push('Ingrese el horario con formato 24hrs');
        setScheduleToError(newScheduleToError);
        hasErrors = true;
      } else {
        newScheduleFromError.push('');
        setScheduleFromError(newScheduleFromError);
      }
    });

    if (!hasErrors) {
      setLoading(true);
      const getLocality = await API.get('locations-api', '/locations/locality', {
        queryStringParameters: {
          zipCode: zipCode,
          neighborhood: neighborhood,
          city: city,
          state: stateLoc,
        }
      });
      const loc = getLocality['data'];

      const getUser = await Auth.currentAuthenticatedUser();
      if (props.edit) {
        try {
          const body = {
            merchant: props.currentStore.merchant,
            createdat: props.currentStore.createdat,
            storeName: storeName,
            address: fiscalAddress,
            shortAddress: shortAddress,
            phone: telephoneNumber,
            schedules,
            longitude: mapPosition === null ? undefined : mapPosition.lng,
            latitude: mapPosition === null ? undefined : mapPosition.lat,
            zipCode: zipCode,
            city: city,
            stateName: stateLoc,
            country: country,
            locality: loc,
            hasAvatar: typeof file.data == 'object' || hasAvatar === true ? true : false,
            autonomous: autonomous,
            pickup: pickup,
            delivery: delivery,
            prepaid: prepaid,
            paidDelivery: paymentByDelivery,
            cashWithDrawal: cashWithDrawal,
            cashDeposit: cashDeposit,
            updateProfile: true,
          }
          if (autonomous && !service_invoice_post_pay) {
            body['contactName'] = contactName;
            body['contactPhone'] = contactPhoneNumber;
            body['contactEmail'] = contactEmail;
            body['businessConstitution'] = businessConstitution;
            body['rfc'] = rfc;
            body['accountNumber'] = accountNumber;
          }

          const CREATE_STORE = await API.put(
            API_STORE,
            `/store/${props.storeId}`,
            {
              body,
            }
          );
          const pic = createMapSnapshot(props.storeId, mapPosition);
          if (typeof file.data == 'object') {
            const config = {
              contentType: file.data.type,
              level: 'public',
            };
            const filePUT = await Storage.put(
              `store/${CREATE_STORE.data.id}/default.jpg`,
              file.data,
              config
            );
          }
          setFile({
            previewImage: '',
            data: '',
          });
          setLoading(false);
          props.handleClose()
          handleAlert('success', 'Registro Finalizado');
        } catch (err) {
          setLoading(false);
          handleAlert('error', err);
        }
      } else {
        try {
          const getGralProfile = await API.get(
            API_PROFILE,
            `/profile/${userKey}`
          );
          const merchant = getGralProfile.data[0].merchantRecordKey;

          const body = {
            merchant: merchant,
            store: {
              storeName: storeName,
              orderNo: 0,
              address: fiscalAddress,
              shortAddress: shortAddress,
              phone: telephoneNumber,
              schedules,
              longitude: mapPosition.lng,
              latitude: mapPosition.lat,
              zipCode: zipCode,
              locality: loc,
              city: city,
              stateName: stateLoc,
              country: country,
              hasAvatar: typeof file.data == 'object' && true,
              published: false,
              autonomous: autonomous,
              pickup: pickup,
              delivery: delivery,
              prepaid: prepaid,
              paidDelivery: paymentByDelivery,
              cashWithDrawal: cashWithDrawal,
              cashDeposit: cashDeposit,
            },
          }
          if (autonomous && !service_invoice_post_pay) {
            body['store']['contactName'] = contactName;
            body['store']['contactPhone'] = contactPhoneNumber;
            body['store']['contactEmail'] = contactEmail;
            body['store']['businessConstitution'] = businessConstitution;
            body['store']['rfc'] = rfc;
            body['store']['accountNumber'] = accountNumber;
          }
          const CREATE_STORE = await API.post(API_STORE, '/store', {
            body,
          });

          const pic = createMapSnapshot(CREATE_STORE.data.id, mapPosition);
          const config = {
            contentType: file.data.type,
            level: 'public',
          };
          if (typeof file.data == 'object') {
            const filePUT = await Storage.put(
              `store/${CREATE_STORE.data.id}/default.jpg`,
              file.data,
              config
            );
          }
          setFile({
            previewImage: '',
            data: '',
          });
          cleanForm();
          setLoading(false);
          handleAlert('success', 'Registro Finalizado');
        } catch (err) {
          setLoading(false);
          handleAlert('error', err);
        }
      }
    }
  };

  const markerMoved = async (event) => {
    const place = await onDragEnd(event);
    if (place) {
      onPlaceSelected(place);
    }
  };

  const handleCityStateChange = (locData) => {
    const {
      address,
      shortAddress,
      zipCode,
      neighborhood,
      city,
      state,
      country,
    } = locData;

    setShortAddress(shortAddress ? shortAddress : '');
    setZipCode(zipCode ? zipCode : '');
    setNeighborhood(neighborhood ? neighborhood : '');
    setCity(city)
    setStateLoc(getStateName(state));
    setCountry(country);

    API.get(API_STORE, `/store/border?city=${city}&state=${getStateName(state)}`).then(
      (resp) => {
        if (resp && resp.data) {
          const msg =
            '*La ciudad seleccionada se encuentra en frontera. Nerito se apegará a los lineamientos de Estímulos fiscales para la región fronteriza norte sobre el Impuesto al Valor Agregado.';
          handleAlert('info', msg);
        }
      }
    );
  };

  const getStateName = (state) => {
    switch (state) {
      case 'Michoacán':
        return 'Michoacán de Ocampo';
      case 'Veracruz':
        return 'Veracruz de Ignacio de la Llave';
      default:
        return state;
    }
  }

  const onPlaceSelected = (place) => {
    if (place.address_components === undefined) {
      return;
    }

    const locData = getLocData(place);

    setFiscalAddress(locData.address);
    /** perdon por ponerlo así, el componente no me permite vincularlo de otra forma. */
    let e = document.getElementById('gAutoC');
    e.value = locData.address;
    /** */
    setMapPosition({ lat: locData.lat, lng: locData.lng });
    setMarkerPosition({ lat: locData.lat, lng: locData.lng });
    handleCityStateChange(locData);
  };

  const updateShedulesElements = (isAdd, index = 0) => {
    const newSchedules = [...schedules];
    if (isAdd) {
      newSchedules.push({ days: [], start: '', end: '' });
      setSchedules(newSchedules);
    } else {
      newSchedules.splice(index, 1);
      setSchedules(newSchedules);
    }
  };

  const classes = useStyles();
  return (
    <>
      {isLoading ? (
        <div className={`${classes.loadingContainer}`}>
          <div className={`${classes.loading} `}>
            <CircularProgress color='secondary' />
          </div>
        </div>
      ) :
        (
          <div className={`${classes.container} scrollBarHidden`}>
            <h1 className={classes.title}>{props.edit ? "Editar Sucursal" : "Nueva Sucursal"}</h1>
            {showCrop ? (
              <div className={classes.cropContainer}>
                <ReactCrop
                  src={upImg}
                  onImageLoaded={onLoad}
                  crop={crop}
                  onChange={(c) => setCrop(c)}
                  onComplete={(c) => setCompletedCrop(c)}
                  style={{ maxWidth: 500, height: 'auto' }}
                />
                <div className={classes.hidden}>
                  <canvas
                    ref={previewCanvasRef}
                    // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                    style={{
                      width: 0,
                      height: 0,
                    }}
                  />
                </div>
                <PrimaryButton
                  type='button'
                  paddingHorizontal={25}
                  onClick={() => generateDownload(previewCanvasRef.current, completedCrop)}
                  primaryButtonStyle={classes.buttonStyle}
                >
                  Guardar Imagen
                </PrimaryButton>
              </div>
            ) : (
              <div>
                <div className={classes.imageAndFormContainer}>
                  <Grid container>
                    <Grid item xs={12} sm={8}>
                      <div style={{ marginRight: 10, marginLeft: -5, }}>
                        <PrimaryInput
                          value={storeName}
                          type='text'
                          placeholder='Nombre de Sucursal'
                          marginVertical={10}
                          autoFocus
                          name='storeName'
                          onChange={(e) => handleChange(e)}
                          error={storeNameError}
                        />
                        <PrimaryInput
                          value={telephoneNumber}
                          type='text'
                          placeholder='Teléfono'
                          marginVertical={10}
                          name='telephoneNumber'
                          onChange={(e) => handleChange(e)}
                          error={telephoneNumberError}
                        />
                      </div>
                      <div className={`scrollBarHidden ${classes.scheduleList}`}>
                        {schedules.map((schedule, index) => (
                          <DefaultSchedule
                            allDays={schedules}
                            data={schedule}
                            position={index}
                            remove={updateShedulesElements}
                            updateSchedule={updateSchedule}
                            scheduleFromError={scheduleFromError}
                            scheduleToError={scheduleToError}
                            inModal={true}
                          />
                        ))}
                        {moreSchedules && (
                          <Typography
                            className={classes.plusScheduleButton}
                            onClick={() => updateShedulesElements(true)}
                          >
                            + Agregar otro horario
                          </Typography>
                        )}
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <div className={classes.imageAndButton}>
                        <div className={classes.imageContainer}>
                          {hasAvatar || hasUploaded ?
                            <img
                              src={file.previewImage}
                              className={classes.image}
                            />
                            :
                            <>
                              <img
                                src={noStore}
                                className={classes.noImage}
                              />
                              <Typography className={classes.label}>Subir una imagen</Typography>
                              <Typography className={classes.greenlabel}>(Recomendado 720x720 px)</Typography>
                            </>
                          }
                        </div>
                        <FileInput placeholder='Subir imagen' onChange={cropImage} accept='image/png,image/jpg,image/jpeg' />
                      </div>
                    </Grid>
                  </Grid>
                </div>

                <div className={classes.imageAndFormContainer}>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      <div className={classes.methodContainer}>
                        <Typography className={classes.addressTitle}>Tipos de pedidos</Typography>
                        <FormControlLabel
                          control={<Checkbox checked={pickup} onChange={handleChange} name="pickup" />}
                          label="Recoger en Tienda"
                        />
                        <FormControlLabel
                          control={<Checkbox checked={delivery} onChange={handleChange} name="delivery" />}
                          label="Entrega a Domicilio"
                        />
                      </div>
                    </Grid>
                    {service_payment_methods_store && <Grid item xs={12} sm={4}>
                      <div className={classes.methodContainer}>
                        <Typography className={classes.addressTitle}>Método de Pago</Typography>
                        <FormControlLabel
                          control={<Checkbox checked={prepaid} onChange={handleChange} name="prepaid" />}
                          label="Pago con Tarjeta"
                        />
                        <FormControlLabel
                          control={<Checkbox checked={paymentByDelivery} onChange={handleChange} name="paymentByDelivery" />}
                          label="Contra entrega"
                        />
                      </div>
                    </Grid>}
                  </Grid>
                </div>

                {(service_edit_inshop_actions || service_nerito_pay) && <>
                  <div>
                    <Typography className={classes.addressTitle}>Retiro de Efectivo</Typography>
                    <Typography>Permitir el retiro de efectivo a sus clientes en esta sucursal</Typography>
                    <RadioGroup row name="cashWithdrawal" value={cashWithDrawal === true && 'true' || cashWithDrawal === false && 'false'} onChange={handleChange}>
                      <FormControlLabel value="true" control={<Radio className={classes.radioButton} />} label="Si" />
                      <FormControlLabel value="false" control={<Radio className={classes.radioButton} />} label="No" />
                    </RadioGroup>
                  </div>

                  <div>
                    <Typography className={classes.addressTitle}>Depósito de Efectivo</Typography>
                    <Typography>Permitir el depósito de efectivo a sus clientes en esta sucursal</Typography>
                    <RadioGroup row name="cashDeposit" value={cashDeposit === true && 'true' || cashDeposit === false && 'false'} onChange={handleChange}>
                      <FormControlLabel value="true" control={<Radio className={classes.radioButton} />} label="Si" />
                      <FormControlLabel value="false" control={<Radio className={classes.radioButton} />} label="No" />
                    </RadioGroup>

                  </div>
                </>}
                <div>
                  {!service_invoice_post_pay && (
                    <FormControlLabel
                      control={<Checkbox checked={autonomous} onChange={handleChange} name="autonomous" />}
                      label="Sucursal Autónoma"
                    />
                  )}
                  {(autonomous && !service_invoice_post_pay) &&
                    <div className={classes.autonomousContainer}>
                      <div className={classes.autonomousInfoContainer}>
                        <Typography className={classes.autonomousTitle}>Contacto</Typography>
                        <PrimaryInput
                          value={contactName}
                          type='text'
                          placeholder='Nombre de Contacto'
                          marginVertical={10}
                          name='contactName'
                          onChange={(e) => handleChange(e)}
                          error={contactNameError}
                        />
                        <PrimaryInput
                          value={contactPhoneNumber}
                          type='text'
                          placeholder='Teléfono de Contacto'
                          marginVertical={10}
                          name='contactPhoneNumber'
                          onChange={(e) => handleChange(e)}
                          error={contactPhoneNumberError}
                        />
                        <PrimaryInput
                          value={contactEmail}
                          type='text'
                          placeholder='Correo de Contacto'
                          marginVertical={10}
                          name='contactEmail'
                          onChange={(e) => handleChange(e)}
                          error={contactEmailError}
                        />
                      </div>
                      <div className={classes.autonomousInfoContainer}>
                        <Typography className={classes.autonomousTitle}>Empresa</Typography>
                        <PrimarySelect
                          value={businessConstitution}
                          onChange={(e) => handleChange(e)}
                          name='businessConstitution'
                          content={businessTypes.map((item) => {
                            return <option value={item.value}>{item.label}</option>;
                          })}
                          error={businessConstitutionError}
                          fullWidth
                          marginVertical={10}
                        />
                        <PrimaryInput
                          value={rfc}
                          type='text'
                          placeholder='RFC'
                          marginVertical={10}
                          name='rfc'
                          onChange={(e) => handleChange(e)}
                          error={rfcError}
                        />
                        <PrimaryInput
                          value={accountNumber}
                          type='text'
                          placeholder='Cuenta Clabe'
                          marginVertical={10}
                          name='accountNumber'
                          onChange={(e) => handleChange(e)}
                          error={accountNumberError}
                        />
                      </div>
                    </div>
                  }
                </div>
                <div className={classes.methodContainer}>
                  <Typography className={classes.addressTitle}>Categorías</Typography>
                  <Typography className={classes.plusScheduleButton}>
                    Esto ayudará a que los clientes te encuentren
                  </Typography>
                  <div className={`scrollBarHidden ${classes.scheduleList}`}>
                    <div className={classes.categoriesContainer}>
                      {categories.map((item, index) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                            />}
                          label={item.label} />
                      ))}
                    </div>
                  </div>
                </div>
                <div className={classes.map}>
                  <Typography className={classes.addressTitle}>Ingresar ubicación</Typography>
                  <Typography className={classes.searchAddress}>Por favor ingrese calle y número exterior</Typography>
                  <Autocomplete
                    id='gAutoC'
                    defaultValue={fiscalAddress}
                    onPlaceSelected={onPlaceSelected}
                    types={['address']}
                    // componentRestrictions={{ country: 'mx' }}
                    fields={['address_components', 'geometry', 'name', 'formatted_address']}
                    className={`autocompleteMapInput`}
                    placeholder='Buscar dirección'
                  />
                  <StoresMap
                    zoom={17}
                    height='300px'
                    center={mapPosition}
                    onDragEnd={markerMoved}
                  />
                </div>
                <div className={classes.buttons}>
                  <SecondaryButton
                    type='button'
                    paddingHorizontal={25}
                    onClick={props.handleClose}
                    secondaryButtonStyle={classes.buttonStyle}
                  >
                    Cancelar
                  </SecondaryButton>
                  <PrimaryButton
                    type='button'
                    paddingHorizontal={25}
                    onClick={createNewStore}
                    primaryButtonStyle={classes.buttonStyle}
                  >
                    Guardar
                  </PrimaryButton>
                </div>
              </div>
            )}
          </div>
        )
      }
    </>
  );
};

export default withSnackbar(ModalNewStore);
